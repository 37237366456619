import React, {useContext, useEffect, useMemo, useState} from "react";
import _ from "lodash";
import AppStore from "../../../store";

const AppointmentContext = React.createContext({
    technicianData: [],
    setTechnicianData: (data) => {
    },
    dataInitBooking: {
        startTime: null,
        technicianId: null
    },
    setDataInitBooking: (data) => {
    },
    zoomCellPxValue: 180,
    setZoomCellPxValue: (data) => {
    },
});
export const AppointmentProvider = ({
                                        date,
                                        branchId,
                                        bookings,
                                        guests,
                                        configAppointment,
                                        technicianWorking,
                                        children,
                                        dataInit,
                                        techData,
                                        firstLoad,
                                        dateSelected,
                                        loadingData,
                                        technicianSchedule,
                                        bookingSchedule,
                                        waitingList,
                                        flatAllData
                                    }) => {
    const [technicianData, setTechnicianData] = useState(techData || []);
    const [dataInitBooking, setDataInitBooking] = useState(dataInit || {});

    const userId = _.get(AppStore.getState(), `root.authData.user.id`, "")
    const zoomValue = localStorage.getItem(`${userId}_zoomValue`);
    const [zoomCellPxValue, setZoomCellPxValue] = useState(zoomValue ? parseInt(zoomValue) : 180);
    const enable = localStorage.getItem(`${userId}_enableDock`);
    const [enableDock, setEnableDock] = useState(enable === "true" ? true : false);

    useEffect(() => {
        techData && setTechnicianData(techData)
    }, [techData])


    const value = useMemo(
        () => ({
            bookings,
            guests,
            date,
            branchId,
            configAppointment,
            technicianWorking,
            technicianData,
            setTechnicianData,
            dataInitBooking,
            setDataInitBooking,
            zoomCellPxValue, setZoomCellPxValue, enableDock, setEnableDock,
            firstLoad,
            dateSelected,
            loadingData,
            technicianSchedule,
            bookingSchedule,
            waitingList,
            flatAllData
        }),
        [bookings, guests, date, branchId,
            configAppointment, technicianWorking,
            technicianData, setTechnicianData,
            dataInitBooking, setDataInitBooking,
            zoomCellPxValue, setZoomCellPxValue,
            enableDock, setEnableDock, firstLoad,
            dateSelected,
            loadingData,
            technicianSchedule,
            bookingSchedule,
            waitingList,
            flatAllData]
    );
    return <AppointmentContext.Provider value={value}>{children}</AppointmentContext.Provider>;
};
export const useAppointmentContext = () => useContext(AppointmentContext);
