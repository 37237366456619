import React from 'react';
import {ContentStepPaymentProvider} from "../context/ContentPaymentContextStep";
import PaymentStep from "./PaymentStep";

const PaymentWrapView = ({
                             bookingId,
                             guestId,
                             onClose,
                             onReloadData,
                             branchId,
                             isDetail,
                             cartIds,
                             billId,
                             step,
                             bodyCart,
                             refFormCart,
                             mainCartId, customerIdCart
                         }) => {
    return (
        <div>
            <ContentStepPaymentProvider vlProps={{
                bookingId, guestId, onClose, onReloadData, branchId, isDetail, cartIds, billId, bodyCart,
                refFormCart, mainCartId, customerIdCart
            }} step={step}>
                <PaymentStep/>
            </ContentStepPaymentProvider>
        </div>

    );
};

export default PaymentWrapView;