import {useMergeState} from "@idtek/component/lib/hook/IDHook";
import _ from "lodash";
import {useEffect} from "react";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";
/* eslint-disable react-hooks/exhaustive-deps */

export const getDetailToInitBill = async (guestId, cartId, bookingId, billId, state, setState) => {
    const res = await AppUtil.postApi(ApiBookingConstant.API_GET_INFO_PAYMET, {
        guestId: _.isArray(guestId) ? guestId : _.isNull(guestId) || _.isUndefined(guestId) ? [] : [guestId],
        cartId: _.isArray(cartId) ? cartId : _.isNull(cartId) || _.isUndefined(cartId) ? [] : [cartId],
        bookingId: bookingId,
    });

    if (_.get(res, "data.success")) {
        const data = _.get(res, "data.result");
        const {guestList, booking, cartList, customerOptions, loyaltyType, isFromSaveBooking} = data;
        const findGuest = _.find(guestList, (x) => x.id === guestId);
        const subBooking = _.sumBy(booking.guests, (x) => x.totalAmount);
        setState({
            booking: booking,
            guest: findGuest,
            guestList: guestList,
            cartList,
            subTotal: _.sumBy(guestList, (x) => x.totalAmount) + _.sumBy(cartList, (x) => x.total),
            subBooking,
            customerOptions,
            loyaltyType,
            //isFromSaveBooking true thi moi duoc xai discount loyalty (guest cung booking)
            isFromSaveBooking,
            loading: false
        });
    }
};


// dùng cho chi tiết, biết billId
export const getDetailBill = async (billId, state, setState) => {
    const res = await AppUtil.postApi(ApiBookingConstant.API_GET_INFO_PAYMET, {
        billId,
        initFirstTime: true
    });
    if (_.get(res, "data.success")) {
        const data = _.get(res, "data.result");
        const {guestList, booking, cartList, customerOptions, loyaltyType, isFromSaveBooking} = data;
        const subBooking = _.sumBy(booking.guests, (x) => x.totalAmount);
        setState({
            booking: booking,
            guestList: guestList,
            cartList,
            subTotal: _.sumBy(guestList, (x) => x.totalAmount) + _.sumBy(cartList, (x) => x.total),
            subBooking,
            customerOptions,
            loyaltyType,
            //isFromSaveBooking true thi moi duoc xai discount loyalty (guest cung booking)
            isFromSaveBooking,
            loading: false
        });
    }
};

const useGetDetailPayment = (guestId, cartId, bookingId, billId, flag = true) => {
    const [state, setState] = useMergeState({
        booking: {},
        guest: {},
        guestList: [],
        cartList: [],
        subTotal: 0,
        subBooking: 0,
        customerOptions: [],
        loading: true,
        loyaltyType: null,
        isFromSaveBooking: false
    });
    useEffect(() => {
        if (flag) {
            if (billId) {
                getDetailBill(billId, state, setState);
            } else {
                getDetailToInitBill(guestId, cartId, bookingId, billId, state, setState);

            }
        }
    }, [bookingId, cartId, guestId]);

    return state;
};
export default useGetDetailPayment;
