import Icon from "@idtek/component/lib/icon/Icon";
import _ from "lodash";
import moment from "moment";
import React, {Component} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../style/date-picker-custom.scss";
import AppUtil from "../../utils/AppUtil";
import vi from "date-fns/locale/vi";

export default class ReactDatePickerCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.parseToDateJs(props.value),
            minDate: this.parseToDateJs(props.minDate),
            maxDate: this.parseToDateJs(props.maxDate),
            open: false
        };
        this.dateFormat = this.handleDateFormat(props.dateFormat ? props.dateFormat : AppUtil.GLOBAL_DATETIME_FORMAT)
        this.disabled = _.get(props, "status") === "Completed" && !props.rightAdmin ? true : false;
        registerLocale("vi", vi)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: this.parseToDateJs(nextProps.value),
                minDate: this.parseToDateJs(nextProps.minDate),
                maxDate: this.parseToDateJs(nextProps.maxDate)
            });
        }
    }

    handleDateFormat(dateFormat) {
        let result = '';
        dateFormat.split('').forEach(letter => {
            if (letter === "D") {
                result += "d";
            } else if (letter === "Y") {
                result += "y";
            } else if (letter === "A") {
                result += "a";
            } else {
                result += letter;
            }
        });
        return result
    }

    parseToDateJs(value) {
        let newValue = null;
        if (value) {
            if (moment(value) instanceof moment) {
                newValue = moment(value).toDate();
            }
            if (typeof value === "string" || value instanceof String) {
                newValue = moment(value).toDate();
            }
        }
        return newValue;
    }

    onChange(date) {
        this.props.onChange && this.props.onChange(moment(date));
        this.setState({
            value: date
        });
    }

    setOpen = () => {
        this.calendar && this.calendar.setOpen(true);
    };

    timeConvert1(data) {
        var minutes = data % 60;
        var hours = (data - minutes) / 60;
        return hours + ":" + ("0" + minutes).slice(-2);
    }

    excludeTimes() {
        const {startHour, endHour} = this.props;
        if (startHour && endHour) {
            const excludeStart = [];
            let [hoursStart, minutesStart] = startHour.split(":");
            for (let i = 0; i <= parseInt(hoursStart); i++) {
                if (i < parseInt(hoursStart)) {
                    for (let j = 0; j <= 60; j++) {
                        const d = new Date();
                        d.setHours(+i); // Set the hours, using implicit type coercion
                        d.setMinutes(j);
                        excludeStart.push(d);
                    }
                } else if (i === parseInt(hoursStart)) {
                    for (let j = 0; j <= parseInt(minutesStart); j++) {
                        const d = new Date();
                        d.setHours(+i); // Set the hours, using implicit type coercion
                        d.setMinutes(j);
                        excludeStart.push(d);
                    }
                }
            }
            const excludeEnd = [];
            let [hoursEnd, minutesEnd] = endHour.split(":");
            for (let k = 23; k >= parseInt(hoursEnd); k--) {
                if (k > parseInt(hoursEnd)) {
                    for (let m = 60; m >= 0; m--) {
                        const d = new Date();
                        d.setHours(+k); // Set the hours, using implicit type coercion
                        d.setMinutes(m);
                        excludeEnd.push(d);
                    }
                } else if (k === parseInt(hoursEnd)) {
                    for (let m = 60; m >= parseInt(minutesEnd); m--) {
                        const d = new Date();
                        d.setHours(+k); // Set the hours, using implicit type coercion
                        d.setMinutes(m);
                        excludeEnd.push(d);
                    }
                }
            }
            return _.concat([], excludeStart, excludeEnd);
        }
        return [];
    }

    render() {
        const {value, minDate, maxDate} = this.state;
        const {showTimeSelect, placeholder, readOnly} = this.props;
        return (
            <div className={`wrap-custom-date ${readOnly ? "readOnly" : ""} `}
                 style={{border: `1px solid var(--main-border-color)`, display: "flex"}}>
                <DatePicker
                    locale={AppUtil.GLOBAL_DATE_LOCALE}
                    className="date-picker-custom"
                    selected={value}
                    onChange={(date) => this.onChange(date)}
                    showTimeSelect={showTimeSelect ? showTimeSelect : false}
                    disabled={this.disabled ? this.disabled : false}
                    dateFormat={this.dateFormat ? this.dateFormat : "MMM/dd/yyyy hh:mm a"}
                    timeIntervals={15}
                    minDate={minDate}
                    maxDate={maxDate}
                    todayButton="Today"
                    placeholderText={placeholder || "Select time..."}
                    ref={(c) => (this.calendar = c)}
                    isClearable={false}
                    readOnly={readOnly}
                    excludeTimes={this.excludeTimes()}
                />
                <span className="icon-field-line" style={{
                    width: "1px",
                    height: "16px",
                    borderLeft: " 1px solid rgb(204, 204, 204)",
                    marginTop: 6
                }}/>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 35,
                    cursor: "pointer"
                }} onClick={this.setOpen}>
                    <Icon type="calendar" style={{fontSize: 16, color: "#0d81c0"}}/>
                </div>
            </div>
        );
    }
}
