import React, {useEffect, useMemo, useState} from 'react';
import Avatar from "@idtek/component/lib/avatar/Avatar";
import {useAppointmentContext} from "../context/AppointmentContext";
import _ from "lodash";

const ResourceHeaderTemplate = ({resourceData}) => {
    const {
        bookingSchedule,
    } = useAppointmentContext();
    const [count, setCount] = useState(0)

    useEffect(() => {
        const serviceByTech = _.filter(bookingSchedule, (x) => x.TechnicianId === resourceData.id);
        const bookingByTech = _.size(_.uniqBy(serviceByTech, "GuestId"));
        setCount(bookingByTech)
    }, [bookingSchedule]);

    return useMemo(() => {
        if (resourceData) {
            let classSex = "any-sex";
            let avatar = resourceData.avatar && resourceData.avatar[0] ?
                <Avatar className={"avatar-tech"}
                        style={{width: 28, height: 28, lineHeight: "28px", marginRight: 7, marginLeft: -10}}
                        src={resourceData.avatar[0].url}/> : null;
            if (resourceData.gender === "FEMALE") {
                classSex = "female-sex";
            } else if (resourceData.gender === "MALE") {
                classSex = "male-sex";
            }
            return (
                <div className={`${classSex} header-technician-cell`}>
                    {avatar}
                    <span className={"label-name"}>{resourceData.name}
                        {resourceData.isFullService && "-FS"}</span>

                    {count > 0 &&
                        <span className="tech-count-booking">{count}</span>}
                </div>
            );
        }
        return <></>;
    }, [count])

};

export default ResourceHeaderTemplate;