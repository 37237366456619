import React, { Component } from 'react';
import Drawer from '@idtek/component/lib/drawer/Drawer';
import '../css/main-drawer.scss';
import Loading from '../../pages/Loading';

class MainDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: true
        };

    }

    open(component, title, width) {
        this.setState({
            title,
            width,
            visible: true,
        });

        setTimeout(() => {
            this.setState({
                component,
                loading: false
            });
        }, 500);
    }

    close() {
        this.setState({
            component: null,
            visible: false
        });
        this.props.onClose && this.props.onClose()
    }


    render() {
        const { visible, title, width, loading, } = this.state;
        const { className } = this.props;
        return (
            <Drawer
                title={title}
                placement="right"
                closable={false}
                onClose={() => this.close()}
                visible={visible}
                width={width || '50%'}
                className={className || "main-drawer"}
            >

                {loading ? <div style={{ height: '100%', width: '100%' }}>
                    <Loading />
                </div> : this.state.component}
            </Drawer>
        );
    }
}

export default MainDrawer;