/* eslint-disable react-hooks/exhaustive-deps */
import {RadioListField} from "@idtek/form/lib";
import _ from "lodash";
import React from "react";
import IconMoney from "../icon/IconMoney";
import {usePaymentContext} from "../context/PaymentContext";
import DiscountLoyalty from "./DiscountLoyalty";
import GiftCardSelect from "./GiftCardSelect";
import PaymentMethodSelect from "./PaymentMethodSelect";
import VoucherSelect from "./VoucherSelect";
import BillInputMoneyDetail from "./BillInputMoneyDetail";
import CheckboxListField from "@idtek/form/lib/checkboxlist/CheckboxListField";
import AddTipComponent from "./AddTipComponent";
import {t} from "@idtek/component/lib/language";
import CheckoutAndInput from "./CheckoutAndInput";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import TenantPricingKeyConstant from "../constant/TenantPricingKeyConstant";

const RightPayment = ({formRef}) => {
    const {
        optionsData,
        paymentData,
        setCustomer,
        resultPayment,
        isDetailPayment,
        setCheckouts,
        tips,
        voucher,
        gift
    } = usePaymentContext();

    const {valueFinally} = resultPayment;
    const rightsPM = _.get(AppStore.getState(), `root.rightsPM`, []);

    const renderComboCustomer = () => {
        return <div className="customer-combo" style={{marginBottom: 5}}>
            <RadioListField name="customerId" options={_.get(paymentData, 'customerOptions')}
                            viewMode={"horizontal"} onChange={onChangeCus} hideError={true}/>
        </div>;
    };

    const onChangeCus = (val) => {
        const cus = _.find(_.get(paymentData, 'customerOptions'), x => x.id === val);
        setCustomer(cus);
    };

    const renderVoucherSelect = () => {
        if (AppUtil.checkPermission(TenantPricingKeyConstant.VOUCHER, rightsPM)) {
            if (!isDetailPayment) {
                return <VoucherSelect/>;
            }
        }
    };

    const renderChoosePaymentMethod = () => {
        return <PaymentMethodSelect/>;
    };

    const renderGiftSelect = () => {
        if (AppUtil.checkPermission(TenantPricingKeyConstant.GIFT, rightsPM)) {
            if (!isDetailPayment) {
                return <GiftCardSelect formRef={formRef}/>;
            }
        }
    };

    const renderRowReceivbleCash = () => {
        return <div className="final-caculate discount-loyalty">
            <div className="sub-total total-in-cash">
                <div className="title-final" style={{width: 130}}>
                    {t("RightPayment-RECEIVABLE_CASH")}
                </div>
                <div className="value-final" style={{color: "var(--main-color-red)", display: 'flex'}}>
                    <div>
                        $ {AppUtil.roundNumber(valueFinally)}
                    </div>
                </div>
            </div>
            {/*<div style={{marginTop: 10}}>*/}
            {/*    <div className="sub-total total-in-cash">*/}
            {/*        <div className="title-final" style={{width: 130}}>*/}
            {/*            <TextTranslate text="Receivable cash"/>*/}
            {/*        </div>*/}
            {/*        <div className="value-final" style={{color: "var(--main-color-red)"}}>*/}
            {/*            $ {valueFinally > 0 ? valueFinally.toFixed(2) : 0}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>;
    };

    const onChangeCheckout = (val) => {
        setCheckouts(_.clone(val))
    };

    /*Chuyển dạng tabs catelory sang checkboxlist*/
    /*
    const renderCheckoutPayment = () => {
        return <div className="text-field">
            <IDField name="typeCheckout" className="receive-money-field" onChange={onChangeCheckout}
                     options={optionsData.checkoutMethods} hideError={true}>
                <CheckoutPaymentSelect/>
            </IDField></div>;

    };
    */

    const renderCheckoutPayment = () => {
        return <div className="text-field">
            <CheckboxListField name="typeCheckout" options={optionsData.checkoutMethods} hideError={true}
                               viewMode={"horizontal"} labelKey={"code"} valueKey={"id"}
                               onChange={(val) => onChangeCheckout(val)} isClearable={false}/>
        </div>;
    };

    const renderInputMoney = () => {
        if (!isDetailPayment) {
            return <CheckoutAndInput formRef={formRef}/>
            // return <BillInputMoney formRef={formRef}/>;
        } else {
            return <BillInputMoneyDetail formRef={formRef}/>;
        }
    };

    const renderDiscountByLoyalty = () => {
        if (AppUtil.checkPermission(TenantPricingKeyConstant.LOYALTY, rightsPM)) {
            if (paymentData.isFromSaveBooking) {
                return <DiscountLoyalty formRef={formRef}/>;
            }
        }
    };

    const renderAddTip = () => {
        if (!isDetailPayment || tips) {
            return <AddTipComponent/>
        }
    }

    return (<div>
        <div className="total">
            <IconMoney/>
            <span className="title">
                {t("RightPayment-PROCEED_PAYMENT")}
            </span>
        </div>
        <div className="children">
            {renderComboCustomer()}
            {renderChoosePaymentMethod()}
            {renderVoucherSelect()}
            {renderDiscountByLoyalty()}
            {renderGiftSelect()}
            {renderRowReceivbleCash()}
            {renderCheckoutPayment()}
            {renderInputMoney()}
            {/*{renderAddTip()}*/}
        </div>
    </div>);
};
export default RightPayment;