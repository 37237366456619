/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, {
    lazy,
    Suspense,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {OPTIONS_ICON} from '../../system/constant/IconPaymentMethodConstant';
import {usePaymentContext} from '../context/PaymentContext';
import AppStore from '../../../store';
import * as Constant from '../../../application/constants/constant';
import {t} from '@idtek/component/lib/language';
import CheckboxField from '@idtek/form/lib/checkbox/CheckboxField';

const IconCash = lazy(() => import('../../system/constant/IconCash'));
const PaymentMethodSelect = () => {
    const {
        optionsData,
        setMethod,
        resultPayment,
        method,
        setIsUseHst,
    } = usePaymentContext();
    const {
        titleRowPM,
        valueRowPM,
        valueRowHST,
        valueRowTotalAmount,
    } = resultPayment;
    const [selected, setSelected] = useState(method ? method.id : optionsData ? _.get(optionsData, 'paymentMethods[0].id') : null);
    const [symbolMoney, setSymbolMoney] = useState(_.find(_.get(AppStore.getState(), `root.branch.branchConfigs`), (x) => x.type === Constant.SYMBOL_MONEY));

    const onSelectMethod = (id, method) => {
        setSelected(id);
        setMethod(method);
    };

    const renderCalculate = () => {
        const title = t(capitalizeFirstLetter(titleRowPM && titleRowPM.toLowerCase()));
        return (
            <div className="final-caculate">
                <div className="sub-total" style={{marginTop: 0}}>
                    <div className="title-final">{title}</div>
                    <div className="value-final">
                        {symbolMoney.value} {valueRowPM !== 0 || valueRowPM ? valueRowPM.toFixed(2) : 0}
                    </div>
                </div>
                <div className="sub-total">
                    <div className="title-final">
                        {t('PaymentMethodSelect-HST')} {optionsData.hst}%
                    </div>
                    <div className="value-final">
                        {symbolMoney.value} {valueRowHST !== 0 || valueRowHST ? valueRowHST.toFixed(2) : 0}
                    </div>
                </div>
                <div className="sub-total">
                    <div className="title-final">
                        {t('PaymentMethodSelect-TOTAL_AMOUNT')}
                    </div>
                    <div className="value-final" style={{color: 'var(--main-color-red)'}}>
                        {symbolMoney.value} {valueRowTotalAmount !== 0 ? valueRowTotalAmount.toFixed(2) : 0}
                    </div>
                </div>
            </div>
        );
    };

    const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    };

    const sortPaymentMethod = useMemo(() => {
        return _.sortBy(optionsData.paymentMethods, (option) => option.order);
    }, [optionsData.paymentMethods]);

    return (
        <div>
            <div className="payment-method">
                <div className="title">
                    {t('PaymentMethodSelect-PAYMENT_METHOD')}
                </div>
                <div className="methods">
                    {_.map(sortPaymentMethod, (item, index) => {
                        const findIcon = _.find(OPTIONS_ICON, (x) => x.value === item.icon);
                        const ComponentIcon = _.get(findIcon, 'component', IconCash);
                        let classActive = '';
                        let styles = {
                            width: '24px',
                            height: '26px',
                        };
                        if (item.id === selected) {
                            if (findIcon.value === 'COIN') {
                                styles.stroke = '#fff';
                            } else {
                                styles.fill = '#fff';
                            }
                            classActive = 'actived';
                        }

                        let title = '';
                        if (item.radio === 'value' && !_.isNull(item.fee) && !_.isUndefined(item.fee) && item.fee !== 0) {
                            title = `${item.fee} ${symbolMoney.value}`;
                        } else if (item.radio === 'percent' && !_.isUndefined(item.percentFee) && item.percentFee !== 0) {
                            title = `${item.percentFee}%`;
                        } else {
                            title = '0$';
                        }

                        return (
                            <div className={`item ${classActive}`} onClick={() => onSelectMethod(item.id, item)}
                                 key={item.id}>
                                <Suspense fallback={<></>}>
                                    <ComponentIcon style={styles}/>
                                </Suspense>
                                <div style={{paddingTop: 5}} className="item-title">{_.get(item, 'name')}</div>
                                <div
                                    className="item-title">{title}</div>
                            </div>
                        );
                    })}
                </div>

                {/*{renderCalculate()}*/}
            </div>
            <div className="text-field hst-check-field" style={{
                marginTop: 0,
                marginBottom: 0,
            }}>
                <CheckboxField name={'isHst'} hideError={true}
                               onChange={(val) => setIsUseHst(val)}/>
            </div>
        </div>
    );
};

export default PaymentMethodSelect;
