import Grid from "@idtek/grid/lib/grid/Grid";
import IDStore from "@idtek/grid/lib/stores/IDStore";
import React, { useRef } from "react";
import { t } from "@idtek/component/lib/language";
import AppUtil from "../../../utils/AppUtil";

const GridBookingCustomerDetail = (segment, guests) => {
    const myGrid = useRef(null)

    const onGridCustomerBookingReady = (data) => {
        if (data) {
            const totalData = [{
                serviceName: t('GridBookingCustomerDetail-TOTAL'),
                price: "$ " + AppUtil.roundNumber(data.totalAmount),
                duration: data.totalTime,
            }]
            myGrid.current.gridApi.setPinnedBottomRowData(totalData)
        }
    }

    const columnDefsBooking = [
        {
            headerName: "GridBookingCustomerDetail-TECHNICIAN",
            field: "technicianName",
            width: 150
        },
        {
            headerName: "GridBookingCustomerDetail-NAME",
            field: "serviceName",
        },
        {
            headerName: "GridBookingCustomerDetail-PRICE",
            field: "price",
            width: 150
        },
        {
            headerName: "GridBookingCustomerDetail-DURATION",
            field: "duration",
            width: 120
        }
    ];

    return (
        <Grid
            numberRows={{
                headerName: t("BaseListView-NO")
            }}
            ref={myGrid}
            // rowSelection={false}
            autoHeightRow={true}
            height="auto"
            minHeight={"0px"}
            pagination={false}
            className={'grid-customer-booking'}
            clsToolbar="base-grid-toolbar"

            store={
                new IDStore({
                    columns: { columnDefsBooking },
                    data: segment.guests.segments,
                })
            }
            onGridReady={() => onGridCustomerBookingReady(segment.guests)}
            globalFilter={false}
            floatingFilter={false}
            columnDefs={columnDefsBooking}
        />
    );
}

export default GridBookingCustomerDetail