import React, { Suspense } from "react";
import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import Grid from "@idtek/grid/lib/grid/Grid";
import _ from "lodash";
import { AddCartFormContext } from "../context/AddCartFormContext";
import { modelGiftCard } from "../../giftCard/model/modelGiftCard";
import BaseListView from "../../../common/baseView/BaseListView";
import GiftCardCreateAddBillForm from "../form/GiftCardCreateAddBillForm";
import { Loading } from "@idtek/component";
import { t } from "@idtek/component/lib/language";
import GiftCardDetailAddBillForm from "../form/GiftCardDetailAddBillForm";
import Alert from "@idtek/component/lib/alert/Alert";
import AppUtil from "../../../utils/AppUtil";

class GridDetailGift extends BaseListView {
  static contextType = AddCartFormContext;

  constructor(props) {
    super(props);
    this.girdStore = new BaseStore({
      data: [],
      autoLoad: true,
    });
    this.apiDelete = "/gift-card/delete";
    this.columnDefs = [
      {
        headerName: t("GridDetailGift-CODE"),
        width: 90,
        field: "code",
        colSpan: function (params) {
          if (params.data && params.data.colSpan > 1) {
            return params.data.colSpan;
          } else {
            return 1;
          }
        },
      },
      {
        headerName: t("GridDetailGift-CONTENT"),
        minWidth: 120,
        field: "content",
      },
      {
        headerName: t("GridDetailGift-RECIPIENT"),
        field: "recipientName",
        width: 100,
      },
      {
        headerName: t("GridDetailGift-RECIPIENT_PHONE"),
        field: "recipientPhone",
        width: 100,
      },
      {
        headerName: t("GridDetailGift-RECIPIENT_EMAIL"),
        field: "recipientEmail",
        width: 140,
      },
      {
        headerName: t("GridDetailGift-PAYABLE_VALUE"),
        width: 100,
        field: "price",
        textAlign: "right",
      },
      {
        headerName: t("GridDetailGift-VALUE_PRINTED_ON_GIFT"),
        width: 110,
        field: "value",
        textAlign: "right",
      },

    ];
    this.heightGrid = "auto"
    this.minHeightGrid = "0px"
    this.cellRenderer = {
      value: {
        renderer: (data, value, rowNode) => {
          if (data.field !== 'pinned')
            return (
              <div className="global-flex-align-center-justify-end">
                $ {value}
              </div>
            );
          return null
        }
      },
      price: {
        renderer: (data, value, rowNode) => {
          return (
            <div className="global-flex-align-center-justify-end">
              $ {value}
            </div>
          );
        }
      },
    }
    this.model = modelGiftCard;
    this.FormModal = GiftCardCreateAddBillForm;
    this.FormModalDetail = GiftCardDetailAddBillForm
    this.hasDelete = false;
    this.hasUpdate = false;
    this.formDefaults = {
      alignLabel: "left",
      widthLabel: "150px"
    };
    this.widthActionRow = 140;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setData(_.get(nextContext, 'giftCardList'))
  }

  componentDidMount() {
    this.myGrid && this.girdStore.setData(_.get(this.context, 'giftCardList'))
  }

  setData(data) {
    this.myGrid && this.girdStore.setData(data)
    this.onGridReady()
  }

  onGridReady = () => {
    if (this.myGrid) {
      const dataGrid = this.girdStore && this.girdStore.getData();
      const sum = _.sumBy(dataGrid, x => x.price)
      const pinned = [{
        code: t("GridDetailGift-TOTAL"),
        price: AppUtil.roundNumber(sum),
        field: "pinned",
        colSpan: 5
      }]
      this.myGrid.gridApi && this.myGrid.gridApi.setPinnedBottomRowData(pinned);
    }
  };

  // event click
  onCreate = () => {
    this.formDefaults["readOnly"] = false;
    const FormModal = this.FormModal;
    this.Modal.onOpen(
      <Suspense fallback={<Loading style={{ height: 300 }} />}>
        <FormModal
          onClose={(flag, dataRow) => {
            this.Modal.onClose()
          }}
          isCreate={true}
          model={this.model}
          formDefaults={{
            alignLabel: "left",
            widthLabel: "150px"
          }}
          initialValues={this.initialValues ? this.initialValues : {}}
          onCreateGiftSuccess={this.props.onCreateGiftSuccess}
        />
      </Suspense>,
      this.renderTitleForm(this.titleCreate + this.moduleName),
      this.popUpWidth
    );
    this.refContainer && this.refContainer.unmask();
  };

  onDeleteRow = async (row) => {
    let confirm = await Alert.Swal_confirm(t("GridDetailGift-CONFIRM"), t("GridDetailGift-ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_RECORD?"), 3);
    if (_.get(confirm, "value") === true) {
      const res = await AppUtil.postApi(`${this.apiDelete}?id=${row[this.keyID]}`);
      if (res && _.get(res.data, "success")) {
        const idDelete = row[this.keyID]
        const data = this.getDataStore()
        const dataIds = _.map(data, x => x.id)
        const dataIdsRest = _.filter(dataIds, x => x !== idDelete)
        this.props.onChangeGift && this.props.onChangeGift(dataIdsRest)
        AppUtil.ToastDeleteSuccess();
      } else {
        AppUtil.ToastDeleteError(t(_.get(res, "data.messages")) || t(_.get(res, "data.message")) || t("GridDetailGift-DATA_ERROR,_PLEASE_TRY_AGAIN!"));
      }
    }
  };
  onEditRow = async (row, rowNode) => {
    this.formDefaults["readOnly"] = false;
    const FormModal = this.FormModal;
    this.Modal.onOpen(
      <Suspense fallback={<Loading style={{ height: 300 }} />}>
        <FormModal
          onClose={(flag, dataRow) => {
            this.onCloseModalForm(flag, dataRow, rowNode);
          }}
          onReloadData={() => {
            this.reLoad();
          }}
          formDefaults={this.formDefaults}
          isCreate={false}
          model={this.model}
          initialValues={{
            ...row
          }}
        />
      </Suspense>,
      this.renderTitleForm(this.titleUpdate + this.moduleName),
      this.popUpWidth
    );
    this.refContainer && this.refContainer.unmask();
  };

  getDataStore() {
    return this.myGrid && this.girdStore.getData()
  }

  renderActions = (row) => {
    // button disable them className: "button-action-row-disabled"
    if (!row || row.field === "pinned") return []
    const buttons = [];
    buttons.push({
      type: "detail",
      onPress: this.onDetailRow,
      icon: {
        type: "info-circle",
        style: { background: "#5e72e4" },
        color: "#ffffff"
      },
      tooltip: t("GridDetailGift-DETAIL"),
    });
    buttons.push({
      type: "edit",
      onPress: this.onEditRow,
      icon: {
        type: "edit",
        style: { background: "#2dce89" },
        color: "#ffffff"
      },
      tooltip: t("GridDetailGift-UPDATE"),
    });
    buttons.push({
      type: "delete",
      onPress: this.onDeleteRow,
      icon: {
        type: "delete",
        style: { background: "var(--main-color-red)" },
        color: "#ffffff"
      },
      tooltip: t("GridDetailGift-DELETE"),
    });
    return buttons;
  };

  render() {
    return (
      <div className="grid-detail-gift">
        <Grid
          ref={grid => (this.myGrid = grid)}
          // className="grid-type-list"
          height={this.heightGrid}
          minHeight={this.minHeightGrid}
          columnDefs={this.columnDefs}
          cellRenderer={this.cellRenderer}
          numberRows={this.defaultNumberRows}
          onGridReady={
            this.onGridReady
          }
          defaultColDef={{
            sortable: false,
            filter: false,
          }}
          emptyComponent={
            <div style={{ height: "0px" }}></div>
          }
          store={this.girdStore}
          floatingFilter={false}
          globalFilter={false}
          suppressRowClickSelection
          actionRows={this.actionRows()}
          rowHeight={28}
          headerVerticalLines={false}
          columnLines={true}
          headerTransparent={true}
          pagination={false}
          headerHeight={60}
          autoHeightHeader={true}
        />
        {this.renderModal()}
      </div>
    );
  }
}

export default GridDetailGift;