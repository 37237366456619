import React, { Component } from "react";
import Form from "@idtek/form/lib/IDForm";
import Icon from "@idtek/component/lib/icon/Icon";
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";
import PasswordField from "@idtek/form/lib/password/PasswordField";
import { t } from "@idtek/component";
import AppUtil from "../../../utils/AppUtil";
import _ from "lodash";

class FormResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.initialValues = {};
        this.formDefaults = {
            alignLabel: "left", widthLabel: "150px"
        };
        this.model = {
            fields: {
                oldPassword: {
                    label: t("FormResetPassword-OLD_PASSWORD"),
                }, password: {
                    label: t("FormResetPassword-NEW_PASSWORD"),
                }, confirmPassword: {
                    label: t("FormResetPassword-CONFIRM_PASSWORD"),
                },
                form: {
                    validate: (values) => {
                        const confirmPassword = _.get(values, 'confirmPassword', "");
                        const password = _.get(values, 'password', "");
                        let object = {}

                        let regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;

                        if (!regExp.test(confirmPassword)) {
                            object = {
                                ...object,
                                confirmPassword: t("FormResetPassword-PASSWORD_MUST_CONTAIN_1_UPPERCASE,_1_LOWERCASE,_1_NUMBER"),
                            };
                        }

                        if (!regExp.test(password)) {
                            object = {
                                ...object,
                                password: t("FormResetPassword-PASSWORD_MUST_CONTAIN_1_UPPERCASE,_1_LOWERCASE,_1_NUMBER"),
                            };
                        }
                        return object;
                    }
                },
            }
        };
    }


    onSave = async (form, values) => {
        if (this.checkValidate(values)) {
            form && form.mask();

            if (_.get(values, "confirmPassword") !== _.get(values, "password")) {
                AppUtil.ToastDeleteError(t("FormResetPassword-CONFIRM_PASSWORD_MUST_MATCH_WITH_THE_NEW_PASSWORD"));
                form && form.unmask();
                return;
            }

            const res = await AppUtil.postApi("/user/update-password", { ...values, userId: this.props.user });
            if (res) {
                if (!_.get(res.data, "success")) {
                    AppUtil.ToastApiError();
                } else {
                    AppUtil.ToastSuccess();
                    this.props.onClose && this.props.onClose();
                }
            } else {
                AppUtil.ToastApiError();
            }
            form && form.unmask();
        }

    }
    checkValidate = (values) => {
        let isValidate = true;
        if (values.confirmPassword !== values.password) {
            this.form.setErrorField("confirmPassword", t("FormResetPassword-CONFIRM_PASSWORD_DOES_NOT_MATCH_THE_PASSWORD,_PLEASE_RETYPE!"));
            isValidate = false;
        }
        return isValidate
    }
    render() {
        const { model, initialValues, formDefaults } = this;
        return (<div className="receive-money-form">
            <Form
                ref={(component) => (this.form = component)}
                initialValues={initialValues}
                model={model}
                buttons={[{
                    text: t("FormResetPassword-SAVE"),
                    className: "btn-success",
                    type: "submit",
                    icon: <Icon type="save" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => this.onSave(form, values),
                }, {
                    text: t("FormResetPassword-CLOSE"),
                    className: "btn-secondary",
                    icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                    onPress: () => this.props.onClose()
                }]}
                defaults={formDefaults}>
                <Row>
                    <Cell>
                        <PasswordField name="oldPassword" />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <PasswordField name="password" />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <PasswordField name="confirmPassword" />
                    </Cell>
                </Row>
            </Form>
        </div>);
    }
}

export default FormResetPassword;