import React, {Component} from 'react';
import App from "./App";
import {LanguageProvider} from "@idtek/component";

class Main extends Component {
    render() {
        return (
            <LanguageProvider defaultLanguage={"en"}>
                <App/>
            </LanguageProvider>
        );
    }
}

export default Main;