import React from 'react';
import GiftCardForm from "../../giftCard/form/GiftCardForm";
import _ from "lodash";
import AppUtil from "../../../utils/AppUtil";
import AppStore from "../../../store";
import {ContentPaymentContextStep} from "../context/ContentPaymentContextStep";

class GiftCardCreateAddBillForm extends GiftCardForm {
    static contextType = ContentPaymentContextStep;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: {
                status: 1,
                code: AppUtil.autoGenGiftCode(),
                branchId: _.get(AppStore.getState(), `root.branch.id`, {}),
                typeCheckout: "CASH",
                ...props.initialValues
            }
        };
    }

    afterSave(form, res) {
        if (!this.isCreate) {
            const {setReloadInit, reloadInit} = this.context
            setReloadInit(reloadInit + 1)
        }
    }

    onSave = async (form, values) => {
        form && form.mask();
        let url = "";
        if (this.isCreate) {
            url = this.apiCreate;
        } else {
            values.id = _.get(this.initialValues, "id");
            url = this.apiUpdate;
        }
        values = this.beforeSave(form, values);
        const res = await AppUtil.postApi(url, values);
        this.afterSave(form, res);
        form && form.unmask();
        if (res) {
            if (!_.get(res.data, "success")) {
                AppUtil.ToastApiError(_.get(res, "data.message"));
            } else {
                AppUtil.ToastSuccess();
                this.isCreate && this.props.onCreateGiftSuccess && this.props.onCreateGiftSuccess(_.get(res.data, "result.id"));
                this.props.onClose && this.props.onClose(this.isCreate, _.get(res.data, "result"));
            }
        } else {
            AppUtil.ToastApiError();
        }
    };

}

export default GiftCardCreateAddBillForm;