import {useMergeState} from "@idtek/component/lib";
import _ from "lodash";
import {useEffect} from "react";
import CalculatePaymentUtil from "../../../utils/CalculatePaymentUtil";

const useCalculatePaymentCart = ({
                                     cartList,
                                     hst,
                                     method,
                                     voucher,
                                     gift,
                                     moneyPoint,
                                     loyaltyType,
                                     objDiscount,
                                     notLoad,
                                     isUseHst
                                 }) => {
    const [state, setState] = useMergeState({
        subTotalPayment: 0,
        titleRowPM: "",
        valueRowPM: 0,
        valueAfterApplyPM: 0,
        valueRowHST: 0,
        valueRowTotalAmount: 0,
        textRowVoucher: "",
        valueRowVoucher: 0
    });

    useEffect(() => {
        const subTotalPayment = _.sumBy(cartList, x => x.total);
        const option = {
            subTotalPayment,
            isUseHst,
            hst,
            method,
            voucher,
            gift,
            moneyPoint,
            loyaltyType,
            objDiscount,
            isOnlyCart: true,
            setState
        }
        CalculatePaymentUtil.calculateAll(option);
    }, [hst, method, voucher, gift, moneyPoint, loyaltyType, objDiscount, cartList, isUseHst])

    return state;
}

export default useCalculatePaymentCart;