import React, {Component} from "react";
import {connect} from "react-redux";
import AppRoute from "../../AppRoute";
import LoginPage from "../../LoginPage";
import "../css/custom.scss";
import "../css/fontExt.css";
import "../css/grid.scss";
import "../css/index.scss";
import "../css/globalButton.scss";
import "antd/dist/antd.css";
import HeaderPage from "./Header";
import SideMenu from "./SideMenu";
import SchedulerView from "../../modules/appointment/view/SchedulerView";
import _ from "lodash";
import Alert from "@idtek/component/lib/alert/Alert";
import {t} from "@idtek/component/lib/language";
// import {refreshToken} from "../../utils/AppUtil";
// import {Button} from "@idtek/component"

class LayoutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isMobile: false
        };
    }

    componentWillMount() {
        const isMobile = this.checkIsMobile();
        if (isMobile) {
            this.setState({
                isMobile: true
            });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!_.get(nextProps, "branch.id")
            && _.get(nextProps, "isAuthenticated")
            && (_.get(this.props, 'location.pathname') !== "/appointment"
                && (!_.get(nextProps, "authData.isTenantSelection") && !_.get(this.props, 'location.pathname').includes("bff"))
            )
        ) {
            this.props && this.props.history.push("/appointment")
        }
    }

    componentDidMount() {
    }

    checkIsMobile() {
        return (
            navigator.userAgent.match(/Android/i) !== null ||
            navigator.userAgent.match(/webOS/i) !== null ||
            navigator.userAgent.match(/iPhone/i) !== null ||
            navigator.userAgent.match(/iPad/i) !== null ||
            navigator.userAgent.match(/iPod/i) !== null ||
            navigator.userAgent.match(/BlackBerry/i) !== null ||
            navigator.userAgent.match(/Windows Phone/i) !== null
        );
    }

    renderLayout() {
        const {allMenuItems, selectKeys, headerMenu} = this.props;
        const {isMobile} = this.state;
        let classWrapHeader = "";
        if (isMobile) {
            classWrapHeader = "header-mobile";
        }
        const menu = SideMenu.findRoute(selectKeys[0], allMenuItems) || {};
        if (_.get(this.props, 'location.pathname') === "/appointment") {
            return (
                <div className="layout-page" style={{flexDirection: "column", height: "100%"}}>
                    <div className={`header-page-v2 ${classWrapHeader}`}>
                        <HeaderPage history={this.props.history} location={_.get(this.props, 'location.pathname')}
                                    headerMenu={headerMenu}/>
                    </div>
                    {/*<Button onClick={() => refreshToken()}>Session Key</Button>*/}
                    {isMobile ? <></> : <SchedulerView/>}
                </div>
            );
        }
        return (
            <div className="layout-page" style={{flexDirection: "column", height: "100%"}}>
                <div className={`header-page-v2 ${classWrapHeader}`}>
                    <HeaderPage history={this.props.history} headerMenu={headerMenu}
                                location={_.get(this.props, 'location.pathname')}/>
                </div>
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        width: "100%",
                        top: "56px",
                        overflow: "hidden"
                    }}>
                    <SideMenu isMobile={isMobile} history={this.props.history}/>
                    <div style={{flex: 1, overflow: "hidden"}}>
                        <AppRoute headerMenu={headerMenu} menuId={menu && menu.id} allMenuItems={allMenuItems}/>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {isAuthenticated, history} = this.props;
        if (isAuthenticated) {
            return this.renderLayout();
        }
        return <LoginPage history={history}/>;
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.root.isAuthenticated,
        selectKeys: state.root.selectKeys,
        allMenuItems: state.root.allMenuItems,
        headerMenu: state.root.headerMenu,
        branch: state.root.branch,
        authData: state.root.authData
    };
};

export default connect(mapStateToProps)(LayoutPage);
