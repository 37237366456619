import React, {useEffect, useState} from 'react';
import AppUtil from "./utils/AppUtil";
import _ from "lodash";
import Loading from "./pages/Loading";
import AppStore from "./store";
import {LOG_OUT_SUCCESSFUL} from "./application/constants/constant";
import {useHistory} from "react-router-dom";

const LogoutPage = () => {
    const history = useHistory()

    useEffect(() => {
        logout();
    }, [])

    const logout = () => {
        history && history.push({
            pathname: "/bff/logout",
            search: `?requestVerificationToken=${AppUtil.getCookie(AppUtil.XSRF_TOKEN_COOKIE_NAME)}`
        })
        AppStore.dispatch({
            type: LOG_OUT_SUCCESSFUL
        });
        window.location.reload();

    };

    return (
        <div style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0}}><Loading/></div>
    )
};

export default LogoutPage;