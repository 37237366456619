import React from 'react'
import {RECEIVE_MONEY, TAKE_MONEY,} from '../constant/BookingConstant'
import {CheckCircleFilled} from '@ant-design/icons'
import AppUtil from '../../../utils/AppUtil'
import Tooltip from '@idtek/component/lib/tooltip/Tooltip'
import moment from 'moment'
import ICON_TOPPED from '../../../application/images/insert_money.png'
import ICON_WITHDRAW from '../../../application/images/withdraw-money.svg'
import ICON_RECEIVE_MONEY from '../../../application/images/rece_money.svg'
import {t} from '@idtek/component/lib/language'
import _ from 'lodash'

const ItemTransaction = ({
                             data,
                             onDetailBill,
                             onDetailOnlyCart,
                             onDetailBooking,
                         }) => {
    const {
        note,
        value,
        actionTime,
        billCode,
        posAction,
        billId,
        bookings,
        carts,
    } = data
    const rowInOutUserHandle = (title, value, icon) => {
        return <div className="item-action">
            <div className="icon">
                <img className={`img-pos icon-receive`} src={icon} alt=""/>
                <div className="pos-status-icon">
                    <CheckCircleFilled style={{color: 'var(--main-color-green)'}}/>
                </div>
            </div>
            <div className="content">
                <div className="row-pos content-tranfer">{title}</div>
                <div
                    className="row-pos action-time"> {` ${moment(data.actionTime).format(AppUtil.GLOBAL_DATETIME_FORMAT)}`}</div>
                <div className="row-pos bottom">
                    {note && note.length >= 30 ? <Tooltip title={note}>
                        <div className={`info-item`}>
                            Note: {note}
                        </div>
                    </Tooltip> : <div className={`info-item`}>
                        {`${t('ItemTransaction-NOTE')}: `}{note}
                    </div>}
                    <div className="value-pos">
                        {value}
                    </div>
                </div>
            </div>
        </div>
    }
    if (!data.billId) {
        //nạp tiền
        if (data.type === RECEIVE_MONEY) {
            return rowInOutUserHandle(t('ItemTransaction-TOPPED_UP_MONEY'), `+ $${value}`, ICON_TOPPED)
        } else if (data.type === TAKE_MONEY) {

            //rút tiền
            return rowInOutUserHandle(t('ItemTransaction-WITHDRAW_MONEY'), value <= 0 ? `$${value}` : `- $${value}`, ICON_WITHDRAW)
        }
        return null
    }
    const itemTake = _.find(posAction, x => x.type === TAKE_MONEY)
    const itemReceive = _.filter(posAction, x => x.type === RECEIVE_MONEY)
    const booking = _.map(bookings, x => x.bookingId)
    return (
        <div className="item-action">
            <div className="icon">
                <img className={`img-pos icon-receive`} src={ICON_RECEIVE_MONEY} alt=""/>
                <div className="pos-status-icon"><CheckCircleFilled style={{color: "var(--main-color-green)"}}/></div>
            </div>
            <div className="content">
                <div className="row-pos content-tranfer">{t(`ItemTransaction-BILL`)}
                    <span className={"link-underline"}
                          onClick={() => {
                              if (!_.isEmpty(booking)) {
                                  onDetailBill && onDetailBill(billId, booking)
                              } else {
                                  onDetailOnlyCart && onDetailOnlyCart(billId, carts)
                              }
                          }}>{` #${billCode} `}</span>
                    {
                        !_.isEmpty(bookings) &&
                        <span>
                            <span className={"item-separator"}>|</span>
                                <span>{`${t('ItemTransaction-BOOKING')}:`}
                                    {
                                        _.map(bookings, (bok, index) => {
                                            const booking = _.get(bok, 'booking')
                                            return <span className={"link-underline"}
                                                         onClick={() => onDetailBooking(booking.id, booking.code)}>
                                            {booking.code}
                                        </span>
                                        })
                                    }
                            </span>
                        </span>

                    }
                </div>
                <div
                    className="row-pos action-time"> {`${moment(actionTime).format(AppUtil.GLOBAL_DATETIME_FORMAT)}`}</div>
                {_.map(itemReceive, item => {
                    return <div className="row-pos bottom">
                        <div
                            className={`info-item`}>{`${t('ItemTransaction-AMOUNT_RECEIVED')} (${AppUtil.capitalizeFirstLetter(_.get(item.checkoutMethod, 'code') && item.checkoutMethod.code.toUpperCase())}) `}</div>
                        <div className="value-pos">{`+ $${item.value}`}</div>
                    </div>
                })}
                {itemTake && itemTake.value > 0 && (<div className="row-pos bottom">
                    <div className={`info-item`}>{t('ItemTransaction-AMOUNT_BACK')}</div>
                    <div className="value-pos">{`${itemTake.value > 0 ? `- $${itemTake.value}` : 0}`}</div>
                </div>)}
            </div>
        </div>)
}

export default ItemTransaction