import _ from "lodash";
import {useEffect, useState} from "react";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";

const useGetBillByBooking = (bookingId) => {
    const [dataBill, setDataBill] = useState(null);
    useEffect(() => {
        const getBillByBooking = async () => {
            const res = await AppUtil.getApi(ApiBookingConstant.API_GET_BILL_BY_BOOKING, {
                bookingId: bookingId
            });
            if (_.get(res, "data.success")) {
                setDataBill(_.get(res, "data.result"));
            }
        };
        getBillByBooking();
    }, [bookingId]);

    return dataBill;
};
export default useGetBillByBooking;
