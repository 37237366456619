import React, {useRef} from 'react';
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";
import TextField from "@idtek/form/lib/text/TextField";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import Icon from "@idtek/component/lib/icon/Icon";
import TextAreaField from "@idtek/form/lib/textarea/TextAreaField";
import ImagePickerField from "@idtek/form/lib/file/ImagePickerField";
import AppUtil from "../../../utils/AppUtil";
import _ from "lodash";
import {t} from '@idtek/component/lib/language';

const GiftCardBodyFormComponent = ({
                                       classCreate, optionSetting, formDefaults, isCreate, onChangeCode, onChangePrice
                                   }) => {
    const textCode = useRef();
    const onGenCode = () => {
        const code = AppUtil.autoGenGiftCode();
        textCode.current && textCode.current.setValue(code);
        onChangeCode && onChangeCode(code)
    }

    return (<div className={`row ${classCreate} style-update-booking`}>
        <Row>
            <Cell>
                {isCreate ? (<TextField
                    ref={textCode}
                    icon={<Tooltip title={t("GiftCardCreateAndSellForm-CLICK_TO_GENERATE_RANDOM_ANOTHER_GIFT_CODE")}>
                        <Icon onClick={() => onGenCode()} type="sync"
                              style={{fontSize: 16, marginBottom: 5}}/>
                    </Tooltip>}
                    onChange={onChangeCode}
                    name="code"
                    upperCase={true}
                />) : (<TextField visibleIcon={false} name="code" readOnly={true}/>)}
            </Cell>
            <Cell>
                <TextField name="recipientName"/>
            </Cell>
        </Row>
        <Row>

            <Cell>
                <TextField
                    name="recipientPhone"
                    type="phone"
                    options={optionSetting}
                />
            </Cell>
            <Cell>
                <TextField name="recipientEmail" type="email"/>
            </Cell>
        </Row>
        <Row>
            <Cell>
                <TextField type="number" name="price"
                           onChange={onChangePrice}
                           readOnly={!!formDefaults["readOnly"]}/>
            </Cell>
            <Cell>
                <TextField type="number" name="value" readOnly={!!formDefaults["readOnly"]}/>
            </Cell>
        </Row>
        <Row>
            <Cell>
                <TextAreaField name="content" minHeight={80}/>
            </Cell>
            <Cell>
                <ImagePickerField display={{size: 80}} name={"imageFile"}/>
            </Cell>
        </Row>
    </div>);
};

export default GiftCardBodyFormComponent;