export class DataUtil {
    static isUndefined(value) {
        return value === undefined;
    }

    static isNull(value) {
        return value === null;
    }

    static isBoolean(value) {
        return typeof value === 'boolean';
    }

    static isObject(value) {
        return value === Object(value);
    }

    static isArray(value) {
        return Array.isArray(value);
    }

    static isDate(value) {
        return value instanceof Date;
    }


    static isBlob(value) {
        return (
            (this.isObject(value) &&
                typeof value.size === 'number' &&
                typeof value.type === 'string' &&
                typeof value.slice === 'function')
        );
    }


    static isFile(value) {
        return (
            this.isBlob(value) &&
            typeof value.name === 'string' &&
            (this.isObject(value.lastModifiedDate) || typeof value.lastModified === 'number')
        );
    }


    static initCfg(value) {
        return this.isUndefined(value) ? false : value;
    }

    static romanize = (num) => {
        if (isNaN(num)) return NaN;
        let digits = String(+num).split(""),
            key = [
                "",
                "C",
                "CC",
                "CCC",
                "CD",
                "D",
                "DC",
                "DCC",
                "DCCC",
                "CM",
                "",
                "X",
                "XX",
                "XXX",
                "XL",
                "L",
                "LX",
                "LXX",
                "LXXX",
                "XC",
                "",
                "I",
                "II",
                "III",
                "IV",
                "V",
                "VI",
                "VII",
                "VIII",
                "IX",
            ],
            roman = "",
            i = 3;
        while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    };


    static serializeToFromData = (obj, cfg, fd, pre) => {
        cfg = cfg || {};
        fd = fd || new FormData();

        cfg.indices = this.initCfg(cfg.indices);
        cfg.nullsAsUndefined = this.initCfg(cfg.nullsAsUndefined);
        cfg.booleansAsIntegers = this.initCfg(cfg.booleansAsIntegers);
        cfg.allowEmptyArrays = this.initCfg(cfg.allowEmptyArrays);
        cfg.noFilesWithArrayNotation = this.initCfg(cfg.noFilesWithArrayNotation);
        cfg.dotsForObjectNotation = this.initCfg(cfg.dotsForObjectNotation);
        if (this.isUndefined(obj)) {
            return fd;
        } else if (this.isNull(obj)) {
            if (!cfg.nullsAsUndefined) {
                fd.append(pre, '');
            }
        } else if (this.isBoolean(obj)) {
            if (cfg.booleansAsIntegers) {
                fd.append(pre, obj ? 1 : 0);
            } else {
                fd.append(pre, obj);
            }
        } else if (this.isArray(obj)) {
            if (obj.length) {
                obj.forEach((value, index) => {
                    let key = pre + '[' + (cfg.indices ? index : '') + ']';

                    if (cfg.noFilesWithArrayNotation && this.isFile(value)) {
                        key = pre;
                    }

                    this.serializeToFromData(value, cfg, fd, key);
                });
            } else if (cfg.allowEmptyArrays) {
                fd.append(pre + '[]', '');
            }
        } else if (this.isDate(obj)) {
            fd.append(pre, obj.toISOString());
        } else if (this.isObject(obj) && !this.isBlob(obj)) {
            Object.keys(obj).forEach((prop) => {
                const value = obj[prop];

                if (this.isArray(value)) {
                    while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
                        prop = prop.substring(0, prop.length - 2);
                    }
                }

                const key = pre
                    ? cfg.dotsForObjectNotation
                        ? pre + '.' + prop
                        : pre + '[' + prop + ']'
                    : prop;

                this.serializeToFromData(value, cfg, fd, key);
            });
        } else {
            fd.append(pre, obj);
        }
        return fd;
    }
}