import {Popover} from "@idtek/component/lib";
import _ from "lodash";
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef} from "react";
import AppUtil from "../../../utils/AppUtil";
import InputSearch from "./InputSearch";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import {useMergeState} from "@idtek/component/lib/hook/IDHook";
import OptionRowPhone from "./OptionRowPhone";
import '../style/search-phone.scss'
import AppStore from "../../../store";
import * as Constant from "../../../application/constants/constant";

const ComboPhoneComponent = forwardRef(({
                                            onSetErrorField,
                                            onChange,
                                            value,
                                            branchId,
                                            readOnly,
                                            name
                                        }, ref) => {
    const [state, setState] = useMergeState({
        visible: false,
        objSelect: value || null,
        options: [],
        allOptions: [],
    });


    const optionPhoneSetting = AppUtil.RegexPhone(
        _.find(_.get(AppStore.getState(), `root.branch.branchConfigs`), (x) => x.type === Constant.PHONE_SET_UP).value);
    const inputRef = useRef(null)
    useEffect(() => {
        onSearch("", true);
    }, []);

    const onSearch = async (phoneSearch, didmount = false) => {
        const res = await AppUtil.postApi(ApiBookingConstant.API_LOAD_COMBO_CUSTOMER, {
            start: 0,
            limit: 100,
            query: phoneSearch,
            branchId
        });
        let opt = _.cloneDeep(options)
        if (_.get(res, "data.success")) {
            opt = _.map(_.get(res, "data.result"), (x) => {
                return {
                    ...x,
                    label: AppUtil.formatPhoneByBlock(x.phone, optionPhoneSetting ? optionPhoneSetting.blocks : [3, 3, 4]) + " - " + x.name
                };
            });
            const objState = {
                options: opt,
            }
            if (didmount) {
                objState.allOptions = opt
            }
            setState(objState);
        }
        if (!phoneSearch && !didmount) {
            onChange && onChange("");
        }

        const lengthPhone = _.sum(_.get(optionPhoneSetting, 'blocks', 10))

        if (_.isEmpty(opt) && phoneSearch.length === lengthPhone) {
            onChange && onChange({phone: phoneSearch});
        } else if (opt[0] && phoneSearch.length === lengthPhone) {
            onSelected && onSelected(opt[0]);
        }
        if (phoneSearch && phoneSearch.length < lengthPhone) {
            onSetErrorField && onSetErrorField("phone", `Please input ${lengthPhone} digits phone number`);
        }

    };

    const handleVisibleChange = (visible) => {
        setState({
            visible: visible
        });
    };

    const onSelected = (selected) => {
        setState({
            objSelect: selected,
            visible: false
        });
        inputRef.current && inputRef.current.setValue(selected.phone);
        onChange && onChange(selected);
    };


    const {objSelect, visible, options, widthPopover} = state;
    return useMemo(() => {
        return (
            <div className="search-cpn-phone">
                <Popover
                    trigger="click"
                    placement="bottomLeft"
                    overlayClassName="search-contact-overlay"
                    getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                    content={
                        <div style={{width: widthPopover || 300}}>
                            {_.size(options) > 0 ? (
                                _.map(options, (option, index) => <OptionRowPhone
                                    key={`option-${index}`} data={option}
                                    onSelected={() => onSelected(option)}/>)
                            ) : (
                                <></>
                            )}
                        </div>
                    }
                    visible={visible}
                    onVisibleChange={handleVisibleChange}>
                    <InputSearch
                        ref={inputRef}
                        value={_.get(objSelect, "phone")}
                        onOpen={(widthPopover) => {
                            setState({
                                visible: true,
                                widthPopover: widthPopover
                            });
                        }}
                        readOnly={readOnly}
                        onChange={_.debounce(onSearch, 500)}
                    />
                </Popover>

            </div>
        );
    }, [visible, options, objSelect, widthPopover]);
});
export default ComboPhoneComponent;
