import React, {useEffect} from 'react';
import {useMergeState} from "@idtek/component/lib/hook/IDHook";
import AppUtil from "../../../utils/AppUtil";
import ApiCartConstant from "../constant/ApiCartConstant";
import _ from "lodash";

export const getDetailPaymentCart = async (mainCartId, cartIds, setState) => {
    const res = await AppUtil.postApi(ApiCartConstant.API_CART_GET_INFO_PAYMENT, {
        mainCartId,
        cartIds
    })
    if (_.get(res, 'data.success')) {
        const data = _.get(res, "data.result");
        setState({
            loyaltyType: data.loyaltyType,
            cartList: data.cartList,
            customerOptions: data.customerOptions,
            loading: false
        })
    }
};
/// xây dựng trước cho trường hợp thanh toán nhiều cart
const useGetDetailCartPayment = (mainCartId, cartIds, flag = true) => {
    const [state, setState] = useMergeState({
        cartList: [],
        customerOptions: [],
        loading: true,
        loyaltyType: null,
    });

    useEffect(() => {
        if (flag) {
            getDetailPaymentCart(mainCartId, cartIds, setState);
        }
    }, [mainCartId, cartIds]);

    return state;
};

export default useGetDetailCartPayment;