/* eslint-disable react-hooks/exhaustive-deps */

import Collapse from "@idtek/component/lib/collapse/Collapse";
import Icon from "@idtek/component/lib/icon/Icon";
import IDField from "@idtek/form/lib/IDField";
import _ from "lodash";
import React, { useRef } from "react";
import InputGiftComponent from "./InputGiftComponent";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import { usePaymentContext } from "../context/PaymentContext";
import { t } from "@idtek/component/lib/language";
import AppUtil from "../../../utils/AppUtil";

const GiftCardSelect = ({ formRef }) => {
  const {
          gift,
          branchId,
          setGift,
          customer,
          resultPayment,
        } = usePaymentContext();
  const refInputGift = useRef(null);
  const onCollapseChange = (collapse) => {
    const visible = !_.isEmpty(collapse) ? true : false;
    if (_.isEmpty(gift) && visible) {
      refInputGift.current && refInputGift.current.handleVisibleChange(true, true);
    } else {
      refInputGift.current && refInputGift.current.handleVisibleChange(false, true);
    }
  };

  const onChangeGift = (gift, setError) => {
    if (setError) {
      onCollapseChange(false);
      AppUtil.ToastApiError(t("GiftCardSelect-THIS_CODE_IS_INVALID!_TRY_AGAIN!"));
      // formRef.current && formRef.current.setValueField("giftCode", "");
    }
    setGift(gift);
  };

  const renderRowGift = () => {
    const {
            valueRowGift,
            textRowGift,
          } = resultPayment;
    if (gift) {
      return (
        <div className="final-caculate row-gift">
          <div className="sub-total">
            <div className="title-final" style={{
              display: "flex",
              alignItems: "center",
            }}>
              {`${textRowGift}`}
            </div>
            {!_.isNull(valueRowGift) && !_.isUndefined(valueRowGift) &&
              <div className="value-final">$ -{valueRowGift.toFixed(2)}</div>}
          </div>
        </div>
      );
    }
  };

  return <div className="gift-card-field voucher-field">
    <Collapse
      ghost
      onChange={onCollapseChange}
      className="collapse-cpn"
      expandIconPosition="right"
      defaultActiveKey={_.get(gift, "code") ? ["1"] : null}
      expandIcon={({ isActive }) => <Icon type="caret-right" style={{
        fontSize: 16,
        color: "#026eaa",
      }}
                                          rotate={isActive ? 90 : 0} />}>
      <Collapse.Panel
        header={
          <div className="total" style={{ marginBottom: 0 }}>
            <div className="icon-gift">
              <i class="fa fa-gift" aria-hidden="true" />
            </div>
            <span className="title">
                            {t("GiftCardSelect-GIFT_CARD")}
                        </span>
          </div>
        }
        key="1">
        <div className="gift-input">
          <IDField widthLabel={120} name="giftCode" onChange={onChangeGift}>
            <InputGiftComponent ref={refInputGift} listSuggestion={_.get(customer, "giftCards")}
                                code={_.get(gift, "code")} gift={gift}
                                apiCheckCode={ApiBookingConstant.API_CHECK_GIFT} branchId={branchId}
                                customerId={_.get(customer, "id")} />
          </IDField>
          {/*{renderRowGift()}*/}
        </div>
      </Collapse.Panel>
    </Collapse>
  </div>;
};

export default GiftCardSelect;