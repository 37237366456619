import React, {useContext, useMemo} from "react";

export const AddCartFormContext = React.createContext({
    booking: {},
    guestList: [],
    segmentList: [],
    giftCardList: [],
    productList: [],
    isDetail: false
});

export const AddCartFormProvider = ({
                                        booking,
                                        guestList,
                                        segmentList,
                                        giftCardList,
                                        productList,
                                        isDetail,
                                        totalAmountGuest,
                                        children
                                    }) => {

    const value = useMemo(() => ({
        booking,
        guestList,
        segmentList,
        giftCardList,
        productList,
        isDetail,
        totalAmountGuest,
    }), [
        booking,
        guestList,
        segmentList,
        giftCardList,
        productList,
        isDetail,
        totalAmountGuest,
    ]);
    return (
        <AddCartFormContext.Provider value={value}>
            {children}
        </AddCartFormContext.Provider>
    );
};

export const useAddCartFormContext = () => useContext(AddCartFormContext);
