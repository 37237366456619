import { DisplayField, SwitchField, TextAreaField } from '@idtek/form/lib';
import React, { Fragment } from 'react';
import BaseForm from '../../../common/baseForm/BaseForm';
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";
import Icon from '@idtek/component/lib/icon/Icon';
import ImagePickerField from "@idtek/form/lib/file/ImagePickerField";
import {
    PRODUCT_ACTIVE
} from "../contant/ProductActiveContant";
import { t } from '@idtek/component/lib/language';

class ProductDetailForm extends BaseForm {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: props.initialValues
        }
        this.apiDetail = "/product/show-detail-product"
        this.buttons = [
            {
                text: t("ProductDetailForm-CLOSE"),
                className: "btn-secondary",
                icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                onPress: () => this.props.onClose()
            }
        ]
    }

    beforeSetValues = async (values) => {
        if (values.status === PRODUCT_ACTIVE) {
            values.status = true;
            return values;
        }
        values.status = false;
        return values;
    }

    renderBody() {
        return (
            <Fragment>
                <Row>
                    <Cell>
                        <DisplayField name="code" upperCase={true} required={false} />
                    </Cell>
                    <Cell>
                        <DisplayField name="name" required={false} />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <DisplayField name="price" required={false} />
                    </Cell>
                    <Cell>
                        <SwitchField name="status" />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <ImagePickerField name={"image"} />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextAreaField name="description" readOnly={true} />
                    </Cell>
                </Row>
            </Fragment>
        );
    }
}

export default ProductDetailForm;