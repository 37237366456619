import React from 'react';
import Result from '@idtek/component/lib/result/Result';
import {connect} from "react-redux";
import {t} from '@idtek/component/lib/language';

const NotPermission = (props) => {
    const {
        text, height,
        status, subTitle, title,
        hideBack, headerMenu, href, style,
        isNotShowButton
    } = props;
    return (
        <div style={
            Object.assign({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: height ? height : "100%",
                // width: '100%',
                flexDirection: 'column',
                zIndex: 2,
                backgroundColor: '#fff',
                margin: "10px 10px 0px 10px",
                borderRadius: '3px'
            }, style)}>
            <Result
                status={status || "403"}
                title={title || t("HolidayOffView-ACCESS_DENIED")}
                subTitle={subTitle || t("HolidayOffView-SORRY,_BUT_YOU_DON'T_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE")}
                extra={hideBack ? null : <div style={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {!isNotShowButton && <a
                        className="ant-btn ant-btn-primary"
                        href={href || `/`}
                        style={{
                            fontSize: 16, display: 'flex', alignItems: 'center',
                        }}>
                        {text || t("NotPermisstion-GO_TO_HOMEPAGE")}
                    </a>}
                </div>}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    const headerMenu = state.root.headerMenu;
    return {
        headerMenu: headerMenu
    };
};

export default connect(
    mapStateToProps
)(NotPermission);

