import Textbox from "@idtek/component/lib/textbox/Textbox";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import {
    closest,
    isNullOrUndefined,
} from "@syncfusion/ej2-base";
import {
    ContextMenuComponent,
    SidebarComponent,
    TreeViewComponent,
} from "@syncfusion/ej2-react-navigations";
import _ from "lodash";
import moment from "moment";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
} from "react";
import AppUtil from "../../../utils/AppUtil";
import TreeUtil from "../../../utils/TreeUtil";
import {useAppointmentContext} from "../context/AppointmentContext";
import {BookingStatusContant} from "../constant/BookingStatusContant";
import AppStore from "../../../store";
import {t} from "@idtek/component";

const menuItems = [
    {
        text: t("ClipboardAppointment-UPDATE_BOOKING"),
        iconCss: "e-icons update",
        id: "update",
    },
    {
        text: t("ClipboardAppointment-CONFIRM_BOOKING"),
        iconCss: "e-icons confirm",
        id: "confirm",
    },
];
const ClipboardAppointment = forwardRef(({
                                             onTreeDragStop,
                                             onItemDrag,
                                             onTreeDragStart,
                                             onUpdateBooking,
                                             onUpdateBookingNew,
                                             onReloadData,
                                             openOverlay
                                         }, ref) => {
    const {configAppointment, enableDock,waitingList} = useAppointmentContext();
    const userId = _.get(AppStore.getState(), `root.authData.user.id`, "")
    const sidebarobj = useRef(null),
        btnCollapse = useRef(null),
        textBox = useRef(null),
        treeObj = useRef(null),
        refMenuContext = useRef(null),
        refData = useRef(waitingList)
    let isOpen = false;

    useImperativeHandle(ref, () => ({
        getTreeInstance() {
            return treeObj.current;
        },
        getTreeData(id) {
            return treeObj.current && treeObj.current.getTreeData(id);
        },

    }));

    useEffect(() => {
        sidebarobj.current.isOpen = enableDock;
        localStorage.setItem(`${userId}_enableDock`, enableDock.toString())
    }, [enableDock])

    useEffect(() => {
        if (treeObj.current) {
            const compareArr = _.differenceWith(waitingList, getDataClipboard(), _.isEqual);
            const compareArr2 = _.differenceWith(getDataClipboard(), waitingList, _.isEqual);
            if (!_.isEmpty(compareArr) || !_.isEmpty(compareArr2)) {
                treeObj.current.fields.dataSource = waitingList;
                refData.current = waitingList
                if (textBox.current.getValue()) {
                    _onSearch(textBox.current.getValue())
                }
            }

        }
    }, [waitingList])

    const translateMenuItem = () =>{
        return _.map(menuItems, item => {
            return {
                ...item,
                text: t(item.text)
            }
        })
    }

    const sidebarChange = () => {
        if (isOpen !== sidebarobj.current.isOpen) {
            btnCollapse.current && btnCollapse.current.setOpen(sidebarobj.current.isOpen);
            isOpen = sidebarobj.current.isOpen;
        }
    };

    const onClickButton = () => {
        sidebarobj.current.toggle();
    };

    const _onSearch = (value) => {
        const arrSearch = [];
        if (treeObj.current) {
            if (value) {
                const data = refData.current
                console.log('Function: _onSearch -> data: ', data);
                _.each(data, (root) => {
                    const bodau = AppUtil.boDauTiengViet(value);
                    if (
                        AppUtil.boDauTiengViet(_.get(root, "customerName")).indexOf(bodau) > -1 ||
                        AppUtil.boDauTiengViet(_.get(root, "serviceName")).indexOf(bodau) > -1 ||
                        AppUtil.boDauTiengViet(_.get(root, "status")).indexOf(bodau) > -1 ||
                        AppUtil.boDauTiengViet(_.get(root, "bookingCode")).indexOf(bodau) > -1
                    ) {
                        arrSearch.push(root);
                    }
                });
                treeObj.current.fields.dataSource = arrSearch;
            } else {
                treeObj.current.fields.dataSource = refData.current;
            }
        }
    };

    const onActionTreeDragStop = (args) => {
        onTreeDragStop && onTreeDragStop(args);
    };
    const onActionItemDrag = (args) => {
        onItemDrag && onItemDrag(args);
    };
    const onActionTreeDragStart = (args) => {
        onTreeDragStart && onTreeDragStart(args);
    };

    const nodeclicked = (args) => {
        const uid = args.node.getAttribute("data-uid");
        if (args.event.which === 3) {
            treeObj.current.selectedNodes = [uid];
        }
    };

    const onContextMenuBeforeOpen = (args) => {
        let targetElement = args.event.target;
        const selectedTarget = closest(targetElement, ".booking-wait-list,.time,.booking");
        refMenuContext.current.hideItems(["confirm"], true);
        if (isNullOrUndefined(selectedTarget)) {
            args.cancel = true;
            return;
        }
        const targetNodeId = treeObj.current.selectedNodes[0];
        const find = _.find(waitingList, (x) => x.id.toString() === targetNodeId);
        if (find && find.Status === BookingStatusContant.STATUS_NEW) {
            refMenuContext.current.showItems(["confirm"], true);
        }
    };

    const getDataClipboard = () => {
        return _.get(treeObj.current, 'fields.dataSource')
    }

    const onMenuItemSelect = async (args) => {
        let targetNodeId = treeObj.current.selectedNodes[0];
        if (targetNodeId) {
            let selectedMenuItem = args.item.id;
            const find = _.find(getDataClipboard(), (x) => x.id.toString() === targetNodeId);
            if (find) {
                if (selectedMenuItem === "update") {
                    if (find.status !== BookingStatusContant.STATUS_NEW) {
                        const guest = _.map(find.segments, x => x.GuestId)
                        console.log('Function: onMenuItemSelect -> find: ', find);
                        onUpdateBooking && onUpdateBooking(find.bookingId, guest, find.bookingCode, find.status, false, "clipboard");
                    } else {
                        onUpdateBookingNew && onUpdateBookingNew(find.bookingId, find.bookingCode, find.status, false, "clipboard");
                    }
                }
                // if (selectedMenuItem === "confirm") {
                //     let confirm = await Alert.Swal_confirm("CONFIRM", "Are you sure you want to confirm this booking?", 3);
                //     if (_.get(confirm, "value") === true) {
                //         const url = "/booking/save?id=" + find.bookingId;
                //         const data = {...find};
                //         const bookingItems = _.map(data.bookingItems, (item) => {
                //             return {
                //                 service_id: item.service_id ? item.service_id : null,
                //                 technician: item.technician_id ? item.technician_id : null,
                //                 startTime: item.startTime ? item.startTime : null,
                //                 endTime: item.endTime ? item.endTime : null
                //             };
                //         });
                //
                //         data.bookingItems = bookingItems;
                //         data.status = "Waiting";
                //         const dataPost = {};
                //         _.mapKeys(data, (value, key) => {
                //             if (key && key.substring(0, 1) && key.substring(0, 1).toUpperCase() !== key.substring(0, 1)) {
                //                 dataPost[key] = value;
                //             }
                //         });
                //         openOverlay && openOverlay();
                //         const res = await AppUtil.postApi(url, dataPost);
                //         if (_.get(res, "data.success")) {
                //             onReloadData && onReloadData();
                //         } else {
                //             AppUtil.ToastApiError();
                //         }
                //     }
                // }
            }
        }
    };
    const treeTemplate = (props) => {
        let classSex = "";
        // switch (Sex) {
        //     case "MALE":
        //         classSex = "node-male-sex";
        //         break;
        //     case "FEMALE":
        //         classSex = "node-female-sex";
        //         break;
        //     default:
        // }
        const {
            // createdTime,
            startTime,
            serviceCode,
            // name,
            phone,
            bookingCode,
            status,
            customerName,
            serviceName,
            duration
        } = props;

        let classBookingOnline = "booking-waiting";
        if (props.status === BookingStatusContant.STATUS_NEW) {
            classBookingOnline = "booking-online";
        }

        if (!props.guestId) {
            return (
                <div id="waiting">
                    <Tooltip
                        title={
                            <div className="tooltip-wrap-custom tooltip-tree">
                                <div
                                    className="row-tt subject">{`${`${t("ClipboardAppointment-BOOKING_CODE")}: `} ${bookingCode} `}</div>
                                <div className="row-tt phone">
                                    <span className="sub-title">{`${t("ClipboardAppointment-STATUS")}: `}</span>
                                    <span className={`status-tooltip ${status}`}>{status}</span>
                                </div>
                                <div className="row-tt customer">
                                    <span className="sub-title">{`${t("ClipboardAppointment-CUSTOMER")}: `} </span>
                                    <span>{customerName}</span>
                                </div>
                                <div className="row-tt phone">
                                    <span className="sub-title">{`${t("ClipboardAppointment-PHONE")}: `}</span>
                                    <span>{phone}</span>
                                </div>
                                {/* <div className="row-tt time">
                                    <span className="sub-title">{endTime ? "Time: " : "Start time: "}</span>
                                    {startTime && endTime && (
                                        <span style={{ paddingLeft: 10 }}>{moment(startTime).format("hh:mm A") + " - " + moment(endTime).format("hh:mm A") + " " + moment(endTime).format(AppUtil.GLOBAL_DATE_FORMAT)}</span>
                                    )}
                                    {startTime && !endTime && <span> {moment(startTime).format(AppUtil.GLOBAL_DATETIME_FORMAT)}</span>}
                                </div> */}
                                {/* 
                                {Sex && (
                                    <div className="row-tt phone">
                                        <span className="sub-title">Priority: </span>
                                        <span>{Sex}</span>
                                    </div>
                                )} */}
                            </div>
                        }
                        placement={sidebarobj.current.isOpen ? "top" : "right"}>
                        <div className={`waitdetails ${classSex}`} id="waitdetails">
                            <div className={`show-time booking ${classBookingOnline}`}>
                                <span className="date">{moment(startTime).format("MMM/DD")}</span>
                                <span className="time">{moment(startTime).format("hh:mm A")}</span>
                            </div>
                            <div id="waitlist" className={`waitlist ${!props.guestId ? "booking-wait-list" : ""}`}>
                                {bookingCode}: {customerName}({phone})
                            </div>
                        </div>
                    </Tooltip>
                </div>
            );
        }
        return (
            <div id="waiting">
                <div className={`waitdetails ${classSex}`} id="waitdetails">
                    <div className="show-time">
                        <span className="circle">
                            <div className="ant-timeline-item-head ant-timeline-item-head-blue custom-lmt-head"></div>
                        </span>
                    </div>
                    <div id="waitlist" className={`waitlist ${!props.guestId ? "booking-wait-list" : ""}`}>
                        {serviceName} - {duration} mins
                    </div>
                    {serviceCode && (
                        <div id="waitlist" className="waitlist none" style={{display: "none"}}>
                            {serviceCode}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return useMemo(() => {
        return (
            <div className="treeview-container">
                <SidebarComponent
                    id="sidebar-menu"
                    ref={sidebarobj}
                    enableDock={false}
                    mediaQuery={"(min-width: 400px)"}
                    dockSize={"72px"}
                    width={"180px"}
                    target={".main-dashboard"}
                    closeOnDocumentClick={false}
                    type={"Push"}
                    zIndex={0}
                    change={sidebarChange}
                    enableGestures={false}
                >
                    <div className="main-menu">
                        <div className="top-menu">
                            {/*<ButtonCollapse ref={btnCollapse} onClick={onClickButton}/>*/}
                            <Textbox iconType="search" ref={textBox} isClearable={true}
                                     onChange={_.debounce(_onSearch, 200)}
                                     placeholder={`${t("ClipboardAppointment-SEARCH")}...`}
                                     className="search-input "/>
                        </div>
                        <div className="main-menu-header">
                            {/*<div className="collapse-icon">*/}
                            {/*    <Tooltip title="Expand all">*/}
                            {/*        <div className="expand" onClick={() => treeObj.current && treeObj.current.expandAll()}>*/}
                            {/*            <Icon type="column-height"/>*/}
                            {/*        </div>*/}
                            {/*    </Tooltip>*/}
                            {/*    <Tooltip title="Collapse all">*/}
                            {/*        <div className="collapse-all"*/}
                            {/*             onClick={() => treeObj.current && treeObj.current.collapseAll()}>*/}
                            {/*            <Icon type="vertical-align-middle"/>*/}
                            {/*        </div>*/}
                            {/*    </Tooltip>*/}
                            {/*</div>*/}
                            <span>{t("ClipboardAppointment-CLIPBOARD")}</span>
                        </div>
                        {/*{_.isEmpty(waitingList) ? (*/}
                        {/*    <div className="global-flex-justify-align-center empty-tree-waiting">*/}
                        {/*        <Empty/>*/}
                        {/*    </div>*/}
                        {/*) : (*/}
                        <>
                            <TreeViewComponent
                                ref={treeObj}
                                cssClass="treeview-external-drag"
                                dragArea=".main-dashboard"
                                nodeTemplate={treeTemplate}
                                fields={{
                                    dataSource: waitingList,
                                    id: "id",
                                    text: t("ClipboardAppointment-NAME"),
                                    child: "segments",
                                    expanded: "expanded",
                                    parentID: "guestId",
                                }}
                                height={"calc(100vh - 145px)"}
                                nodeDragStop={onActionTreeDragStop}
                                nodeDragStart={onActionTreeDragStart}
                                nodeDragging={onActionItemDrag}
                                allowDragAndDrop={true}
                                allowMultiSelection={false}
                                fullRowSelect={false}
                                nodeClicked={nodeclicked}
                                expandOn={"none"}
                            />
                            <ContextMenuComponent cssClass="tree-context-menu schedule-context-menu"
                                                  ref={refMenuContext}
                                                  target=".e-treeview"
                                                  items={translateMenuItem()} beforeOpen={onContextMenuBeforeOpen}
                                                  select={onMenuItemSelect}/>
                        </>
                        {/*)}*/}
                    </div>
                </SidebarComponent>
            </div>
        );
    }, []);
});

export default ClipboardAppointment;
