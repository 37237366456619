import React, {forwardRef, useImperativeHandle} from "react";
// import "antd/dist/antd.css";
import {Modal} from "antd";
import '../style/alert-antd-idbeauty.scss'

const AlertAntd = forwardRef((props, ref) => {
    const [modal, contextHolder] = Modal.useModal();
    useImperativeHandle(ref, () => ({
        warning(config = {}) {
            const cfg = {
                title: "Warning!",
                content: "",
                maskClosable: true,
                zIndex: 1099,
                className: "alert-antd-idbeauty",
                ...config
            };
            modal.warning(cfg);
        },
        info(config = {}) {
            const cfg = {
                title: "Info",
                content: "",
                maskClosable: true,
                zIndex: 1099,
                className: "alert-antd-idbeauty",
                ...config
            };
            modal.info(cfg);
        },
        error(config = {}) {
            const cfg = {
                title: "Error!",
                content: "",
                maskClosable: true,
                zIndex: 1099,
                className: "alert-antd-idbeauty",
                ...config
            };
            modal.error(cfg);
        }
    }));
    return (
        <>
            {contextHolder}
        </>
    );
});

export default AlertAntd;
