import Alert from "@idtek/component/lib/alert/Alert";
import Container from "@idtek/component/lib/container/Container";
import Modal from "@idtek/component/lib/modal/Modal";
import Popover from "@idtek/component/lib/popover/Popover";
import _ from "lodash";
import React, { Suspense } from "react";
import { connect } from "react-redux";
import GlobalStore from "../../GlobalStore";
import PosActionsDetail from "../../modules/posTransaction/view/PosTransactionDetailView";
import FormPosReceiveMoney from "../../modules/posTransaction/form/FormPosReceiveMoney";
import FormPosTakeMoney from "../../modules/posTransaction/form/FormPosTakeMoney";
import IconMoney from "../../modules/appointment/icon/IconMoney";
import Loading from "../../pages/Loading";
import AppStore from "../../store";
import AppUtil from "../../utils/AppUtil";
import { logout, showSideBarMenu } from "../actions/action";
import { HEADER_HEIGHT, LOG_OUT_SUCCESSFUL } from "../constants/constant";
import LOGO_IDBEAUTY from "../images/logo_2024.png";
import ButtonHeader from "./ButtonHeader";
import MainDrawer from "./MainDrawer";
import moment from "moment";
import { t } from "@idtek/component";
import Icon from "@idtek/component/lib/icon/Icon"
import { allRights } from "../../utils/rightUtils";
import { onChangeSelects } from "../actions/menuAction";
import FormResetPassword from "../../modules/user/form/FormResetPassword";
import { TextTranslate } from "@idtek/component/lib/language";

class HeaderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: localStorage.getItem("collapsedSideMenu") === "true" ? true : false, visible: false
        };
        this.branchId = _.get(AppStore.getState(), `root.branch.id`, null);

    }

    componentDidMount() {
        // const {dispatch, headerMenu} = this.props;
        // if (
        //     window.location.pathname === "/appointment" ||
        //     window.location.pathname.startsWith("/booking/create-mobile") ||
        //     window.location.pathname.startsWith("/booking/update-mobile")
        // ) {
        //     dispatch(showSideBarMenu(false));
        // } else {
        //     dispatch(showSideBarMenu(true));
        // }
    }

    handleClick = (e) => {
        const { dispatch } = this.props;
        if (e.key === "logout") {
            dispatch(logout());
        }
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };
    handleChangePassword = () => {
        this.showDrawerChangePass();
        this.setState({
            visible: !this.state.visible
        });
    };

    onSaveChangePass = () => {
        this.Form.mask();
        const data = this.Form.getValues();
        AppUtil.postApi(`/employee/change-pass`, data)
            .then((res) => {
                if (res.data.success) {
                    AppUtil.ToastUpdateSuccess();
                    if (window._qaq) {
                        window._qaq.push(["resetUserId"]);
                        window._qaq.push(["trackPageView"]);
                    }
                    setTimeout(() => {
                        AppStore.dispatch({
                            type: LOG_OUT_SUCCESSFUL
                        });
                        window.location.hash = "#/login";
                    }, 500);
                } else {
                    AppUtil.ToastServerError(res.data.message);
                }
            })
            .catch((res) => {
                AppUtil.ToastServerError();
            })
            .finally(() => {
                this.Form.unmask();
            });
    };

    onLogout = async () => {
        let confirm = await Alert.Swal_confirm(t("Notification"), t("Are you sure want to Logout?"), 3);
        if (_.get(confirm, "value") === true) {
            //const tenantId ="664fc5f3-4398-4187-af40-b53bb6e11e29";
            const token = AppUtil.getCookie(AppUtil.XSRF_TOKEN_COOKIE_NAME);
            if (token !== "") {
                this.props.history && this.props.history.push({
                    pathname: "/bff/logout", search: `?requestVerificationToken=${token}`
                })
                localStorage.removeItem("branchId");
                localStorage.removeItem("type");

                AppStore.dispatch({
                    type: LOG_OUT_SUCCESSFUL
                });
                window.location.reload();
            }

            // setTimeout(() => {
            //
            // }, 300);
            // await AppUtil.getApi("/bff/logout").then((response) => {
            //     const {data} = response;
            //     if (data.success) {
            //         if (window._qaq) {
            //             window._qaq.push(["resetUserId"]);
            //             window._qaq.push(["trackPageView"]);
            //         }
            //         setTimeout(() => {
            //             AppStore.dispatch({
            //                 type: LOG_OUT_SUCCESSFUL
            //             });
            //             // window.location.hash = '/';
            //         }, 300);
            //     }
            // });
        }
    };

    onResetPassword = async () => {
        const { authData } = this.props;
        this.Modal.onOpen(<FormResetPassword
            ref={(c) => (this.refFormTakeMoney = c)}
            onClose={() => {
                this.Modal.onClose();
            }}
            user={_.get(authData, "user.id")}
        />, <span>
            <TextTranslate text="Reset Password" />
        </span>, "600px");
    };


    checkActionMoneyFlow = () => {
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        return AppUtil.checkPermission(allRights.APPOINTMENT_POS_ACTION, rights);
    }

    onRecevie = () => {
        const { authData, branch } = this.props;
        this.Modal.onOpen(<FormPosReceiveMoney
            ref={(c) => (this.refFormRecevieMoney = c)}
            onClose={() => {
                this.Modal.onClose();
            }}
            user={_.get(authData, "user.fullName")}
            branch={_.get(branch, "id")}
        />, <TextTranslate text={"Header-PAYINS"} />, "600px");
    };

    onTake = () => {
        const { authData, branch } = this.props;
        this.Modal.onOpen(<FormPosTakeMoney
            ref={(c) => (this.refFormTakeMoney = c)}
            onClose={() => {
                this.Modal.onClose();
            }}
            user={_.get(authData, "user.fullName")}
            branch={_.get(branch, "id")}
        />, <span>
            {t("Header-PAYOUTS")}
        </span>, "600px");
    };

    onPosActions = () => {
        const { authData } = this.props;
        GlobalStore.DrawerPosActions && GlobalStore.DrawerPosActions.open(<Suspense fallback={<Loading />}>
            <PosActionsDetail branchId={_.get(authData, "user.branchId")} />
        </Suspense>, null, "450px");
    };

    onChangeBranch = async (data) => {
        const params = {
            branchId: data.value, date: moment().format("YYYY-MM-DD HH:mm"), type: data.type
        };
        let confirm;
        if (data.type === "TENANT") {
            confirm = await Alert.Swal_confirm(t("MessageConfirm-CONFIRM"), t("HEADER-ARE_YOU_SURE_WANT_TO_SWITCH_TO_TENANT"), 3);
            params.branchId = null
        } else {
            confirm = await Alert.Swal_confirm(t("MessageConfirm-CONFIRM"), t("HEADER-ARE_YOU_SURE_WANT_TO_CHANGE_BRANCH"), 3);
        }

        if (confirm && confirm.value === true) {
            const res = await AppUtil.postApi("/identity/change-branch", params);
            if (res) {
                if (params.branchId) {
                    localStorage.setItem("branchId", params.branchId);
                } else {
                    localStorage.removeItem("branchId");
                }

                if (params.type) {
                    localStorage.setItem("type", params.type ? params.type : "");
                } else {
                    localStorage.removeItem("type");
                }
                window.location.href = "/";
            }
        }
    };

    onChangeRoute = (item) => {
        const { dispatch } = this.props;
        if (item.route === "/appointment") {
            dispatch(showSideBarMenu(false));
            this.props.history.push("/appointment");
        } else {
            const routeChild = this.getFirstChildRoute(item.id)
            if (routeChild) {
                this.props.history.push(routeChild.route);
            }
            dispatch(showSideBarMenu(true));
            dispatch(onChangeSelects([routeChild.route]));
        }
    };

    getNameSelection() {
        const { authData, branchOptions, branch } = this.props;
        if (_.get(authData, 'isTenantSelection')) {
            return "TENANT"
        }
        return _.get(branch, 'name') ? branch.name : "----- BRANCH -----";
    }

    getBranchSelection() {
        const { authData, branch } = this.props;
        if (_.get(authData, 'isTenantSelection')) {
            return true
        }
        return !!_.get(branch, 'name');
    }

    getClassNameHeader(route) {
        const { history, showSideMenuDesktop, location } = this.props
        if (location === route) {
            return "menu-header active"
        } else {
            if (route !== "/appointment" && showSideMenuDesktop) {
                return "menu-header active"
            }
        }
        return "menu-header";
    }

    getFirstChildRoute(parentId) {
        const { allMenuItems } = this.props
        return _.find(_.sortBy(allMenuItems, y => y.localIndex), x => x.parentId === parentId)
    }

    renderModal() {
        return (<Modal
            draggable
            ref={(component) => {
                this.Modal = component;
            }}
            onClose={this.onCloseDefault}
            maskClosable={false}
            className="popup-crud popup-container popup-method"
        />);
    }

    renderDrawer() {
        return (<MainDrawer
            className="drawer-pos-actions"
            ref={(component) => (GlobalStore.DrawerPosActions = component)}
            onClose={() => {
            }}
        />);
    }

    render() {
        const { authData, branchOptions, branch, headerMenu, rights } = this.props;
        const tenantId = _.get(authData, 'user.tenantId');
        const username = _.get(authData, "user.userName");
        const usernameFormat = username && username.replace(`_${tenantId}`, "")
        const rightOverview = AppUtil.checkPermission(allRights.APPOINTMENT_OVERVIEW, rights)
        return (<Container ref={(component) => (this.Container = component)}>
            <header
                style={{
                    height: HEADER_HEIGHT, paddingLeft: 20
                }}
                className="header-page">
                <div
                    style={{
                        display: "flex", flex: 1, alignItems: "center", justifyContent: "flex-start"
                    }}>
                    <img className="logo" src={LOGO_IDBEAUTY} style={{ cursor: "pointer" }} alt=""
                        onClick={() => (window.location.href = "/")} />
                    {headerMenu && this.getBranchSelection() && headerMenu.map((item, index) => {
                        return <div key={index} className={this.getClassNameHeader(item.route)}
                            onClick={() => this.onChangeRoute(item)}>
                            {t("Header-" + item.name.split(' ').join('').toUpperCase())}
                        </div>
                    })}

                    <div className="end-menu">
                        {!_.isEmpty(branchOptions) && <Popover
                            placement="bottom"
                            trigger="click"
                            overlayClassName="header-submenu header-combo-branch"
                            onVisibleChange={this.handleVisibleSiteChange}
                            visible={this.state.visibleSite}
                            getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                            content={<div className='header-menu'>
                                {!_.isEmpty(branchOptions) && branchOptions.map((data, index) => {
                                    if (data.value != this.branchId) {
                                        if (this.branchId === null && data.value === "TENANT") {
                                            return (<div></div>);
                                        }
                                        return (<div key={index}
                                            className="header-submenu-item"
                                            onClick={this.onChangeBranch.bind(this, data)}
                                            style={{
                                                padding: 10, width: '100%', // minWidth: '160px',
                                                fontSize: '17px'
                                            }}>

                                            <Icon type="home" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                flex: 'initial'
                                            }} />
                                            <span style={{
                                                flex: 'inherit',
                                                marginLeft: 10,
                                                textAlign: 'start',
                                                fontSize: '13px',
                                                marginTop: 'auto'
                                            }}>{data.label}</span>

                                        </div>)
                                    } else {
                                        return (<div></div>);
                                    }
                                })}
                            </div>}>

                            <ButtonHeader
                                iconCls="fa fa-home"
                                text={this.getNameSelection()}
                                style={{
                                    background: '#EAF9FF',
                                    border: '1px solid #006499',
                                    color: '#006499',
                                    fontWeight: 600,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    height: 34,
                                    fontSize: 13,
                                    borderRadius: 0
                                }}
                                styleIcon={{
                                    fontSize: 20
                                }}
                            />
                        </Popover>}
                        {this.branchId && AppUtil.checkPermission(allRights.APPOINTMENT_POS_ACTION, rights) &&
                            <div style={{ display: "flex" }}>
                                <div className="button-header-v2 receive-money" onClick={this.onRecevie}>
                                    {t("Header-PAYINS")}
                                </div>
                                <div className="button-header-v2 take-money" onClick={this.onTake}>
                                    {t("Header-PAYOUTS")}
                                </div>
                            </div>}
                        {this.branchId && rightOverview &&
                            <div className="button-header-money" onClick={this.onPosActions}>
                                <IconMoney fill={"#fff"} />
                            </div>}

                        {<div className={`button-header`}
                            onClick={() => window.open("http://readme.idtek.com.vn/O4BjPmV8R2-w1FuQk9UfJQ")}
                            title={"User Guides"} style={{ width: 20 }}>
                            <i className={"fa fa-info-circle"} style={{ fontSize: 18 }} />
                        </div>}

                        <Popover
                            placement="bottom"
                            trigger="click"
                            overlayClassName="header-submenu"
                            onVisibleChange={this.handleVisibleChange}
                            visible={this.state.visible}
                            content={<div>
                                <div
                                    className="header-submenu-item"
                                    onClick={() => this.onResetPassword()}
                                    style={{
                                        padding: 10, width: "100%",
                                    }}>
                                    <i className="fa fa-key" style={{ paddingRight: "5px" }}></i>
                                    <span>
                                        {t("Header-RESET_PASSWORD")}
                                    </span>
                                </div>
                                <div
                                    className="header-submenu-item"
                                    onClick={() => this.onLogout()}
                                    style={{
                                        padding: 10, width: "100%",
                                    }}>
                                    <i className="fa fa-sign-in" style={{ paddingRight: "5px" }}></i>
                                    <span>
                                        {t("Header-LOGOUT")}
                                    </span>
                                </div>
                            </div>}>
                            <ButtonHeader style={{ marginRight: 16 }} iconCls="fa fa-user-circle-o"
                                text={usernameFormat}
                                className="avatar-user" />
                        </Popover>
                    </div>
                </div>
            </header>
            {this.renderDrawer()}
            {this.renderModal()}
        </Container>);
    }
}

const mapStateToProps = (state) => {
    return {
        ...(state.root || {})
    };
};

export default connect(mapStateToProps)(HeaderPage);
