import Icon from "@idtek/component/lib/icon/Icon";
import DropdownList from "@idtek/grid/lib/common/components/dropdown/DropdownList";
import _ from "lodash";
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useAppointmentContext} from "../context/AppointmentContext";
import Alert from "@idtek/component/lib/alert/Alert";
import AppUtil from "../../../utils/AppUtil";
import TreeServiceSelect from "./TreeServiceSelect";
import Modal from "@idtek/component/lib/modal/Modal";
import {v4 as uuidv4} from "uuid";
import IDStore from "@idtek/grid/lib/stores/IDStore";
import Grid from "@idtek/grid/lib/grid/Grid";
import Textbox from "@idtek/component/lib/textbox/Textbox";
import Combobox from "@idtek/component/lib/combobox/Combobox";
import TreeUtil from "../../../utils/TreeUtil";
import {t, TextTranslate} from "@idtek/component/lib/language";
import {OPTION_GENDER} from "../../posTransaction/constant/BookingConstant";

const BookingServiceComponent = forwardRef(({
                                                isMobile,
                                                technicianId,
                                                startTime,
                                                id,
                                                initData = [],
                                                readOnly,
                                                genderGuest,
                                                isCreate
                                            }, ref) => {
    const {technicianData, branchId} = useAppointmentContext();
    const myGrid = useRef(null), refModal = useRef(null), refCbx = useRef(null), refCbxGender = useRef(null),
        isChangeService = useRef(false)

    const [serviceSelected, setServiceSelected] = useState(_.map(initData, (x) => x.serviceId));
    const serviceSelectedDefault = useRef(_.map(initData, (x) => x.serviceId))
    const [options, setOptions] = useState([]);
    const store = useMemo(() => new IDStore({
        data: _.filter(initData, x => x.type !== 'extra'), autoLoad: _.size(initData) > 0
    }), []);
    const optionsGrid = {
        pagination: false,
        globalFilter: false,
        floatingFilter: false,
        heightGrid: "auto",
        minHeightGrid: "188px",
        defaultNumberRows: {
            headerName: t("BookingServiceComponent-NO"),
            width: 60,
            textAlign: "center"
        },
        animateRows: isCreate ?? false,
        rowDragManaged: isCreate ?? false,
        checkboxSelection: !readOnly,
        headerCheckboxSelection: !readOnly,
        defaultColDef: {
            sortable: isCreate ?? false, filter: !readOnly, headerAlign: "left", textAlign: "left"
        }
    };

    const columnDefs = [{
        headerName: t("BookingServiceComponent-NAME"),
        field: "serviceName",
        minWidth: isMobile ? 150 : 230,
        textAlign: "left",
        rowDrag: isCreate ?? false,
        colSpan: function (params) {
            if (_.get(params, "data.field") === "total") {
                return 2;
            }
            return 1;
        }
    }, {
        headerName: t("BookingServiceComponent-TECHNICIAN"), field: "technicianId", width: isMobile ? 150 : 280
    }, {
        headerName: t("BookingServiceComponent-GENDER"), field: "gender", width: 90
    }, {
        headerName: t("BookingServiceComponent-PRICE_(CAD)"), field: "price", width: 80
    }, {
        headerName: t("BookingServiceComponent-DURATION_(MINUTES)"), field: "duration", width: 80, textAlign: "right"
    }];

    useEffect(() => {
        handleData();
    }, [technicianId, technicianData]);

    useEffect(() => {
        handleData();
        isChangeService.current = _.difference(serviceSelectedDefault.current, serviceSelected).length > 0;
    }, [serviceSelected]);

    useImperativeHandle(ref, () => ({
        checkValid() {

            const sizePinned = myGrid.current && myGrid.current.gridApi.getPinnedBottomRowCount();
            const dataGridBooking = store && store.getData();
            if (_.size(dataGridBooking) === 0) {
                return false;
            }
            const filterNullTech = _.filter(dataGridBooking, (x) => !x.technicianId);
            let rawError = document.querySelectorAll(`.grid-service-${id} .error-technican`);
            _.each(rawError, (x) => {
                x.classList.remove(`error-technican`);
            });
            if (!_.isEmpty(filterNullTech)) {
                _.each(filterNullTech, (x) => {
                    const classParent = `ExtraTech_${x.serviceId}_${id}`;
                    const input = document.querySelectorAll(`.${classParent} .rc-select-selection-search-input`);
                    input[0].classList.add(`error-technican`);
                });
                return false;
            }

            //check price + duration service
            let validService = true
            _.each(dataGridBooking, row => {
                if (!row.price && row.price !== 0) {
                    validService = false
                    const classParent = `Price_${row.serviceId}_${id}`;
                    setErrorInput(classParent)
                    return false;
                }
                if (!row.duration && row.duration !== 0) {
                    validService = false
                    const classParent = `Duration_${row.serviceId}_${id}`;
                    setErrorInput(classParent)
                    return false;
                }
            })
            if (!validService) {
                return false
            }

            //check service nhieu tech
            let rawErrorMulti = document.querySelectorAll(`.grid-service-${id} .error-multi-technican`);
            _.each(rawErrorMulti, (x) => {
                x.classList.remove(`error-multi-technican`);
            });
            let validMulti = true
            _.each(dataGridBooking, row => {
                if (row.numberTechnician > 1) {
                    const quanTech = _.isArray(row.technicianId) ? _.size(row.technicianId) : 1
                    if (quanTech !== row.numberTechnician) {
                        const classParent = `ExtraTech_${row.serviceId}_${id}`;
                        const input = document.querySelectorAll(`.${classParent} .ant-select-selector`);
                        input && input[0] && input[0].classList.add(`error-multi-technican`);
                        validMulti = false
                    }
                }

            })
            if (!validMulti) return false
            ///end

            for (let i = 0; i < sizePinned; i++) {
                const rowPin = myGrid.current && myGrid.current.gridApi.getPinnedBottomRow(i);
                const {data} = rowPin;
                if (data.field === "extra") {
                    if (!data.serviceName) {
                        const classParent = `ExtraName_${data.index}_${id}`;
                        setErrorInput(classParent)

                        return false;
                    }
                    if (!data.technicianId) {
                        const classParent = `ExtraTech_${data.index}_${id}`;
                        const input = document.querySelectorAll(`.${classParent} .rc-select-selection-search-input`);
                        if (input) {
                            input[0].style.borderBottom = "1px solid red";
                            input[0].style.opacity = 1;
                            input[0].style.marginBottom = "1px";
                            input[0].focus();
                        }
                        return false;
                    }
                    if (!data.price && data.price !== 0) {
                        // if (data.price === 0) {
                        //     return false;
                        // }
                        const classParent = `ExtraPrice_${data.index}_${id}`;
                        setErrorInput(classParent)
                        return false;
                    }
                }
            }
            return true;
        }, getData() {
            const dataGrid = getDataGrid();
            const data = []
            _.each(dataGrid, (x, index) => {
                if (!x.id) {
                    delete x.id;
                }
                if (_.isArray(x.technicianId)) {
                    for (let i = 0; i < x.numberTechnician; i++) {
                        const obj = {
                            ...x
                        }
                        obj.type = "service";
                        obj.active = true;
                        obj.displayIndex = index;
                        obj.technicianId = x.technicianId[i]
                        obj.technicianName = _.get(_.find(x.technicians, y => y.id === x.technicianId[i]), 'name');
                        if (x.segmentIds && x.segmentIds[i]) {
                            obj.id = x.segmentIds[i]
                        }
                        data.push(obj)
                    }
                } else {
                    x.type = "service";
                    x.active = true;
                    x.displayIndex = index;
                    x.technicianName = _.get(_.find(x.technicians, y => y.id === x.technicianId), 'name');
                    data.push(x)
                }
            })
            const dataPinned = getDataPinned()
            _.each(dataPinned.dataExtra, (item, index) => {
                const obj = {
                    type: "extra",
                    serviceId: null,
                    technicianId: item.technicianId,
                    technicianName: _.get(_.find(item.technicians, y => y.id === item.technicianId), 'name'),
                    active: true,
                    displayIndex: index,
                    serviceName: item.serviceName,
                    price: item.price
                }
                if (item.id) {
                    obj.id = item.id
                }
                data.push(obj)
            })
            return data;
        }, clearService() {
            setServiceSelected([]);
            setDataGrid([]);
            isChangeService.current = false
        }, checkChangeService() {
            return isChangeService.current
        }, clearChange() {
            isChangeService.current = false
        }, getGenderGuest() {
            let gender = refCbxGender.current && refCbxGender.current.getValue()
            if (!gender) {
                const dataGrid = getDataGrid();
                const arrGender = _.uniq(_.map(dataGrid, x => x.gender))
                if (_.size(arrGender) === 1 && arrGender[0]) gender = arrGender[0]
            }
            return gender
        }
    }));

    const translateOptionGender = () => {
        return _.map(OPTION_GENDER, (item) => {
            return {
                ...item,
                label: t(item.label)
            }
        })
    }


    const handleData = () => {
        const optionService = getOptionService();
        ///xử lý option tech ở grid
        const dataGrid = getDataGrid();
        const guestGender = refCbxGender.current && refCbxGender.current.getValue()
        const dataHandle = _.map(dataGrid, x => {
            const techs = _.get(_.find(optionService, (item) => item.serviceId === x.serviceId), "technicians", []);
            if (_.isArray(x.technicianId) || _.size(x.technicianMulti) > 1) {
                //tim tech dc select nhung ko co trong option
                if (_.size(x.technicianMulti) > 1) {
                    //trường hợp update
                    const optId = _.map(techs, t => t.id)
                    const arrFind = _.filter(x.technicianMulti, (t) => _.indexOf(optId, t.id) < 0);
                    _.each(arrFind, a => techs.push(a))
                    x.technicians = techs;
                    x.technicianId = _.map(x.technicianMulti, y => y.id)
                }
                const serviceParent = _.find(optionService, s => s.serviceId === x.serviceId)
                x.numberTechnician = serviceParent ? serviceParent.numberTechnician : 1
                x.segmentIds = _.map(x.technicianMulti, y => y.segmentId)
                x.gender = x.gender ? x.gender : guestGender ? guestGender : null
            } else {
                const find = _.find(techs, (t) => t.id === x.technicianId);
                if (x.technicianId && !find) {
                    techs.push({
                        id: x.technicianId, name: x.technicianName
                    });
                }
                x.technician = find
                x.technicians = techs;
                x.gender = x.gender ? x.gender : guestGender ? guestGender : null

            }
            return {...x}
        })
        setDataGrid(dataHandle);
        //xử lý option của combo service
        /// option sẽ ko bao gồm các service đã chọn
        const options = _.filter(optionService, (x) => _.indexOf(serviceSelected, x.serviceId) < 0);
        refCbx.current && refCbx.current.setData(_.filter(options, x => x.parentId == null), null);
        setOptions(options);
        myGrid.current && myGrid.current.gridApi && myGrid.current.gridApi.redrawRows();
    };

    /// lấy service ban đầu theo technician
    const getOptionService = () => {
        if (_.isEmpty(technicianData)) return []
        let mapOption = _.find(technicianData, (x) => x.id === technicianId);
        if (!mapOption) {
            // ko có tech nên clear màu
            mapOption = technicianData[0]
            mapOption.services = _.map(mapOption.services, x => {
                x.color = ""
                return {...x}
            })
        }
        const allService = [];
        TreeUtil.onParseTreeToFlatData(mapOption.services, allService);
        allService && allService.map(item => {
            const techIds = _.map(item.technicians, x => x.id)
            if (_.indexOf(techIds, technicianId) >= 0) {
                item.priority = true
            }
        })
        return _.sortBy(allService, x => x.priority)
    };

    const addService = (service) => {
        const dataGrid = getDataGrid();
        //gird service đầu tiên sẽ set mặc định technician
        let tech = _.find(technicianData, (x) => x.id === technicianId);
        let gender = refCbxGender.current && refCbxGender.current.getValue()
        if (service) {
            const obj = {
                technicianId: service.color && id === 0 && (!gender || tech.gender === gender) ? technicianId : null,
                serviceId: service.id, ...service,
                id: null,
                // TODO:lỗi ngày 20/6 -> hot fix chờ core support
                price: service.price
            };
            dataGrid.push(obj);
            setDataGrid(dataGrid);
        }

    };

    const selectServiceHandler = (val) => {
        const ids = _.cloneDeep(serviceSelected);
        const service = _.find(options, (x) => x.serviceId === val);
        ids.push(val);
        addService(service);
        setServiceSelected(ids);
    };

    const onChangeGenderGuest = (val) => {
        const dataGrid = getDataGrid();
        const newData = _.map(dataGrid, x => {
            if (val) {
                x.gender = val
                if (_.isArray(x.technicianId) || _.size(x.technicianMulti) > 1) {
                    if (x.gender) {
                        const arr = x.technicianId
                        const techsInCondition = _.filter(x.technicians, item => _.indexOf(arr, item.id) >= 0 && item.gender === val)
                        x.technicianId = _.map(techsInCondition, item => item.id)
                    }
                } else {
                    const technician = x.technician
                    if (x.gender && technician && x.gender !== technician.gender) {
                        x.technicianId = null
                        x.technician = null
                    }
                }
            }

            return {...x}
        })
        setDataGrid(newData)

    }

    const selectManyServiceHandler = (arr) => {
        const ids = _.cloneDeep(serviceSelected);
        const dataGrid = getDataGrid();
        const services = _.filter(options, (x) => _.indexOf(arr, x.serviceId) > -1);
        _.each(services, (service) => {
            service.technicianId = service.color && id === 0 ? technicianId : null;
            service.id = null
            ids.push(service.serviceId);
            dataGrid.push(service);
        });
        setDataGrid(dataGrid);
        setServiceSelected(ids);
    };

    const setErrorInput = (key) => {
        const input = document.querySelectorAll(`.${key} > .text-input`);
        if (input) {
            input[0].focus();
            input[0].style.borderBottom = "1px solid red";
        }
    }

    const getDataGrid = () => {
        return store && store.getData();
    };

    const setDataGrid = (data) => {
        store && store.setData(data);
    };

    const onAddExtra = () => {
        if (myGrid.current) {
            const newExtra = {
                serviceName: null,
                technicianId: technicianId ? technicianId : null,
                price: null,
                duration: null,
                stt: `Extra`,
                field: "extra",
                index: uuidv4(),
                type: "extra",
                technicians: technicianData
            };
            handleAddPinnedRows(newExtra);
        }
    };

    const formulaSumColumn = (key) => {
        const data = getDataGrid()
        const dataPinned = getDataPinned();
        let {dataExtra, totalRow} = dataPinned;
        const totalData = _.concat(data, dataExtra);
        if (_.isEmpty(totalData)) return null;
        const totalFilter = _.filter(totalData, item => item[key])
        totalFilter && totalFilter.map(x => {
            x[key] = parseFloat(x[key]);
        });
        const sum = _.sumBy(totalFilter, `${key}`);
        if (sum == 0) return 0;
        return sum ? AppUtil.roundNumber(sum) : null;
    }

    const getDataPinned = () => {
        const dataExtra = [];
        let toTal = {};
        const sizePinned = myGrid.current && myGrid.current.gridApi.getPinnedBottomRowCount();
        for (let i = 0; i < sizePinned; i++) {
            const rowPin = myGrid.current && myGrid.current.gridApi.getPinnedBottomRow(i);
            if (_.get(rowPin, "data.field") !== "total") {
                dataExtra.push(rowPin.data);
            } else {
                toTal = rowPin.data;
            }
        }
        const extraHandle = _.map(dataExtra, x => {
            if (!x.price) x.price = null
            return {...x}
        })
        return {
            dataExtra: extraHandle, totalRow: toTal
        };
    }

    const handleAddPinnedRows = (newExtra) => {
        const dataPinned = getDataPinned();
        let {dataExtra, totalRow} = dataPinned;
        if (newExtra) {
            dataExtra.push(newExtra);
        }
        dataExtra = _.sortBy(dataExtra, (x) => x.type);
        dataExtra.push(totalRow)
        myGrid.current && myGrid.current.gridApi.setPinnedBottomRowData(dataExtra);
    };

    const onBlurInput = async (data, value, fieldKey, rawValue, rowNode) => {
        // console.log('Function: onBlurInput -> data, value: ', data, value);
        // if (rawValue !== value) {
        //     data[fieldKey] = value;
        //     myGrid.current && myGrid.current.gridApi.updateRowData({update: getDataGrid()});
        //     myGrid.current && myGrid.current.gridApi.redrawRows();
        // }
        myGrid.current && myGrid.current.gridApi.redrawRows();
    };

    const onChangeInput = (data, value, fieldKey) => {
        data[fieldKey] = value;
    }

    const onSelect = () => {
        const widthModal = isMobile ? "100%" : "750px";
        const optionHasSkill = _.map(_.filter(options, (x) => x.color), (y) => y.serviceId);
        refModal.current && refModal.current.onOpen(<TreeServiceSelect
            onClose={() => {
                refModal && refModal.current.onClose();
            }}
            branchId={branchId}
            technicianId={technicianId}
            optionHasSkill={optionHasSkill}
            onSelectTree={selectManyServiceHandler}
            serviceSelected={serviceSelected}
        />, <span>
            {t("BookingServiceComponent-ALL_SERVICES")}
        </span>, widthModal);
    };

    const onDeselect = async () => {
        const selectedRows = myGrid.current && myGrid.current.gridApi.getSelectedRows();
        if (_.size(selectedRows) > 0) {
            let confirm = await Alert.Swal_confirm(`${t("BookingServiceComponent-CONFIRM")}`, `${t("BookingServiceComponent-ARE_YOU_SURE_YOU_WANT_TO_DESELECT_SELECTED_SERVICES?")}`, 3);
            if (_.get(confirm, "value") === true) {
                deselectServiceHandler(_.map(selectedRows, (x) => x.serviceId), selectedRows);
            }
        } else {
            AppUtil.ToastError(`${t("BookingServiceComponent-SELECT_AT_LEAST_ONE_SERVICE_TO_DESELECT")}`);
        }
    };

    const deselectServiceHandler = (deleteIds, deletedRows) => {
        const dataGrid = getDataGrid();
        let serviceSameParent = []
        _.each(deletedRows, x => {
            if (x.parentId) {
                serviceSameParent = _.concat([], serviceSameParent, _.map(_.filter(dataGrid, dta => dta.parentId === x.parentId), s => s.serviceId))
            }
        })
        _.each(serviceSameParent, id => deleteIds.push(id))
        const newData = _.filter(dataGrid, (x) => _.indexOf(deleteIds, x.serviceId) < 0);
        setDataGrid(newData);
        setServiceSelected(_.filter(serviceSelected, (x) => _.indexOf(deleteIds, x) < 0));
    };

    const onDeselectExtra = async (row) => {
        let dataPinned = getDataPinned();
        let newExtra = []
        let {dataExtra, totalRow} = dataPinned;
        if (!row.id) {
            newExtra = _.filter(dataExtra, (item) => item.index !== row.index);
            newExtra.push(totalRow)
            myGrid.current && myGrid.current.gridApi && myGrid.current.gridApi.setPinnedBottomRowData(newExtra);
        } else {
            let title = `${t("BookingServiceComponent-ARE_YOU_SURE_YOU_WANT_TO_DESELECT_SELECTED_EXTRA_SERVICE?")}`;
            let confirm = await Alert.Swal_confirm(t("BookingServiceComponent-CONFIRM"), title, 3);
            if (_.get(confirm, "value") === true) {
                newExtra = _.filter(dataExtra, (item) => item.index !== row.index);
                newExtra.push(totalRow)
                myGrid.current && myGrid.current.gridApi && myGrid.current.gridApi.setPinnedBottomRowData(newExtra);
            }
        }
    };

    const actionRows = () => {
        return {
            pinned: "right",
            width: readOnly ? 0 : 60,
            title: `${t("BookingServiceComponent-ACTION")} `,
            items: renderActions
        };
    };


    const onGridReady = () => {
        if (myGrid) {
            const dataGrid = getDataGrid();
            const extras = _.filter(initData, x => x.type === "extra")
            const ex = _.map(extras, x => {
                x.technicians = technicianData
                x.field = "extra"
                x.index = x.id
                return {...x}
            })
            const pinned = [].concat(ex)
            pinned.push({
                gender: `${t("BookingServiceComponent-TOTAL")}`,
                serviceName: "",
                technicianId: "",
                price: _.sumBy(dataGrid, 'price'),
                duration: _.sumBy(dataGrid, 'duration') + " " + t("BookingServiceComponent-MIN"),
                stt: " ",
                field: "total"
            })
            myGrid.current && myGrid.current.gridApi && myGrid.current.gridApi.setPinnedBottomRowData(pinned);
        }
    };

    const onCloseDefault = () => {
    };

    const renderLeftActionBar = () => {
        return (<div style={{display: "flex", flex: 1, marginRight: 30}}>
            <div className="" style={{width: 110, marginRight: 10, paddingTop: 5, fontWeight: 600}}>
                {t("BookingServiceComponent-CHOOSE_SERVICE")}
            </div>
            <div style={{flex: 1, display: 'flex'}}>
                <Combobox ref={refCbx} className="choose-service" options={[]} style={{flex: 1, marginRight: 5,}}
                          labelKey="serviceName" valueKey="serviceId" onChange={selectServiceHandler}
                          placeholder={t("BookingServiceComponent-SELECT")}/>
                <Combobox ref={refCbxGender}
                          value={genderGuest}
                          className="choose-service"
                          options={translateOptionGender()}
                          style={{width: "120px"}}
                          placeholder={t('BookingServiceComponent-GENDER')}
                          onChange={onChangeGenderGuest}/>
            </div>
        </div>);
    };

    const renderActionBar = () => {
        const buttons = [];
        if (!readOnly) {
            buttons.push({
                    className: "create-btn text-button",
                    text: `${t("BookingServiceComponent-SERVICE")}`,
                    tooltip: `${t("BookingServiceComponent-SELECT_SERVICE")}`,
                    style: {
                        width: 80
                    },
                    icon: <Icon type="plus-square" style={{fontSize: 13}}/>,
                    onPress: onSelect
                }, {
                    className: "import-btn text-button",
                    tooltip: `${t("BookingServiceComponent-ADD_EXTRA_SERVICE")}`,
                    text: `${t("BookingServiceComponent-EXTRA")}`,
                    style: {
                        width: 80
                    },
                    icon: <Icon type="file-add" style={{fontSize: 13}}/>,
                    onPress: onAddExtra
                },
                {
                    className: "delete-btn text-button",
                    text: `${t("BookingServiceComponent-DESELECT")}`,
                    tooltip: `${t("BookingServiceComponent-DESELECT_SERVICE")}`,
                    icon: <Icon type="minus-square" style={{fontSize: 13}}/>,
                    onPress: onDeselect
                });
        }

        return buttons;
    };

    const renderActions = (row) => {
        const buttons = [];
        if (_.get(row, "field") !== "total" && !readOnly) {
            buttons.push({
                type: "delete", onPress: async (row) => {
                    if (row.field !== "extra") {
                        let confirm = await Alert.Swal_confirm(`${t("BookingServiceComponent-CONFIRM")}`, `${t("BookingServiceComponent-ARE_YOU_SURE_YOU_WANT_TO_DESELECT_SELECTED_SERVICES?")}`, 3);
                        if (_.get(confirm, "value") === true) {
                            deselectServiceHandler([row.serviceId], [row]);
                        }
                    } else {
                        onDeselectExtra(row);
                    }
                }, icon: {
                    type: "minus-square", style: {background: "var(--main-color-red)"}, color: "#ffffff"
                }, tooltip: `${t("BookingServiceComponent-DESELECT")}`
            });
        }
        return buttons;
    };

    const renderGrid = () => {
        const {
            pagination,
            globalFilter,
            floatingFilter,
            heightGrid,
            minHeightGrid,
            defaultNumberRows,
            animateRows,
            rowDragManaged,
            checkboxSelection,
            headerCheckboxSelection,
            defaultColDef
        } = optionsGrid;
        return (<>
            <Grid
                ref={myGrid}
                height={heightGrid}
                minHeight={minHeightGrid}
                rowSelection={"multiple"}
                checkboxSelection={checkboxSelection}
                headerCheckboxSelection={headerCheckboxSelection}
                pagination={pagination}
                rowHeight={28}
                className={`base-grid`}
                clsToolbar="base-grid-toolbar"
                store={store}
                globalFilter={globalFilter}
                floatingFilter={floatingFilter}
                columnDefs={columnDefs}
                renderLeftActionBar={renderLeftActionBar}
                numberRows={{
                    headerName: '#', width: 40
                }}
                actionToolbar={renderActionBar()}
                onRowDoubleClicked={false}
                headerVerticalLines={true}
                headerHorizotalLines={true}
                suppressColumnVirtualisation={false}
                headerHeight={32}
                defaultColDef={defaultColDef}
                animateRows={animateRows}
                rowDragManaged={rowDragManaged}
                actionRows={actionRows()}
                // autoHeightRow={true}
                cellRenderer={{
                    serviceName: {
                        renderer: (data, value, rowNode) => {
                            if (_.get(data, "field") === "extra") {
                                const key = `ExtraName_${data.index}_${id}`;
                                return (<Textbox
                                    key={key}
                                    className={`cpn-add-extra ${key}`}
                                    value={value}
                                    placeholder={t("BookingServiceComponent-INPUT_NAME_OF_EXTRA_SERVICE")}
                                    // onBlur={(val) => onBlurInput(data, val, "serviceName", value, rowNode)}
                                    onChange={(val) => onChangeInput(data, val, "serviceName")}
                                    inputStyle={{textAlign: "left"}}
                                    autoFocus={true}
                                    readOnly={readOnly}
                                    isClearable={!readOnly}
                                    visibleIcon={!readOnly}
                                />);
                            } else if (_.get(data, "field") === "total") {
                                return <div
                                    className="global-flex-align-center-justify-end label-bottom">{value}</div>;
                            }
                            return <div style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%'
                            }}>{value} {data.numberTechnician > 1 ? <span className={"font-size11"}
                                                                          style={{
                                                                              marginLeft: 5, fontStyle: "italic",
                                                                          }}>{`${t("BookingServiceComponent-NEED")} ${data.numberTechnician} ${t("BookingServiceComponent-TECHNICIAN").toLowerCase()}`}</span> : <></>}</div>;
                        }
                    }, price: {
                        renderer: (data, value, rowNode) => {
                            const classText = data.field === "total" ? "label-bottom" : "";
                            if (_.get(data, "field") === "total") {
                                data.price = formulaSumColumn("price");
                                return <div className="global-flex-align-center">
                                    <span className="label-bottom">$</span>
                                    <div className={`global-flex-align-center-justify-end global-flex-1 ${classText}`}>
                                        {data.price ? AppUtil.FormatCurrencyBranch(data.price) : 0}
                                    </div>
                                </div>
                            } else {
                                let key = ""
                                if (_.get(data, "field") === "extra") {
                                    key = `ExtraPrice_${data.index}_${id}`;
                                } else {
                                    key = `Price_${data.serviceId}_${id}`;
                                }
                                return (<Textbox
                                    key={key}
                                    className={`cpn-add-extra ${key} price`}
                                    value={value}
                                    placeholder={(`${t("BookingServiceComponent-INPUT")}...`)}
                                    onChange={(val) => onChangeInput(data, val, "price")}

                                    onBlur={(val) => onBlurInput(data, val, "price", value, rowNode)}
                                    inputStyle={{textAlign: "left"}}
                                    type={"number"}
                                    autoFocus={true}
                                    readOnly={readOnly}
                                    isClearable={!readOnly}
                                    visibleIcon={false}
                                    iconLine={false}
                                    iconAlign="before"
                                />);
                            }
                        }
                    }, duration: {
                        renderer: (data, value, rowNode) => {
                            if (_.get(data, "field") === "total") {
                                data.duration = formulaSumColumn("duration");
                                return <div className="global-flex-align-center-justify-end label-bottom">
                                    {data.duration ? data.duration : 0} {t("BookingServiceComponent-MINS")}
                                </div>
                            } else if (!value && _.get(data, "field") === "extra") {
                                return null;
                            }
                            const key = `Duration_${data.serviceId}_${id}`;
                            return (<Textbox
                                key={key}
                                className={`cpn-add-extra ${key} duration`}
                                value={value}
                                placeholder={<span> <TextTranslate text=" Input..."/> </span>}
                                onChange={(val) => onChangeInput(data, val, "duration")}

                                onBlur={(val) => onBlurInput(data, val, "duration", value, rowNode)}
                                inputStyle={{textAlign: "left"}}
                                type={"number"}
                                autoFocus={true}
                                readOnly={readOnly}
                                isClearable={!readOnly}
                                visibleIcon={false}
                                iconAlign="before"
                            />);
                        }
                    }, technicianId: {
                        renderer: (data, value, rowNode, context, colDef, dataTestID) => {
                            if (_.get(data, "field") !== "total") {
                                let key = ""
                                if (data.serviceId) {
                                    key = `ExtraTech_${data.serviceId}_${id}`;
                                } else {
                                    key = `ExtraTech_${data.index}_${id}`;
                                }
                                return (<DropdownList
                                    value={value}
                                    dataTestID={dataTestID}
                                    className={`drop-down-cpn ${key}`}
                                    options={_.map(data.technicians, x => {
                                        if (data.gender) {
                                            if (data.gender !== x.gender) {
                                                x.disabled = true
                                            } else {
                                                x.disabled = false
                                            }
                                        } else {
                                            x.disabled = false
                                        }

                                        return {
                                            ...x
                                        }
                                    })}
                                    labelKey={"name"}
                                    valueKey={"id"}
                                    placeholder={t("BookingServiceComponent-SELECT") + "..."}
                                    getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                                    onChange={(val) => {
                                        data.technicianId = val;
                                        if (_.isArray(data.technicianId) || _.size(data.technicianMulti) > 1) {

                                        } else {
                                            data.technician = _.find(data.technicians, x => x.id === val)
                                        }
                                    }}
                                    readOnly={readOnly}
                                    allowClear={!readOnly}
                                    multiple={data.numberTechnician > 1 ? true : false}
                                    visibleIcon={!readOnly}
                                />);
                            }
                        }
                    }, gender: {
                        renderer: (data, value, rowNode, context, colDef, dataTestID) => {
                            if (_.get(data, "field") !== "total") {
                                return (<DropdownList
                                    value={value}
                                    dataTestID={dataTestID}
                                    className={`drop-down-cpn`}
                                    options={translateOptionGender()}
                                    labelKey={"label"}
                                    valueKey={"value"}
                                    placeholder={t("BookingServiceComponent-SELECT") + "..."}
                                    getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                                    onChange={(val) => {
                                        data.gender = val;
                                        if (_.isArray(data.technicianId) || _.size(data.technicianMulti) > 1) {
                                            if (val) {
                                                const arr = data.technicianId
                                                const techsInCondition = _.filter(data.technicians, item => _.indexOf(arr, item.id) >= 0 && item.gender === val)
                                                data.technicianId = _.map(techsInCondition, item => item.id)
                                            }
                                        } else {
                                            const tech = data.technician
                                            if (tech && val && tech.gender !== val) {
                                                data.technicianId = null
                                                data.technician = null
                                            }
                                        }
                                        const row = myGrid.current.gridApi && myGrid.current.gridApi.getDisplayedRowAtIndex(rowNode.rowIndex);
                                        myGrid.current && myGrid.current.gridApi && myGrid.current.gridApi.redrawRows({rowNodes: [row]});

                                    }}
                                    readOnly={readOnly}
                                    allowClear={!readOnly}
                                    visibleIcon={!readOnly}
                                />);
                            }
                        }
                    }
                }}
                onGridReady={onGridReady}
            />
        </>);
    };

    const renderModal = () => {
        return <Modal ref={refModal} onClose={onCloseDefault} maskClosable={false}
                      className="popup-crud popup-container"/>;
    };
    return (<div className={`grid-booking-service grid-service-${id}`}>
        {renderGrid()}
        {renderModal()}
    </div>);
});
export default BookingServiceComponent;
