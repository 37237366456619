/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Fieldset, Modal } from "@idtek/component/lib";
import { useMergeState } from "@idtek/component/lib/hook/IDHook";
import Icon from "@idtek/component/lib/icon/Icon";
import { FieldArray, IDField, RadioListField, SwitchField, TextAreaField, TextField } from "@idtek/form/lib";
import Form from "@idtek/form/lib/IDForm";
import { Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../pages/Loading";
import AppUtil from "../../../utils/AppUtil";
import ColorRandomField from "../../../common/component/ColorRandomField";
import ReactDatePickerCustom from "../../../common/component/ReactDatePickerCustom";
import "../style/style-update-booking.scss";
import BookingServiceComponent from "../component/BookingServiceComponent";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import { BookingStatusContant, BookingStatusOption } from "../constant/BookingStatusContant";
import { useAppointmentContext } from "../context/AppointmentContext";
import { modelBooking } from "../model/modelBooking";
import Cell from "@idtek/component/lib/container/Cell";
import Row from "@idtek/component/lib/container/Row";
import AppStore from "../../../store";
import * as Contant from "../../../application/constants/constant";
import { t } from '@idtek/component/lib/language';
import ComboField from "@idtek/form/lib/combox/ComboField";
import PaymentDetailByBookingContainer from "../view/PaymentDetailByBookingContainer";
import PaymentContainer from "../view/PaymentContainer";
import AlertAntd from "../component/AlertAntd";
import SearchTextCustomer from "../component/SearchTextCustomer";
import SelectPhoneFieldCustom from '../component/SelectPhoneFieldCustom'

const BookingForm = forwardRef(({
    bookingId,
    buttons,
    fields,
    isCreate,
    formDefaults,
    onReloadData,
    onReloadDataPayment,
    onClose,
    loadData,
    onCheckReloadPage
}, ref) => {
    const { configAppointment, branchId, dataInitBooking, phoneSetting } = useAppointmentContext();
    //variable
    const formRef = useRef(null),
        refComboPhoneComponent = useRef(null),
        refSearchTextCustomer = useRef(null),

        modalPayment = useRef(null),
        refAlertAntd = useRef(null);
    const isChangeData = useRef(false)

    //state
    const [state, setState] = useMergeState({
        startTime: dataInitBooking.startTime,
        technicianId: dataInitBooking.technicianId,
        serviceFlatCombo: [],
        loading: !isCreate,
        contentAlert: [],
        warningAlert: false,
    });
    const [listIdGuest, setListIdGuest] = useState(_.map(dataInitBooking.guests, (x) => x.id));
    const bookingIdCurrent = useRef(bookingId)
    const initialValues = useRef({
        numberGuest: 1,
        branchId,
        guests: [],
        block: false,
        gender: "ANY",
        ...dataInitBooking
    });
    const gridRef1 = useRef(null),
        refTempCustomerName = useRef("")
    const model = modelBooking;
    let className = "style-update-booking style-form-booking";
    const gridsRef = useMemo(
        () =>
            listIdGuest.map(() => ({
                current: null
            })),
        [listIdGuest]
    );
    const optionPhoneSetting = AppUtil.RegexPhone(_.get(_.find(_.get(AppStore.getState(), `
            root.branch.branchConfigs`), (x) => x.type === Contant.PHONE_SET_UP), 'value'));
    const init = initialValues.current
    let minDate = moment(init.minDate).startOf("days") || moment(init.startTime).startOf("days");
    let maxDate = init.maxDate;
    if (moment(init.startTime).format("YYYY/MM/DD") < moment().format("YYYY/MM/DD") && init?.id) {
        maxDate = moment().add(-1, "days")
        minDate = moment(init.startTime).startOf("day")
    }
    if (init.status === BookingStatusContant.STATUS_PROCESSING) {
        maxDate = moment()
    }
    const statusFirstGuest = _.get(init, 'firstGuest.status')
    let readOnlyFirstGuest = false
    if (!isCreate) {
        readOnlyFirstGuest = formDefaults.readOnly || statusFirstGuest === BookingStatusContant.STATUS_COMPLETE
    }
    useEffect(() => {
        if (!isCreate && dataInitBooking.customer) {
            handleCustomerBlock(dataInitBooking.customer)
        }
    }, [dataInitBooking])

    useEffect(() => {
        const { startTime } = state;
        loadData && loadData(startTime, doneLoading);
    }, [state.startTime]);

    const doneLoading = () => {
        setState({
            loading: false
        });
    };

    useImperativeHandle(ref, () => ({
        async onCreateAndContinue(form, values, url, callback) {
            onSaveForm(form, values, url, callback ? callback : () => clearFormAndContinute());
        },
        async onSave(form, values, url, callback) {
            onSaveForm(form, values, url, callback ? callback : () => closeAndReload());
        },

        async onProceedPayment(guestId, isDetail) {
            const PaymentCtn = isDetail ? PaymentDetailByBookingContainer : PaymentContainer
            modalPayment.current && modalPayment.current.onOpen(
                <PaymentCtn
                    onClose={() =>
                        modalPayment.current && modalPayment.current.onClose()
                    }
                    onReloadData={onReloadDataPayment ? onReloadDataPayment : onReloadData}
                    bookingId={bookingIdCurrent.current}
                    guestId={guestId}
                    branchId={branchId} />
                , <span>{isDetail ? t("BookingForm-DETAIL_PAYMENT") : t("BookingForm-TAKE_PAYMENT")}</span>,
                "1000px");
        },

        async onStart(form, values, url) {
            if (isCreate) {
                showConfirm(`${t("BookingForm-CONFIRM")}`, `${t("BookingForm-ARE_YOU_SURE_YOU_WANT_TO_START_BOOKING?")}`, () => onSaveForm(form, values, url, () => closeAndReload()));
            } else {
                showConfirm(`${t("BookingForm-CONFIRM")}`, `${t("BookingForm-ARE_YOU_SURE_YOU_WANT_TO_START_BOOKING?")}`, () => onSaveAndStartForm(form, values, url));

            }
        },
        async onCancel(form, values) {
            showConfirm(`${t("BookingForm-CONFIRM")}`, `${t("BookingForm-ARE_YOU_SURE_YOU_WANT_TO_CANCEL_BOOKING?")}`, () => doActionUpdateStatusBooking(form, values.id, BookingStatusContant.STATUS_CANCEL, () => closeAndReload()));
        },
        async onNoShow(form, values) {
            showConfirm(`${t("BookingForm-CONFIRM")}`, `${t("BookingForm-ARE_YOU_SURE_YOU_WANT_TO_HIDE_BOOKING?")}`, () => doActionUpdateStatusBooking(form, values.id, BookingStatusContant.STATUS_NOSHOW, () => closeAndReload()));
        },
        checkChangeData() {
            if (isChangeData.current) {
                return true
            }
            if (gridRef1.current && gridRef1.current.checkChangeService()) {
                return true
            }
            _.each(gridsRef, (item, index) => {
                if (gridsRef[index].current.checkChangeService()) {
                    return true
                }
            });
            return false
        }
    }));


    const showConfirm = async (titleConfirm, messageConfirm, callback) => {
        let confirm = await Alert.Swal_confirm(titleConfirm, messageConfirm, 3);
        if (_.get(confirm, "value") === true) {
            callback && callback();
        }
    };

    const doActionUpdateStatusBooking = async (form, bookingId, status, callback) => {
        form && form.mask();
        const params = { bookingId: bookingId, status: status, force: false };
        const response = await AppUtil.postApi(ApiBookingConstant.API_UPDATE_STATUS, params);
        form && form.unmask();
        if (_.get(response, "data.success")) {
            callback && callback();
        } else {
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    };

    const onSaveAndStartForm = async (form, values, url) => {
        if (checkValidBooking()) {
            form && form.mask();
            const newValues = beforeSave(values);
            const res = await AppUtil.postApi(url, newValues);
            const valuesGrid = gridsRef;
            form && form.unmask();
            if (_.get(res, "data.success")) {
                const data = _.get(res, "data.result")
                bookingIdCurrent.current = data.id
                await doActionUpdateStatusBooking(form, values.id, BookingStatusContant.STATUS_PROCESSING, () => closeAndReload())
            } else {
                AppUtil.ToastApiError(_.get(res, "data.message"));
            }
        } else {
            AppUtil.ToastError(`${t("BookingForm-BOOKING_INVALID!")}`);
        }
    }

    const onSaveForm = async (form, values, url, successAction) => {
        if (checkValidBooking()) {
            form && form.mask();
            const newValues = beforeSave(values);
            const res = await AppUtil.postApi(url, newValues);
            const valuesGrid = gridsRef;
            form && form.unmask();
            if (_.get(res, "data.success")) {
                const data = _.get(res, "data.result")
                bookingIdCurrent.current = data.id
                successAction && successAction();
                isChangeData.current = true
                afterSaveSuccess()
            } else {
                AppUtil.ToastApiError(_.get(res, "data.message"));
            }
        } else {
            AppUtil.ToastError(`${t("BookingForm-BOOKING_INVALID!")}`);
        }
    };

    const afterSaveSuccess = () => {
        // gridRef1.current && gridRef1.current.clearChange();
        // _.each(gridsRef, (item, index) => {
        //     gridsRef[index].current.clearChange();
        // });
    }


    const clearFormAndContinute = () => {
        AppUtil.ToastSuccess();
        const { startTime } = state;
        loadData && loadData(startTime, doneLoading);
        gridRef1.current.clearService();
        _.each(gridsRef, (item, index) => {
            gridsRef[index].current.clearService();
        });
        onReloadData && onReloadData();
    };

    const closeAndReload = () => {
        AppUtil.ToastSuccess();
        onClose && onClose();
        onReloadData && onReloadData(bookingIdCurrent.current);
        onCheckReloadPage && onCheckReloadPage();

    };

    const checkValidBooking = () => {
        let valid = true;
        if (gridRef1.current && !gridRef1.current.checkValid()) {
            valid = false;
        }
        _.each(gridsRef, (item, index) => {
            if (!gridsRef[index].current.checkValid()) {
                valid = false;
            }
        });
        return valid;
    };

    const beforeSave = (values) => {
        const dataPost = {};
        if (values.id) {
            dataPost.id = values.id;
        }
        const customer = values.customer;
        let nameOfCustomer = refTempCustomerName.current ? refTempCustomerName.current : values.customerName
        if (nameOfCustomer === dataInitBooking.customerId && isCreate) {
            nameOfCustomer = refSearchTextCustomer.current?.getCustomerNameById(dataInitBooking.customerId)
        }
        if (nameOfCustomer) nameOfCustomer = nameOfCustomer.toUpperCase()
        if (_.isObject(customer)) {
            const phone = _.get(values.customer, "phone")
            dataPost.customer = {
                name: nameOfCustomer,
                phone: phone ? phone.replace(/\s/g, "") : "",
                branchId: _.get(values.customer, "branchId", branchId),
                email: values.email,
                birthDay: customer.birthDay,
                active: true
            };
        } else {
            dataPost.customer = {
                name: nameOfCustomer,
                phone: customer ? customer.replace(/\s/g, "") : "",
                branchId: branchId,
                email: values.email,
                active: true
            };
        }
        dataPost.customerName = nameOfCustomer;
        dataPost.note = values.note;
        dataPost.color = values.color;
        dataPost.type = "TYPE_BOOKING_OFFLINE";
        dataPost.startTime = moment(values.startTime).format("YYYY-MM-DD HH:mm");
        dataPost.branchId = branchId;
        const guestPost = [];
        //grid 1
        const guest1 = {};
        guest1.id = _.get(dataInitBooking, "firstGuest.id");
        guest1.block = values.block;
        guest1.name = nameOfCustomer;
        guest1.status = "Waiting";
        guest1.segments = gridRef1.current && gridRef1.current.getData();
        guest1.gender = gridRef1.current && gridRef1.current.getGenderGuest();
        guest1.displayIndex = 0;
        guest1.isReOrder = values.isReOrder;
        guestPost.push(guest1);
        _.each(values.guests, (x, index) => {
            const arrIds = x.id.split("_");
            const obj = {
                id: x.id,
                name: x.name,
                block: x.block,
                segments: gridsRef[index].current && gridsRef[index].current.getData(),
                gender: gridsRef[index].current && gridsRef[index].current.getGenderGuest(),
                status: "Waiting",
                displayIndex: index + 1,
                bookingId: bookingIdCurrent.current,
                isReOrder: x.isReOrder
            };
            if (arrIds[0] === "NEW") {
                obj.id = undefined;
            }

            guestPost.push(obj);
        });
        dataPost.guests = guestPost;
        return dataPost;
    };

    const getStatusConstant = () => {
        return _.map(BookingStatusOption, (item) => {
            return {
                ...item,
                label: t(item.label)
            }
        })
    }

    const onSetErrorField = (field, message) => {
        formRef && formRef.current.setErrorField(field, message);
    };

    const handleCustomerBlock = (customer, callback) => {
        let content = []
        let warning = false
        if (customer) {
            if (customer.block) {
                warning = true
                content.push(<div style={{ color: "red" }}>{t("BookingForm-Blocked-CUSTOMER")}</div>)
            }
            if (customer.notes && !_.isEmpty(customer.notes)) {
                _.each(customer.notes, x => {
                    content.push(<div>- {x.note}</div>)
                })
            }
        }
        if (!_.isEmpty(content)) {
            if (warning) {
                refAlertAntd.current && refAlertAntd.current.warning({
                    title: `${t("AppUtil-WARNING")}!`,
                    content: <div>
                        {content && content.map(x => x)}
                    </div>
                })
            } else {
                refAlertAntd.current && refAlertAntd.current.info({
                    title: t("DetailBillCart-INFORMATION"),
                    content: <div>
                        {content && content.map(x => x)}
                    </div>
                })
            }
        }
        const objWarning = {
            contentAlert: content,
            warningAlert: warning
        }
        setState(objWarning)
        callback && callback()
    }

    const callBackSelectPhone = (obj) => {
        refSearchTextCustomer.current && refSearchTextCustomer.current.setVisibleTag(false)
        const arrEmail = _.get(obj, "email") ? obj.email.split(",") : [];
        formRef.current && formRef.current.setValueField("customerName", _.get(obj, "name", ""), true);
        formRef.current && formRef.current.setValueField("email", _.isEmpty(arrEmail) ? "" : _.last(arrEmail), true);
    }

    const onChangePhone = (obj, force) => {
        if (!force) {
            if (obj && obj.phone) {
                obj.phone = obj.phone.replace(/\s/g, "");
                refTempCustomerName.current = obj.name
                handleCustomerBlock(obj, () => callBackSelectPhone(obj))
            } else {
                refSearchTextCustomer.current && refSearchTextCustomer.current.setVisibleTag(true)
                setTimeout(() => {
                    formRef.current && formRef.current.setValueField("email", "", true);
                }, 100)
            }
        }
    };


    const onSelectCustomerFromTag = (obj) => {
        if (obj && obj.isNew) {
            refTempCustomerName.current = _.get(obj, 'value')
            refComboPhoneComponent.current && refComboPhoneComponent.current.setVisibleOption(false)
            setTimeout(() => {
                formRef.current && formRef.current.setValueField("customer", "", true);
                formRef.current && formRef.current.setValueField("email", "", true);
            }, 100)
        } else if (obj && obj.phone) {
            obj.phone = obj.phone.replace(/\s/g, "");
            refTempCustomerName.current = _.get(obj, 'name')
            const arrEmail = _.get(obj, "email") ? obj.email.split(",") : [];
            handleCustomerBlock(obj, () => {
                refComboPhoneComponent.current && refComboPhoneComponent.current.setVisibleOption(false)
                setTimeout(() => {
                    formRef.current && formRef.current.setValueField("customer", obj.phone, true);
                    formRef.current && formRef.current.setValueField("email", _.isEmpty(arrEmail) ? "" : _.last(arrEmail), true);
                }, 100)
            })
        } else {
            refComboPhoneComponent.current && refComboPhoneComponent.current.setVisibleOption(true)
            refSearchTextCustomer.current && refSearchTextCustomer.current.setVisibleTag(true)
            formRef.current && formRef.current.setValueField("email", "", true);

        }
    }

    const onChangeDate = async (val) => {
        setState({
            startTime: val
        });
    };

    const onChangeColor = () => {
    };
    const onCloseDefault = () => {
    };
    const addGuest = (index) => {
        const formValues = formRef && formRef.current.getValues();
        let nameOfCustomer = refTempCustomerName.current ? refTempCustomerName.current : formValues.customerName
        const items = _.cloneDeep(_.get(formValues, "guests", []));
        let status = BookingStatusContant.STATUS_WAITING
        if (moment(formValues.startTime).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
            status = BookingStatusContant.STATUS_PROCESSING
        }
        const defaultName = _.size(items) + 1
        let nameGuest = t("BookingForm-GUEST") + " " + defaultName + " "
        if (nameOfCustomer) {
            nameGuest += t(`BookingForm-OF`) + " " + `${nameOfCustomer}`
        }
        items.splice(index, 0, {
            name: nameGuest,
            gender: "ANY",
            block: false,
            id: "NEW_" + uuidv4(),
            status
        });
        setListIdGuest(_.map(items, (x) => x.id));
        formRef.current.setValueField("guests", items);
        isChangeData.current = true
    };

    const removeGuest = (fields, idx) => {
        const formValues = formRef && formRef.current.getValues();
        const items = _.cloneDeep(_.get(formValues, "guests", []));
        items.splice(idx, 1);
        setListIdGuest(_.map(items, (x) => x.id));
        formRef.current.remove("guests", idx);
        isChangeData.current = true
    };

    const onInputChange = (name, value) => {
        isChangeData.current = true
    }

    const handleLabelCustomer = () => {
        const { contentAlert, warningAlert } = state
        return <span className={"customer-label-form"}>
            {/*<span>Phone</span>*/}
            {!_.isEmpty(contentAlert)
                && <Tooltip
                    title={<div>
                        {contentAlert.map(x => x)}
                    </div>}
                >
                    <Icon className={"icon-info"}
                        type="exclamation-circle"
                        style={{
                            color: warningAlert ? "#faad14" : "#006096",
                        }} />
                </Tooltip>
            }
        </span>
    }


    //render section
    const renderForm = () => {
        const { technicianId, startTime } = state;

        let classNameForm = ""
        const startHour = configAppointment.extraStartHour ? configAppointment.extraStartHour : configAppointment.startHour
        const endHour = configAppointment.extraEndHour ? configAppointment.extraEndHour : configAppointment.endHour
        return (
            <Form ref={formRef} initialValues={init} model={model} className="base-form-modal"
                style={{ padding: 15 }} buttons={buttons} defaults={formDefaults}
                onInputChange={(name, value) => onInputChange(name, value)}
            >
                <div className={classNameForm}>
                    <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {fields &&
                            fields.map((field, index) => {
                                switch (field) {
                                    case "startTime":
                                        return (
                                            <Cell style={{ flex: "50%" }}>
                                                <IDField
                                                    name={field}
                                                    showTimeSelect={true}
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                    onChange={onChangeDate}
                                                    startHour={moment(startHour, "HH:mm").subtract(1, 'minutes').format("HH:mm")}
                                                    endHour={moment(endHour, "HH:mm").add(1, 'minutes').format("HH:mm")}
                                                    timeWork={8}
                                                >
                                                    <ReactDatePickerCustom />
                                                </IDField>
                                            </Cell>
                                        );
                                    case "customer":
                                        return (
                                            <Cell style={{ flex: '50%' }} className="phone-input">
                                                <SelectPhoneFieldCustom ref={refComboPhoneComponent} name={field}
                                                    onSetErrorField={onSetErrorField}
                                                    onChange={onChangePhone}
                                                    branchId={branchId}
                                                    options={optionPhoneSetting}
                                                    tooltip={handleLabelCustomer()}
                                                    readOnly={formDefaults.readOnly || !isCreate} />
                                            </Cell>
                                        );
                                    case "customerName":
                                        if (isCreate) {
                                            return (
                                                <Cell style={{ flex: '50%' }}>
                                                    <SearchTextCustomer ref={refSearchTextCustomer} field={field}
                                                        branchId={branchId}
                                                        defaultData={_.get(init, "customerId")}
                                                        onChange={onSelectCustomerFromTag}
                                                        onChangeName={(val) => {
                                                            refTempCustomerName.current = val
                                                        }}
                                                    />
                                                </Cell>
                                            );
                                        } else {
                                            return (
                                                <Cell style={{ flex: '50%' }}>
                                                    <TextField name={field} upperCase={true}
                                                        onChange={(val) => refTempCustomerName.current = val} />
                                                </Cell>
                                            );
                                        }

                                    case "email":
                                        return (
                                            <Cell style={{ flex: '50%' }}>
                                                <TextField name={field} type="email" />
                                            </Cell>
                                        );
                                    case "gender":
                                        return (
                                            <Cell
                                                style={{
                                                    height: 50,
                                                    paddingRight: 5,
                                                    flex: '50%'
                                                }}>
                                                <RadioListField
                                                    name={field}
                                                    viewMode={"horizontal"}
                                                    labelKey={"label"}
                                                    valueKey={"value"}
                                                    label={t("BookingForm-PRIORITY_TECHNICIAN")}
                                                    options={[
                                                        {
                                                            label: t("BookingForm-ANY"),
                                                            value: "ANY"
                                                        },
                                                        {
                                                            label: t("BookingForm-MALE"),
                                                            value: "MALE"
                                                        },
                                                        {
                                                            label: t("BookingForm-FEMALE"),
                                                            value: "FEMALE"
                                                        }
                                                    ]}
                                                />
                                            </Cell>
                                        );
                                    case "block":
                                        return (
                                            <Cell style={{ height: 46, flex: '50%' }}>
                                                <SwitchField name={"block"} />
                                            </Cell>
                                        );
                                    case "color":
                                        return (
                                            <Cell style={{ flex: '50%' }}>
                                                <IDField name={field} onChange={onChangeColor}>
                                                    <ColorRandomField isCreate={isCreate} />
                                                </IDField>
                                            </Cell>
                                        );
                                    case "status":
                                        return (
                                            <Cell style={{ flex: '50%' }}>
                                                <ComboField name={field} options={getStatusConstant()}
                                                    isClearable={false} />
                                            </Cell>
                                        );
                                    case "note":
                                        return (
                                            <Cell style={{ flex: '100%' }}>
                                                <TextAreaField name={field} classNameInput={"text-area-input"} />
                                            </Cell>
                                        );

                                    default:
                                    // code block
                                }
                            })}
                    </Row>
                    <div className={"gird-0"} style={{ position: "relative" }}>
                        {!readOnlyFirstGuest && <div style={{ zIndex: 1, position: 'absolute', right: '300px' }}>
                            <SwitchField name={"isReOrder"} widthLabel={125}
                                tooltip={t("BookingForm-REORDER_SEGMENT_WHEN_CHANGE_DURATION")} />
                        </div>}
                        <BookingServiceComponent id={0} ref={gridRef1} technicianId={technicianId} startTime={startTime}
                            initialValues={init} initData={init.segments}
                            genderGuest={_.get(init, 'firstGuest.gender')}
                            readOnly={readOnlyFirstGuest} isCreate={isCreate} />
                    </div>


                    {!formDefaults.readOnly && _.size(listIdGuest) === 0 && renderAddBlock(0)}

                    <FieldArray name="guests">
                        {({ fields }) => {
                            return (
                                <div className="list-timeline">
                                    {fields.map((name, index) => {
                                        const id = fields.value[index].id;
                                        const isLast = _.size(fields) === index + 1 ?? true
                                        return renderSegment(name, index, id, isLast);
                                    })}
                                </div>
                            );
                        }}
                    </FieldArray>

                </div>

            </Form>
        );
    };

    const renderAddBlock = (index) => {
        const title = t("BookingForm-ADD_GUEST");
        return (
            <Tooltip title={title}>
                <div className="block-item-add">
                    <hr />
                    <div className="block-item-add-icon" onClick={() => addGuest(index)}>
                        <i className="fa fa-plus-circle" />
                    </div>
                </div>
            </Tooltip>
        );
    };

    const titleFieldsetGuest = (index, status) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{t("BookingForm-GUEST")} #{index + 1}</span>
            {!isCreate && status && <span style={{ marginLeft: 8 }} className={"status-booking " + status}>
                {`${t("BookingHistoryListView-" + status.toUpperCase())}`}
            </span>}
        </div>
    }

    const renderSegment = (field, index, id, isLast) => {
        const { technicianId, startTime } = state;
        const guests = initialValues.current.guests
        const guestInfo = _.find(guests, x => x.id === id)
        const statusGuest = _.get(guestInfo, 'status')
        let readOnlyGuest = false
        if (!isCreate) {
            readOnlyGuest = [BookingStatusContant.STATUS_COMPLETE, BookingStatusContant.STATUS_CANCEL, BookingStatusContant.STATUS_NOSHOW].indexOf(statusGuest) >= 0
        }
        return (
            <div className="guests" key={`guest-${id}`}>
                {
                    !formDefaults.readOnly && statusGuest !== BookingStatusContant.STATUS_COMPLETE &&
                    <button className={"btn-danger btn-remove-guest"} onClick={() => removeGuest(field.fields, index)}>
                        <Icon type="close" style={{ fontSize: 14 }} />
                    </button>
                }
                <Fieldset collapsed={false}
                    title={titleFieldsetGuest(index, statusGuest)}
                    style={{}}>
                    <div style={{ marginTop: 5 }}>
                        <Row>
                            <Cell style={{ marginRight: 60 }}>
                                <TextField key={`name-${id}`} name={`${field}.name`}
                                    label={`${t("BookingForm-GUEST_NAME")}`}
                                    upperCase={true}
                                    required={true} />
                            </Cell>
                            <Row style={{ flex: 1 }}>
                                <Cell style={{ height: 50, paddingLeft: 30 }}>
                                    <SwitchField readOnly={readOnlyGuest} key={`block-${id}`} name={`${field}.block`}
                                        label={t("BookingForm-LOCK_TECHNICIAN")} />
                                </Cell>
                                {!(formDefaults.readOnly || readOnlyGuest) && <Cell style={{ height: 50 }}>
                                    <SwitchField readOnly={readOnlyGuest} key={`block -${id}`}
                                        name={`${field}.isReOrder`}
                                        widthLabel={125}
                                        label={t("BookingForm-REORDER")} />
                                </Cell>}
                            </Row>
                        </Row>
                        <Row>
                            <Cell>
                                <BookingServiceComponent ref={gridsRef[index]} id={id} technicianId={technicianId}
                                    startTime={startTime}
                                    readOnly={formDefaults.readOnly || readOnlyGuest}
                                    genderGuest={_.get(guestInfo, 'gender')}
                                    initData={_.get(guestInfo, "segments")}
                                    isCreate={isCreate} />
                            </Cell>
                        </Row>
                    </div>

                </Fieldset>
                {!formDefaults.readOnly && isLast && renderAddBlock(index + 1)}
            </div>
        );
    };

    const renderModalPayment = () => {
        return <Modal draggable ref={modalPayment} onClose={onCloseDefault} maskClosable={false}
            className="popup-crud popup-container popup-method" />;
    };

    const renderNotice = () => {
        return <div />;
    };

    if (state.loading) return <Loading loadingHeight={500} />;
    return (
        <div className={className}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                }}>
                <div
                    style={{
                        flex: 1,
                        minHeight: 400,
                        display: "flex",
                        alignItems: "center"
                    }}>
                    {renderNotice()}
                    {renderForm()}
                </div>
                {renderModalPayment()}
                <AlertAntd ref={refAlertAntd} />
            </div>
        </div>
    );
});
export default BookingForm;
