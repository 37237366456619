import React, {useContext, useMemo, useState} from 'react';


export const ContentPaymentContextStep = React.createContext({
    stepCurrent: "",
    valuesProps: {
        bookingId: null,
        guestId: null,
        onClose: () => {
        },
        onReloadData: () => {
        },
        branchId: null,
        isDetail: false,
        isDetailCart: false,
        step: "",
        //for payment
        cartIds: null, billId: null
    },
    reloadInit: 0
});
export const ContentStepPaymentProvider = ({vlProps, step, children}) => {

    const [stepCurrent, setStepCurrent] = useState(step)
    const [valuesProps, setValuesProps] = useState(vlProps)
    const [reloadInit, setReloadInit] = useState(0)

    const value = useMemo(() => ({
        stepCurrent,
        setStepCurrent,
        valuesProps,
        setValuesProps,
        reloadInit,
        setReloadInit
    }), [
        stepCurrent,
        setStepCurrent,
        valuesProps,
        setValuesProps,
        reloadInit,
        setReloadInit
    ]);


    return (
        <ContentPaymentContextStep.Provider value={value}>
            {children}
        </ContentPaymentContextStep.Provider>
    );
};

export const useContentPaymentContextStep = () => useContext(ContentPaymentContextStep);