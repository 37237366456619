import React, { Component } from 'react'

export default class IconCoupon extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g fill="none" fill-rule="evenodd">
                    <path fill="#0D5CB6" d="M18.549 4c.757 0 1.378.73 1.445 1.662L20 5.83v1.958c-1.268.247-2.222 1.323-2.222 2.613s.954 2.366 2.222 2.613v1.958c0 .954-.58 1.737-1.32 1.822l-.131.007H1.452c-.757 0-1.379-.73-1.446-1.662l-.005-.167L0 13.013c1.268-.246 2.223-1.323 2.223-2.613S1.268 8.033 0 7.787V5.829c0-.954.58-1.737 1.32-1.822L1.452 4h17.097zM1.517 5.065l-.067.002c-.11.012-.292.247-.33.617l-.008.145-.002 1.197.09.041c1.217.591 2.051 1.772 2.128 3.128l.006.205c0 1.44-.857 2.712-2.134 3.333l-.09.04.001 1.162.004.13c.027.38.195.612.3.66l.037.008 17.031.002.067-.002c.11-.012.292-.247.331-.617l.008-.144-.001-1.2-.088-.04c-1.217-.59-2.05-1.772-2.127-3.127l-.006-.205c0-1.44.856-2.712 2.133-3.332l.088-.042.002-1.161-.004-.13c-.028-.38-.195-.612-.3-.66l-.037-.008-17.032-.002zM12.5 12c.46 0 .833.358.833.8 0 .442-.373.8-.833.8-.46 0-.834-.358-.834-.8 0-.442.373-.8.834-.8zm.442-4.424c.217.208.24.531.072.765l-.072.083-5 4.8c-.244.235-.64.235-.884 0-.217-.208-.241-.531-.072-.765l.072-.083 5-4.8c.244-.235.64-.235.884 0zM7.5 7.2c.46 0 .833.358.833.8 0 .442-.373.8-.833.8-.46 0-.834-.358-.834-.8 0-.442.374-.8.834-.8z" />
                </g>
            </svg>

        )
    }
}
