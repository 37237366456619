export default {
    STEP_2_CART_BILL: "STEP_2",
    STEP_2_CART_BILL_DETAIL: "STEP_2_DETAIL",
    STEP_3_PAYMENT: "STEP_3",
    STEP_3_PAYMENT_DETAIL: "STEP_3_DETAIL",
    STEP_3_PAYMENT_UPDATE: "STEP_3_UPDATE",
    STEP_3_PAYMENT_ONLY_CART: "STEP_3_PAYMENT_ONLY_CART",

    //// THANH TOÁN CART
    STEP_2_CART_BILL_NO_BOOKING: "STEP_2_NO_BOOKING", //create cart
    STEP_2_CART_DETAIL_NO_BOOKING: "STEP_2_CART_DETAIL_NO_BOOKING", //update/detail cart
    STEP_3_PAYMENT_NO_BOOKING: "STEP_3_NO_BOOKING",

};
