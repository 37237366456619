import { t } from '@idtek/component/lib/language';
import _ from 'lodash';

export const TECHNICIAN_WORKING_TYPE_FULL_TIME = "FULL_TIME";
export const TECHNICIAN_WORKING_TYPE_WORKING = "WORKING";
export const TECHNICIAN_WORKING_TYPE_OFF = "OFF";
export const TECHNICIAN_WORKING_TYPE_OFF_AL = "OFF_AL";
export const TECHNICIAN_TYPE = "Technician";
export const TIME_SHEET_TYPE = "TimeSheet";
export const HISTORY_TYPE_QUICK_TIME_SHEET = "QUICK_TIME";

export const TECHNICIAN_WORKING_COMBO = [
    { label: t("TechnicianConstant-FULL_TIME"), value: TECHNICIAN_WORKING_TYPE_FULL_TIME },
    { label: t("TechnicianConstant-SHIFT_WORK"), value: TECHNICIAN_WORKING_TYPE_WORKING },
    { label: t("TechnicianConstant-OFF"), value: TECHNICIAN_WORKING_TYPE_OFF },
];

export const TECHNICIAN_WORK_HOUR = [
    { label: t("TechnicianConstant-SHIFT_WORK"), value: TECHNICIAN_WORKING_TYPE_WORKING },
    { label: t("TechnicianConstant-UNPAID_TIME_OFF"), value: TECHNICIAN_WORKING_TYPE_OFF },
    { label: t("TechnicianConstant-PAID_TIME_OFF"), value: TECHNICIAN_WORKING_TYPE_OFF_AL },
];

export const DEFAULT_COLUMNDEF_HISTORY_TIMESHEET = [{
    headerName: t("TechnicianListView-CREATED_BY"),
    field: "createdBy",
    width: 140,
}, {
    headerName: t("TechnicianListView-WORK_TYPE"),
    field: "type",
    minWidth: 180,
    valueGetter: (data) => {
        const oldType = _.get(data, 'data.oldType')
        const type = _.get(data, 'data.type')
        return `${t("TechnicianConstant-" + oldType)}  >>  ${t("TechnicianConstant-" + type)}`
    }
}, {
    headerName: t("modelLoyaltyDiscount-DATE_APPLY"),
    field: "applyDate",
    minWidth: 100,
    textAlign: "center",
    valueGetter: (data) => {
        return _.get(data, "data.editInfo.applyDate")
    }
}, {
    headerName: t("TechnicianListView-OLD_TIME"),
    field: "oldWorkHouse"
}, {
    headerName: t("TechnicianListView-NEW_TIME"),
    field: "newWorkHouse"
}, {
    headerName: t("TechnicianListView-CREATED_TIME"),
    field: "actionTime",
    width: 120
}, {
    headerName: t("TechnicianHistoryComponent-ACTION_TYPE"),
    field: "action"
}];

export const DEFAULT_COLUMNDEF_HISTORY_QUICK_TIME_SHEET = [{
    headerName: t("TimeSheetListView-FROM_DATE"),
    field: "fromDate",
    textAlign: "center",
    minWidth: 100
}, {
    headerName: t("TimeSheetListView-TO_DATE"),
    field: "toDate",
    textAlign: "center",
    minWidth: 100
}, {
    headerName: t("TechnicianListView-CREATED_BY"),
    field: "createdBy"
}, {
    headerName: t("TechnicianListView-CREATED_TIME"),
    field: "actionTime",
    width: 120
}, {
    headerName: t("TechnicianHistoryComponent-ACTION_TYPE"),
    field: "action"
}]