export default {
    API_LOAD_TECHNICAN_BY_DAY: "/technician/get-technician-by-day",
    API_LOAD_COMBO_CUSTOMER: "/customer/get-combo-by-branch-id",
    API_SEARCH_TEXT_COMBO_CUSTOMER: "/customer/search-text-get-combo",
    API_LOAD_COMBO_SERVICE: "/service/flat-service-child",
    API_LOAD_TREE_SERVICE: "/service/index-tree-by-branch-id",
    API_CREATE_BOOKING: "/booking/create-with-customer",
    API_CREATE_HISTORY_BOOKING: "/booking/create-history-with-customer",

    API_UPDATE_BOOKING: "/booking/update-booking",
    API_UPDATE_HISTORY_BOOKING: "/booking/update-booking-history",

    API_CREATE_AND_START_BOOKING: "/booking/create-with-customer-start",
    API_GET_DETAIL_BOOKING: "/booking/get-detail-booking",
    API_UPDATE_SEGMENT: "/booking/update-segment",
    API_GET_OPTION_PAYMENT: "/bill/checkout-payment-method",
    API_SEARCH_CART: "/bill/search-cart-add-to-bill",
    API_SEARCH_GUEST: "/bill/search-guest-add-to-bill",
    API_INIT_SEARCH_GUEST: "/bill/init-and-search-guest-add-to-bill",
    API_UPDATE_STATUS: "/booking/update-status",
    API_GET_INFO_PAYMET: "/bill/get-guest-cart-info",

    API_CHECK_VOUCHER: "/bill/check-voucher",
    API_CHECK_GIFT: "/bill/check-gift",
    API_CREATE_BILL: "/bill/create-bill",
    API_UPDATE_BILL_FROM_DRAFT: "/bill/update-bill-from-draft",
    API_UPDATE_BILL_FROM_COMPLETE_STATUS: "/bill/update-bill-complete-status",
    API_CREATE_BILL_CART: "/bill/create-bill-cart",
    API_UPDATE_BILL_CART: "/bill/update-bill-cart",
    API_UPDATE_CART: "/cart/update-cart",

    API_CANCEL_BILL_CART: "/bill/cancel-bill-cart",
    API_CANCEL_CART_BILL: "/cart/cancel-cart-bill",
    API_PRINT_BILL: "/bill/print-bill",
    API_PRINT_BILL_BY_ID: "/bill/print-bill-by-id",
    API_PRINT_BILL_SLIP: "/bill/print-bill-slip",
    API_PRINT_BILL_CASH: "/bill/print-bill-cash",

    API_GET_BILL_BY_GUEST: "/bill/get-bill-by-guestId",
    API_GET_BILL_BY_BOOKING: "/bill/get-all-bill-by-booking",
    API_GET_BILL_BY_ID: "/bill/get-bill-by-id",
    API_GET_BILL_NO_BOOKING: "/bill/get-bill",

    API_UPDATE_STATUS_BOOKING: "/booking/update-status",
    API_UPDATE_STATUS_GUEST: "/booking/update-status-guest",
    API_UPDATE_STATUS_BOOKING_FROM_NOSHOW: "/booking/update-status-from-no-show",


    API_GET_INFO_FORM_ADD_CART: "/bill/get-add-cart-form-info",
    API_GET_DETAIL_BILL_ADD_CART: "/bill/get-detail-bill-cart",
    API_GET_DETAIL_ONLY_CART: "/bill/get-detail-only-cart",


};
