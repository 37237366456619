/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import Loading from "../../../pages/Loading";
import useGetDetailPayment from "../hook/useGetDetailPayment";
import useGetOptionsPayment from "../hook/useGetOptionsPayment";
import "../style/payment-v3.scss";
import "../style/proceed-payment.scss";
import PaymentForm from "../form/PaymentForm";
import _ from "lodash";
import {PaymentCreateProvider} from "../context/PaymentCreateProvider";

const PaymentContainer = ({branchId, bookingId, guestId, onClose, onReloadData, cartIds, billId, customerIdCart}) => {
    const optData = useGetOptionsPayment(branchId);
    const initValue = useGetDetailPayment(guestId, cartIds, bookingId, billId);
    if (initValue.loading || _.isEmpty(optData)) return <Loading loadingHeight={500}/>;
    return (
        <div className="proceed-payment-wrap payment-v3">
            <PaymentCreateProvider optData={optData} initValue={initValue} branchId={branchId} bookingId={bookingId}
                                   guestId={guestId} guestIds={_.isArray(guestId) ? guestId : [guestId]}
                                   cartIds={cartIds} billId={billId} customerIdCart={customerIdCart}>
                <PaymentForm onClose={onClose} onReloadData={onReloadData}/>
            </PaymentCreateProvider>
        </div>
    );
};
export default PaymentContainer;
