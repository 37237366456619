import {BookingStatusContant} from "../constant/BookingStatusContant";
import _ from "lodash";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import AppUtil from "../../../utils/AppUtil";
import Alert from "@idtek/component/lib/alert/Alert";
import {t} from '@idtek/component/lib/language';

export default class AppointmentAction {
    static allGuestInGroupIsSameBooking = (getBookingSchedule, status, bookingId) => {
        return _.map(_.uniqBy(_.filter(getBookingSchedule(), x => x.BookingId === bookingId && x.Status === status), g => g.GuestId), u => u.GuestId)
    }
    static doActionUpdateStatusBooking = async (actionName, node, onReloadData, getBookingSchedule) => {
        let status = "";
        let action = actionName
        let url = ApiBookingConstant.API_UPDATE_STATUS_BOOKING
        const params = {
            bookingId: node.BookingId,
            force: false,
        };
        switch (actionName) {
            case "check-in":
            case "check-in-all":
            case "check-in-only":
                status = BookingStatusContant.STATUS_CHECKIN;
                action = "check-in"
                break;
            case "start":
            case "start-only":
            case "start-all":
                status = BookingStatusContant.STATUS_PROCESSING;
                action = "start"
                break;
            case "complete":
                status = BookingStatusContant.STATUS_COMPLETE;
                break;
            case "cancel":
            case "cancel-only":
            case "cancel-all":
                status = BookingStatusContant.STATUS_CANCEL;
                action = "cancel"
                break;
            case "no-show":
            case "no-show-only":
            case "no-show-all":
                status = BookingStatusContant.STATUS_NOSHOW;
                action = "no-show"

                break;
            default:
            // code block
        }
        switch (actionName) {
            case "check-in":
            case "check-in-only":
            case "start":
            case "start-only":
            case "cancel":
            case "cancel-only":
            case "no-show":
            case "no-show-only":
                params.guestId = node.GuestId
                url = ApiBookingConstant.API_UPDATE_STATUS_GUEST
                break;
            case "check-in-all":
            case "start-all":
            case "cancel-all":
            case "no-show-all":
                params.guests = this.allGuestInGroupIsSameBooking(getBookingSchedule, node.Status, node.BookingId)
                break;
            default:
            //
        }
        params.status = status

        let confirm = null
        if (action === "cancel") {
            confirm = await Alert.Swal_confirm(t('AppointmentAction-CONFIRM'), t(`AppointmentAction-ARE_YOU_SURE_YOU_WANT_TO_${action.toUpperCase()}?`), 3, {
                input: 'checkbox',
                inputValue: 1,
                inputPlaceholder:
                    t('AppointmentAction-SEND_NOTIFICATION_TO_CUSTOMER'),
                showCancelButton: true,
                confirmButtonText:
                    t('AppointmentAction-CONFIRM'),
            });
        } else {
            confirm = await Alert.Swal_confirm(t('AppointmentAction-CONFIRM'), t(`AppointmentAction-ARE_YOU_SURE_YOU_WANT_TO_${action.toUpperCase()}?`), 3);
        }
        if (_.get(confirm, "isConfirmed") === true) {
            _.get(confirm, "value") === 1 ? params.statusSendNotification = true : params.statusSendNotification = false;
            const response = await AppUtil.postApi(url, params);
            if (_.get(response, "data.success")) {
                AppUtil.ToastSuccess();
                onReloadData && onReloadData();
            } else {
                AppUtil.ToastApiError(_.get(response, "data.message"));
            }
        }

    }
}