import React, {Component} from 'react';

export default class IconFilter extends Component {
    render() {
        return (
            <img
                src="data:image/gif;base64,R0lGODlhCgAKAIAAAPn5+Tt9sSH5BAAAAAAALAAAAAAKAAoAAAISTACGqHnLXosUulqbfll6uEEFADs="
                alt={"icon"}
                style={{marginRight : 5}}
            />
        );
    }
}


