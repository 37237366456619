import Icon from '@idtek/component/lib/icon/Icon'
import Form from '@idtek/form/lib/IDForm'
import _ from 'lodash'
import moment from 'moment'
import React, {useRef} from 'react'
import AppUtil from '../../../utils/AppUtil'
import {t} from '@idtek/component'
import ApiBookingConstant from '../../appointment/constant/ApiBookingConstant'
import {usePaymentContext} from '../../appointment/context/PaymentContext'
import {useContentPaymentContextStep} from '../../appointment/context/ContentPaymentContextStep'
import PrintBillComponent from '../../appointment/component/PrintBillComponent'
import {modelPayment} from '../../appointment/model/modelPayment'
import RightPayment from '../../appointment/component/RightPayment'
import PaymentConstant from '../../appointment/constant/PaymentConstant'
import LeftPaymentCart from '../component/LeftPaymentCart'
import AppStore from '../../../store'
import {allRights} from '../../../utils/rightUtils'
import Alert from '@idtek/component/lib/alert/Alert'

const PaymentCartForm = ({
                             onClose,
                             onReloadData,
                         }) => {
    const {
        initialValues,
        branchId,
        method,
        resultPayment,
        paymentData,
        receivedObj,
        backMoney,
        isDetailPayment,
        optionsData,
        technicianTip,
        tips,
        billId,
        isUseHst,
    } = usePaymentContext()
    const {
        stepCurrent,
        setStepCurrent,
        valuesProps,
        setValuesProps,
    } = useContentPaymentContextStep()
    const formRef = useRef(null),
        refCpnPrintBill = useRef(null)
    const formDefaults = {
        alignLabel: 'left',
        widthLabel: '130px',
        textAlign: 'left',
        readOnly: isDetailPayment,
    }

    const handlePostAction = (values) => {
        const {checkOutDefault, checkOutBack} = optionsData

        const results = []
        _.each(receivedObj, (item) => {
            const obj = {}
            obj.branchId = branchId
            obj.billId = billId ?? null
            obj.checkoutMethodId = item.checkoutMethodId
            obj.type = 'IN'
            obj.actionTime = moment(values.actionTime).format('YYYY-MM-DD HH:mm')
            obj.value = item.value
            results.push(obj)
        })

        const objBack = {
            branchId: branchId,
            billId: billId ?? null,
            checkoutMethodId: checkOutBack,
            type: 'OUT',
            actionTime: moment(values.actionTime).format('YYYY-MM-DD HH:mm'),
            value: backMoney,
        }

        if (tips && tips > 0) {
            const objTip = {
                branchId: branchId,
                billId: null,
                checkoutMethodId: values.typeCheckoutTip,
                type: 'TIP',
                actionTime: moment(values.actionTime).format('YYYY-MM-DD HH:mm'),
                value: tips,
                technicianTips: technicianTip,
            }
            results.push(objTip)
        }
        results.push(objBack)
        return results
    }

    const onFinish = async (form, values, printBillAction = false) => {
        form && form.mask()
        if (backMoney < 0) {
            AppUtil.ToastError(t('PaymentCartForm-NOT_ENOUGH_MONEY_TO_PAYMENT!'))
            form && form.unmask()
            return
        }
        if (!isDetailPayment) {
            let url = '/bill/create-bill-only-cart'
            const dataPost = {}
            const {
                valueRowTotalAmount,
                valueRowHST,
                valueRowPM,
                subTotalPayment,
                valueFinally,
            } = resultPayment
            dataPost.branchId = branchId
            dataPost.giftCardCode = _.get(values.giftCode, 'code', '')
            dataPost.voucherCode = _.get(values.voucherCode, 'code', '')
            dataPost.paymentMethodId = _.get(method, 'id')
            dataPost.paymentFee = valueRowPM
            dataPost.fee = 0
            dataPost.tax = valueRowHST
            dataPost.total = valueRowTotalAmount
            dataPost.customerId = values.customerId
            dataPost.guestIdList = []
            dataPost.cartIdList = _.map(paymentData.cartList, (x) => x.id)
            dataPost.posActions = handlePostAction(values)
            dataPost.subTotal = subTotalPayment
            dataPost.isHst = values.isHst
            if (values.actionTime) {
                dataPost.actionTime = moment(values.actionTime).format('YYYY-MM-DD HH:mm')
            }

            if (values.moneyPoint) {
                dataPost.customerTransactionAmount = parseFloat(values.moneyPoint)
            }
            const response = await AppUtil.postApi(url, dataPost)
            if (_.get(response, 'data.success')) {
                AppUtil.ToastSuccess()
                if (printBillAction) {
                    await printBill(_.get(response, 'data.result.id'))
                    form && form.unmask()

                } else {
                    form && form.unmask()
                    onClose && onClose()
                    onReloadData && onReloadData()
                }
            } else {
                AppUtil.ToastApiError(_.get(response, 'data.message'))
                form && form.unmask()
            }
        } else {
            await printBill(initialValues.billId)
            form && form.unmask()
        }
    }

    const printBill = async (id) => {
        const response = await AppUtil.getApi(ApiBookingConstant.API_PRINT_BILL_BY_ID, {billId: id})
        if (_.get(response, 'data.success')) {
            const template = response.data.result
            onClose && onClose()
            refCpnPrintBill.current && refCpnPrintBill.current.onChangBill(template)
            onReloadData && onReloadData()
        } else {
            AppUtil.ToastApiError(_.get(response, 'data.message'))
        }
    }

    const onGoBack = () => {
        const vlProps = {
            ...valuesProps,
            cartIds: _.map(paymentData.cartList, (x) => x.id),
            onClose: onClose,

        }
        setValuesProps(vlProps)
        setStepCurrent(PaymentConstant.STEP_2_CART_DETAIL_NO_BOOKING)
    }
    const onCancelBill = async (form, values) => {
        const confirm = await Alert.Swal_confirm(t('PaymentCartForm-CONFIRM'), t('PaymentCartForm-ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_BILL?'), 3)

        if (_.get(confirm, 'value') === true) {
            form && form.mask()
            const res = await AppUtil.getApi(`/bill/cancel-cart-completed`, {billId: values.billId})
            form && form.unmask()
            if (_.get(res, 'data.success')) {
                AppUtil.ToastSaveSuccess(t('PaymentCartForm-CANCEL_SUCCESS'))
                onClose && onClose()
                onReloadData && onReloadData()
            } else {
                AppUtil.ToastApiError(_.get(res, 'data.message'))
            }
        }
    }

    const renderLeft = () => {
        return (<div className="container-left">
            <LeftPaymentCart/>
        </div>)
    }

    const renderRight = () => {
        return (<div className="container-right">
            <RightPayment formRef={formRef}/>
        </div>)
    }

    const renderButtons = () => {
        const buttons = []
        if (!isDetailPayment) {
            buttons.unshift({
                text: t('PaymentCartForm-FINISH_PAYMENT'),
                type: 'submit',
                className: 'btn-primary-lighter', // style: {width: "150px"},
                icon: <Icon type="check-square" style={{fontSize: 16}}/>,
                onPress: (form, values) => onFinish(form, values),
            })
            buttons.unshift({
                text: t('PaymentCartForm-BACK_TO_CART'),
                className: 'btn-primary', // style: {width: "150px"},
                icon: <Icon type="shopping-cart" style={{fontSize: 16}}/>,
                onPress: () => onGoBack(),
            })
        }
        const rights = _.get(AppStore.getState(), `root.rights`, [])
        const rightCancelBill = AppUtil.checkPermission(allRights.OLD_BILL_CANCEL, rights)
        if (isDetailPayment && rightCancelBill) {
            buttons.push({
                text: t('PaymentCartForm-CANCEL_BILL'),
                type: 'submit',
                className: 'btn-danger',
                icon: <Icon type="close-circle" style={{fontSize: 16}}/>, // style: {width: "150px"},
                onPress: (form, values) => onCancelBill(form, values),
            })
        }
        buttons.push({
            text: t('PaymentCartForm-PRINT_BILL'),
            type: 'submit',
            className: 'btn-success',
            icon: <Icon type="printer" style={{fontSize: 16}}/>, // style: {width: "150px"},
            onPress: (form, values) => onFinish(form, values, true),
        })
        buttons.push({
            text: t('PaymentCartForm-CLOSE'),
            className: 'btn-secondary',
            icon: <Icon type="close-square" style={{fontSize: 16}}/>, // style: {width: "150px"},
            onPress: () => onClose && onClose(),
        })
        return buttons
    }

    const renderBill = () => {
        return <PrintBillComponent ref={refCpnPrintBill}/>
    }

    return (<Form ref={formRef}
                  initialValues={initialValues}
                  model={modelPayment}
                  buttons={renderButtons()}
                  defaults={formDefaults}>
        <div className={isDetailPayment ? 'proceed-payment detail-payment' : 'proceed-payment'}>
            {renderLeft()}
            {renderRight()}
        </div>
        {renderBill()}
    </Form>)
}

export default PaymentCartForm
