// những hàm render ko bao giờ sửa
import {addClass} from "@syncfusion/ej2-base";
import React from "react";
import {BookingStatusContant} from "../constant/BookingStatusContant";
import _ from "lodash";
import Icon from "@idtek/component/lib/icon/Icon";
import moment from "moment";
import Avatar from "@idtek/component/lib/avatar/Avatar";
import AppUtil from "../../../utils/AppUtil";
import {
    TECHNICIAN_WORKING_TYPE_OFF,
    TECHNICIAN_WORKING_TYPE_OFF_AL
} from "../../technician/constant/TechnicianConstant";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import {t} from "@idtek/component/lib/language";

export default class AppointmentRenderUtil {
    static cellTemplate = (args, technicianSchedule, onUnbreak) => {
        if (args.type === "workCells") {
            const formatType = "YYYY-MM-DD HH:mm";
            const techWorking = _.get(technicianSchedule[args.groupIndex], 'technicianWorkings', [])
            const timesOff = _.filter(techWorking, item => item.type === TECHNICIAN_WORKING_TYPE_OFF || item.type === TECHNICIAN_WORKING_TYPE_OFF_AL)
            if (!_.isEmpty(timesOff)) {
                const dateOfCell = moment(args.date).format("YYYY-MM-DD")
                if (dateOfCell < moment().format("YYYY-MM-DD")) {
                    return <></>
                }
                const dateCell = moment(args.date).format(formatType)
                const cellTimesheetOff = _.find(timesOff, item =>
                    moment(item.startDate).format(formatType) === dateCell
                    && dateCell < moment(item.endDate).format(formatType)
                )
                if (cellTimesheetOff) {
                    const noteTimeOff = cellTimesheetOff.note
                    return <div className={"break-time-cell"}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className={"unbreak-click"} onClick={() => onUnbreak && onUnbreak(cellTimesheetOff)}>
                                {t("AppointmentRenderUtil-UNBREAK")}
                            </span>
                            <span style={{
                                marginLeft: 6,
                                fontSize: 9,
                                lineHeight: "19px",
                                // fontStyle: 'italic',
                                fontWeight: 400
                            }}>({cellTimesheetOff.type === TECHNICIAN_WORKING_TYPE_OFF_AL ? t("AppointmentRenderUtil-PAID") : t("AppointmentRenderUtil-UNPAID")})
                            </span>
                        </div>
                        {noteTimeOff && <Tooltip title={noteTimeOff} placement={'bottomLeft'}>
                            <div className={"unbreak-note"}>
                                <div>{`${t("AppointmentRenderUtil-NOTE")}: `}</div>
                                <div className={"note-desc"}>{noteTimeOff}</div>
                            </div>
                        </Tooltip>}
                    </div>
                }
            }
        }
        return <></>
    }

    static onRenderCell = (args, toggleMouseTooltip, onClickMouse) => {
        if (args) {
            if (args.elementType === "dateHeader") {
                addClass([args.element], "display-none");
            }
            if (args.elementType === "emptyCells") {
                addClass([args.element], "content-header");
                args.element.innerHTML = t('Time/Techn')
            }
            if (args.elementType === "workCells") {
                args.element.onmouseover = function () {
                    toggleMouseTooltip(args.date);
                };
                args.element.onclick = function () {
                    onClickMouse();
                };
            }
        }
    }

    static onEventRendered = (args, getBookingSchedule) => {
        if (args) {
            const {data} = args;
            let statusBackground = "";
            let colorBorder = "";
            if (data) {
                switch (data.Status) {
                    case BookingStatusContant.STATUS_WAITING:
                        statusBackground = "#fbf6ca";
                        colorBorder = "#f5ac53";
                        break;
                    case BookingStatusContant.STATUS_CHECKIN:
                        statusBackground = "#e5ddf6";
                        colorBorder = "#a87fd9";
                        break;
                    case BookingStatusContant.STATUS_PROCESSING:
                        statusBackground = "#D1EEFF";
                        colorBorder = "#2c8eb8";
                        break;
                    case BookingStatusContant.STATUS_COMPLETE:
                        statusBackground = "#f2ffe4";
                        colorBorder = "#73a839";
                        break;
                    case BookingStatusContant.STATUS_CANCEL:
                    case BookingStatusContant.STATUS_NOSHOW:
                        statusBackground = "#eee";
                        colorBorder = "#ccc";
                        break;
                    default:
                        args.cancel = true;
                }
            }
            // const findSimilar = _.filter(getBookingSchedule(), (x) => x.BookingId === data.BookingId);
            // const sizeSimilar = _.size(findSimilar);
            args.element.style.backgroundColor = statusBackground;
            args.element.style.border = `0px solid #fff`;
            if (data.Color && data.Color !== "#ffffff") {
                if (data.DurationChange) {
                    args.element.style.borderLeft = `4px dashed ${data.Color}`;
                } else {
                    args.element.style.borderLeft = `4px solid ${data.Color}`;
                }
            }
            args.element.style.color = `#000`;
            // args.element.style.borderTop = `1px dashed ${data.Color}`;
            // args.element.style.borderTopLeftRadius = "6px"
            args.element.style.borderRadius = "0px"

            args.element.classList.add(data.Status);
        }
    }

    static eventTemplate = (args, waitingList, getBookingSchedule, openDetailCustomer, getActiveCellsData) => {
        const {
            Subject,
            StartTime,
            EndTime,
            CustomerName,
            Phone,
            Status,
            PaymentMethod,
            Block,
            Color,
            TypeBooking,
            Description,
            Confirm,
            CustomerId,
            BookingId,
            Id,
            GuestId,
            Gender,
            IsCustomerNew
        } = args;
        let warning = <></>;
        if (_.find(waitingList, (x) => x.Id === BookingId)) {
            warning = <Icon type="exclamation-circle" style={{color: "red", fontSize: 12}}/>;
        }
        let block = <></>;
        if (Block) {
            block = <Icon type="lock" style={{color: "red", fontSize: 12}}/>;
        }
        let confirm = <></>;
        if (Confirm) {
            confirm = <Icon type="like" style={{color: "#00a98f", fontSize: 12}}/>;
        }
        let genderIcon = <></>
        if (Gender === "MALE") {
            genderIcon = <Icon type="man" style={{color: "rgb(30 116 223)", fontSize: 12}}/>;
        } else if (Gender === "FEMALE") {
            genderIcon = <Icon type="woman" style={{color: "rgb(231 34 179)", fontSize: 12}}/>;
        }
        let progressIcon = <></>
        switch (Status) {
            case BookingStatusContant.STATUS_WAITING:
                progressIcon = <span className={"progress-circle progress-25"}/>
                break;
            case BookingStatusContant.STATUS_CHECKIN:
                progressIcon = <span className={"progress-circle progress-50"}/>
                break;
            case BookingStatusContant.STATUS_PROCESSING:
                progressIcon = <span className={"progress-circle progress-75"}/>
                break;
            case BookingStatusContant.STATUS_COMPLETE:
                progressIcon = <span className={"progress-circle progress-100"}/>
                break;
            default:
                args.cancel = true;
        }
        const dataSchedule = getBookingSchedule()
        const findSimilar = _.filter(dataSchedule, (x) => x.GuestId === GuestId);
        const index = _.findIndex(findSimilar, (x) => x.Id === Id);
        const sizeSimilar = _.size(findSimilar);

        let groupIcon = <></>
        const findGroup = _.groupBy(_.filter(dataSchedule, (x) => x.BookingId === BookingId), 'GuestId')
        if (_.size(findGroup) > 1) {
            groupIcon = <Icon type="team" style={{color: Color, fontSize: 12}}/>;
        }
        return (
            <div className="template-wrap-lmt">
                {PaymentMethod && Status === BookingStatusContant.STATUS_COMPLETE ? (
                    <>
                        <div className="e-subject subject">
                            {TypeBooking === "TYPE_BOOKING_ONLINE" &&
                                <span style={{color: "#f1632a", marginTop: -1}}>@</span>}
                            {Description &&
                                <Icon style={{color: "#2d72d9", fontSize: 12, lineHeight: "11px"}} type="info-circle"/>}
                            {Subject}
                            {progressIcon}
                        </div>
                        <div className="e-customer link-underline" onClick={(e) => openDetailCustomer(e, CustomerId)}>
                            {CustomerName}
                        </div>
                        <div className="e-phone">{AppUtil.formatPhone(Phone)}</div>
                        <div
                            className="e-time time">{moment(StartTime).format("hh:mm A") + " - " + moment(EndTime).format("hh:mm A")}</div>
                        <div className="e-icon">
                            <Icon style={{color: "#73a839", fontSize: 12}} type="check-circle"/>
                            {groupIcon}
                            {sizeSimilar > 1 && (
                                <div className="link">
                                    <span className="number-link"
                                          style={{color: Color ? Color : "var(--main-color-red)"}}>
                                        {index + 1}/{sizeSimilar}
                                    </span>
                                    <Icon type="link" style={{color: Color ? Color : "red", fontSize: 13}}/>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="e-subject subject">
                            {TypeBooking === "TYPE_BOOKING_ONLINE" &&
                                <span style={{color: "#f1632a", marginTop: -1}}>@</span>}
                            {Description &&
                                <Icon style={{color: "#2d72d9", fontSize: 12, lineHeight: "11px"}} type="info-circle"/>}
                            {Subject}
                            {progressIcon}
                        </div>
                        <div className="e-customer link-underline" onClick={(e) => openDetailCustomer(e, CustomerId)}>
                            {CustomerName}

                        </div>
                        <div className="e-phone">{AppUtil.formatPhone(Phone)}</div>
                        <div
                            className="e-time time">{moment(StartTime).format("hh:mm A") + " - " + moment(EndTime).format("hh:mm A")}</div>
                        <div className="e-icon">
                            {warning}
                            {block}
                            {confirm}
                            {groupIcon}
                            {genderIcon}
                            {sizeSimilar > 1 && (
                                <div className="link">
                                    <span className="number-link"
                                          style={{color: Color ? Color : "var(--main-color-red)"}}>
                                        {index + 1}/{sizeSimilar}
                                    </span>
                                    <Icon type="link" style={{color: Color ? Color : "red", fontSize: 12}}/>
                                </div>
                            )}
                        </div>
                    </>
                )}

            </div>
        );
    }

    static resourceHeaderTemplate = (args, getBookingSchedule) => {
        const {resourceData, resource} = args;
        if (resourceData) {
            // const serviceByTech = _.filter(getBookingSchedule(), (x) => x.TechnicianId === resourceData.id);
            // const bookingByTech = _.size(_.uniqBy(serviceByTech, "GuestId"));
            let classSex = "any-sex";
            let avatar = resourceData.avatar && resourceData.avatar[0] ?
                <Avatar className={"avatar-tech"}
                        style={{width: 28, height: 28, lineHeight: "28px", marginRight: 7, marginLeft: -10}}
                        src={resourceData.avatar[0].url}/> : null;
            if (resourceData.gender === "FEMALE") {
                classSex = "female-sex";
            } else if (resourceData.gender === "MALE") {
                classSex = "male-sex";
            }
            return (
                <div className={`${classSex} header-technician-cell`}>
                    {avatar}
                    <span className={"label-name"}>{resourceData.name}
                        {resourceData.isFullService && "-FS"}</span>

                    {resourceData.bookingByTech > 0 &&
                        <span className="tech-count-booking">{resourceData.bookingByTech}</span>}
                </div>
            );
        }
        return <></>;
    };

    static tooltipTemplate = (props) => {
        const {Subject, StartTime, EndTime, CustomerName, Phone, Status, CustomerNotes, Description, CreatedAt} = props;
        return (
            <div className="tooltip-wrap-custom">
                <div className="row-tt subject">{Subject}</div>
                <div className={`row-tt`}>
                    <span className="sub-title">
                        {`${t("AppointmentRenderUtil-STATUS")}: `}
                    </span>
                    <span
                        className={`status-tooltip ${Status}`}>{t("AppointmentRenderUtil-" + Status.toUpperCase())}</span>
                </div>
                <div className="row-tt customer">
                    <span className="sub-title">
                        {`${t("AppointmentRenderUtil-CUSTOMER")}: `}
                    </span>
                    <span>{CustomerName}</span>
                </div>
                <div className="row-tt phone">
                    <span className="sub-title">
                        {`${t("AppointmentRenderUtil-PHONE")}: `}
                    </span>
                    <span>{AppUtil.formatPhone(Phone)}</span>
                </div>
                <div className="row-tt time">
                    <span className="sub-title">
                        {`${t("AppointmentRenderUtil-TIME")}: `}
                    </span>
                    <span> {`${moment(StartTime).format("hh:mm A")} - ${moment(EndTime).format("hh:mm A")}`}</span>
                </div>
                <div className="row-tt time">
                    <span className="sub-title">
                        {`${t("AppointmentRenderUtil-CREATED_AT")}: `}
                    </span>
                    <span> {moment(CreatedAt).format(AppUtil.GLOBAL_DATETIME_FORMAT)}</span>
                </div>
                {Description && (
                    <div className="row-tt time">
                        <span className="sub-title">
                            {`${t("AppointmentRenderUtil-NOTE")}: `}
                        </span>
                        <span>{Description}</span>
                    </div>
                )}
                {!_.isEmpty(CustomerNotes) && (
                    <div className="row-tt time">
                        <span className="sub-title">
                            {`${t("AppointmentRenderUtil-CUSTOMER_NOTE")}: `}
                        </span>
                        <div>
                            {_.map(CustomerNotes, (x) => {
                                return <div>- {x.note}</div>;
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    };
}