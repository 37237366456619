import Icon from "@idtek/component/lib/icon/Icon";
import Form from "@idtek/form/lib/IDForm";
import _ from "lodash";
import moment from "moment";
import React, {Suspense, useRef} from "react";
import AppUtil from "../../../utils/AppUtil";
import RightPayment from "../component/RightPayment";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import {usePaymentContext} from "../context/PaymentContext";
import {modelPayment} from "../model/modelPayment";
import PrintBillComponent from "../component/PrintBillComponent";
import {Loading, Modal, t} from '@idtek/component'
import LeftPaymentV2 from "../component/LeftPaymentV2";
import {useContentPaymentContextStep} from "../context/ContentPaymentContextStep";
import PaymentConstant from "../constant/PaymentConstant";
import {allRights} from "../../../utils/rightUtils";
import AppStore from "../../../store";
import Alert from "@idtek/component/lib/alert/Alert";
import {TIP_MONEY} from "../../posTransaction/constant/BookingConstant";
import TechnicianEditTipGrid from "../component/TechnicianEditTipGrid";

const PaymentForm = ({
                         onClose,
                         onReloadData,
                     }) => {
    const {
        initialValues,
        branchId,
        method,
        resultPayment,
        paymentData,
        receivedObj,
        backMoney,
        isDetailPayment,
        optionsData,
        technicianTip,
        tips,
        billId,
        isUseHst,
        viewCanCancelPayment,
        initBill
    } = usePaymentContext();
    const {stepCurrent, setStepCurrent, valuesProps, setValuesProps} = useContentPaymentContextStep();

    const formRef = useRef(null),
        refCpnPrintBill = useRef(null);
    const formDefaults = {
        alignLabel: "left",
        widthLabel: "130px",
        textAlign: "left",
        readOnly: isDetailPayment
    };
    const refModal = useRef(null)

    const handlePostAction = (values) => {
        const {checkOutDefault, checkOutBack} = optionsData;
        const results = [];
        _.each(receivedObj, (item) => {
            const obj = {};
            obj.branchId = branchId;
            obj.billId = billId ?? null;
            obj.checkoutMethodId = item.checkoutMethodId;
            obj.type = "IN";
            obj.actionTime = moment(values.actionTime).format("YYYY-MM-DD HH:mm")
            obj.value = item.value;
            results.push(obj);
        });
        const objBack = {
            branchId: branchId,
            billId: billId ?? null,
            checkoutMethodId: checkOutBack,
            type: "OUT",
            actionTime: moment(values.actionTime).format("YYYY-MM-DD HH:mm"),
            value: backMoney
        };
        if (tips && tips > 0) {
            const objTip = {
                branchId: branchId,
                billId: null,
                checkoutMethodId: values.typeCheckoutTip,
                type: "TIP",
                actionTime: moment(values.actionTime).format("YYYY-MM-DD HH:mm"),
                value: tips,
                technicianTips: technicianTip
            }
            results.push(objTip)
        }
        results.push(objBack);
        return results;
    };


    const onCancelBill = async (form, values) => {
        const confirm = await Alert.Swal_confirm(t("PaymentForm-CONFIRM"),
            t("PaymentForm-ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_BILL?")
            , 3);
        if (_.get(confirm, "value") === true) {
            form && form.mask();
            const res = await AppUtil.getApi(`/bill/cancel-bill`, {id: values.billId})
            form && form.unmask();
            if (_.get(res, "data.success")) {
                AppUtil.ToastSaveSuccess(t("PaymentForm-CANCEL_SUCCESS"));
                onClose && onClose();
                onReloadData && onReloadData();
            } else {
                AppUtil.ToastApiError(_.get(t(res), "data.message"));
            }
        }
    }

    const onTip = async (alreadyTip) => {
        refModal.current && refModal.current.onOpen(
            <Suspense fallback={<Loading style={{height: 300}}/>}>
                <TechnicianEditTipGrid
                    onClose={(flag,) => {
                        refModal.current && refModal.current.onClose();
                    }}
                    billId={initBill.id}
                    alreadyTip={alreadyTip}
                />
            </Suspense>,
            AppUtil.renderTitleForm("PaymentForm-DISTRIBUTE_TIP"), '850px'
        )
    }

    const printBill = async (id) => {
        const response = await AppUtil.getApi(ApiBookingConstant.API_PRINT_BILL_BY_ID, {billId: id});
        if (_.get(response, "data.success")) {
            const template = response.data.result;
            onClose && onClose();
            refCpnPrintBill.current && refCpnPrintBill.current.onChangBill(template);
            onReloadData && onReloadData();
        } else {
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    };

    const onGoBack = () => {
        const vlProps = {
            ...valuesProps,
            billId: billId,
            guestId: _.map(paymentData.guestList, (x) => x.id),
            cartIds: _.map(paymentData.cartList, (x) => x.id)
        }
        setValuesProps(vlProps)
        if (billId) {
            setStepCurrent(PaymentConstant.STEP_2_CART_BILL_DETAIL)
        } else {
            setStepCurrent(PaymentConstant.STEP_2_CART_DETAIL_NO_BOOKING)
        }
    }

    const onFinish = async (form, values, printBillAction = false) => {
        form && form.mask();
        const {valueRowTotalAmount, valueRowHST, valueRowPM, subTotalPayment, valueFinally} = resultPayment;
        if (backMoney < 0) {
            AppUtil.ToastError(t("PaymentForm-NOT_ENOUGH_MONEY_TO_PAYMENT!"));
            form && form.unmask();
            return
        }
        if (!isDetailPayment) {
            let url = ApiBookingConstant.API_CREATE_BILL
            const dataPost = {};
            if (billId) {
                url = ApiBookingConstant.API_UPDATE_BILL_FROM_DRAFT
                dataPost.id = billId
            }
            dataPost.branchId = branchId;
            dataPost.giftCardCode = _.get(values.giftCode, "code", "");
            dataPost.voucherCode = _.get(values.voucherCode, "code", "");
            dataPost.paymentMethodId = _.get(method, "id");
            dataPost.paymentFee = valueRowPM;
            dataPost.fee = 0;
            dataPost.tax = valueRowHST;
            dataPost.total = valueRowTotalAmount;
            dataPost.customerId = values.customerId;
            dataPost.guestIdList = _.map(paymentData.guestList, (x) => x.id);
            dataPost.cartIdList = _.map(paymentData.cartList, (x) => x.id);
            dataPost.posActions = handlePostAction(values);
            dataPost.subTotal = subTotalPayment
            dataPost.isHst = values.isHst
            dataPost.actionTime = moment(values.actionTime).format("YYYY-MM-DD HH:mm")
            if (values.moneyPoint) {
                dataPost.customerTransactionAmount = parseFloat(values.moneyPoint);
            }
            const response = await AppUtil.postApi(url, dataPost);
            if (_.get(response, "data.success")) {
                AppUtil.ToastSuccess();
                if (printBillAction) {
                    await printBill(_.get(response, "data.result.id"))
                    form && form.unmask();
                } else {
                    form && form.unmask();
                    onClose && onClose();
                    onReloadData && onReloadData();
                }

            } else {
                AppUtil.ToastApiError(_.get(response, "data.message"));
                form && form.unmask();
            }
        } else {
            await printBill(initialValues.billId)
            form && form.unmask();
        }
    };

    const renderLeft = () => {
        return (
            <div className="container-left">
                {/*<LeftPayment/>*/}
                <LeftPaymentV2/>
            </div>
        );
    };
    const renderRight = () => {
        return (
            <div className="container-right">
                <RightPayment formRef={formRef}/>
            </div>
        );
    };

    const renderButtons = () => {
        const buttons = []
        if (!isDetailPayment) {
            buttons.push({
                text: t("PaymentForm-FINISH_PAYMENT"),
                type: "submit",
                className: "btn-primary-lighter",
                // style: {width: "150px"},
                icon: <Icon type="check-square" style={{fontSize: 16}}/>,
                onPress: (form, values) => onFinish(form, values)
            })
            //trường hợp là bước ba
            if (billId) {
                buttons.unshift({
                    text: t("PaymentForm-CART"),
                    className: "btn-primary",
                    // style: {width: "150px"},
                    icon: <Icon type="shopping-cart" style={{fontSize: 16}}/>,
                    onPress: () => onGoBack(),
                })
            }
        }
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        const rightCancelBill = AppUtil.checkPermission(allRights.OLD_BILL_CANCEL, rights)
        if (isDetailPayment && rightCancelBill && viewCanCancelPayment) {
            buttons.push({
                text: t("PaymentForm-CANCEL_BILL"),
                type: "submit",
                className: "btn-danger",
                icon: <Icon type="close-circle" style={{fontSize: 16}}/>,
                // style: {width: "150px"},
                onPress: (form, values) => onCancelBill(form, values)
            })
        }
        buttons.push({
            text: t("PaymentForm-PRINT_BILL"),
            type: "submit",
            className: "btn-success",
            icon: <Icon type="printer" style={{fontSize: 16}}/>,
            // style: {width: "150px"},
            onPress: (form, values) => onFinish(form, values, true)
        })
        if (isDetailPayment) {
            const tips = _.filter(initBill.posActions, x => x.type === TIP_MONEY)
            if (!_.isEmpty(tips)) {
                buttons.push({
                    text: t("PaymentForm-DISTRIBUTE_TIP"),
                    className: "btn-primary-lighter",
                    icon: <Icon type="unordered-list" style={{fontSize: 16}}/>,
                    onPress: (form, values) => onTip(true)
                },)
            } else {
                buttons.push({
                    text: t("PaymentForm-ADD_TIP"),
                    className: "btn-primary-lighter",
                    icon: <Icon type="plus-square" style={{fontSize: 16}}/>,
                    onPress: (form, values) => onTip(false)
                },)
            }
        }

        buttons.push({
            text: t("PaymentForm-CLOSE"),
            className: "btn-secondary",
            icon: <Icon type="close-square" style={{fontSize: 16}}/>,
            // style: {width: "150px"},
            onPress: () => onClose && onClose()
        })
        return buttons;
    };

    const renderBill = () => {
        return <PrintBillComponent ref={refCpnPrintBill}/>;
    }
    const renderModal = () => {
        return <Modal ref={refModal} onClose={() => {
        }} maskClosable={false} className="popup-crud popup-container"/>;
    };
    return (
        <Form ref={formRef}
              initialValues={initialValues}
              model={modelPayment}
              buttons={renderButtons()}
              defaults={formDefaults}>
            <div className={isDetailPayment ? "proceed-payment detail-payment" : "proceed-payment"}>
                {renderLeft()}
                {renderRight()}
            </div>
            {renderBill()}
            {renderModal()}
        </Form>
    );
};

export default PaymentForm;
