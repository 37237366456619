import { t } from '@idtek/component/lib/language';
import _ from 'lodash';
import React, { Component } from 'react'

class GridChangeStatusBookingHistory extends Component {
    constructor(props) {
        super(props);
        this.data = this.props.initialValues;
        this.className = this.props.classNames;
    }

    render() {
        return (
            <>
                <div className={`grid grid-cols-3 color-children-${this.className}`}>
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("ClipboardAppointment-BOOKING_CODE")}:
                            </div>
                            <div className='value'>
                                {_.get(this.data, "editInfo.bookingCode", "")}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("GridDetailGuest-GUEST")}:
                            </div>
                            <div className='value'>
                                {_.get(this.data, "editInfo.guests")}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("GridChangeStatusBooking-OLD_STATUS")}:
                            </div>
                            <div className={`value status-booking ${_.get(this.data, "editInfo.oldStatus")}`}>
                                {t(`SchedulerView-${_.get(this.data, "editInfo.oldStatus").toUpperCase()}`)}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("GridChangeStatusBooking-NEW_STATUS")}:
                            </div>
                            <div className={`value status-booking ${_.get(this.data, "editInfo.newStatus")}`}>
                                {t(`SchedulerView-${_.get(this.data, "editInfo.newStatus").toUpperCase()}`)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default GridChangeStatusBookingHistory;