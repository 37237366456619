import { t } from "@idtek/component/lib/language";
import { CANCEL_ACTION, CHANGE_STATUS_ACTION, UPDATE_ACTION } from "../common/history/constant/HistoryConstant";
import { SERVICE_TYPE } from "../modules/service/constant/ServiceConstant";
import { DELETE_ACTION, CREATE_ACTION } from './../common/history/constant/HistoryConstant';
import ServiceHistoryComponent from './../modules/service/component/ServiceHistoryComponent';
import { TECHNICIAN_TYPE } from './../modules/technician/constant/TechnicianConstant';
import TechnicianHistoryComponent from './../modules/technician/component/TechnicianHistoryComponent';
import HistoryHolidayComponent from './../modules/branch/components/HistoryHolidayComponent';
import { BILL_TYPE, BOOKING_TYPE } from "../modules/posTransaction/constant/BookingConstant";
import GridBookingHistoryTemplate from './../modules/appointment/component/GridBookingHistoryTemplate';
import BillHistoryTemplate from './../modules/system/view/BillHistoryTemplate';
import _ from "lodash";

export class HistoryUtil{
    static getTypeHistory(action){
        switch (action) {
            case UPDATE_ACTION:
                return t("BaseListView-UPDATE");
            case DELETE_ACTION:
                return t("BaseListView-DELETE");
            case CHANGE_STATUS_ACTION:
                return t("BaseListView-CHANGE_STATUS");
            case CREATE_ACTION:
                return t("BaseListView-CREATE");
            default:
                break;
        }

    }

    static getComponentHistory(type, status){
        let component;
        switch (type) {
            case SERVICE_TYPE: {
                component = ServiceHistoryComponent;
                break;
            }
            case TECHNICIAN_TYPE: {
                component = TechnicianHistoryComponent;
                break;
            }
            case BOOKING_TYPE: {
                component =  GridBookingHistoryTemplate;
                break;
            }
            case BILL_TYPE: {
                component = BillHistoryTemplate;
                break;
            }
            default: {
                component = HistoryHolidayComponent;
            }
        }
        return component;
    }

    static getClassNameAction(action){       
        let className = 'create-item'; 
        switch (action) {
            case UPDATE_ACTION:
                className = 'update-item';
                break;
            case DELETE_ACTION:
                className = 'delete-item';
                break;
            case CHANGE_STATUS_ACTION:
                className = 'change-status-item';
                break;
            case CREATE_ACTION:
                className = 'create-item';
                break;
            case CANCEL_ACTION:
                className = 'cancel-item';
                break;
            default:
                break;
        }
        return className;
    }

    static getTypeFormEntity(entityType){
        const type = entityType.split('.'); 
        return _.last(type);
    }
}