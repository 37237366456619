import React, {
    useEffect,
    useRef,
} from "react";
import _ from "lodash";
import "../style/add-cart.scss";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import { useMergeState } from "@idtek/component/lib/hook/IDHook";
import GridDetailGuest from "../component/GridDetailGuest";
import Loading from "../../../pages/Loading";
import AddOrderComponent from "../component/AddOrderComponent";
import SearchAndSelectComponent from "../component/SearchAndSelectComponent";
import AppStore from "../../../store";
import { AddCartFormProvider } from "../context/AddCartFormContext";
import Button from "@idtek/component/lib/button/IDButton";
import Icon from "@idtek/component/lib/icon/Icon";
import InfoBooking from "../component/InfoBooking";
import { t } from "@idtek/component/lib/language";
import { useContentPaymentContextStep } from "../context/ContentPaymentContextStep";
import PaymentConstant from "../constant/PaymentConstant";
import moment from "moment";

const AddCartForm = ({
    bookingId, guestId, onClose, onReloadData, branchId, isDetail, bodyCart,
    refFormCart,
    mainCartId,
    customerIdCart
}) => {

    const guestFormat = _.isArray(guestId) ? guestId : _.isNull(guestId) || _.isUndefined(guestId) ? [] : [guestId]
    const { stepCurrent, setStepCurrent, valuesProps, setValuesProps, reloadInit } = useContentPaymentContextStep();
    const [state, setState] = useMergeState({
        guestId: guestFormat,
        booking: {},
        guestList: [],
        segmentList: [],
        giftCardIds: [],
        giftCardList: [],
        productIds: [],
        productList: [],
        cartIds: valuesProps.cartIds,
        loading: !!bookingId,
        billId: null,
    });
    const refAddOrderComponent = useRef(null)

    const urlDetail = ApiBookingConstant.API_GET_INFO_FORM_ADD_CART
    const urlSave = ApiBookingConstant.API_CREATE_BILL_CART
    useEffect(() => {
        const loadData = async () => {
            const params = {
                giftCardIds: state.giftCardIds,
                productIds: state.productIds,
                productData: refAddOrderComponent.current && refAddOrderComponent.current.getDataProduct()
            }
            if (bookingId) {
                params.bookingId = bookingId
            }
            if (state.guestId && bookingId) {
                params.guestId = state.guestId
            }

            const res = await AppUtil.postApi(urlDetail, params)
            if (_.get(res, "data.success")) {
                const data = _.get(res, "data.result")
                setState({
                    booking: data.booking,
                    guestList: data.guestList,
                    segmentList: data.segmentList,
                    giftCardList: data.giftCardList,
                    productList: data.productList,
                    totalAmountGuest: data.totalAmountGuest,

                    loading: false
                })
            } else {
                AppUtil.ToastApiError()
            }
        }
        loadData()
    }, [state.guestId, state.giftCardIds, state.productIds, reloadInit])

    const onChangeGuest = (ids) => {
        setState({
            ...state,
            guestId: _.uniq(_.concat(guestFormat, ids))
        });
    };

    const customRowGuest = (data) => {
        return <div className="row-add-more">
            <div>
                {t("AddCartForm-BOOKING")}: {data.code} - {t("AddCartForm-GUEST")}: {data.name}
            </div>
            <div>
                {t("AddCartForm-CUSTOMER")}: {data.customerName}- {t("AddCartForm-SUBTOTAL")}: {data.totalAmount.toFixed(2)}$
            </div>
        </div>;
    };

    const addGiftToBill = (gift) => {
        setState({
            giftCardIds: gift
        })
    }

    const addProductToBill = (product) => {
        setState({
            productIds: product
        })
    }

    const onCreateGiftSuccess = (id) => {
        const { giftCardIds } = state
        const newIds = _.cloneDeep(giftCardIds)
        newIds.push(id)
        setState({
            giftCardIds: newIds
        })
    }

    const onCreateProductSuccess = (id) => {
        const { productIds } = state
        const newIds = _.cloneDeep(productIds)
        newIds.push(id)
        setState({
            productIds: newIds
        })
    }

    const onSaveAndContinue = async (continute = false) => {

        let url = urlSave
        const cart = {}
        if (refFormCart) {
            if (refFormCart.current.isValid()) {
                const valuesCart = refFormCart.current && refFormCart.current.getValues()
                cart.phone = _.get(valuesCart, "customer.phone");
                cart.branchId = branchId
                cart.customerName = _.get(valuesCart, "customerName");
                cart.email = _.get(valuesCart, "email");
            } else {
                AppUtil.ToastError(t("AddCartForm-FORM_INVALID"));
                return;
            }
        }
        const params = {
            guestIdList: state.guestId,
            giftCardIdList: state.giftCardIds,
            productIdList: state.productIds,
            branchId: branchId,
            productData: refAddOrderComponent.current && refAddOrderComponent.current.getDataProduct(),
            cartCreate: cart
        }

        if (!bookingId) {
            if (_.isEmpty(state.giftCardIds) && _.isEmpty(state.productIds)) {
                AppUtil.ToastApiError(t("AddCardForm-PLEASE_CHOOSE_ANY_PRODUCT_OR_GIFT_CARD"));
                return
            }
        }
        const response = await AppUtil.postApi(url, params);
        onReloadData && onReloadData()
        if (_.get(response, "data.success")) {
            if (continute) {
                const data = _.get(response, "data.result")
                const cartId = _.get(data, 'cartId')
                let cartIds = cartId ? [cartId] : []
                const vlProps = {
                    ...valuesProps,
                    guestId: state.guestId,
                    cartIds: cartIds,
                    billId: _.get(data, 'id'),
                    mainCartId: cartId,
                    customerIdCart: _.get(data, 'customerIdCart'),
                }
                setValuesProps(vlProps)
                if (bookingId) {
                    setStepCurrent(PaymentConstant.STEP_3_PAYMENT)
                } else {
                    setStepCurrent(PaymentConstant.STEP_3_PAYMENT_NO_BOOKING)
                }
                setState({
                    ...state,
                    cartIds,
                    billId: _.get(data, 'id'),
                })
            } else {
                onClose && onClose();
                AppUtil.ToastSuccess(t("AddCartForm-CREATE_CART_SUCCESS!"));
            }
        } else {
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    }

    const renderButton = () => {
        return <div className={"wrap-button"}>
            {
                !bookingId && <Button className={"btn-form btn btn-success"}
                    type={"default"}
                    icon={<Icon type="save" style={{ fontSize: 16, marginTop: -2 }} />}
                    onClick={() => onSaveAndContinue()}
                >
                    {t("AddCartForm-SAVE")}
                </Button>
            }
            <Button className={"btn-form btn btn-primary-lighter"}
                // style={{width: 120}}
                type={"default"}
                icon={<Icon type="dollar" style={{ fontSize: 16, marginTop: -2 }} />}
                onClick={() => onSaveAndContinue(true)}
            >
                <span>
                    {t("AddCartForm-PAYMENT")}
                </span>
            </Button>
            <Button className={"btn-form btn btn-secondary"}
                // style={{width: 120}}
                type={"default"}
                icon={<Icon type="close-square" style={{ fontSize: 16, marginRight: 8 }} />}
                onClick={() => onClose && onClose()}
            >
                {t("AddCartForm-CLOSE")}
            </Button>
        </div >
    }


    if (state.loading) return <div style={{ flex: 1, height: "400px" }}><Loading /></div>;
    const { guestList, segmentList, giftCardList, booking, productList, totalAmountGuest } = state

    return (
        <AddCartFormProvider guestList={guestList} segmentList={segmentList} giftCardList={giftCardList}
            booking={booking} isDetail={isDetail} productList={productList}
            totalAmountGuest={totalAmountGuest}>
            <div className={"add-cart"}>
                <div className={"right"} style={{ display: "flex", flexDirection: "column" }}>
                    {bodyCart && bodyCart()}
                    {
                        bookingId && <>
                            <InfoBooking data={state.booking} />
                            <SearchAndSelectComponent
                                urlSearch={ApiBookingConstant.API_SEARCH_GUEST}
                                params={{
                                    branchId: _.get(AppStore.getState(), `root.branch.id`, null),
                                    exceptGuest: _.isArray(guestId) ? guestId : _.isNull(guestId) || _.isUndefined(guestId) ? [] : [guestId],
                                    dateBill: moment(booking.startTime).format("YYYY-MM-DD")
                                }}
                                className={"combo-search-guest mgt-0"}
                                onChange={onChangeGuest} placeholder={t("AddCardForm-SEARCH_GUEST") + ("...")}
                                customRow={customRowGuest}
                                method={"post"} />
                            <GridDetailGuest />
                        </>
                    }
                    <AddOrderComponent
                        ref={refAddOrderComponent}
                        addProductToBill={addProductToBill}
                        addGiftToBill={addGiftToBill}
                        onCreateGiftSuccess={onCreateGiftSuccess}
                        onCreateProductSuccess={onCreateProductSuccess}
                    />
                </div>
            </div>
            {renderButton()}
        </AddCartFormProvider>
    );
};

export default AddCartForm;