import _ from "lodash";
import React from "react";
import AppUtil from "../../../utils/AppUtil";
import { TYPE_RECEIVE_MONEY, TYPE_TAKE_MONEY } from "../../posTransaction/constant/BookingConstant";
import BaseForm from "../../../common/baseForm/BaseForm";
import "../style/style.scss";
import { DEFAULT_IMAGE_GIFT } from "../constant/GiftCardConstant";
import AppStore from "../../../store";
import * as Contant from "../../../application/constants/constant";
import { DataUtil } from "../../../utils/DataUtil";
import GiftCardBodyFormComponent from "../component/GiftCardBodyFormComponent";
import { t } from "@idtek/component/lib/language";

export default class GiftCardForm extends BaseForm {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: props.initialValues
        };

        this.columnDefsBooking = [
            {
                headerName: t("GiftCardForm-BOOKING_CODE"),
                field: "booking.code",
                width: 120
            },
            {
                headerName: t("GiftCardForm-CUSTOMER"),
                field: "customer.name",
                minWidth: 200
            },
            {
                headerName: t("GiftCardForm-DATE"),
                field: "date",
                width: 150,
                format: {
                    type: "date",
                    text: AppUtil.GLOBAL_DATETIME_FORMAT
                }
            }
        ];
        this.apiDetail = "/gift-card/show";
        this.apiUpdate = "/gift-card/update";
        this.apiCreate = "/gift-card/create";
        this.apiListCustomer = "/customer/get-combo";
        this.isCreate = props.isCreate;
        const phoneSetting = AppUtil.RegexPhone(_.find(_.get(AppStore.getState(), `root.branch.branchConfigs`), (x) => x.type === Contant.PHONE_SET_UP).value)
        this.optionSetting = { ...phoneSetting, numeralPositiveOnly: true }


    }

    autoHeightRow = false;
    cellRenderer = {};
    defaultRenderGrid = true;
    defaultNumberRows = {
        headerName: t("GiftCardForm-NO")
    };
    defaultColDef = {};
    headerHeight = 32;
    textCenterRow = "";
    rowSelection = "multiple";
    floatingFilter = true;
    globalFilter = true;
    gridProps = {};
    suppressColumnVirtualisation = false;

    componentDidMount() {
        const { initialValues } = this.state;
        if (!this.isCreate) {
            this.loadData(initialValues.id);
        } else {
            this.onLoadInitForm();
        }
    }

    onLoadInitForm = async () => {
        this.initialValues = {
            ...this.initialValues,
            code: AppUtil.autoGenGiftCode(),
            image: DEFAULT_IMAGE_GIFT.url
        };

        this.setState({
            initialValues: this.initialValues,
            loading: false
        });
    };

    onSave = async (form, values) => {
        form && form.mask();
        let url = "";
        if (this.isCreate) {
            url = this.apiCreate;
        } else {
            values.id = _.get(this.initialValues, "id");
            url = this.apiUpdate;
        }

        if (values.recipientPhone && values.recipientPhone.length < this.optionSetting.length) {
            this.form && this.form.setErrorField('recipientPhone', `${t('GiftCardForm-PLEASE_ENTER')} ${this.optionSetting.length} ${t('GiftCardForm-DIGITS_NUMBER')}`)
            form && form.unmask()
            AppUtil.ToastError(t('GiftCardForm-PHONE_IS_INVALID!'))
            return;
        }

        values = this.beforeSave(form, values);
        const res = await AppUtil.postApi(url, values);
        this.afterSave(form, res);
        form && form.unmask();
        if (res) {
            if (!_.get(res.data, "success")) {
                let message = _.get(res, "data.message");
                AppUtil.ToastApiError(message);
            } else {
                AppUtil.ToastSuccess();
                this.props.onClose && this.props.onClose(this.isCreate, _.get(res.data, "result"));
            }
        } else {
            AppUtil.ToastApiError();
        }
    };

    async loadData(id) {
        const res = await AppUtil.getApi(`${this.apiDetail}/${id}`, {});
        if (_.get(res, "data.success")) {
            const values = await this.beforeSetValues(_.get(res.data, "result"));
            this.setState({
                initialValues: Object.assign(this.initialValues, values),
                loading: false
            });
        }
    }

    beforeSetValues = async (values) => {
        values.imageFile = _.filter(values.image, x => x);
        return values;
    };

    onShow = async () => {
        this.onMask();
        const fetchs = [
            await AppUtil.getApi(`/customer/combobox`, {
                start: 0,
                limit: 100,
                query: ""
            }),
            await AppUtil.getApi(`${this.apiDetail}/${this.props.id}`, {})
        ];
        await AppUtil.Axios.all(fetchs)
            .then(async ([resCus, res]) => {
                if (resCus && _.get(res, "data.success")) {
                    const values = await this.beforeSetValues(_.get(res.data, "results"));
                    this.setState({ optionsCustomer: _.get(resCus, "data"), amount: values.amount, loading: false });
                    this.actionRecieved = _.find(values.posActions, (x) => x.posType.id === TYPE_RECEIVE_MONEY);
                    this.actionTake = _.find(values.posActions, (x) => x.posType.id === TYPE_TAKE_MONEY);
                    if (this.actionRecieved) {
                        values.typeCheckout = this.actionRecieved ? this.actionRecieved.type : "CASH";
                        values.received = this.actionRecieved.value;
                    }
                    if (this.actionTake) {
                        values.return = this.actionTake.value;
                    }

                    this.form.setValues(values);
                } else {
                    AppUtil.ToastApiError();
                }
            })
            .catch((error) => {
                AppUtil.ToastApiError();
            })
            .finally(() => {
                this.onUnMask();
            });
    };


    onChangePhone = (phone) => {
        this.form && this.form.setValueField("customerName", _.get(phone, "name", ""));
    };

    onSetErrorField = (field, message) => {
        this.form && this.form.setErrorField(field, message);
    };

    beforeSave(form, values) {
        let newValues = {
            ...values
        }
        if (!values.imageFile) {
            newValues.image = null;
        }
        newValues.branchId = _.get(AppStore.getState(), `root.branch.id`, null);
        if (_.isEmpty(values.giftCardActions)) {
            newValues.amount = values.value
        }
        return DataUtil.serializeToFromData(newValues, {
            noFilesWithArrayNotation: true,
            indices: true
        }, new FormData, '');
    }

    renderBody() {
        const { formDefaults } = this.props;
        let classCreate = "";
        if (this.isCreate) {
            classCreate = "create-random-code";
        }
        return (
            <GiftCardBodyFormComponent classCreate={classCreate} optionSetting={this.optionSetting}
                formDefaults={formDefaults}
                isCreate={this.isCreate} />
        );
    }
}
