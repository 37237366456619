import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import Collapse from "@idtek/component/lib/collapse/Collapse";
import Icon from "@idtek/component/lib/icon/Icon";
import TextField from "@idtek/form/lib/text/TextField";
import { usePaymentContext } from "../context/PaymentContext";
import AppUtil from "../../../utils/AppUtil";
import _ from "lodash";
import TechnicianTipGrid from "./TechnicianTipGrid";
import { RadioListField } from "@idtek/form/lib";
import { t } from "@idtek/component/lib/language";

const AddTipComponent = () => {
    const {
              guestIdsSelect,
              isDetailPayment,
              tips,
              setTips,
              optionsData,
              technicianTip,
          } = usePaymentContext();
    const refGrid = useRef(null);
    const [data, setData] = useState(technicianTip);
    useEffect(() => {
        const getInfo = async () => {
            if (!isDetailPayment) {
                const res = await AppUtil.postApi("/bill/get-technician-info-tip", { guestIds: guestIdsSelect });
                if (_.get(res, "data.success")) {
                    setData(_.get(res, 'data.result'))
                    if (tips) {
                        onChangeTipTotal(tips, _.get(res, 'data.result'))
                    }
                }
            }
        }
        getInfo()
    }, [guestIdsSelect])

    useEffect(() => {
        refGrid.current && refGrid.current.setDataGird(data)
    }, [data])

    const onChangeTipTotal = (val, dataGrid) => {
        const dataNew = dataGrid ? _.cloneDeep(dataGrid) : _.cloneDeep(data)
        if (!val) {
            dataNew && dataNew.map(x => {
                x.value = null;
                x.percent = null
            })
        } else {
            const avgValue = (val / _.size(dataNew)).toFixed(1)
            const avgPercent = ((1 / _.size(dataNew)) * 100).toFixed(1)
            dataNew && dataNew.map(x => {
                x.value = avgValue;
                x.percent = avgPercent
            })
        }
        setTips(val)
        setData(dataNew)
    }

    return <div className="gift-card-field" style={{marginTop: 5}}>
        <Collapse
            ghost
            className="collapse-cpn"
            expandIconPosition="right"
            defaultActiveKey={tips ? "1" : null}
            expandIcon={({isActive}) => <Icon type="caret-right" style={{fontSize: 16, color: "#026eaa"}}
                                              rotate={isActive ? 90 : 0}/>}>
            <Collapse.Panel
                header={
                    <div className="total" style={{marginBottom: 0}}>
                        <div className="icon-gift">
                            <i class="fa fa-plus-square" aria-hidden="true"/>
                        </div>
                        <span className="title">
                            {t("AddTipComponent-TIP")}
                        </span>
                    </div>
                }
                key="1">
                <div className="text-field" style={{paddingBottom: 0, marginBottom: 10}}>
                    <TextField hideError={true} name="valueTip" options={{
                        numeralPositiveOnly: true
                    }} onChange={(val) => onChangeTipTotal(val ? parseFloat(val) : null)}/>
                </div>
                <div className="text-field">
                    <RadioListField name="typeCheckoutTip" options={optionsData.checkoutMethods} hideError={true}
                                    viewMode={"horizontal"} labelKey={"code"} valueKey={"id"} isMulti={false}
                                    isClearable={false}/>
                </div>
                <TechnicianTipGrid ref={refGrid} data={data} isDetail={isDetailPayment}/>
            </Collapse.Panel>
        </Collapse>
    </div>;

};

export default AddTipComponent;