import React, {Component, lazy} from 'react';
import Switch from "react-router-dom/es/Switch";
import Route from "react-router-dom/es/Route";

const BookingReviewListView = lazy(()=>import('./view/BookingReviewListView'));

class BookingReviewIndex extends Component {
    render() {
        return (
            <Switch>
                <Route path={'/booking-review/index'}
                       component={BookingReviewListView}/>
            </Switch>
        );
    }
}

export default BookingReviewIndex;