import React from 'react';
import {useContentPaymentContextStep} from "../context/ContentPaymentContextStep";
import PaymentConstant from "../constant/PaymentConstant";
import AddCartForm from "../form/AddCartForm";
import AddCartDetailForm from "../form/AddCartDetailForm";
import PaymentContainer from "./PaymentContainer";
import CartPaymentContainer from "../../cart/view/CartPaymentContainer";
import AddCartDetailNoBookingForm from "../form/AddCartDetailNoBookingForm";

const PaymentStep = () => {
    const {stepCurrent, valuesProps} = useContentPaymentContextStep();
    if (stepCurrent === PaymentConstant.STEP_2_CART_BILL) {
        return <AddCartForm {...valuesProps}/>
    } else if (stepCurrent === PaymentConstant.STEP_2_CART_BILL_DETAIL) {
        return <AddCartDetailForm {...valuesProps} isDetailCart={true}/>
    } else if (stepCurrent === PaymentConstant.STEP_2_CART_DETAIL_NO_BOOKING) {
        return <AddCartDetailNoBookingForm {...valuesProps} isDetailCart={true}/>
    } else if (stepCurrent === PaymentConstant.STEP_3_PAYMENT) {
        return <PaymentContainer {...valuesProps}/>
    } else if (stepCurrent === PaymentConstant.STEP_3_PAYMENT_NO_BOOKING) {
        return <CartPaymentContainer {...valuesProps}/>
    }
    return <></>
};

export default PaymentStep;