import React from 'react'
import BaseListView from '../../../common/baseView/BaseListView'
import BaseStore from '@idtek/grid/lib/stores/BaseStore'
import Textbox from '@idtek/component/lib/textbox/Textbox'
import _ from 'lodash'
import AppUtil from '../../../utils/AppUtil'
import '../style/payment-v3.scss'
import TextField from "@idtek/form/lib/text/TextField";
import Form from "@idtek/form/lib/IDForm";
import { Checkbox, t } from "@idtek/component";
import Icon from "@idtek/component/lib/icon/Icon";
import AppStore from "../../../store";
import { RadioListField } from "@idtek/form";
import { TIP_MONEY } from "../../posTransaction/constant/BookingConstant";
import TechnicianTipConstant from "../constant/TechnicianTipConstant";
import CheckboxField from "@idtek/form/lib/checkbox/CheckboxField";
import Alert from "@idtek/component/lib/alert/Alert";

class TechnicianEditTipGrid extends BaseListView {
    constructor(props) {
        super(props)
        this.state = {
            value: null,
            checkoutMethods: [],
            alreadyTip: this.props.alreadyTip
        }
        this.store = new BaseStore({
            data: props.data ? props.data : [],
            pageSize: this.pageSize,
        })
        this.defaultColDef = {
            sortable: false
        };
        this.autoHeightRow = false
        this.defaultNumberRows = {
            headerName: t('TechnicianEditTipGrid-NO'),
        }
        this.overrideTopLeftGrid = true
        this.pagination = false
        this.globalFilter = false
        this.floatingFilter = false
        this.suppressRowClickSelection = true
        this.columnDefs = [
            {
                headerName: t('TechnicianEditTipGrid-TECHNICIAN'),
                field: 'technicianName',
                width: 150,
                colSpan: function (params) {
                    if (params.data && params.data.colSpan > 1) {
                        return params.data.colSpan
                    } else {
                        return 1
                    }
                },
            },
            {
                headerName: t('TechnicianEditTipGrid-SERVICE'),
                field: 'segmentName',
                minWidth: 150,
            },
            {
                headerName: t('TechnicianEditTipGrid-PERCENT'),
                field: 'percent',
                width: 120,
                textAlign: 'right'
            },
            {
                headerName: t('TechnicianEditTipGrid-VALUE'),
                field: 'value',
                width: 120,
                textAlign: 'right'
            },
            {
                headerName: t('TechnicianEditTipGrid-DISTRIBUTE'),
                field: 'distribute',
                width: 90,
                textAlign: 'right'
            }
        ];
        this.cellRenderer = {

            value: {
                renderer: (data, value, rowNode) => {
                    if (data.field !== "pinned") {
                        return (
                            <Textbox
                                value={value}
                                placeholder={`${t('TechnicianEditTipGrid-INPUT')}...`}                                // className={`input-tips`}
                                onBlur={(val) => this.onBlurInput(data, val, "value", value, rowNode)}
                                inputStyle={{ textAlign: "right" }}
                                type={"number"}
                                autoFocus={true}
                                readOnly={!this.state.value && !this.state.alreadyTip}
                                isClearable={false}
                                iconLine={false}
                                icon={<span style={{ fontSize: 12, fontWeight: 600 }}>$</span>}
                                iconAlign="before"
                                max={this.state.value}
                                style={{ marginTop: 2 }}
                                options={{
                                    numeralPositiveOnly: true,
                                    numeralDecimalScale: 2
                                }}
                            />
                        );
                    }
                    const sum = _.sumBy(this.getDataStore(), x => x.value ? parseFloat(x.value) : 0)
                    return <div
                        className={"global-flex-align-center-justify-end"}>{sum.toFixed(2)}</div>

                }
            },
            percent: {
                renderer: (data, value, rowNode) => {
                    if (data.field !== "pinned") {
                        return (
                            <Textbox
                                value={value}
                                placeholder={""}
                                // className={`input-tips`}
                                onBlur={(val) => this.onBlurInput(data, val, "percent", value, rowNode)}
                                inputStyle={{ textAlign: "right" }}
                                type={"number"}
                                autoFocus={true}
                                isClearable={false}
                                iconLine={false}
                                icon={<span style={{ fontSize: 12, fontWeight: 600 }}>%</span>}
                                iconAlign="before"
                                max={100}
                                options={{
                                    numeralPositiveOnly: true,
                                    numeralDecimalScale: 2
                                }}
                                readOnly={!this.state.value && !this.state.alreadyTip}
                                style={{ marginTop: 2 }}

                            />
                        );
                    }
                    const sum = _.sumBy(this.getDataStore(), x => x.percent ? parseFloat(x.percent) : 0)
                    return <div
                        className={"global-flex-align-center-justify-end"}>{sum.toFixed(2)}</div>
                }
            },
            distribute: {
                renderer: (data, value, rowNode) => {
                    if (data.field !== "pinned") {
                        return <div style={{ textAlign: "center", marginTop: 5 }}>
                            <Checkbox value={value}
                                onChange={(val) => data['distribute'] = val} />
                        </div>;
                    }
                    return null
                }
            },
        }
        this.rowHeight = 34;
        this.minHeightGrid = 200
        this.heightGrid = "auto"
    }

    componentDidMount() {
        this.getInfo()
    }

    getInfo() {
        const fetchs = [
            AppUtil.postApi("/bill/get-technician-info-tip-by-billId", { billId: this.props.billId }),
            AppUtil.getApi("/checkout-method/get-combo")
        ];
        AppUtil.Axios.all(fetchs)
            .then(async ([res1, res2]) => {
                let checkoutMethods = []
                if (_.get(res2, 'data.success')) {
                    checkoutMethods = _.get(res2, 'data.result')
                }
                if (_.get(res1, 'data.success')) {
                    const data = _.get(res1, 'data.result')
                    const dta = data ? data.technicianTips : []
                    let distributeAll = true
                    const newDta = _.map(dta, item => {
                        item.distribute = item.status === TechnicianTipConstant.STATUS_DISTRIBUTE ? true : false
                        if (!item.distribute) {
                            distributeAll = false
                        }
                        item.selected = this.props.technicianId === item.technicianId ? true : false
                        return { ...item }
                    })
                    this.setDataGrid(newDta)
                    this.myGrid.gridApi && this.myGrid.gridApi.redrawRows();
                    if (data.hasTip) {
                        this.form && this.form.setValues({
                            valueTip: data.value,
                            typeCheckoutTip: data.checkoutMethodId,
                            distributeAll
                        })
                    }
                    this.setState({
                        value: data.value,
                        data,
                        alreadyTip: data.hasTip,
                        initialValues: {
                            valueTip: data.value,
                            typeCheckoutTip: data.checkoutMethodId,
                            distributeAll
                        },
                        checkoutMethods: checkoutMethods
                    })
                }
            }).catch((error) => {
                AppUtil.ToastApiError();
            });

    }

    onBlurInput = async (data, value, fieldKey, rawValue, rowNode) => {
        if (rawValue != value) {
            data[fieldKey] = value;
            if (fieldKey === 'value') {
                data['seflEdit'] = true;
                const percentInput = value / this.state.value
                data['percent'] = (percentInput * 100).toFixed(2);
                this.myGrid && this.myGrid.gridApi.updateRowData({ update: this.myGrid.store.getData() });
                const dataGrid = this.myGrid && this.myGrid.store.getData()
                const arr = _.filter(dataGrid, x => x.seflEdit)
                const valAccept = _.sumBy(arr, x => parseFloat(x.value))
                const restValue = this.state.value - valAccept
                const avgValue = (restValue / (_.size(dataGrid) - _.size(arr))).toFixed(2)
                const avgPercent = ((1 - percentInput) / (_.size(dataGrid) - _.size(arr)) * 100).toFixed(2)
                dataGrid && dataGrid.map(x => {
                    if (!x.seflEdit) {
                        x.value = avgValue
                        x.percent = avgPercent
                    }
                })
                this.setDataGrid(dataGrid)
            }

            if (fieldKey === "percent") {
                data['seflEditP'] = true;
                const valueInput = (value / 100 * this.state.value).toFixed(2)
                data['value'] = valueInput
                this.myGrid && this.myGrid.gridApi.updateRowData({ update: this.myGrid.store.getData() });
                const dataGrid = this.myGrid && this.myGrid.store.getData()
                const arr = _.filter(dataGrid, x => x.seflEditP)
                const perAccpept = _.sumBy(arr, x => parseFloat(x.percent))
                const restPercent = 100 - perAccpept
                const avgPercent = (restPercent / (_.size(dataGrid) - _.size(arr))).toFixed(2)
                const avgValue = (avgPercent * this.state.value / 100).toFixed(2)
                dataGrid && dataGrid.map(x => {
                    if (!x.seflEditP) {
                        x.value = avgValue
                        x.percent = avgPercent
                    }
                })
                this.setDataGrid(dataGrid)
            }

        }
    }

    renderActionBar() {

    }

    actionRows = () => {
        return {}
    }

    onGridReady() {

    }

    setDataGrid(data) {
        this.myGrid && this.myGrid.store.setData(data)
        this.myGrid.gridApi && this.myGrid.gridApi.redrawRows();

    }

    onChangeTipTotal = (val) => {
        const dataGrid = this.myGrid && this.myGrid.store.getData()
        if (!val) {
            dataGrid && dataGrid.map(x => {
                x.value = null
            })
        } else {
            const avgValue = (val / _.size(dataGrid)).toFixed(2)
            const avgPercent = ((1 / _.size(dataGrid)) * 100).toFixed(2)
            dataGrid && dataGrid.map(x => {
                x.value = x.percent ? ((x.percent * val) / 100).toFixed(2) : avgValue
                x.percent = x.percent ? x.percent : avgPercent
            })
        }
        this.setState({
            value: val,
        })
        this.setDataGrid(dataGrid)


    }

    onChangeDistributeAll = (val) => {
        const dataGrid = this.myGrid && this.myGrid.store.getData()
        dataGrid && dataGrid.map(x => {
            x.distribute = val;
        })
        this.setDataGrid(dataGrid)
    }

    async onSave(form, values) {
        if (values.valueTip <= 0) {
            AppUtil.ToastApiError("Input tip value!")
            return;
        }
        let res
        const dataGrid = this.myGrid && this.myGrid.store.getData()
        const technicianTips = _.map(dataGrid, item => {
            item.status = item.distribute ? TechnicianTipConstant.STATUS_DISTRIBUTE : TechnicianTipConstant.STATUS_SAVE
            item.percent = Math.round(item.percent * 100) / 100
            item.value = Math.round(item.value * 100) / 100
            return { ...item }
        })
        if (_.get(this.state, 'alreadyTip')) {
            res = await AppUtil.postApi("/pos-action/update-tip", {
                ...this.state.data,
                value: values.valueTip,
                // actionTime: moment().format("YYYY-MM-DD HH:mm"),
                technicianTips: technicianTips,
                checkoutMethodId: values.typeCheckoutTip,
            })
        } else {
            res = await AppUtil.postApi("/pos-action/create-tip", {
                branchId: _.get(AppStore.getState(), `root.branch.id`, null),
                billId: this.props.billId,
                // actionTime: moment().format("YYYY-MM-DD HH:mm"),
                value: values.valueTip,
                type: TIP_MONEY,
                technicianTips: technicianTips,
                checkoutMethodId: values.typeCheckoutTip,
            })
        }

        if (_.get(res, 'data.success')) {
            AppUtil.ToastSuccess()
            this.props.onReloadData && this.props.onReloadData()
            this.props.onClose(true)
        }
    }

    onGridReady = () => {
        if (this.myGrid) {
            const dataGrid = this.girdStore && this.girdStore.getData();
            const pinned = [{
                technicianName: t("TechnicianEditTipGrid-TOTAL"),
                percent: _.sumBy(dataGrid, x => x.percent),
                price: _.sumBy(dataGrid, x => x.price),
                field: "pinned",
                colSpan: 2,
                distribute: null
            }]
            this.myGrid.gridApi && this.myGrid.gridApi.setPinnedBottomRowData(pinned);
        }
    };

    onCancelTip = async () => {
        const {data} = this.state;
        const idTip = _.get(data, 'id');

        let confirm = await Alert.Swal_confirm(t("BaseListView-CONFIRM"), t("TechnicianEditTipGrid-ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TIP?"), 3);
        if (_.get(confirm, "value") === true) {
            const res = await AppUtil.postApi(`${'/pos-action/delete'}?id=${idTip}`);
            if (res && _.get(res.data, "success")) {
                // const log = LogUtil.createActionLog(this.entityType, "Xóa dữ liệu", _.get(res, "results.data[this.keyID]"), this.functionName || "Xóa dữ liệu");
                // LogUtil.writeActionLog(log);
                AppUtil.ToastDeleteSuccess();
                this.props.onClose();
            } else {
                AppUtil.ToastDeleteError(_.get(res, "data.messages") || _.get(res, "data.message") || t("BaseListView-DATA_ERROR,_PLEASE_TRY_AGAIN!"));
            }
        }
    }

    getDataStore() {
        return this.myGrid && this.store.getData()
    }

    renderButton() {
        const alreadyTip = _.get(this.state, 'alreadyTip');
        const buttons = [
            {
                text: t('TechnicianEditTipGrid-SAVE'),
                className: "btn-success",
                type: "submit",
                icon: <Icon type="save" style={{ fontSize: 16 }} />,
                onPress: (form, values) => this.onSave(form, values)
            },
            {
                text: t('TechnicianEditTipGrid-CLOSE'),
                className: "btn-secondary",
                icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                onPress: () => this.props.onClose()
            }
        ];

        if (alreadyTip) {
            buttons.push({
                text: t('TechnicianEditTipGrid-CANCEL_TIP'),
                type: 'submit',
                className: 'btn-danger',
                icon: <Icon type="close-circle" style={{ fontSize: 16 }} />,
                onPress: (form, values) => this.onCancelTip(),
            })
        }

        return buttons;
    }

    render() {
        const { checkoutMethods, initialValues } = this.state
        return (
            <Form
                ref={(component) => (this.form = component)}
                initialValues={initialValues || {}}
                model={{
                    fields: {
                        valueTip: {
                            label: t('TechnicianEditTipGrid-VALUE'),
                            required: true,
                        },
                        typeCheckoutTip: {
                            label: t('TechnicianEditTipGrid-TIP_METHOD'),
                        },
                        distributeAll: {
                            label: t("TechnicianEditTipGrid-DISTRIBUTE")
                        },
                    }

                }}
                className="base-form-modal"
                style={{ padding: 15 }}
                buttons={this.renderButton()}
                defaults={this.formDefaults}>
                <div>
                    <div className="text-field" style={{ paddingBottom: 0, marginBottom: 10 }}>
                        <TextField type="number" hideError={true} name="valueTip" options={{
                            numeralPositiveOnly: true,
                            numeralDecimalScale: 2
                        }} onChange={(val) => this.onChangeTipTotal(val ? parseFloat(val) : null)} />
                    </div>
                    <div className="text-field">
                        <CheckboxField name="distributeAll" hideError={true}
                            onChange={(val) => this.onChangeDistributeAll(val)} />
                    </div>
                    <div className="text-field">
                        <RadioListField name="typeCheckoutTip" required={true} options={checkoutMethods}
                            hideError={true}
                            viewMode={"horizontal"} labelKey={"code"} valueKey={"id"} isMulti={false}
                            isClearable={false} onChange={(val) => console.log('Function:  -> : ', val)} />
                    </div>
                    {this.renderGrid()}
                </div>
            </Form>)
    }
}

export default TechnicianEditTipGrid