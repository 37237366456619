import React, { Component } from 'react'
import { CHANGE_STATUS_ACTION } from '../../../common/history/constant/HistoryConstant';
import GridChangeStatusBookingHistory from './GridChangeStatusBookingHistory';
import GridHistoryBookingItem from './GridHistoryBookingItem';
import { HistoryUtil } from './../../../utils/HistoryUtil';
import { SEGMENT_TYPE, BILL_TYPE } from './../../posTransaction/constant/BookingConstant';
import HistorySegmentBookingTemplate from './../../booking/component/HistorySegmentBookingTemplate';
import _ from 'lodash';
import BillHistoryTemplate from './../../system/view/BillHistoryTemplate';

class GridBookingHistoryTemplate extends Component {
    constructor(props) {
        super(props);
        this.initialValues = this.props.config;
        this.status = this.props.status;
        this.classNames = this.props.classNames
    }

    renderBodyItem(){
        const { entityType } = this.initialValues;
        const data = _.get(this.initialValues, 'editInfo');
        const type = HistoryUtil.getTypeFormEntity(entityType)
        switch (type) {
            case SEGMENT_TYPE:
                return HistorySegmentBookingTemplate(data, this.classNames)
            case BILL_TYPE:
                return BillHistoryTemplate(this.initialValues, this.classNames)
            default:
                return this.renderGridHistoryBooking();
        }
    }

    renderGridHistoryBooking() {
        if (this.status === CHANGE_STATUS_ACTION) {
            return <GridChangeStatusBookingHistory initialValues={this.initialValues} classNames={this.classNames} />
        }
        return <GridHistoryBookingItem initialValues={this.initialValues} />
    }

    render() {
        return (<>
            {this.renderBodyItem()}
        </>
        )
    }
}
export default GridBookingHistoryTemplate;