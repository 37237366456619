import React from 'react';
import GiftCardForm from "../../giftCard/form/GiftCardForm";
import _ from "lodash";
import AppUtil from "../../../utils/AppUtil";
import AppStore from "../../../store";
import Icon from "@idtek/component/lib/icon/Icon";
import { t } from '@idtek/component/lib/language';

class GiftCardDetailAddBillForm extends GiftCardForm {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: {
                status: 1,
                code: AppUtil.autoGenGiftCode(),
                branchId: _.get(AppStore.getState(), `root.branch.id`, {}),
                typeCheckout: "CASH",
                ...props.initialValues
            }
        };
        this.buttons = [
            {
                text: t("GiftCardDetailAddBillForm-CLOSE"),
                className: "btn-secondary",
                icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                onPress: () => this.props.onClose()
            }
        ];
    }


}

export default GiftCardDetailAddBillForm;