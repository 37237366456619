import React, {useEffect} from 'react';
import {usePaymentContext} from "../context/PaymentContext";
import {Empty, Fieldset, t} from "@idtek/component";
import ConditionField from "@idtek/form/lib/condition/ConditionField";
import _ from "lodash";
import {CSSTransition} from 'react-transition-group';
import AppUtil from "../../../utils/AppUtil";
import {TextField} from "@idtek/form";

const CheckoutAndInput = ({formRef}) => {
    const {
        optionsData,
        resultPayment,
        backMoney,
        checkouts,
        setReceivedObj,
        receivedObj,
        setTotalReceivedMoney,
        setBackMoney,
        totalReceivedMoney
    } = usePaymentContext();
    const titleField = t("CheckoutAndInput-AMOUNT_RECEIVED");
    const debouncedOnChangeReceived = _.debounce((money, checkOutId) => onChangeReceived(money, checkOutId), 250)

    useEffect(() => {
        if (!resultPayment.loading) {
            if (_.size(checkouts) === 1) {
                const init = _.map(checkouts, id => {
                    setTimeout(() => {
                        formRef.current && formRef.current.setValueField(`received_${id}`, resultPayment.valueFinally, true)
                        formRef.current && formRef.current.setValueField("return", 0, true);

                    }, 0)

                    return {
                        checkoutMethodId: id,
                        value: resultPayment.valueFinally
                    }
                });
                setReceivedObj(init)
            } else {
                const values = formRef.current.getValues()
                let total = 0
                Object.keys(values).forEach(function (key) {
                    // do something with obj[key]
                    if (key.includes(`received_`) && values[key]) {
                        total = total + parseFloat(values[key])
                    }
                });
                const backValue = AppUtil.roundNumber(total - resultPayment.valueFinally)
                setTimeout(() => {
                    formRef.current && formRef.current.setValueField("return", backValue, true);
                }, 0)
                setBackMoney(backValue);
            }

        }
    }, [resultPayment.loading, checkouts, resultPayment.valueFinally])

    const onChangeReceived = (money, checkOutId) => {
        const {valueFinally} = resultPayment;
        const clone = _.cloneDeep(receivedObj);
        let exist = false
        const newReces = _.map(clone, item => {
            if (item.checkoutMethodId === checkOutId) {
                item.value = parseFloat(money);
                exist = true;
            }
            return {...item};
        });
        if (!exist) {
            newReces.push({
                checkoutMethodId: checkOutId,
                value: parseFloat(money)
            })
        }
        let sum = _.sumBy(newReces, x => x.value ? x.value : 0);
        const backValue = AppUtil.roundNumber(sum - (valueFinally ? valueFinally : 0));
        setBackMoney(backValue);
        setTotalReceivedMoney(sum ? sum : 0);
        setReceivedObj(newReces);
        formRef.current && formRef.current.setValueField("return", backValue, true);
    };
    if (resultPayment.loading || !formRef.current) return <></>
    return (
        <div>
            <div className="bill-input-money">
                <Fieldset title={titleField}>
                    {_.isEmpty(checkouts) &&
                        <div>
                            <Empty style={{height: "50px"}} description={<div style={{
                                marginTop: -5,
                                fontSize: 11,
                                fontStyle: 'italic'
                            }}>{t("*Choose check-out method")}</div>}
                                   imageStyle={{
                                       height: 32,
                                   }}/>
                        </div>}
                    <CSSTransition
                        in={!_.isEmpty(checkouts)}
                        timeout={200}
                        classNames="checkout"
                        unmountOnExit
                    >
                        <div>
                            {
                                optionsData.checkoutMethods && optionsData.checkoutMethods.map((item, index) => {
                                    const style = index ? {marginTop: 10} : {};
                                    return <ConditionField renderCondition={(val) => _.indexOf(val, item.id) >= 0}
                                                           when="typeCheckout">
                                        <div className="text-field" style={style}>
                                            <TextField
                                                options={{
                                                    numeralPositiveOnly: true
                                                }}
                                                hideError={true}
                                                className="back-money"
                                                name={`received_${item.id}`}
                                                label={item ? item.name : ""}
                                                widthLabel="120px"
                                                type="money"
                                                required={true}
                                                onChange={(val) => onChangeReceived(val, item.id)}
                                                inCondition={true}
                                            />
                                        </div>
                                    </ConditionField>
                                })
                            }
                        </div>
                    </CSSTransition>


                </Fieldset>
                <div className="text-field">
                    <TextField
                        options={{}}
                        hideError={true}
                        className="back-money"
                        name="return"
                        type="money"
                        // max={_.max([0, backMoney])}
                        // min={_.min([0, backMoney])}
                        // readOnly={backMoney <= 0 || !backMoney}
                        readOnly={true}
                        widthLabel={130}
                    />
                </div>
            </div>
        </div>
    );
};

export default CheckoutAndInput;