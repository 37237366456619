import React, {
    useEffect,
    useRef,
} from "react";
import _ from "lodash";
import "../style/add-cart.scss";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import { useMergeState } from "@idtek/component/lib/hook/IDHook";
import GridDetailGuest from "../component/GridDetailGuest";
import Loading from "../../../pages/Loading";
import SearchAndSelectComponent from "../component/SearchAndSelectComponent";
import AppStore from "../../../store";
import { AddCartFormProvider } from "../context/AddCartFormContext";
import Button from "@idtek/component/lib/button/IDButton";
import Icon from "@idtek/component/lib/icon/Icon";
import InfoBooking from "../component/InfoBooking";
import AddOrderDetailComponent from "../component/AddOrderDetailComponent";
import { t } from "@idtek/component/lib/language";
import Alert from "@idtek/component/lib/alert/Alert";
import { useContentPaymentContextStep } from "../context/ContentPaymentContextStep";
import PaymentConstant from "../constant/PaymentConstant";
import moment from "moment";

const AddCartDetailForm = ({
    bookingId,
    guestId,
    onClose,
    onReloadData,
    branchId,
    isDetailCart,
    bodyCart,
    refFormCart,
    mainCartId,
    customerIdCart
}) => {
    const { stepCurrent, setStepCurrent, valuesProps, setValuesProps, reloadInit } = useContentPaymentContextStep();
    const [state, setState] = useMergeState({
        guestId: _.isArray(guestId) ? guestId : _.isNull(guestId) || _.isUndefined(guestId) ? [] : [guestId],
        booking: {},
        guestList: [],
        segmentList: [],
        giftCardIds: [],
        giftCardList: [],
        productList: [],
        productIds: [],
        cartIds: [],
        loading: true,
        initFirstTime: true,
        guestListInitId: null,
        giftListInitId: null,
        billId: null
    });

    const refSearchAndSelectComponent = useRef(null),
        refAddOrderDetailComponent = useRef(null)
    const urlDetail = ApiBookingConstant.API_GET_DETAIL_BILL_ADD_CART
    const urlSave = ApiBookingConstant.API_UPDATE_BILL_CART

    useEffect(() => {
        const loadData = async () => {
            //vì lúc đầu ko init đc value gift, guest nên phải load từ api và set ngược lại
            const valueGuest = refSearchAndSelectComponent.current && refSearchAndSelectComponent.current.getValue()
            const valueGift = refAddOrderDetailComponent.current && refAddOrderDetailComponent.current.getValueGift()
            const valueProduct = refAddOrderDetailComponent.current && refAddOrderDetailComponent.current.getValueProduct()

            if (refFormCart) refFormCart.current && refFormCart.current.mask()
            let params = {
                guestId: state.initFirstTime ? state.guestId : valueGuest,
                bookingId,
                giftCardIds: valueGift,
                productIds: valueProduct,
                productData: _.filter(state.productList, x => _.indexOf(valueProduct, x.id) >= 0),
                initFirstTime: state.initFirstTime,
                mainCartId
            }

            const res = await AppUtil.postApi(urlDetail, params)

            if (_.get(res, "data.success")) {
                const data = _.get(res, "data.result")
                const valueFormCart = _.get(data, 'cart')
                if (valueFormCart) {
                    valueFormCart.customerName = _.get(valueFormCart, "customer.name");
                    valueFormCart.email = _.get(valueFormCart, "customer.email");
                    if (refFormCart) {
                        refFormCart.current && refFormCart.current.setValues(valueFormCart)
                        refFormCart.current && refFormCart.current.unmask()
                    }

                }
                const newState = {
                    booking: data.booking,
                    guestList: data.guestList,
                    segmentList: data.segmentList,
                    giftCardList: data.giftCardList,
                    productList: data.productList,
                    billId: data.billId,
                    initFirstTime: false,
                    totalAmountGuest: data.totalAmountGuest,
                    loading: false
                }
                if (state.initFirstTime) {
                    newState.guestListInitId = _.map(data.guestList, x => x.id)
                    newState.giftListInitId = _.map(data.giftCardList, x => x.id)
                    newState.productListInitId = _.map(data.productList, x => x.id)
                }
                setState(newState)
            } else {
                AppUtil.ToastApiError()
            }
        }

        stepCurrent === PaymentConstant.STEP_2_CART_BILL_DETAIL && loadData()
    }, [state.guestId, state.giftCardIds, state.productIds, reloadInit])


    const onChangeGuest = (ids) => {
        setState({
            ...state,
            guestId: _.uniq(_.concat([guestId], ids))
        });
    };

    const customRowGuest = (data) => {
        return <div className="row-add-more">
            <div>
                {t("AddCartDetailForm-BOOKING")}: {data.code} - {t("AddCartDetailForm-GUEST")}: {data.name}
            </div>
            <div>
                {t("AddCartDetailForm-CUSTOMER")}: {data.customerName} -
                {t("AddCartDetailForm-SUBTOTAL")}: {data.totalAmount}
            </div>
        </div>;
    };

    const addGiftToBill = (gift) => {
        setState({
            giftCardIds: gift
        })
    }

    const onCreateGiftSuccess = (id) => {
        const { giftCardIds } = state
        const newIds = _.cloneDeep(giftCardIds)
        newIds.push(id)
        setState({
            giftCardIds: newIds
        })
    }

    const addProductToBill = (product) => {
        setState({
            productIds: product
        })
    }

    const onCreateProductSuccess = (id) => {
        const { productIds } = state
        const newIds = _.cloneDeep(productIds)
        newIds.push(id)
        setState({
            productIds: newIds
        })
    }

    const onSaveAndContinue = async (continute = true) => {
        const cart = {}
        if (refFormCart) {
            if (refFormCart.current.isValid()) {
                const valuesCart = refFormCart.current && refFormCart.current.getValues()
                cart.phone = _.get(valuesCart, "customer.phone");
                cart.branchId = branchId
                cart.customerName = _.get(valuesCart, "customerName");
                cart.customerId = _.get(valuesCart, "customerId");
                cart.email = _.get(valuesCart, "email");
                cart.id = mainCartId
            } else {
                AppUtil.ToastError(t("AddCartDetailForm-FORM_INVALID"));
                return;
            }
        }
        const valueGuest = refSearchAndSelectComponent.current && refSearchAndSelectComponent.current.getValue()
        const valueGift = refAddOrderDetailComponent.current && refAddOrderDetailComponent.current.getValueGift()
        const valueProduct = refAddOrderDetailComponent.current && refAddOrderDetailComponent.current.getValueProduct()
        const params = {
            guestIdList: valueGuest,
            giftCardIdList: valueGift,
            branchId: branchId,
            billId: state.billId,
            productIdList: valueProduct,
            productData: _.filter(state.productList, x => _.indexOf(valueProduct, x.id) >= 0),
            cartUpdate: cart,
        }
        if (cart.phone) {
            params.cartCreate = {
                ...cart,
                id: null
            }
        }
        const response = await AppUtil.postApi(urlSave, params);
        // onReloadData && onReloadData()
        if (_.get(response, "data.success")) {
            const data = _.get(response, "data.result")
            const cartIds = _.get(data, 'cartIds')
            setState({
                ...state,
                cartIds,
                guestId: _.get(data, 'guestIds'),
                billId: _.get(data, 'id'),
            })
            const vlProps = {
                ...valuesProps,
                guestId: _.get(data, 'guestIds'),
                cartIds: cartIds,
                billId: _.get(data, 'id'),
                customerIdCart: _.get(data, 'customerIdCart'),
            }
            setValuesProps(vlProps)
            if (continute) {
                if (bookingId) {
                    setStepCurrent(PaymentConstant.STEP_3_PAYMENT)
                } else {
                    setStepCurrent(PaymentConstant.STEP_3_PAYMENT_NO_BOOKING)
                }
            }

        } else {
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    }

    const onCancel = async () => {
        let confirm = await Alert.Swal_confirm(t("AddCartDetailForm-CONFIRM"), t("AddCartDetailForm-ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_CART?"), 3);
        if (_.get(confirm, "value") === true) {
            const res = await AppUtil.getApi(`${ApiBookingConstant.API_CANCEL_BILL_CART}`, { billId: billId });
            if (res && _.get(res.data, "success")) {
                AppUtil.ToastDeleteSuccess();
                onReloadData && onReloadData()
                onClose && onClose();
            } else {
                AppUtil.ToastDeleteError(_.get(res, "data.messages") || _.get(res, "data.message") || t("AddCartDetailForm-DATA_ERROR,_PLEASE_TRY_AGAIN!"));
                onClose && onClose();

            }
        }
    }


    const renderButton = () => {
        return <div className={"wrap-button"}>
            <Button className={"btn-form btn btn-danger"}
                // style={{width: 120}}
                type={"default"}
                icon={<Icon type="close-circle" style={{ fontSize: 16, marginTop: -3 }} />}
                onClick={() => onCancel()}
            >
                {t("AddCartDetailForm-CANCEL")}
            </Button>
            <Button className={"btn-form btn btn-primary-lighter"}
                // style={{width: 120}}
                type={"default"}
                icon={<Icon type="dollar" style={{ fontSize: 16, marginTop: -3 }} />}
                onClick={() => onSaveAndContinue(true)}
            >
                {t("AddCartDetailForm-PAYMENT")}
            </Button>
            <Button className={"btn-form btn btn-secondary"}
                // style={{width: 120}}
                type={"default"}
                icon={<Icon type="close-square" style={{ fontSize: 16, marginTop: -3}} />}
                onClick={() => onClose && onClose()}
            >
                {t("AddCartDetailForm-CLOSE")}
            </Button>
        </div>
    }

    if (state.loading) return <div style={{ flex: 1, height: "400px" }}><Loading /></div>;
    const {
        guestList,
        segmentList,
        giftCardList,
        booking,
        // continueState,
        cartIds,
        guestListInitId,
        giftListInitId,
        billId,
        productList,
        productListInitId,
        totalAmountGuest
    } = state

    return (
        <AddCartFormProvider guestList={guestList} segmentList={segmentList} giftCardList={giftCardList}
            booking={booking} isDetail={isDetailCart} productList={productList}
            customerIdCart={customerIdCart} totalAmountGuest={totalAmountGuest}>
            <div className={"add-cart"}>
                <div className={"right"}>
                    {bodyCart && bodyCart()}
                    {bookingId && <>
                        <InfoBooking data={state.booking} />
                        <SearchAndSelectComponent
                            ref={refSearchAndSelectComponent}
                            urlSearch={ApiBookingConstant.API_INIT_SEARCH_GUEST}
                            params={{
                                branchId: _.get(AppStore.getState(), `root.branch.id`, null),
                                guestIds: guestListInitId,
                                dateBill: moment(booking.startTime).format("YYYY-MM-DD")
                            }}
                            className={"combo-search-guest mgt-0"}
                            onChange={onChangeGuest}
                            placeholder={`${t("AddCartDetailForm-SEARCH_GUEST")}...`}
                            customRow={customRowGuest}
                            initValue={guestListInitId}
                            method={"post"}
                        />
                        <GridDetailGuest />
                    </>}

                    <AddOrderDetailComponent
                        ref={refAddOrderDetailComponent}
                        giftListInitId={giftListInitId}
                        productListInitId={productListInitId}
                        addGiftToBill={addGiftToBill}
                        onCreateGiftSuccess={onCreateGiftSuccess}
                        addProductToBill={addProductToBill}
                        onCreateProductSuccess={onCreateProductSuccess}
                    />
                </div>
            </div>
            {renderButton()}
        </AddCartFormProvider>
    );
};

export default AddCartDetailForm;