import Container from '@idtek/component/lib/container/Container'
import Tooltip from '@idtek/component/lib/tooltip/Tooltip'
import _ from 'lodash'
import moment from 'moment'
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import { useAppointmentContext } from '../context/AppointmentContext'
import { t } from '@idtek/component/lib/language'

const StatisticOfDay = () => {
    const {
        bookings,
        date,
        guests,
    } = useAppointmentContext()
    const [show, setShow] = useState(false)
    const refStatistic = useRef(null)
    let totalPrice = 0
    let price = 0
    let completedBooking = 0
    let waitingBooking = 0
    let processingBooking = 0
    let cancelBooking = 0
    let noShowBooking = 0
    // eslint-disable-next-line array-callback-return
    guests && guests.map((booking) => {
        switch (booking.status) {
            case 'Waiting':
                totalPrice += booking.totalAmount
                waitingBooking += 1
                break
            case 'Processing':
                totalPrice += booking.totalAmount
                processingBooking += 1
                break
            case 'Completed':
                price += booking.totalAmount
                totalPrice += booking.totalAmount
                completedBooking += 1
                break
            case 'Cancel':
                cancelBooking += 1
                break
            case 'NoShow':
                noShowBooking += 1
                break
            default:
                break
        }
    })

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        // returned function will be called on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleClickOutside = (e) => {
        if (refStatistic && refStatistic.current && !refStatistic.current.contains(e.target)) {
            setShow(false)
        }
    }

    return (<Container className="statistic-of-day">
        <Tooltip title={t('StatisticOfDay-STATISTIC_OF_THE_DAY!')} placement="topRight">
            <div className="button-statistic" onClick={() => setShow(!show)}>
                <i className="fa fa-bar-chart" />
            </div>
            <div className="popup-statistic" hidden={!show} ref={refStatistic}>
                <div className="statistic-info">
                    <div className="title-statistic">{t("Daily performance summary")}: {moment(date).format('DD/MM/YYYY')}</div>
                    <div className="content-statistic">
                        <div className="row-info">
                            <span className="text-info">{t('StatisticOfDay-THE_ACTUAL_RECEIPTS')}</span>
                            <span className="value-info">{price.toFixed(2)} $</span>
                        </div>
                        <div className="row-info">
                            <span className="text-info">{t('StatisticOfDay-THE_PROJECTED_TURNOVER')}</span>
                            <span className="value-info">{totalPrice.toFixed(2)} $</span>
                        </div>
                        <div className="row-info">
                            <span className="text-info">{t('StatisticOfDay-TOTAL_BOOKINGS')}</span>
                            <span className="value-info">{_.size(bookings)}</span>
                        </div>
                        <div className="row-info">
                            <span className="text-info">{t('StatisticOfDay-TOTAL_GUESTS')}</span>
                            <span className="value-info">{_.size(guests)}</span>
                        </div>
                        <div className="row-info-child">
                            <span className="text-info">{t('StatisticOfDay-WAITING')}</span>
                            <span className="value-info">{waitingBooking}</span>
                        </div>
                        <div className="row-info-child">
                            <span className="text-info">{t('StatisticOfDay-PROCESSING')}</span>
                            <span className="value-info">{processingBooking}</span>
                        </div>
                        <div className="row-info-child">
                            <span className="text-info">{t('StatisticOfDay-COMPLETED')}</span>
                            <span className="value-info">{completedBooking}</span>
                        </div>
                        <div className="row-info-child">
                            <span className="text-info">{t('StatisticOfDay-CANCELLED')}</span>
                            <span className="value-info">{cancelBooking}</span>
                        </div>
                        <div className="row-info-child">
                            <span className="text-info">{t('StatisticOfDay-NOSHOW')}</span>
                            <span className="value-info">{noShowBooking}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    </Container>)
}

export default StatisticOfDay
