import React, { Suspense } from "react";
import BaseListView from "../../../common/baseView/BaseListView";
import AppUtil from "../../../utils/AppUtil";
import ProductDetailForm from "../../product/form/ProductDetailForm";
import { modelProduct } from "../../product/model/modelProduct";
import { t } from "@idtek/component/lib/language";
import { allRights } from "../../../utils/rightUtils";
import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import Grid from "@idtek/grid/lib/grid/Grid";
import Loading from "../../../pages/Loading";
import _ from "lodash";
import { AddCartFormContext } from "../context/AddCartFormContext";
import Alert from "@idtek/component/lib/alert/Alert";
import Textbox from "@idtek/component/lib/textbox/Textbox";
import ProductCreateAddBillForm from "../form/ProductCreateAddBillForm";

class GridDetailProduct extends BaseListView {
  static contextType = AddCartFormContext;

  constructor(props) {
    super(props);
    this.state = {};
    this.apiIndex = AppUtil.GLOBAL_API_PATH + "/product/index-product";
    this.apiDelete = "/product/delete-product";
    this.girdStore = new BaseStore({
      data: [],
      autoLoad: true,
    });
    this.rightAccess = allRights.PRODUCT_ACCESS;
    this.updateRight = allRights.PRODUCT_CREATE;
    this.createRight = allRights.PRODUCT_CREATE;
    this.deleteRight = allRights.PRODUCT_DELETE;
    this.model = modelProduct;
    this.hasCreate = false;
    this.globalFilter = false;
    this.floatingFilter = false;
    this.defaultNumberRows = true;
    this.FormModal = ProductCreateAddBillForm;
    this.FormModalDetail = ProductDetailForm;
    this.moduleName = t("GridDetailProduct-PRODUCT");
    this.columnDefs = [
      {
        headerName: t("GridDetailProduct-CODE"),
        width: 90,
        field: "code",
        colSpan: function (params) {
          if (params.data && params.data.colSpan > 1) {
            return params.data.colSpan;
          } else {
            return 1;
          }
        },
      },
      {
        headerName: t("GridDetailProduct-NAME"),
        field: "name",
        width: 150,
      },
      {
        headerName: t("GridDetailProduct-DESCRIPTION"),
        field: "description",
      },
      {
        headerName: t("GridDetailProduct-QUANTITY"),
        field: "quantity",
        width: 130,
        filter: false,
      },
      {
        headerName: t("GridDetailProduct-PRICE"),
        field: "price",
        width: 80,
        textAlign: "center",
        filter: false,
      },
    ];


    this.cellRenderer = {
      quantity: {
        renderer: (data, value, rowNode) => {
          if (data.field !== 'pinned') {
            return (
              <div style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Textbox
                  value={value}
                  options={{
                    numeralPositiveOnly: true,
                    numeralDecimalScale: 0
                  }}
                  placeholder={`${t("GridDetailProduct-INPUT")}...`}
                  defaultValue={1}
                  style={{ height: '28px' }}
                  min={1}
                  onBlur={this.onBlurInput.bind(this, data, "quantity")}
                  type={"number"}
                  inputStyle={{ textAlign: "right" }}
                  // readOnly={!this.state.value}
                  isClearable={false}
                  // iconLine={false}
                  // icon={<span style={{fontSize: 12, fontWeight: 600}}>$</span>}
                  iconAlign="before"
                />
              </div>
            );
          }
          return <div className={"global-flex-align-center-justify-end"}>{_.sumBy(this.getDataStore(), x =>
            x.quantity ? parseInt(x.quantity) : 1)}</div>
        }
      },
      price: {
        renderer: (data, value, rowNode) => {
          if (data.field !== 'pinned') {
            let quantity = data.quantity ? parseInt(data.quantity) : 1
            return <div
              className={"global-flex-align-center-justify-end"}>$ {AppUtil.roundNumber(value * quantity)}</div>
          }
          const sumGift = _.sumBy(this.getDataStore(), x => x.price * (x.quantity ? parseInt(x.quantity) : 1))
          return <div
            className={"global-flex-align-center-justify-end"}>$ {sumGift ? AppUtil.roundNumber(sumGift) : 0}</div>
        }
      }

    }
    this.heightGrid = "auto";
    this.minHeightGrid = "0px";
    this.widthActionRow = 140;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setData(_.get(nextContext, 'productList'))
    this.myGrid.gridApi && this.myGrid.gridApi.redrawRows();
  }

  componentDidMount() {
    this.setData(_.get(this.context, 'productList'))
  }

  setData(data) {
    this.myGrid && this.girdStore.setData(data)
  }

  getDataStore() {
    return this.myGrid && this.girdStore.getData()
  }

  onGridReady = () => {
    if (this.myGrid) {
      const pinned = [{
        code: t("GridDetailProduct-TOTAL"),
        price: "",
        quantity: "",
        field: "pinned",
        colSpan: 3
      }]
      this.myGrid.gridApi && this.myGrid.gridApi.setPinnedBottomRowData(pinned);
    }
  };

  onBlurInput = async (data, fieldKey, value) => {
    data[fieldKey] = value;
    this.myGrid.gridApi && this.myGrid.gridApi.redrawRows();
  };

  onEditRow = async (row, rowNode) => {
    this.formDefaults["readOnly"] = false;
    const FormModal = this.FormModal;
    this.Modal.onOpen(
      <Suspense fallback={<Loading style={{ height: 300 }} />}>
        <FormModal
          onClose={(flag, dataRow) => {
            this.onCloseModalForm(flag, dataRow, rowNode);
          }}
          onReloadData={() => {
            this.reLoad();
          }}
          formDefaults={this.formDefaults}
          isCreate={false}
          model={this.model}
          initialValues={{
            ...row,
          }}
        />
      </Suspense>,
      this.renderTitleForm(this.titleUpdate + this.moduleName),
      this.popUpWidth,
    );
    this.refContainer && this.refContainer.unmask();
  };
  onCreate = () => {
    this.formDefaults["readOnly"] = false;
    const FormModal = this.FormModal;
    this.Modal.onOpen(
      <Suspense fallback={<Loading style={{ height: 300 }} />}>
        <FormModal
          onClose={(flag, dataRow) => {
            this.Modal.onClose();
          }}
          isCreate={true}
          model={this.model}
          formDefaults={{
            alignLabel: "left",
            widthLabel: "150px",
          }}
          initialValues={this.initialValues ? this.initialValues : {}}
          onCreateSuccess={this.props.onCreateSuccess}
        />
      </Suspense>,
      this.renderTitleForm(this.titleCreate + this.moduleName),
      this.popUpWidth,
    );
    this.refContainer && this.refContainer.unmask();
  };

  onDeleteRow = async (row) => {
    let confirm = await Alert.Swal_confirm(t("GridDetailProduct-CONFIRM"), t("GridDetailProduct-ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_RECORD?"), 3);
    if (_.get(confirm, "value") === true) {
      const res = await AppUtil.postApi(`${this.apiDelete}?id=${row[this.keyID]}`);
      if (res && _.get(res.data, "success")) {
        const idDelete = row[this.keyID];
        const data = this.getDataStore();
        const dataIds = _.map(data, x => x.id);
        const dataIdsRest = _.filter(dataIds, x => x !== idDelete);
        this.props.onChange && this.props.onChange(dataIdsRest);
        AppUtil.ToastDeleteSuccess();
      } else {
        AppUtil.ToastDeleteError(_.get(res, "data.messages") || _.get(res, "data.message") || t("GridDetailProduct-DATA_ERROR,_PLEASE_TRY_AGAIN!"));
      }
    }
  };

  renderActions = (row) => {
    // button disable them className: "button-action-row-disabled"
    if (!row || row.field === "pinned") return [];
    const buttons = [];
    buttons.push({
      type: "detail",
      onPress: this.onDetailRow,
      icon: {
        type: "info-circle",
        style: { background: "#5e72e4" },
        color: "#ffffff",
      },
      tooltip: t("GridDetailProduct-DETAIL"),
    });
    buttons.push({
      type: "edit",
      onPress: this.onEditRow,
      icon: {
        type: "edit",
        style: { background: "#2dce89" },
        color: "#ffffff",
      },
      tooltip: t("GridDetailProduct-UPDATE"),
    });
    buttons.push({
      type: "delete",
      onPress: this.onDeleteRow,
      icon: {
        type: "delete",
        style: { background: "var(--main-color-red)" },
        color: "#ffffff",
      },
      tooltip: t("GridDetailProduct-DELETE"),
    });
    return buttons;
  };

  render() {
    return (
      <div>
        <Grid
          ref={grid => this.myGrid = grid}
          height={this.heightGrid}
          minHeight={this.minHeightGrid}
          columnDefs={this.columnDefs}
          cellRenderer={this.cellRenderer}
          defaultColDef={{
            sortable: false,
            filter: false,
          }}
          emptyComponent={
            <div style={{ height: "0px" }}></div>
          }
          store={this.girdStore}
          onGridReady={
            this.onGridReady
          }
          floatingFilter={this.floatingFilter}
          globalFilter={this.globalFilter}
          actionRows={this.actionRows()}
          rowHeight={32}
          headerVerticalLines={false}
          columnLines={true}
          headerTransparent={true}
          pagination={false}
          numberRows={{
            headerName: t("GridDetailProduct-NO"),
          }}
        />
        {this.renderModal()}
      </div>
    );
  }
}

export default GridDetailProduct;