import React, {useMemo, useState} from 'react';
import {useMergeState} from "@idtek/component";
import _ from "lodash";
import {PaymentContext} from "./PaymentContext";
import useCalculatePaymentCart from "../../cart/hook/useCalculatePaymentCart";
import moment from "moment";

const PaymentCartProvider = ({children, initValue, optData, cartIds, mainCartId, branchId, isUseHstVal = true}) => {
    const [paymentData, setPaymentData] = useMergeState(initValue);
    const mainCart = _.find(paymentData?.cartList, x => x.id === mainCartId)
    let findCus = _.find(_.get(initValue, "customerOptions"), (x) => x.value === _.get(mainCart, "customerId"));

    const [cartIdsSelect, setCartIdsSelect] = useState(cartIds)
    const [customer, setCustomer] = useState({...findCus, id: findCus ? findCus.value : null});
    const [optionsData, setOptionsData] = useState(optData);
    const [voucher, setVoucher] = useState(null);
    const [method, setMethod] = useState(_.get(optionsData, "paymentMethods[0]"));
    const [gift, setGift] = useState(null);
    const [checkouts, setCheckouts] = useState(_.get(optionsData, 'checkOutDefault'));
    const [totalReceivedMoney, setTotalReceivedMoney] = useState(0);
    const [backMoney, setBackMoney] = useState(0);
    const [receivedObj, setReceivedObj] = useState(null);
    const [moneyPoint, setMoneyPoint] = useState(0);
    const [tips, setTips] = useState(null)
    const [isUseHst, setIsUseHst] = useState(isUseHstVal)

    const [initialValues, setInitialValues] = useState({
        customerId: _.get(mainCart, "customerId"),
        typeCheckout: checkouts,
        isHst: true,
        actionTime: moment().format("YYYY-MM-DD HH:mm")
    });

    const resultPayment = useCalculatePaymentCart({
        guestList: paymentData?.guestList,
        cartList: paymentData?.cartList,
        hst: optionsData ? optionsData.hst : null,
        method,
        voucher,
        gift,
        moneyPoint,
        loyaltyType: paymentData?.loyaltyType,
        objDiscount: _.get(paymentData?.booking, "bookingDiscounts[0]"),
        tips,
        isFromSaveBooking: paymentData?.isFromSaveBooking,
        isUseHst
    });

    const value = useMemo(
        () => ({
            paymentData,
            setPaymentData,
            initialValues,
            setInitialValues,
            optionsData,
            setOptionsData,
            customer,
            setCustomer,
            voucher,
            setVoucher,
            method,
            setMethod,
            gift,
            setGift,
            checkouts,
            setCheckouts,
            tips, setTips,
            totalReceivedMoney,
            setTotalReceivedMoney,
            backMoney,
            setBackMoney,
            receivedObj,
            setReceivedObj,
            moneyPoint,
            setMoneyPoint,
            isUseHst, setIsUseHst,
            mainCartId,
            cartIdsSelect, setCartIdsSelect,
            resultPayment,
            branchId
        }),
        [
            initialValues,
            paymentData,
            setPaymentData,
            optionsData,
            setOptionsData,
            customer,
            setCustomer,
            voucher,
            setVoucher,
            method,
            setMethod,
            gift,
            setGift,
            checkouts,
            setCheckouts,
            totalReceivedMoney,
            setTotalReceivedMoney,
            backMoney,
            setBackMoney,
            receivedObj,
            setReceivedObj,
            moneyPoint,
            setMoneyPoint,
            tips, setTips,
            isUseHst, setIsUseHst,
            mainCartId,
            cartIdsSelect, setCartIdsSelect,
            resultPayment,
            branchId
        ]
    );
    return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;

};

export default PaymentCartProvider;