import { t } from "@idtek/component/lib/language";
export const modelGiftCard = {
    fields: {
        code: {
            label: t("modelGiftCard-CODE"),
            required: true
        },
        content: {
            label: t('modelGiftCard-CONTENT'),
            required: true
        },
        recipientName: {
            label: t("modelGiftCard-RECIPIENT")
        },
        recipientPhone: {
            label: t('modelGiftCard-RECIPIENT_PHONE')
        },
        recipientEmail: {
            label: t('modelGiftCard-RECIPIENT_EMAIL')
        },
        branchIds: {
            label: t('modelGiftCard-BRANCH'),
        },
        payer: {
            label: t("modelGiftCard-PAYER")
        },
        phoneNumberPayer: {
            label: t("modelGiftCard-PAYER'S_PHONE")
        },
        emailPayer: {
            label: t("modelGiftCard-PAYER'S_EMAIL")
        },
        value: {
            label: t('modelGiftCard-VALUE_PRINTED_ON_GIFT'),
            required: true
        },
        price: {
            label: t('modelGiftCard-PAYABLE_VALUE'),
            required: true
        },
        imageFile: {
            label: t('modelGiftCard-BACKGROUND_IMAGE')
        },
        image: {
            label: t('modelGiftCard-BACKGROUND_IMAGE')
        },
        note: {
            label: t('modelGiftCard-NOTES')
        },
        branchId: {
            label: ""
        }
    }
};
