import {useMergeState} from "@idtek/component/lib/hook/IDHook";
import _ from "lodash";
import {useEffect} from "react";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";

const useGetOptionsPayment = (branchId) => {
    const [state, setState] = useMergeState({});
    useEffect(() => {
        const getOptions = async () => {

            const resOption = await AppUtil.getApi(ApiBookingConstant.API_GET_OPTION_PAYMENT, {
                branchId: branchId
            });
            if (_.get(resOption, "data.success")) {
                const data = _.get(resOption, "data.result");
                setState(data);
            }
        };
        branchId && getOptions();
    }, [branchId]);

    return state
};
export default useGetOptionsPayment;
