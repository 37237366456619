import _ from "lodash";
import {BookingStatusContant} from "../constant/BookingStatusContant";
import moment from "moment";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import TenantPricingKeyConstant from "../constant/TenantPricingKeyConstant";

export default class AppointmentContextMenu {

    static findQuantityGuestInBookingWithStatus = (bookingId, getBookingSchedule, status) => {
        const guests = _.uniqBy(_.filter(getBookingSchedule(), x => x.BookingId === bookingId && x.Status === status), g => g.GuestId)
        return _.size(guests)
    }

    static findQuantityGuestInBookingNoBillWithStatus = (node, bookingId, getBookingSchedule, status) => {
        const guests = _.uniqBy(_.filter(getBookingSchedule(), x => x.BookingId === bookingId && x.Status === status && _.size(x.BillBookingMappings) === 0), g => g.GuestId)
        return _.size(guests)
    }

    static findQuantityGuestCanCancel = (bookingId, getBookingSchedule) => {
        const guests = _.uniqBy(_.filter(getBookingSchedule(), x => x.BookingId === bookingId
            && x.Status !== BookingStatusContant.STATUS_COMPLETE &&
            x.Status !== BookingStatusContant.STATUS_PROCESSING
        ), g => g.GuestId)
        return _.size(guests)
    }

    static checkBeforeOpenContextMenu(args, cellData, eventObj, getBookingSchedule, selectedTarget, refMenuContext, scheduleObj, waitingList, multiSetting,) {
        const rightsPM = _.get(AppStore.getState(), `root.rightsPM`, []);
        if (selectedTarget.classList.contains("e-appointment")) {
            refMenuContext.current.hideItems(["add", "offTimeTech", "offTimeTechAL"], true);
            if (eventObj.Status) {
                const guestInBooking = this.findQuantityGuestInBookingWithStatus(eventObj.BookingId, getBookingSchedule, eventObj.Status)
                const guetCanCancel = this.findQuantityGuestCanCancel(eventObj.BookingId, getBookingSchedule)
                switch (eventObj.Status) {
                    case BookingStatusContant.STATUS_WAITING:

                        refMenuContext.current.showItems(["update", "clipboard", "print-slip", "resend-sms"], true);

                        if (!eventObj.Confirm) {
                            refMenuContext.current.showItems(["confirm"], true);
                        }
                        // if (!eventObj.Remind) {
                        //     refMenuContext.current.showItems(["resend-sms"], true);
                        // }
                        if (moment(eventObj.StartTime).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
                            if (guestInBooking > 1) {
                                refMenuContext.current.showItems(["check-in-group", "start-group"], true);
                            } else {
                                refMenuContext.current.showItems(["check-in", "start"], true);
                            }
                        }

                        if (guetCanCancel > 1) {
                            refMenuContext.current.showItems(["cancel-group", "no-show-group"], true);
                        } else {
                            refMenuContext.current.showItems(["cancel", "no-show"], true);
                        }
                        break;
                    case BookingStatusContant.STATUS_CHECKIN:
                        refMenuContext.current.showItems(["update", "print-slip", "back-to-waiting"], true);
                        if (guestInBooking > 1) {
                            refMenuContext.current.showItems(["start-group"], true);
                        } else {
                            refMenuContext.current.showItems(["start"], true);
                        }
                        if (guetCanCancel > 1) {
                            refMenuContext.current.showItems(["cancel-group"], true);
                        } else {
                            refMenuContext.current.showItems(["cancel"], true);
                        }

                        break;
                    case BookingStatusContant.STATUS_PROCESSING:
                        const guestInBookingNoBill = this.findQuantityGuestInBookingNoBillWithStatus(eventObj, eventObj.BookingId, getBookingSchedule, eventObj.Status)
                        refMenuContext.current.showItems(["update", "print-slip", "back-to-checkin"], true);

                        // if (guestInBooking > 1) {
                        //     refMenuContext.current.showItems(["payment-group"], true);
                        // } else {
                        //     refMenuContext.current.showItems(["payment"], true);
                        // }
                        const pricingRightCart = AppUtil.checkPermission(TenantPricingKeyConstant.PRODUCT, rightsPM) || AppUtil.checkPermission(TenantPricingKeyConstant.GIFT, rightsPM)
                        if (guestInBookingNoBill > 1 && _.size(eventObj.BillBookingMappings) === 0) {
                            refMenuContext.current.showItems(["payment-group"], true);
                            if (pricingRightCart) {
                                refMenuContext.current.showItems(["cart-group"], true);
                            }
                        } else if (_.size(eventObj.BillBookingMappings) === 1) {
                            if (pricingRightCart) {
                                refMenuContext.current.showItems(["cart-detail"], true);
                            }
                        } else {

                            refMenuContext.current.showItems(["payment"], true);
                            if (pricingRightCart) {
                                refMenuContext.current.showItems(["cart"], true);
                            }
                        }

                        break;
                    case BookingStatusContant.STATUS_COMPLETE:
                        if (eventObj.AlreadyTip) {
                            refMenuContext.current.showItems(["detail", "detailPayment", "cancelBill", "print", "hasTipTechnician"], true);
                        } else {
                            refMenuContext.current.showItems(["detail", "detailPayment", "cancelBill", "print", "tipTechnician"], true);
                        }

                        break;
                    default:
                    // code block
                }
            }
            return;
        } else {
            selectedTarget.classList.add("e-selected-cell");
            if (cellData && moment(cellData.startTime).add(15, "minutes").format("YYYY-MM-DD HH:mm") < moment().format("YYYY-MM-DD HH:mm")) {
                // args.cancel = true;
                // return;
            } else {
                if (multiSetting) {
                    if (_.size(scheduleObj.current.getSelectedElements()) === 1) {
                        refMenuContext.current.showItems(["add"], true);
                    }
                } else {
                    const format = "YYYY-MM-DD HH:mm:ss";
                    let resourceDetails = scheduleObj.current.getResourcesByIndex(cellData.groupIndex);
                    const technician = resourceDetails.resourceData.id;
                    const findAbove = _.find(
                        getBookingSchedule(),
                        (x) => x.TechnicianId === technician && moment(x.EndTime).format(format) > moment(cellData.startTime).format(format) && moment(x.EndTime).format(format) < moment(cellData.endTime).format(format)
                    );
                    const start = findAbove ? moment(findAbove.EndTime).format(format) : cellData.startTime;
                    const isSlot = scheduleObj.current.isSlotAvailable(start, cellData.endTime, cellData.groupIndex);
                    if (isSlot) {
                        if (_.size(scheduleObj.current.getSelectedElements()) === 1) {
                            refMenuContext.current.showItems(["add"], true);
                        }
                    } else {
                        args.cancel = true;
                    }
                }
            }
        }
    }
}