import * as React from "react";
import {SampleBase} from "../../../common/component/sample-base";
import _ from "lodash";
import Icon from "@idtek/component/lib/icon/Icon";
import Textbox from "@idtek/component/lib/textbox/Textbox";
import AppUtil from "../../../utils/AppUtil";
import AppStore from "../../../store";
import * as Contant from "../../../application/constants/constant";

export default class InputSearch extends SampleBase {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };

        this.optionPhoneSetting = AppUtil.RegexPhone(
            _.find(_.get(AppStore.getState(), `root.branch.branchConfigs`), (x) => x.type === Contant.PHONE_SET_UP).value);

    }

    setValue(value) {
        this.setState({
            value
        });
    }

    maskChange = (value) => {
        if (this.wrapSearchBox) {
            const widthPopover = this.wrapSearchBox.clientWidth;
            widthPopover && this.props.onOpen && this.props.onOpen(widthPopover);
        }
        this.props.onChange && this.props.onChange(value);
        this.setState({
            value: value
        });

        this.rawValue = value;
    };

    render() {
        const {value} = this.state;
        const {readOnly, phoneSetting, onOpen} = this.props;
        return (
            <div className="wrap-search-box" ref={(c) => (this.wrapSearchBox = c)}>
                <div
                    style={{flex: 1}}
                    onClick={() => {
                        const widthPopover = this.wrapSearchBox.clientWidth;
                        onOpen && onOpen(widthPopover);
                    }}>
                    <Textbox
                        value={value}
                        type={"phone"}
                        icon={<Icon type="phone" style={{
                            fontSize: 16,
                            color: "#0d81c0",
                            display: "flex",
                            justifyContent: "center"
                        }}/>}
                        required
                        options={this.optionPhoneSetting}
                        style={{width: "100%"}}
                        mask={_.get(phoneSetting, "regex") || "000 000 0000"}
                        onChange={this.maskChange}/>
                </div>

            </div>
        );
    }
}
