import _ from "lodash";
import {useEffect, useState} from "react";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../../appointment/constant/ApiBookingConstant";

const useGetDetailBillNoBooking = (billId) => {
    const [dataBill, setDataBill] = useState(null);
    useEffect(() => {
        const getDetailBill = async () => {
            const res = await AppUtil.getApi(ApiBookingConstant.API_GET_BILL_NO_BOOKING, {
                billId: billId
            });
            if (_.get(res, "data.success")) {
                setDataBill(_.get(res, "data.result"));
            }
        };
        getDetailBill();
    }, [billId]);

    return dataBill;
};
export default useGetDetailBillNoBooking;
