import React, {useRef} from 'react';
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import {Popover, Slider} from "@idtek/component";
import {ColumnWidthOutlined} from "@ant-design/icons";
import StatisticOfDay from "./StatisticOfDay";
import Button from "../../../common/component/Button";
import {useAppointmentContext} from "../context/AppointmentContext";
import _ from "lodash";
import AppStore from "../../../store";
import { t } from "@idtek/component/lib/language";

const FilterHeaderRight = () => {
    const refSliderZoom = useRef(null);
    const {zoomCellPxValue, setZoomCellPxValue} = useAppointmentContext();
    const onReset = () => {
        setZoomCellPxValue(180)
    }

    const onZoom = (value) => {
        const userId = _.get(AppStore.getState(), `root.authData.user.id`, "")
        let valueZoom = value
        if (value > 300) valueZoom = 300
        else if (value < 40) valueZoom = 40
        setZoomCellPxValue(valueZoom)
        localStorage.setItem(`${userId}_zoomValue`, valueZoom.toString())
    }

    const renderSliderZoom = () => {
        return (
            <div className="zoom-in-out-reset" style={{display: 'flex', width: '200px'}}>
                <Slider value={zoomCellPxValue} className="slider-zoom" step={20} defaultValue={zoomCellPxValue}
                        min={40} max={300} ref={refSliderZoom}
                        onChange={(val) => onZoom(val)} style={{flex: 1}}/>
                <Button className={"zoom-button"} icon={"sync"} Tooltip={ t("FilterHeaderRight-INITIAL_VIEW") }
                        onClick={() => onReset()}/>
            </div>

        );
    }

    return (
        <div className="filter-right" style={{justifyContent: "flex-end", flex: 1, display: "flex"}}>
            <div className="zoom-in-out-btn" style={{marginRight: 12}}>
                <Tooltip title={`${t("FilterHeaderRight-COLUMN_WIDTH")}: ${zoomCellPxValue} px`} placement="topRight">
                    <Popover placement="bottomRight" content={renderSliderZoom()}
                             title={`${t("FilterHeaderRight-COLUMN_WIDTH")}: ${zoomCellPxValue} px`} trigger="click">
                        <button className="btn-popover-size"
                                style={{fontSize: '17px', width: "36px", height: '36px'}}>
                            <ColumnWidthOutlined style={{display: 'flex', justifyContent: 'center'}}/>
                        </button>
                    </Popover>
                </Tooltip>
            </div>
            <StatisticOfDay/>
        </div>
    );
};

export default FilterHeaderRight;