
import { t } from '@idtek/component/lib/language';
import { lazy } from 'react';
const IconCash = lazy(() => import('./IconCash'));
const IconPercent = lazy(() => import('./IconPercent'));
const IconRef = lazy(() => import('./IconRef'));
const IconBirth = lazy(() => import('./IconBirth'));
const IconCoin = lazy(() => import('./IconCoin'));

export const OPTIONS_ICON = [
    { value: "CASH", label: t('IconPaymentMethodConstant-CASH'), component: IconCash },
    { value: "PERCENT", label: t('IconPaymentMethodConstant-PERCENT'), component: IconPercent },
    { value: "REFERRAL", label: t('IconPaymentMethodConstant-REFERRAL'), component: IconRef },
    { value: "BIRTHDAY", label: t('IconPaymentMethodConstant-BIRTHDAY'), component: IconBirth },
    { value: "COIN", label: t('IconPaymentMethodConstant-COIN'), component: IconCoin },
]