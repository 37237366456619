import {lazy} from "react";
import BookingReviewIndex from "./modules/bookingReview/BookingReviewIndex";

const TenantIndex = lazy(() => import("./modules/tenantAdmin/TenantIndex"));

const BookingIndex = lazy(() => import("./modules/booking/BookingIndex"));
const CustomerListView = lazy(() => import("./modules/customer/CustomerListView"));
const BranchIndex = lazy(() => import("./modules/branch/BranchIndex"));
const TechnicianIndex = lazy(() => import("./modules/technician/TechnicianIndex"));
const ReportIndex = lazy(() => import("./modules/report/ReportIndex"));

const ServiceListView = lazy(() => import("./modules/service/view/ServiceListView"));
const SmsCampaignListView = lazy(() => import("./modules/smsCampaign/view/SmsCampaignListView"));

const PackageListView = lazy(() => import("./modules/package/view/PackageListView"));
const CartListView = lazy(() => import("./modules/cart/view/CartListView"));

const SystemIndex = lazy(() => import("./modules/system/SystemIndex"));
const ConfigIndex = lazy(() => import("./modules/config/ConfigIndex"));

const RoleIndex = lazy(() => import("./modules/role/RoleIndex"));
const RelationshipIndex = lazy(() => import("./modules/relationship/RelationshipIndex"));
const SchedulerView = lazy(() => import("./modules/appointment/view/SchedulerView"));
const ProductIndex = lazy(() => import ("./modules/product/view/ProductListView"));
const Routes = [
    {id: "/tenant", component: TenantIndex},
    {id: "/booking", component: BookingIndex},
    {id: "/cart", component: CartListView},
    {id: "/customer", component: CustomerListView},
    {id: "/booking-review", component: BookingReviewIndex},
    {id: "/package", component: PackageListView},
    {id: "/branch", component: BranchIndex},
    {id: "/technician", component: TechnicianIndex},
    {id: "/service", component: ServiceListView},
    {id: "/sms-campaign", component: SmsCampaignListView},
    {id: "/report", component: ReportIndex},
    {id: "/system", component: SystemIndex},
    {id: "/config", component: ConfigIndex},
    {id: "/role", component: RoleIndex},
    {id: "/relationship", component: RelationshipIndex},
    {id: "/appointment", component: SchedulerView},
    {id: "/product", component: ProductIndex},
    // { id: '*', component: NotFound }
];

export default Routes;
