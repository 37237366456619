import React from 'react';
import useGetOptionsPayment from "../../appointment/hook/useGetOptionsPayment";
import _ from "lodash";
import useGetDetailCartPayment from "../hook/useGetDetailCartPayment";
import Loading from "../../../pages/Loading";
import "../../appointment/style/payment-v3.scss";
import "../../appointment/style/proceed-payment.scss";
import PaymentCartProvider from "../../appointment/context/PaymentCartProvider";
import PaymentCartForm from "../form/PaymentCartForm";
import AppStore from "../../../store";

const CartPaymentContainer = ({mainCartId, cartIds, onClose, onReloadData}) => {
    const branchId = _.get(AppStore.getState(), `root.branch.id`, null);
    const optData = useGetOptionsPayment(branchId);
    const initValue = useGetDetailCartPayment(mainCartId, cartIds);
    if (initValue.loading || _.isEmpty(optData)) return <Loading loadingHeight={500}/>;
    return (
        <div className="proceed-payment-wrap payment-v3">
            <PaymentCartProvider
                optData={optData}
                initValue={initValue}
                cartIds={cartIds}
                mainCartId={mainCartId}
                branchId={branchId}
            >
                <PaymentCartForm onClose={onClose} onReloadData={onReloadData}/>
            </PaymentCartProvider>

        </div>
    );
};

export default CartPaymentContainer;