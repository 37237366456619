import React, {useMemo} from 'react';
import useGetOptionsPayment from "../../appointment/hook/useGetOptionsPayment";
import _ from "lodash";
import useGetDetailCartPayment from "../hook/useGetDetailCartPayment";
import Loading from "../../../pages/Loading";
import "../../appointment/style/payment-v3.scss";
import "../../appointment/style/proceed-payment.scss";
import PaymentCartForm from "../form/PaymentCartForm";
import AppStore from "../../../store";
import {PaymentCartProviderDetail} from "../../appointment/context/PaymentCartProviderDetail";
import useGetDetailBillNoBooking from "../hook/useGetDetailBillNoBooking";

const CartPaymentDetailContainer = ({billId, cartIds, mainCartId, onClose, onReloadData}) => {
    const initBill = useGetDetailBillNoBooking(billId);
    const branchId = _.get(AppStore.getState(), `root.branch.id`, null);
    const optData = useGetOptionsPayment(branchId);
    const carts = useMemo(() => {
        if (!initBill) return null
        return _.map(initBill.carts, (x) => x.id)
    }, [initBill]);
    const initValue = useGetDetailCartPayment(mainCartId, carts, !!carts);
    if (initValue.loading) return <Loading loadingHeight={500}/>;
    return (
        <div className="proceed-payment-wrap payment-v3">
            <PaymentCartProviderDetail
                optData={optData}
                initValue={initValue}
                cartIds={cartIds}
                mainCartId={mainCartId}
                branchId={branchId}
                initBill={initBill}
                isDetailPayment={true}
            >
                <PaymentCartForm onClose={onClose} onReloadData={onReloadData}/>
            </PaymentCartProviderDetail>

        </div>
    );
};

export default CartPaymentDetailContainer;