import React from 'react'
import { t } from '@idtek/component/lib/language';
import _ from 'lodash';

const HistorySegmentBookingTemplate = (data, className) => {
    const isUpdatePrice = !_.isUndefined(data.oldPrice);
    const isUpdateDuration = !_.isUndefined(data.oldDuration);

    return (
        <>
            <div className={`grid grid-cols-3 color-children-${className}`}>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("ServiceListView-SERVICE_NAME")}:
                        </div>
                        <div className='value'>
                            {_.get(data, 'serviceName', "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("HistorySegmentBookingTemplate-OLD_TECHNICIAN")}:
                        </div>
                        <div className='value'>
                            {_.get(data, 'oldTechnician', "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("HistorySegmentBookingTemplate-NEW_TECHNICIAN")}:
                        </div>
                        <div className='value'>
                            {_.get(data, 'newTechnician', "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-OLD_TIME")}:
                        </div>
                        <div className='value'>
                            {_.get(data, 'oldTime', "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-NEW_TIME")}:
                        </div>
                        <div className='value'>
                            {_.get(data, 'newTime', "")}
                        </div>
                    </div>
                </div>
                {
                    isUpdateDuration ? <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("TechnicianListView-OLD_DURATION")}:
                            </div>
                            <div className='value'>
                                {_.get(data, 'oldDuration', "")}
                            </div>
                        </div>
                    </div> : <></>
                }
                {
                    isUpdateDuration ? <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("TechnicianListView-NEW_DURATION")}:
                            </div>
                            <div className='value'>
                                {_.get(data, 'newDuration', "")}
                            </div>
                        </div>
                    </div> : <></>
                }
                {
                    isUpdatePrice ? <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("TechnicianListView-OLD_PRICE")}:
                            </div>
                            <div className='value'>
                                ${_.get(data, 'oldPrice', "")}
                            </div>
                        </div>
                    </div> : <></>
                }
                {
                    isUpdatePrice ? <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("TechnicianListView-NEW_PRICE")}:
                            </div>
                            <div className='value'>
                                ${_.get(data, 'newPrice', "")}
                            </div>
                        </div>
                    </div> : <></>
                }
                <div className='col-span-3'>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("BranchConstant-NOTE")}:
                        </div>
                        <div className='value'>
                            {t('HistorySegmentBookingTemplate-BOOKING_WAS_UPDATE_BY_SEGMENT')}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HistorySegmentBookingTemplate;