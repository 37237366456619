import React from "react";
import AppUtil from "../../../utils/AppUtil";
import moment from "moment";
import { t } from '@idtek/component/lib/language';

const RowOptionGuest = ({data}) => {
    return <div className="row-add-more">
        <div>
        {`${t('RowOptionCart-BOOKING')}: ${data.code} - ${t('RowOptionCart-GUEST')}: ${data.name}`}
        </div>
        <div>
        {`${t('RowOptionCart-SUBTOTAL')}`}: {AppUtil.roundNumber(data.totalAmount)}$ - {`${t("RowOptionCart-DATE")}`}: {moment(data.startTime).format(AppUtil.GLOBAL_DATE_FORMAT)}
        </div>
    </div>;
};

export default RowOptionGuest;
