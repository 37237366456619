import React, {PureComponent} from 'react';
import Icon from '@idtek/component/lib/icon/Icon';
class ButtonHeader extends PureComponent {
    render() {
        const { iconCls,type, onClick, text, className, style,subMenu, styleIcon } = this.props;
        return (
            <div
                className={`button-header ${className ? className : ""}`}
                style={style}
                onClick={() => {
                    onClick && onClick();
                }}
            >
                {iconCls ? <i className={iconCls} style={styleIcon} /> : null}
                {type? <Icon type={type} />: null}
                {text?<span>{text}</span>: null}
                {subMenu? <Icon className="icon-sub" type="caret-down" />: null}
            </div>
        );
    }
}

export default ButtonHeader;