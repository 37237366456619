import {t} from "@idtek/component/lib/language";

const CreateCustomerModel = {
    fields: {
        name: {
            label: t("CreateCustomerModel-NAME"),
            required: true
        },
        phone: {
            label: t("CreateCustomerModel-PHONE"),
            required: true
        },
        email: {
            label: t("CreateCustomerModel-EMAIL")
        },
        level: {
            label: t("CreateCustomerModel-LEVEL")
        },
        active: {
            label: t("CreateCustomerModel-ACTIVE")
        },
        levelName: {
            label: t("CreateCustomerModel-LEVEL")
        },
        address: {
            label: t("CreateCustomerModel-ADDRESS")
        },
        note: {
            label: t("CreateCustomerModel-DESCRIPTION")
        },
        spentThisMonth: {
            label: t("CreateCustomerModel-SPENT_IN_MONTH")
        },
        birthDay: {
            label: t("CreateCustomerModel-BIRTHDAY")
        },
        spentThisYear: {
            label: t("CreateCustomerModel-SPENT_IN_YEAR")
        },
        notes: {
            label: t("CreateCustomerModel-NOTES")
        },
        receiveSms: {
            label: t("CreateCustomerModel-RECEIVE_SMS")
        },
        receiveEmail: {
            label: t("CreateCustomerModel-RECEIVE_EMAIL")
        },
        amountMoney: {
            label: t("CreateCustomerModel-AMOUNT_MONEY")
        },
        numberBookingToLoyalReward: {
            label: t("CreateCustomerModel-NUMBER_BOOKING_TO_LOYAL_REWARD")
        },
        amountPointLeft: {
            label: t("CreateCustomerModel-AMOUNT_POINT_LEFT")
        }
    }
}

export default CreateCustomerModel