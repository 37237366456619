import React from 'react';
import { t } from "@idtek/component/lib/language";
import { usePaymentContext } from "../context/PaymentContext";
import _ from "lodash";

const DetailBill = () => {
    const {
        paymentData,
        resultPayment,
        optionsData,
        backMoney,
        checkouts,
        initialValues,
        isDetailPayment,
        receivedObj, moneyPoint
    } = usePaymentContext();
    const {
        titleRowPM, valueRowPM, subTotalPayment, valueRowHST, valueFinally, valueRowVoucher,
        textRowVoucher,
        valueRowGift,
        textRowGift,
        valueRowLoyalty, textRowLoyalty, titleRowHST,
    } = resultPayment
    const { checkoutMethods } = optionsData

    const  renderCheckout = () => {
        if (isDetailPayment) {
            return <table className={"table-bill"}>
                <tbody className={"class-tbody"}>
                    {checkouts && checkouts.map(x => {
                        const find = _.find(checkoutMethods, y => y.id === x);
                        const text = `${t("DetailBill-AMOUNT_RECEIVED")} (${find.name})`
                        return <tr className={"border-bottom-dash"} style={{ fontWeight: 600 }}>
                            <td colSpan={3} className={"text-align-right"}>
                                {text}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${_.get(initialValues, `received_${find.id}`) ? _.get(initialValues, `received_${find.id}`).toFixed(2) : 0}
                            </td>
                        </tr>
                    })}
                    <tr className={""} style={{ fontWeight: 600 }}>
                        <td colSpan={3} className={"text-align-right"}>
                            {t("DetailBill-AMOUNT_BACK")}
                        </td>
                        <td className={"with-20-percent text-align-right"}>
                            ${_.get(initialValues, 'return') ? _.get(initialValues, 'return').toFixed(2) : 0}
                        </td>
                    </tr>
                </tbody>
            </table>
        } else {
            const totalReceive = _.sumBy(receivedObj, 'value')
            if (totalReceive >= valueFinally) {
                return <table className={"table-bill"}>
                    <tbody className={"class-tbody"}>
                        {receivedObj && receivedObj.map(x => {
                            const find = _.find(checkoutMethods, y => y.id === x.checkoutMethodId);
                            const text = `${t("DetailBill-AMOUNT_RECEIVED")} (${find.name})`
                            return <tr className={"border-bottom-dash"} style={{ fontWeight: 600 }}>
                                <td colSpan={3} className={"text-align-right"}>
                                    {text}
                                </td>
                                <td className={"with-20-percent text-align-right"}>
                                    ${x.value ? x.value : ""}
                                </td>
                            </tr>
                        })}
                        <tr className={""} style={{ fontWeight: 600 }}>
                            <td colSpan={3} className={"text-align-right"}>
                                {t("DetailBill-AMOUNT_BACK")}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${backMoney}
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        }
        return <></>
    }

    return (
        <div className={"wrap-table-bill"}>

            <table className={"table-bill"}>
                <tbody className={"class-tbody"}>
                    <tr className={"border-bottom-solid"}>
                        <th className={"with-10-percent"}>
                            {t('DetailBill-NO')}
                        </th>
                        <th className={"with-40-percent"}>
                            {t('DetailBill-SERVICE')}
                        </th>
                        <th className={"with-30-percent"}>
                            {t('DetailBill-TECHNICIAN')}
                        </th>
                        <th className={"with-20-percent"}>
                            {t('DetailBill-PRICE')}
                        </th>
                    </tr>
                </tbody>
            </table>
            {paymentData.guestList && paymentData.guestList.map(guest => {
                return <table className={"table-bill"}>
                    <tbody className={"class-tbody"}>
                        <tr className={"border-bottom-solid-lighter"}>
                            <td colSpan={4} style={{ width: '100%', fontWeight: 600 }}>
                                {t("DetailBill-BOOKING")} {guest.code}: {guest.name}
                            </td>
                        </tr>
                        {guest.segment.map((seg, index) => {
                            return <tr className={"border-bottom-solid-lighter"}>
                                <td className={"with-10-percent"}>
                                    {index + 1}
                                </td>
                                <td className={"with-40-percent"}>
                                    {seg.serviceName}
                                </td>
                                <td className={"with-30-percent"}>
                                    {seg.technicianName}
                                </td>
                                <td className={"with-20-percent text-align-right"}>
                                    ${seg.price}
                                </td>
                            </tr>

                        })}
                        <tr>

                        </tr>
                    </tbody>
                </table>
            })}

            {paymentData.cartList && paymentData.cartList.map(cart => {
                return <table className={"table-bill"}>
                    <tbody className={"class-tbody"}>
                        {
                            (_.size(cart.giftCards) > 0 || _.size(cart.packageItems) > 0 || _.size(cart.cartProductMappings) > 0) &&
                            <tr className={"border-bottom-solid-lighter"}>
                                <td colSpan={4} style={{ width: '100%', fontWeight: 600 }}>
                                    {`${t("DetailBill-CART")} ${cart.code}`}: {cart.customerName}
                                </td>
                            </tr>
                        }
                        {
                            _.map(cart.giftCards, (gift, index) => {
                                return <tr className={"border-bottom-solid-lighter"}>
                                    <td className={"with-10-percent"}>
                                        {index + 1}
                                    </td>
                                    <td className={"with-40-percent"}>
                                        {t("DetailBill-GIFT_CARD")}
                                    </td>
                                    <td className={"with-30-percent"}>
                                        {gift.code}
                                    </td>
                                    <td className={"with-20-percent text-align-right"}>
                                        ${gift.price}
                                    </td>
                                </tr>
                            })
                        }
                        {
                            _.map(cart.cartProductMappings, (item, index) => {
                                return <tr className={"border-bottom-solid-lighter"}>
                                    <td className={"with-10-percent"}>
                                        {index + 1}
                                    </td>
                                    <td className={"with-40-percent"}>
                                        {_.get(item, 'product.name')}
                                    </td>
                                    <td className={"with-30-percent"}>
                                        {_.get(item, 'quantity')} x ${_.get(item, 'price')}
                                    </td>
                                    <td className={"with-20-percent text-align-right"}>
                                        ${item.totalAmount}
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                //info gift

            })}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <table className={"table-bill"}>
                    <tbody className={"class-tbody"}>
                        <tr className={"border-bottom-dash"}>
                            <td colSpan={3} className={"text-align-right"}>
                                {t("DetailBill-SUB_TOTAL")}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${subTotalPayment ? subTotalPayment.toFixed(2) : 0}
                            </td>
                        </tr>
                        {valueRowPM !== 0 && <tr className={"border-bottom-dash"}>
                            <td colSpan={3} className={"text-align-right"}>
                                {titleRowPM}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${valueRowPM ? valueRowPM.toFixed(2) : 0}
                            </td>
                        </tr>}
                        <tr className={"border-bottom-dash"}>
                            <td colSpan={3} className={"text-align-right"}>
                                {titleRowHST}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${valueRowHST ? valueRowHST.toFixed(2) : 0}
                            </td>
                        </tr>

                        {textRowVoucher && <tr className={"border-bottom-dash"}>
                            <td colSpan={3} className={"text-align-right"}>
                                {t("DetailBill-DISCOUNT")} {textRowVoucher}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${valueRowVoucher ? -valueRowVoucher.toFixed(2) : 0}
                            </td>
                        </tr>}
                        {textRowLoyalty && <tr className={"border-bottom-dash"}>
                            <td colSpan={3} className={"text-align-right"}>
                                {textRowLoyalty}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${valueRowLoyalty ? -valueRowLoyalty.toFixed(2) : 0}
                            </td>
                        </tr>}
                        {moneyPoint > 0 && <tr className={"border-bottom-dash"}>
                            <td colSpan={3} className={"text-align-right"}>
                                {t("DetailBill-LOYALTY_POINT")}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${moneyPoint ? -moneyPoint.toFixed(2) : 0}
                            </td>
                        </tr>}

                        {textRowGift && valueRowGift != null && <tr className={"border-bottom-dash"}>
                            <td colSpan={3} className={"text-align-right"}>
                                {textRowGift}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${valueRowGift > 0 ? "-" + valueRowGift.toFixed(2) : valueRowGift}
                            </td>
                        </tr>}

                        <tr className={"border-bottom-dash"}>
                            <td colSpan={3} className={"text-align-right"}>
                                {t("DetailBill-RECEIVABLE_CASH")}
                            </td>
                            <td className={"with-20-percent text-align-right"}>
                                ${valueFinally ? valueFinally.toFixed(2) : 0}

                            </td>
                        </tr>

                    </tbody>
                </table>
                {renderCheckout()}
            </div>

        </div>
    );
};

export default DetailBill;