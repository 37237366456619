import Icon from "@idtek/component/lib/icon/Icon";
import Form from "@idtek/form/lib/IDForm";
import TextField from "@idtek/form/lib/text/TextField";
import TextAreaField from "@idtek/form/lib/textarea/TextAreaField";
import _ from "lodash";
import moment from "moment";
import React, {Component} from "react";
import AppUtil from "../../../utils/AppUtil";
import {RECEIVE_MONEY, TYPE_RECEIVE_MONEY} from "../constant/BookingConstant";
import "../style/receive-money.scss";
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";
import ApiBookingConstant from "../../appointment/constant/ApiBookingConstant";
import PrintBillComponent from "../../appointment/component/PrintBillComponent";
import {t} from "@idtek/component/lib/language";

export default class FormPosReceiveMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.initialValues = {};
        this.formDefaults = {
            alignLabel: "left",
            widthLabel: "140px"
        };
        this.model = {
            fields: {
                note: {
                    label: t("FormPosReceiveMoney-NOTE")
                },
                value: {
                    label: t("FormPosReceiveMoney-INPUT_CASH_AMOUNT"),
                    required: true
                }
            }
        };
    }


    posType = TYPE_RECEIVE_MONEY;
    type = RECEIVE_MONEY;

    async onFinish(form, values, printBill = false) {
        form && form.mask();
        const receive = {
            booking: null,
            posType: this.posType,
            status: 1,
            value: _.get(values, "value") ? parseFloat(_.get(values, "value")) : 0,
            actionTime: moment().format("YYYY-MM-DD HH:mm"),
            note: _.get(values, "note"),
            branchId: this.props.branch,
            type: this.type
        };
        const res = await AppUtil.postApi(`/pos-action/create`, receive);
        const data = _.get(res, "data.result");
        if (_.get(res, "data.success")) {
            form && form.unmask();
            AppUtil.ToastCreateSuccess(t("FormPosReceiveMoney-SUCCESS"));
            if (printBill) {
                const id = _.get(data, "id");
                const resBill = await AppUtil.getApi(ApiBookingConstant.API_PRINT_BILL_CASH, {id});
                if (_.get(resBill, "data.success")) {
                    this.props.onClose && this.props.onClose();
                    this.PrintBillComponent && this.PrintBillComponent.onChangBill(_.get(resBill, "data.result"));
                }
            } else {
                this.props.onClose && this.props.onClose();
            }
        } else {
            form && form.unmask();
            AppUtil.ToastApiError();
        }
    }

    render() {
        const {model, initialValues, formDefaults} = this;
        const {user} = this.props;
        return (
            <div className="receive-money-form">
                <Form
                    ref={(component) => (this.form = component)}
                    initialValues={initialValues}
                    model={model}
                    buttons={[
                        {
                            text: t("FormPosReceiveMoney-PRINT"),
                            type: "submit",
                            className: "btn-success",
                            icon: <Icon type="printer" style={{fontSize: 16}}/>,
                            onPress: (form, values) => this.onFinish(form, values, true)
                        },
                        {
                            text: t("FormPosReceiveMoney-FINISH"),
                            type: "submit",
                            className: "btn-primary-lighter",
                            icon: <Icon type="check-square" style={{fontSize: 16}}/>,
                            onPress: (form, values) => this.onFinish(form, values)
                        }
                    ]}
                    defaults={formDefaults}>
                    <Row>
                        {/*<Cell style={{*/}
                        {/*    fontStyle: "italic",*/}
                        {/*    marginBottom: 5,*/}
                        {/*    color: "#4B5050",*/}
                        {/*    fontWeight: "bold",*/}
                        {/*    textAlign: "right"*/}
                        {/*}}>*/}
                        {/*    <span>*/}
                        {/*        {t("FormPosReceiveMoney-USER")}:*/}
                        {/*    </span>*/}
                        {/*    <span style={{marginLeft: 5}}>{user}</span>*/}
                        {/*</Cell>*/}
                    </Row>
                    <Row>
                        <Cell style={{
                            fontStyle: "italic",
                            marginBottom: 5,
                            color: "#4B5050",
                            fontWeight: "bold",
                            textAlign: "right"
                        }}>
                            <span>
                                {t("FormPosReceiveMoney-TIME")}:
                            </span>
                            <span style={{marginLeft: 5}}>{moment().format(AppUtil.GLOBAL_DATETIME_FORMAT)}</span>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <TextField name="value" type="money"/>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <TextAreaField name="note" minHeight={40}/>
                        </Cell>
                    </Row>
                </Form>
                <PrintBillComponent ref={(c) => (this.PrintBillComponent = c)}/>
            </div>
        );
    }
}
