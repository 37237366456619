import React from "react";
import _ from "lodash";
import { t } from '@idtek/component/lib/language';
import AppUtil from "../../../utils/AppUtil";

const RowOptionCart = ({data}) => {
    return <div className="row-add-more">
        <div>
            {`${t('RowOptionCart-CART')}: ${data.code} - ${t('RowOptionCart-CUSTOMER')}: ${_.get(data, 'customer.name')}`}</div>
        <div>
            {`${t('RowOptionCart-PRICE')}: ${AppUtil.roundNumber(data.total)}$`}
        </div>
    </div>;
};

export default RowOptionCart;