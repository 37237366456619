import {t} from "@idtek/component/lib/language";

export const ContextMenu = [
    {
        text: t("ContextMenu-NEW_BOOKING"),
        iconCss: "e-icons new",
        id: "add",
    },
    {
        text: t("ContextMenu-EDIT_BOOKING"),
        iconCss: "e-icons edit",
        id: "save",
    },
    {
        text: t("ContextMenu-DETAIL_BOOKING"),
        iconCss: "e-icons detail",
        id: "detail",
    },
    {
        text: t("ContextMenu-UPDATE_BOOKING"),
        iconCss: "e-icons update",
        id: "update",
    },

    {
        text: t("ContextMenu-COMPLETE_&_PAYMENT"),
        iconCss: "e-icons complete",
        id: "complete",
    },
    {
        text: t("ContextMenu-ADD_TO_CARD"),
        iconCss: "e-icons cart",
        id: "cart",
    },
    {
        text: t("ContextMenu-DETAIL_CARD"),
        iconCss: "e-icons cart",
        id: "cart-detail",
    },
    {
        text: t("ContextMenu-ADD_TO_CARD"),
        iconCss: "e-icons cart",
        id: "cart-group",
        items: [
            {
                text: t("ContextMenu-ONLY_THIS_GUEST"),
                iconCss: "e-cm-icons",
                id: "cart-only",
            },
            {
                text: t("ContextMenu-ALL_GUESTS"),
                iconCss: "e-cm-icons",
                id: "cart-all",
            },
        ]
    },
    {
        text: t("ContextMenu-TAKE_PAYMENT"),
        iconCss: "e-icons payment",
        id: "payment",
    },
    {
        text: t("ContextMenu-TAKE_PAYMENT"),
        iconCss: "e-icons payment",
        id: "payment-group",
        items: [
            {
                text: t("ContextMenu-ONLY_THIS_GUEST"),
                iconCss: "e-cm-icons",
                id: "payment-only",
            },
            {
                text: t("ContextMenu-ALL_GUESTS"),
                iconCss: "e-cm-icons",
                id: "payment-all",
            },
        ]
    },

    {
        text: t("ContextMenu-PRINT_SLIP"),
        iconCss: "e-icons print-slip",
        id: "print-slip",
    },
    {
        text: t("ContextMenu-PRINT_BILL"),
        iconCss: "e-icons print",
        id: "print",
    },
    {
        text: t("ContextMenu-DETAIL_PAYMENT"),
        iconCss: "e-icons payment",
        id: "detailPayment",
    },
    {
        text: t("ContextMenu-CANCEL_BILL"),
        iconCss: "e-icons cancel",
        id: "cancelBill",
    },
    // {
    //     text: t("ContextMenu-Payment",
    //     iconCss: "e-icons payment",
    //     id: "detailPaymentGroup",
    //     items: [
    //         {
    //             text: t("ContextMenu-Detail payment",
    //             iconCss: "e-cm-icons",
    //             id: "detailPayment",
    //         },
    //         {
    //             text: t("ContextMenu-Edit payment",
    //             iconCss: "e-cm-icons",
    //             id: "edit-payment",
    //         }
    //     ]
    // },
    {
        text: t("ContextMenu-CUSTOMER_CONFIRM"),
        iconCss: "e-icons confirm",
        id: "confirm",
    },
    {
        text: t("ContextMenu-CHECKIN"),
        iconCss: "e-icons check-in",
        id: "check-in",
    },
    {
        text: t("ContextMenu-CHECKIN"),
        iconCss: "e-icons check-in",
        id: "check-in-group",
        items: [
            {
                text: t("ContextMenu-ONLY_THIS_GUEST"),
                iconCss: "e-cm-icons",
                id: "check-in-only",
            },
            {
                text: t("ContextMenu-ALL_GUESTS"),
                iconCss: "e-cm-icons",
                id: "check-in-all",
            },
        ]
    },
    {
        text: t("ContextMenu-START"),
        iconCss: "e-icons start",
        id: "start",
    },
    {
        text: t("ContextMenu-START"),
        iconCss: "e-icons start",
        id: "start-group",
        items: [
            {
                text: t("ContextMenu-ONLY_THIS_GUEST"),
                iconCss: "e-cm-icons",
                id: "start-only",
            },
            {
                text: t("ContextMenu-ALL_GUESTS"),
                iconCss: "e-cm-icons",
                id: "start-all",
            },
        ]
    },
    {
        text: t("ContextMenu-RESEND_SMS"),
        iconCss: "e-icons resend",
        id: "resend-sms",
    },
    {
        text: t("ContextMenu-MOVE_GUEST_TO_CLIPBOARD"),
        iconCss: "e-icons clipboard",
        id: "clipboard",
    },
    {
        text: t("ContextMenu-PAID_TIME_OFF"),
        iconCss: "e-icons off-time",
        id: "offTimeTechAL"
    },
    {
        text: t("ContextMenu-UNPAID_TIME_OFF"),
        iconCss: "e-icons off-time",
        id: "offTimeTech",
    },
    {
        text: t("ContextMenu-TIPS"),
        iconCss: "e-icons tip-icon",
        id: "tipTechnician",
    },
    {
        text: t("ContextMenu-BILL_HAS_TIPS"),
        iconCss: "e-icons tip-icon",
        id: "hasTipTechnician",
    },
    {
        text: t("ContextMenu-NOSHOW"),
        iconCss: "e-icons noshow",
        id: "no-show"
    },
    {
        text: t("ContextMenu-NOSHOW_BOOKING"),
        iconCss: "e-icons noshow",
        id: "no-show-group",
        items: [
            {
                text: t("ContextMenu-ONLY_THIS_GUEST"),
                iconCss: 'e-cm-icons',
                id: "no-show-only",
            },
            {
                text: t("ContextMenu-ALL_GUESTS"),
                iconCss: 'e-cm-icons',
                id: "no-show-all",
            }
        ]
    },
    {
        text: t("ContextMenu-CANCEL_BOOKING"),
        iconCss: "e-icons cancel",
        id: "cancel",
    },
    {
        text: t("ContextMenu-CANCEL_BOOKING"),
        iconCss: "e-icons cancel",
        id: "cancel-group",
        items: [
            {
                text: t("ContextMenu-ONLY_THIS_GUEST"),
                iconCss: "e-cm-icons",
                id: "cancel-only",
            },
            {
                text: t("ContextMenu-ALL_GUESTS"),
                iconCss: "e-cm-icons",
                id: "cancel-all",
            },
        ]
    },
    {
        text: t("ContextMenu-BACK_TO_WAITING"),
        iconCss: "e-icons back-status-guest",
        id: "back-to-waiting",
    },
    {
        text: t("ContextMenu-BACK_TO_CHECKIN"),
        iconCss: "e-icons back-status-guest",
        id: "back-to-checkin",
    },
];