import _ from 'lodash';
export default class TreeUtil {
  static buildTreeFromFlatData = (arr, idKey, parentKey, childrenKey) => {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem[idKey]] = arrElem;
      mappedArr[arrElem[idKey]][childrenKey] = [];
    }
    for (let id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem[parentKey] && mappedArr[mappedElem[parentKey]]) {
          mappedArr[mappedElem[parentKey]][childrenKey].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  };

  static buildTreeFromPath = function (paths) {
    // Adapted from http://brandonclapp.com/arranging-an-array-of-flat-paths-into-a-json-tree-like-structure/
    const tree = [];

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      let currentLevel = tree;
      for (let j = 0; j < path.length; j++) {
        const part = path[j];

        const existingPath = findWhere(currentLevel, 'name', part);

        if (existingPath) {
          currentLevel = existingPath.children;
        } else {
          let pathString = null;
          if (path.length >= 2) {
            pathString = path.slice(0, path.length - 1).join('.');
          }
          const newPart = {
            parent: currentLevel,
            name: part,
            path: pathString,
            children: []
          };

          currentLevel.push(newPart);
          currentLevel = newPart.children;
        }
      }
    }
    return tree;

    function findWhere(array, key, value) {
      // Adapted from https://stackoverflow.com/questions/32932994/findwhere-from-underscorejs-to-jquery
      let t = 0; // t is used as a counter
      while (t < array.length && array[t][key] !== value) { t++; }; // find the index where the id is the as the aValue

      if (t < array.length) {
        return array[t]
      } else {
        return false;
      }
    }
  };

  static forEachTreeFromNodeToLeaf(node, keyChildren, callback, parent = null, nodeIndex = 0, refData = {}, level) {
    refData = callback(node, parent, nodeIndex, level);
    if (node[keyChildren] && node[keyChildren].length > 0) {
      node[keyChildren].forEach((child, childIndex) => {
        const newLvl = level + 1;
        TreeUtil.forEachTreeFromNodeToLeaf(child, keyChildren, callback, node, childIndex, refData, newLvl);
      });
    }
  }

  static forEachTreeFromLeafToNode(node, keyChildren, callback, parent = null, nodeIndex = 0, refData = {}, level = 0) {
    if (node[keyChildren] && node[keyChildren].length > 0) {
      node[keyChildren].forEach((child, childIndex) => {
        TreeUtil.forEachTreeFromNodeToLeaf(child, keyChildren, callback, node, childIndex, refData, level);
      });
    }
    refData = callback(node, parent, nodeIndex, level);
  }

  static convertParentChild_Multi(array, expanded = false) {
    let map = {};
    const newArray = _.orderBy(array, [(o) => { return o.parentId || '' }], ['asc']);
    const arrayId = array.map(function (value, index) {
      return value['value'];
    })
    for (let i = 0; i < newArray.length; i++) {
      if (_.indexOf(arrayId, newArray[i].parentId) === -1) {
        newArray[i].parentId = null;
      }
    }
    for (let i = 0; i < newArray.length; i++) {
      let obj = newArray[i];
      obj.expanded = expanded;
      map[obj.value] = obj;
      let parent = obj.parentId || '*';
      if (!map[parent]) {
        map[parent] = {};
      }
      map[parent].children = map[parent].children || [];
      map[parent].children.push(obj);
    }
    return map['*'].children;
  }
  static QAconvertParentChild_Multi(array, expanded = false) {
    let map = {};
    for (let i = 0; i < array.length; i++) {
      let obj = array[i];
      obj.expanded = expanded;
      map[obj.value] = map[obj.value] ? { ...map[obj.value], ...obj } : obj;
      let parent = obj.parentId || '*';
      if (!map[parent]) {
        map[parent] = {};
      }
      map[parent].children = map[parent].children || [];
      map[parent].children.push(map[obj.value]);
    }
    return map['*'].children;
  }

  static buildTreeParentId(list, idAttr, parentAttr, childrenAttr) {
    //custom
    if (!idAttr) idAttr = 'id';
    if (!parentAttr) parentAttr = 'parent';
    if (!childrenAttr) childrenAttr = 'children';

    const treeList = [];
    const lookup = {};
    list.forEach(function (obj) {
      lookup[obj[idAttr]] = obj;
      obj['selected'] = false
      obj[childrenAttr] = [];
    });
    list.forEach(function (obj) {
      if (obj[parentAttr] != null && lookup[obj[parentAttr]]) {
        lookup[obj[parentAttr]][childrenAttr].push(obj);
      }
      else {
        treeList.push(obj);
      }
    });
    return treeList;
  }

  static sortTree(tree, key) {
    if (tree.children) {
      tree.children = _.sortBy(tree.children, (data) => { return data[key] })
      tree.children = _.forEach(tree.children, (data) => { return this.sortTree(data) })
      return tree.children
    }
    if (_.isArray(tree)) {
      tree = _.sortBy(tree, (data) => { return data[key] })
      tree = _.forEach(tree, (data) => { return this.sortTree(data) })
      return tree
    }
    return tree
  }

  static onParseTreeToFlatData = (data, arr, parentId) => {
    _.map(data, item => {
      item.parentId = parentId
      item.selected = false
      if (!_.isEmpty(item.children)) {
        this.onParseTreeToFlatData(item.children, arr, item.id)
      }
      arr.push(item);
    });
  };
}