import React from 'react';
import _ from "lodash"
import { t } from '@idtek/component/lib/language';
import { CHANGE_STATUS_ACTION } from './../../../common/history/constant/HistoryConstant';

const ServiceHistoryComponent = (data, className) => {

    let oldStatus = _.get(data, 'editInfo.oldStatus');
    let newStatus = _.get(data, 'editInfo.newStatus');
    let active = _.get(data, 'editInfo.active');
    if (oldStatus) {
        oldStatus = <>
            <span className='status-booking Completed'>
                {t("ProductListView-ACTIVE")}
            </span>
        </>
    } else {
        oldStatus = <>
            <div className='status-booking Cancel'>
                {t("ProductListView-INACTIVE")}
            </div>
        </>
    }

    if (newStatus) {
        newStatus = <>
            <span className='status-booking Completed'>
                {t("ProductListView-ACTIVE")}
            </span>
        </>
    } else {
        newStatus = <>
            <div className='status-booking Cancel'>
                {t("ProductListView-INACTIVE")}
            </div>
        </>
    }

    if (active) {
        active = <>
            <span className='status-booking Completed'>
                {t("ProductListView-ACTIVE")}
            </span>
        </>
    } else {
        active = <>
            <div className='status-booking Cancel'>
                {t("ProductListView-INACTIVE")}
            </div>
        </>
    }

    if (_.get(data, 'action') === CHANGE_STATUS_ACTION) {
        return (
            <>
                <div className={`grid grid-cols-2 color-children-${className}`}>
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("GridChangeStatusBooking-OLD_STATUS")}:
                            </div>
                            <div className='value'>
                                {oldStatus}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("GridChangeStatusBooking-NEW_STATUS")}:
                            </div>
                            <div className='value'>
                                {newStatus}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={`grid grid-cols-2 color-children-${className}`}>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("CustomServiceListView-NAME")}:
                        </div>
                        <div className='value'>
                            {_.get(data, "editInfo.name", "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("ServiceListView-DURATION_(MINUTES)")}:
                        </div>
                        <div className='value'>
                            {_.get(data, "editInfo.duration", "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("ServiceListView-PRICE_(CAD)")}:
                        </div>
                        <div className='value'>
                            {_.get(data, "editInfo.price", "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-STATUS")}:
                        </div>
                        <div className='value'>
                            {active}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ServiceHistoryComponent;
