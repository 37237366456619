import React, {useMemo} from 'react';
import {useAppointmentContext} from "../context/AppointmentContext";
import moment from "moment";
import _ from "lodash";
import {BookingStatusContant} from "../constant/BookingStatusContant";

const MajorSlotTemplate = ({date}) => {
    const {
        bookingSchedule,
        configAppointment
    } = useAppointmentContext();
    const {extraStartHour, startHour} = configAppointment
    const format = "HH:mm";
    const hourFormat = moment(date).format("hh:mm A");
    const endMajor = moment(date).add(15, "m").format(format);
    const endMajorPre = moment(date).format(format)

    const startTime = useMemo(() => {
        return extraStartHour ? extraStartHour : startHour
    }, [extraStartHour, startHour])

    return useMemo(() => {
        if (!_.isEmpty(bookingSchedule)) {
            const rs = [];
            const previousRs = []
            _.each(bookingSchedule, (item) => {
                if (item.Type !== "booking" && item.Status !== BookingStatusContant.STATUS_NEW && item.StartTime && item.EndTime && item.TechnicianId) {
                    const startItem = moment(item.StartTime).format(format);
                    const endItem = moment(item.EndTime).format(format);
                    if (startItem <= endMajor && endItem >= endMajor) {
                        rs.push(item);
                    }

                    const startItemPre = moment(item.StartTime).format(format);
                    const endItemPre = moment(item.EndTime).format(format);

                    //endMajorPre = date
                    if (startItemPre <= endMajorPre && endItemPre >= endMajorPre) {
                        previousRs.push(item);
                    }
                }
            });
            const count = _.size(_.uniqBy(rs, "GuestId"));
            const countPre = _.size(_.uniqBy(previousRs, "GuestId"));

            if (count !== countPre) {
                return (
                    <div className="custom-major-lmt">
                        <span className="hour-format">{hourFormat}</span>
                        <span className="count">{count}</span>
                    </div>
                );
            }
        }
        return (
            <div className="custom-major-lmt">
                <span className="hour-format">{hourFormat}</span>
                {moment(date).format("HH:mm") === startTime && <span className="count">{0}</span>}
            </div>
        );
    }, [startTime, bookingSchedule])
};

export default MajorSlotTemplate;