/* eslint-disable react-hooks/exhaustive-deps */

import Collapse from '@idtek/component/lib/collapse/Collapse'
import Icon from '@idtek/component/lib/icon/Icon'
import IDField from '@idtek/form/lib/IDField'
import _ from 'lodash'
import React from 'react'
import InputVoucherComponent from './InputVoucherComponent'
import ApiBookingConstant from '../constant/ApiBookingConstant'
import { usePaymentContext } from '../context/PaymentContext'
import { t } from '@idtek/component/lib/language'

const VoucherSelect = () => {
    const {
              branchId,
              customer,
              setVoucher,
              voucher,
              resultPayment,
          } = usePaymentContext()
    const {
              textRowVoucher,
              valueRowVoucher,
          } = resultPayment
    const onChangeCode = (voucher) => {
        setVoucher(voucher)
    }

    const renderRowVoucher = () => {
        if (voucher) {
            return (
                    <div className="final-caculate row-voucher">
                        <div className="sub-total">
                            <div className="title-final">{`${t('VoucherSelect-DISCOUNT')} ${textRowVoucher}`}</div>
                            <div className="value-final">$ -{valueRowVoucher.toFixed(2)}</div>
                        </div>
                    </div>
            )
        }
    }

    return <div className="voucher-field">
        <Collapse
                ghost
                className="collapse-cpn"
                expandIconPosition="right"
                defaultActiveKey={voucher ? ['1'] : null}
                expandIcon={({ isActive }) => <Icon type="caret-right" style={{
                    fontSize: 16,
                    color: '#026eaa',
                }} rotate={isActive ? 90 : 0} />}>
            <Collapse.Panel
                    header={
                        <div className="total" style={{ marginBottom: 0 }}>
                            <div className="icon-gift">
                                <i class="fa fa-credit-card" aria-hidden="true" />
                            </div>
                            <span className="title">
                            {t('VoucherSelect-VOUCHER')}
                        </span>
                        </div>
                    }
                    key="1">
                <div className="gift-input" style={{ paddingBottom: 0 }}>
                    <IDField widthLabel={120} name="voucherCode" onChange={onChangeCode}>
                        <InputVoucherComponent customer={_.get(customer, 'id')}
                                               code={_.get(voucher, 'code')}
                                               voucher={voucher}
                                               apiCheckCode={ApiBookingConstant.API_CHECK_VOUCHER}
                                               branchId={branchId} />
                    </IDField>
                    {/*{renderRowVoucher()}*/}
                </div>
            </Collapse.Panel>
        </Collapse>
    </div>
}

export default VoucherSelect