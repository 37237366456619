import Container from "@idtek/component/lib/container/Container";
import Icon from "@idtek/component/lib/icon/Icon";
import { t } from "@idtek/component/lib/language";
import Popover from "@idtek/component/lib/popover/Popover";
import Textbox from "@idtek/component/lib/textbox/Textbox";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import AppUtil from "../../../utils/AppUtil";
import IconCoupon from "../icon/IconCoupon";
import NotFound from "../icon/NotFound";

export default class InputVoucherComponent extends Component {
    constructor(props) {
        super(props);
        const init = props.voucher;
        this.state = {
            visible: false,
            voucher: init,
            code: props.code,
            isApply: init ? true : false,
            disabled: init ? true : false,
            valueText: props.code
        };
        this.initCode = props.code;
    }

    apiCheckCode = this.props.apiCheckCode;
    voucherName = t("InputVoucherComponent-DISCOUNT");

    componentDidMount() {
        this.cpnInput && this.cpnInput.setValue(this.initCode);
    }

    handleVisibleChange = (visible, isSearch = false) => {
        if (this.state.voucher) {
            this.setState({ visible }, () => {
                if (isSearch) {
                    this.refContainer && this.refContainer.mask();
                }
            });
        } else {
            if (isSearch) {
                this.setState({ visible }, () => {
                    this.refContainer && this.refContainer.mask();
                });
            } else {
                if (!visible) {
                    const activeElement = document.activeElement;
                    const inputs = ["input"];
                    const listClass = activeElement.classList;
                    if (activeElement && listClass && listClass[1] && inputs.indexOf(activeElement.tagName.toLowerCase()) >= 0 && listClass[1] === "input-voucher-cpn") {
                        console.log("common");
                    } else {
                        this.cpnInput && this.cpnInput.setValue("");
                        this.setState({ visible, voucher: null, code: "" });
                    }
                }
            }
        }
    };

    renderContent() {
        const { voucher, disabled, message } = this.state;
        if (voucher) {
            let discount = "";
            if (voucher.value && voucher.value !== 0) {
                discount = voucher.value + "$";
            } else if (voucher.percent && voucher.percent !== 0) {
                discount = voucher.percent + "%";
            }
            return (
                <div className="voucher-card">
                    <div className="value">
                        {this.voucherName} {discount}
                    </div>
                    <div className="condition">{voucher.content}</div>

                    <div className="bottom">
                        <div
                            className="end-date">{t("InputVoucherComponet-EXP")}: {moment(voucher.endDate).format(AppUtil.GLOBAL_DATE_FORMAT)}</div>
                        {!disabled && (
                            <button onClick={this.onDeselect} className="deselect">
                                {" "}
                                <Icon type="close-square" style={{ marginRight: 5 }} />
                                {t("InputVoucherComponent-DETECT")}
                            </button>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="voucher-card">
                    <NotFound />
                    <div style={{
                        fontSize: 12,
                        color: "red",
                        textAlign: "center",
                        marginTop: 10
                    }}>{t(message)}</div>
                </div>
            );
        }
    }

    onDeselect = async () => {
        this.props.onChange && this.props.onChange(null);
        this.cpnInput && this.cpnInput.setValue("");
        this.setState({
            visible: false,
            isApply: false,
            code: "",
            voucher: null,
            valueText: ""
        });
    };

    async onSetVoucher(code) {
        if (code) {
            const res = await AppUtil.postApi(this.apiCheckCode, {
                code: code,
                customerId: this.props.customer,
                branchId: this.props.branchId
            });
            if (_.get(res, "data.success")) {
                const voucher = _.get(res, "data.result.voucher", null);
                const message = _.get(res, "data.result.message", null);
                this.props.onChange && this.props.onChange(voucher);
                this.setState(
                    {
                        voucher: voucher,
                        // code: code,
                        isApply: !!voucher,
                        message: message,
                    },
                    () => {
                        this.refContainer && this.refContainer.unmask();
                    }
                );
            } else {
                AppUtil.ToastApiError();
            }
        }
    }

    onApply = async () => {
        const value = this.cpnInput && this.cpnInput.getValue();
        if (!this.state.isApply) {
            if (value) {
                this.handleVisibleChange(true, true);
            }
            this.onSetVoucher(value);
        } else {
            this.handleVisibleChange(true);
        }
    };

    render() {
        const { valueText } = this.state;
        const placeholder = t("InputVoucherComponent-INPUT_CODE");
        return (
            <div className="voucher-code">
                <Popover
                    placement="bottomLeft"
                    trigger="click"
                    overlayClassName="voucher-overlay"
                    getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                    visible={this.state.visible}
                    onVisibleChange={this.handleVisibleChange}
                    autoAdjustOverflow={false}
                    content={<Container ref={(c) => (this.refContainer = c)}>{this.renderContent()}</Container>}>
                    <div style={{ flex: 1 }}>
                        <Textbox
                            ref={(c) => (this.cpnInput = c)}
                            placeholder={placeholder}
                            onChange={(val) => {
                                this.code = val;
                                this.setState({ valueText: val });
                            }}
                            inputStyle={{ textAlign: "left" }}
                            readOnly={this.state.isApply}
                            isClearable={false}
                            icon={<IconCoupon />}
                            iconAlign="before"
                            iconLine={false}
                            upperCase={true}
                            inputCls={"input-voucher-cpn"}
                        />
                    </div>
                </Popover>
                <button className={`button ${this.state.isApply ? "readOnly" : ""}`}
                    onClick={this.onApply}>
                    <Icon type="safety-certificate" style={{ marginRight: 5 }} />
                    {t("InputVoucherComponent-APPLY")}
                </button>
            </div>
        );
    }
}
