import {Loading, TextTranslate} from "@idtek/component/lib";
import Alert from "@idtek/component/lib/alert/Alert";
import Container from "@idtek/component/lib/container/Container";
import FullBrowser from "@idtek/component/lib/fullScreen/FullBrowser";
import Icon from "@idtek/component/lib/icon/Icon";
import Modal from "@idtek/component/lib/modal/Modal";
import Grid from "@idtek/grid/lib/grid/Grid";
import TreeGrid from "@idtek/grid/lib/treegrid/TreeGrid";
import _ from "lodash";
import React, {Component, Suspense} from "react";
import AppUtil from "../../utils/AppUtil";
import ContentView from "../component/ContentView";
import Overlay from "../component/Overlay";
import BasePermission from "./BasePermission";
import AppStore from "../../store";
import {t} from "@idtek/component/lib/language";
import moment from "moment";

class BaseListView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.isComponent = props.isComponent ? props.isComponent : false;
    }

    //form
    FormModal = null;
    FormModalDetail = null;
    apiDelete = "";
    apiExport = "";
    keyID = "id";
    // default right action
    module = "";
    ///right
    createRight = undefined;
    updateRight = undefined;
    deleteRight = undefined;
    rightExport = "";

    //action
    hasUpdate = true;
    hasDelete = true;
    hasDetail = true;
    hasCreate = true;
    hasExport = false;
    rightAccess = "";


    columnDefs = [];
    pageSize = 15;
    functionName = AppUtil.getMenuName() ? AppUtil.getMenuName() : "";
    contentHeight = window.innerHeight - 95;
    // default form
    moduleName = "";
    titleCreate = `${t("BaseListView-CREATE").toUpperCase()} `;
    titleUpdate = `${t("BaseListView-UPDATE").toUpperCase()} `;
    titleDetail = `${t("BaseListView-DETAIL").toUpperCase()} `;
    titleHistory = `${t("BaseListView-HISTORY").toUpperCase()} `;
    model = {fields: {}};

    // default Grid
    levelTree = {
        levelMax: 2,
        levelFormat: ["I", "1"]
    };
    suppressColumnVirtualisation = false;
    suppressScrollOnNewData = false
    groupDefaultExpanded = false;
    groupUseEntireRow = false;
    autoHeightRow = false;
    cellRenderer = {};
    defaultRenderGrid = true;
    defaultNumberRows = {
        headerName: t("BaseListView-NO")
    };
    defaultColDef = {};
    resizable = true;
    columnWidthExcel = [];
    popUpWidth = "850px";
    headerHeight = 32;
    textCenterRow = "";
    rowSelection = "multiple";
    floatingFilter = true;
    globalFilter = {
        placeholder: `${t("BaseListView-SEARCH")}...`
    };
    gridProps = {};
    renderTitle = "";
    className = null;
    isRowDoubleClick = false;
    formDefaults = {
        alignLabel: "left",
        widthLabel: "100px"
    };
    titleActionRow = " ";
    heightGrid = window.innerHeight - 100;
    minHeightGrid = "";
    rowHeight = "28px";
    widthActionRow = 150;
    overrideTopLeftGrid = false;
    checkboxSelection = false;
    headerCheckboxSelection = false;
    suppressRowClickSelection = false;
    animateRows = false;
    rowDragManaged = false;
    extraProps = {}

    ///check right

    componentWillMount() {
        const isMobile = this.checkIsMobile();
        if (isMobile) {
            this.popUpWidth = "100%";
        }
    }

    checkIsMobile() {
        return (
            navigator.userAgent.match(/Android/i) !== null ||
            navigator.userAgent.match(/webOS/i) !== null ||
            navigator.userAgent.match(/iPhone/i) !== null ||
            navigator.userAgent.match(/iPad/i) !== null ||
            navigator.userAgent.match(/iPod/i) !== null ||
            navigator.userAgent.match(/BlackBerry/i) !== null ||
            navigator.userAgent.match(/Windows Phone/i) !== null
        );
    }

    checkCreateRight() {
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        return !this.createRight || AppUtil.checkPermission(this.createRight, rights);
    }

    checkUpdateRight() {
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        return !this.updateRight || AppUtil.checkPermission(this.updateRight, rights);
    }

    checkDeleteRight() {
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        return !this.deleteRight || AppUtil.checkPermission(this.deleteRight, rights);
    }

    checkExportRight() {
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        return !this.rightExport || AppUtil.checkPermission(this.rightExport, rights);
    }

    // event api

    reLoad = () => {
        this.myGrid && this.store.reload();
    };

    // event click
    onCreate = () => {
        this.formDefaults["readOnly"] = false;
        const FormModal = this.FormModal;
        this.Modal.onOpen(
            <Suspense fallback={<Loading style={{height: 300}}/>}>
                <FormModal
                    onClose={(flag, dataRow) => {
                        this.onCloseModalForm(flag, dataRow);
                    }}
                    onReloadData={() => {
                        this.reLoad();
                    }}
                    isCreate={true}
                    model={this.model}
                    formDefaults={this.formDefaults}
                    initialValues={this.initialValues ? this.initialValues : {}}
                    {...this.extraProps}
                />
            </Suspense>,
            this.renderTitleForm(this.titleCreate + this.moduleName),
            this.popUpWidth
        );
        this.refContainer && this.refContainer.unmask();
    };

    onEditRow = async (row, rowNode) => {
        this.formDefaults["readOnly"] = false;
        const FormModal = this.FormModal;
        this.Modal.onOpen(
            <Suspense fallback={<Loading style={{height: 300}}/>}>
                <FormModal
                    onClose={(flag, dataRow) => {
                        this.onCloseModalForm(flag, dataRow, rowNode);
                    }}
                    onReloadData={() => {
                        this.reLoad();
                    }}
                    formDefaults={this.formDefaults}
                    isCreate={false}
                    model={this.model}
                    initialValues={row}
                    {...this.extraProps}
                />
            </Suspense>,
            this.renderTitleForm(this.titleUpdate + this.moduleName),
            this.popUpWidth
        );
        this.refContainer && this.refContainer.unmask();
    };

    onDetailRow = async (row) => {
        this.formDefaults["readOnly"] = true;
        let newModel = _.cloneDeep(this.model);
        _.mapKeys(newModel.fields, (value, key) => {
            if (key) {
                value.visibleIcon = false;
                value.placeholder = " ";
            }
        });
        const FormModal = this.FormModalDetail ? this.FormModalDetail : this.FormModal;
        this.Modal.onOpen(
            <Suspense fallback={<Loading style={{height: 300}}/>}>
                <FormModal
                    onClose={(flag, dataRow) => {
                        this.onCloseModalForm(flag, dataRow);
                    }}
                    onReloadData={() => {
                        this.reLoad();
                    }}
                    formDefaults={this.formDefaults}
                    model={newModel}
                    initialValues={row}
                    {...this.extraProps}
                />
            </Suspense>,
            this.renderTitleForm(this.titleDetail + this.moduleName),
            this.popUpWidth,
        );
        this.refContainer && this.refContainer.unmask();
    };

    onExport = () => {
    };
    onDeleteRow = async (row) => {
        let confirm = await Alert.Swal_confirm(t("BaseListView-CONFIRM"), t("BaseListView-ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_RECORD?"), 3);
        if (_.get(confirm, "value") === true) {
            const res = await AppUtil.postApi(`${this.apiDelete}?id=${row[this.keyID]}`);
            if (res && _.get(res.data, "success")) {
                // const log = LogUtil.createActionLog(this.entityType, "Xóa dữ liệu", _.get(res, "results.data[this.keyID]"), this.functionName || "Xóa dữ liệu");
                // LogUtil.writeActionLog(log);
                AppUtil.ToastDeleteSuccess();
                this.reLoad();
            } else {
                AppUtil.ToastDeleteError(_.get(res, "data.messages") || _.get(res, "data.message") || t("BaseListView-DATA_ERROR,_PLEASE_TRY_AGAIN!"));
            }
        }
    };

    onCloseModalForm = (flag, dataRow, rowNode) => {
        if (flag) {
            this.reLoad();
        }
        if (dataRow && rowNode) {
            rowNode.setData(dataRow);
        }
        this.Modal.onClose();
    };

    onCloseDefault = () => {
    };

    onRowDoubleClicked = (value) => {
        this.onDetailRow(value);
    };

    onGlobalFilter = () => {
    };

    //render
    renderTitleForm(text) {
        return (
            <span
                style={{
                    position: "relative",
                    marginRight: 7,
                    display: "flex",
                    alignItems: "center"
                }}>
                <Icon type="form" style={{fontSize: 16, display: "flex"}}/>
                <span
                    style={{
                        fontSize: 15,
                        display: "flex",
                        marginLeft: 10
                    }}>
                    <TextTranslate text={text}/>
                </span>
            </span>
        );
    }

    renderLeftActionBar = () => {
        return <div className={"title-grid"}>{_.toUpper(this.renderTitle)}</div>;
    };

    renderActionBar() {
        const buttons = [];
        if (this.checkCreateRight() && this.hasCreate) {
            buttons.push({
                className: "create-btn",
                tooltip: t("BaseListView-CREATE") + " " + this.moduleName,
                icon: <Icon type="plus"/>,
                onPress: this.onCreate
            });
        }
        if (this.checkExportRight() && (this.apiExport || this.hasExport)) {
            buttons.push({
                className: "export-btn",
                tooltip: t("BaseListview-EXPORT") + " " + this.moduleName,
                icon: <Icon type="file-excel"/>,
                onPress: this.onExport
            });
        }
        buttons.push(
            {
                type: "refresh",
                tooltip: t("BaseListView-REFRESH"),
                onPress: this.reLoad
            },
            {
                type: "fullScreen",
                tooltip: t("BaseListView-FULL_SCREEN"),
                onPress: () => this.FullBrowser.toogleFullScreen()
            }
        );
        return buttons;
    }

    actionRows = () => {
        return {
            pinned: "right",
            width: this.widthActionRow,
            title: this.titleActionRow,
            items: this.renderActions
        };
    };

    renderActions = () => {
        // button disable them className: "button-action-row-disabled"
        const buttons = [];
        buttons.push({
            type: "detail",
            onPress: this.onDetailRow,
            icon: {
                type: "info-circle",
                style: {background: "#5e72e4"},
                color: "#ffffff"
            },
            tooltip: t("BaseListView-DETAIL")
        });
        if (this.checkUpdateRight() && this.hasUpdate) {
            buttons.push({
                type: "edit",
                onPress: this.onEditRow,
                icon: {
                    type: "edit",
                    style: {background: "#2dce89"},
                    color: "#ffffff"
                },
                tooltip: t("BaseListView-UPDATE")
            });
        }
        if (this.checkDeleteRight() && this.hasDelete) {
            buttons.push({
                type: "delete",
                onPress: this.onDeleteRow,
                icon: {
                    type: "delete",
                    style: {background: "var(--main-color-red)"},
                    color: "#ffffff"
                },
                tooltip: t("BaseListView-DELETE")
            });
        }
        return buttons;
    };

    renderTop() {
    }

    renderCustom = () => {
    };

    onGridReady() {
    }

    onSelectionChanged = () => {
    };

    onRowDataChanged = (val) => {
    };

    onRowDragEnd = (val) => {
    };

    exportGridToExcelv2 = (grid, fileName, sheetName, title, description, callback) => {
        // const values = this.FormFilter.getValues();
        // const financeYear = _.get(values, 'financeYear');
        const gridColumns = _.get(grid, "gridColumnApi.columnController.allDisplayedColumns");
        const gridLength = gridColumns ? gridColumns.length - 1 : 10;
        const contentTitle = [
            [
                {
                    styleId: "titleExcelv2",
                    data: {
                        type: "String",
                        value: title.toUpperCase()
                    },
                    mergeAcross: gridLength
                }
            ],
            [
                {
                    styleId: "titleTime",
                    data: {
                        type: "String",
                        value: description
                    },
                    mergeAcross: gridLength
                }
            ]
        ];

        const options = {
            fileName: fileName || `Daily_technician_report_${moment().format(AppUtil.GLOBAL_DATETIME_FORMAT)}`,
            sheetName: sheetName || "Data",
            headerRowHeight: 50,
            columnGroups: true,
            // columnKeys=[]
            customHeader: AppUtil.titleExcelv2(contentTitle)
        };
        // options.processCellCallback = (params) => {
        //     params.column.minWidth = 50;
        //     console.log("param: =>>>", params.column);
        // }
        // options.columnKeys = columnKeys;
        options.processCellCallback = this.customDataCellExcel;
        AppUtil.exportExcelClient(grid, options);
        callback && callback();
    };

    customDataCellExcel = (params) => {
        return AppUtil.customDataCellExcel(params)
    }


    renderGrid() {
        return (
            <Grid
                excelConfig={{
                    columnWidth: this.columnWidthExcel,
                }}
                height={this.heightGrid}
                minHeight={this.minHeightGrid ? this.minHeightGrid : ""}
                numberRows={this.defaultNumberRows}
                ref={(myGrid) => (this.myGrid = myGrid)}
                rowSelection={this.rowSelection}
                checkboxSelection={this.checkboxSelection}
                headerCheckboxSelection={this.headerCheckboxSelection}
                pagination={this.pagination}
                rowHeight={this.rowHeight}
                className={`base-grid ${this.textCenterRow} `}
                clsToolbar="base-grid-toolbar"
                store={this.store}
                globalFilter={this.globalFilter}
                floatingFilter={this.floatingFilter}
                columnDefs={this.columnDefs}
                onSelected={this.onSelectedRow}
                groupDefaultExpanded={this.groupDefaultExpanded}
                groupUseEntireRow={this.groupUseEntireRow}
                renderLeftActionBar={this.overrideTopLeftGrid ? this.renderLeftActionBar : false}
                actionToolbar={this.renderActionBar()}
                onRowDoubleClicked={this.isRowDoubleClick ? this.onRowDoubleClicked : false}
                autoHeightRow={this.autoHeightRow}
                headerVerticalLines={true}
                headerHorizotalLines={true}
                suppressColumnVirtualisation={this.suppressColumnVirtualisation}
                headerHeight={this.headerHeight}
                defaultColDef={{
                    ...this.defaultColDef,
                    resizable: this.resizable,
                    cellClassRules: this.cellClassRules
                }}
                onRowDataChanged={this.onRowDataChanged}
                animateRows={this.animateRows}
                rowDragManaged={this.rowDragManaged}
                actionRows={this.actionRows()}
                gridOptions={{
                    rowClassRules: Object.assign({}, this.rowClassRules),
                    onRowDragEnd: this.onRowDragEnd,
                    excelStyles: AppUtil.excelStylesv2(),
                    suppressScrollOnNewData: this.suppressScrollOnNewData,

                }}

                cellRenderer={this.cellRenderer}
                onGridReady={() => {
                    this.onGridReady();
                }}
                onSelectionChanged={this.onSelectionChanged}
            />
        );
    }

    renderTreeGrid() {
        return (
            <TreeGrid
                excelConfig={{
                    columnWidth: this.columnWidthExcel
                }}
                height={this.heightGrid}
                numberRows={this.defaultNumberRows}
                ref={(myGrid) => (this.myGrid = myGrid)}
                levelTree={this.levelTree}
                rowSelection={this.rowSelection}
                checkboxSelection={this.checkboxSelection}
                headerCheckboxSelection={this.headerCheckboxSelection}
                className={`base-tree-grid ${this.textCenterRow} base-data-grid`}
                clsToolbar="base-grid-toolbar"
                store={this.store}
                globalFilter={this.globalFilter}
                onGlobalFilter={_.debounce(this.onGlobalFilter, 300)}
                floatingFilter={this.floatingFilter}
                columnDefs={this.columnDefs}
                onSelected={this.onSelectedRow}
                groupDefaultExpanded={this.groupDefaultExpanded}
                groupUseEntireRow={this.groupUseEntireRow}
                onRowDoubleClicked={this.isRowDoubleClick ? this.onRowDoubleClicked : false}
                renderLeftActionBar={this.overrideTopLeftGrid ? this.renderLeftActionBar : false}
                actionToolbar={this.renderActionBar()}
                autoHeightRow={this.autoHeightRow}
                headerVerticalLines={true}
                headerHorizotalLines={true}
                headerHeight={this.headerHeight}
                defaultColDef={this.defaultColDef}
                rowClassRules={this.rowClassRules}
                actionRows={this.actionRows()}
                cellRenderer={this.cellRenderer}
                onSelectionChanged={this.onSelectionChanged}
                suppressRowClickSelection={this.suppressRowClickSelection}
                lineType={"dashed"}
                gridOptions={{
                    suppressScrollOnNewData: this.suppressScrollOnNewData
                }}
                treeLine={true}
                {...this.gridProps}
            />
        );
    }

    renderBottom() {
    }

    renderModal() {
        return (
            <Modal
                draggable
                ref={(component) => {
                    this.Modal = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container"
            />
        );
    }

    renderOverLay() {
        return (
            <Overlay
                ref={(component) => (this.refOverlay = component)}
                style={{
                    padding: 15,
                    borderRadius: 3,
                    zIndex: 9
                }}
            />
        );
    }

    render() {
        const contentHeight = this.contentHeight;
        return (
            <BasePermission rightAccess={this.rightAccess}>
                <FullBrowser ref={(component) => (this.FullBrowser = component)}>
                    <Container ref={(c) => (this.refContainer = c)}>
                        {this.isComponent ? (
                            <div className={this.className}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    height: contentHeight
                                }}>
                                    {this.renderTop()}
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex"
                                        }}>
                                        {this.defaultRenderGrid ? this.renderGrid() : this.renderTreeGrid()}
                                    </div>
                                    {this.renderBottom()}
                                </div>
                                {this.renderModal()}
                                {this.renderOverLay()}
                            </div>
                        ) : (
                            <ContentView>
                                <div className={this.className}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            height: contentHeight
                                        }}>
                                        {this.renderTop()}
                                        <div style={{flex: 1}}>
                                            {this.defaultRenderGrid ? this.renderGrid() : this.renderTreeGrid()}
                                            {this.renderCustom()}
                                        </div>
                                        {this.renderBottom()}
                                    </div>
                                    {this.renderModal()}
                                    {this.renderOverLay()}
                                </div>
                            </ContentView>
                        )}
                    </Container>
                </FullBrowser>
            </BasePermission>
        );
    }
}

export default BaseListView;
