import _ from "lodash";
import {
    STATUS_WAITING,
    TYPE_LOYALTY_BOOKING_BASED,
    TYPE_VOUCHER
} from "../../modules/posTransaction/constant/BookingConstant";
import rightUtils from "../../utils/rightUtils";
import TreeUtil from "../../utils/TreeUtil";
import * as Constant from "../constants/constant";
import menuConstants from "../constants/menuConstants";

const initialState = {
    showMenuDesktop: localStorage.getItem("showMenuDesktop") === "1" ? true : false,
    isAuthenticated: false,
    authData: {},
    rights: {},
    openKeys: [],
    dialog: undefined,
    breadcrumbs: [],
    allMenuItems: [],
    sideMenu: [],
    selectKeys: [],
    headerMenu: [],
    isPermisstion: true,
    selectedServiceStoreReordering: [],
    totalAmount: 0,
    subTotal: 0,
    totalTime: 0,
    totalGST: 0,
    discountLoyalty: 0,
    discountVoucher: 0,
    technician: null,
    branch: {},
    extraServices: [
        // {
        //     name: 'TIP',
        //     technician: "",
        //     price: 0,
        //     time: "",
        //     stt: "TIP",
        //     field: "extra",
        //     type: "tip",
        //     index: uuidv4()
        // }
    ],
    booking: {},
    showSideMenuDesktop: true,
    storeList: {
        priorityTechnicians: [],
        priorityTechniciansDefault: [],
        technicianSkillStore: [],
        bookingStatus: [],
        customerStore: [],
        servicesStore: [],
        allServiceStore: [],
        allServiceStoreFlatData: [],
        timeWork: "",
        startTime: ""
    },
    notResetStoreServices: false,
    optionsCustomer: [],
    gst: null,
    technicianSchedule: [],
    bookingSchedule: [],
    startHour: "08:00",
    endHour: "17:00",
    waitingList: [],
    flatAllData: [],
    dateSelected: new Date(),
    reloadWaiting: false,
    readOnly: false,
    scrollToCurrentHour: false,
    multiSetting: false,
    extraEndHour: null,
    minuteOverlap: 0,
    printBillSlip: false,
    phoneSetting: {
        length: 10,
        regex: "000 000 0000"
    },
    branchOptions: [],
    listPricing: [],
    axiosStop: false,
    isPointBase: false
};

const rootReducer = (state = initialState, action) => {
    const {storeList} = state;
    switch (action.type) {
        case Constant.SET_CONFIG_APP:
            const {payload} = action
            const {
                authData,
                menu,
                headerMenu,
                branch,
                rights,
                branchOptions,
                rightsPM,
                isAuthenticated,
                loyalty,
                phoneSetting
            } = payload;
            rightUtils.rights = _.get(authData, 'rights');
            return {
                ...state,
                isAuthenticated,
                authData,
                allMenuItems: menu,
                phoneSetting: _.get(authData, "phoneSetting") ? phoneSetting : state.phoneSetting,
                headerMenu,
                branch,
                rights,
                rightsPM,
                branchOptions,
                isPointBase: loyalty
            };
        case Constant.ROUTE_CHANGED:

            return state;
        case Constant.SET_AXIOS_STOP:
            return {...state, axiosStop: action.bool};
        case Constant.TOGGLE_MENU_DESKTOP:
            if (action.show !== undefined) {
                localStorage.setItem("showMenuDesktop", action.show ? 1 : 0);
                return {...state, showMenuDesktop: action.show};
            } else {
                localStorage.setItem("showMenuDesktop", !state.showMenuDesktop ? 1 : 0);
                return {...state, showMenuDesktop: !state.showMenuDesktop};
            }
        case Constant.LOG_OUT:
            return state;
        case Constant.LOG_OUT_SUCCESSFUL:
            return {
                ...state,
                isAuthenticated: false,
                authData: {},
                rights: [],
                site: {}
            };
        // case Constant.LOG_IN_SUCCESSFUL:
        //     const {authData, menuConfig} = action;
        //     rightUtils.rights = _.get(authData, 'rights');
        //     if (_.get(authData, "isReview")) {
        //         rightUtils.rights[allRights.BOOKING_REVIEW_RIGHT__ACCESS] = true;
        //     }
        //     //check right
        //     //const menu = rightUtils.applyRightToNode(menuConfig);
        //     //const allMenuItems = _.filter(menuConfig, (x) => x.visible);
        //     const allMenuItems = menuConfig;
        //     return {
        //         ...state,
        //         isAuthenticated: true,
        //         authData,
        //         allMenuItems,
        //         phoneSetting: _.get(authData, "phoneSetting") ? authData.phoneSetting : state.phoneSetting
        //     };
        case Constant.CHANGE_PTW_RIGHT:
            return {
                ...state,
                rights: action.rights
            };
        case Constant.CHANGE_USER_CREATED_BY:
            return {
                ...state,
                userCreatedBy: action.userCreatedBy
            };

        case Constant.CHANGE_DIALOG:
            return {
                ...state,
                dialog: action.dialog
            };
        case Constant.CHANGE_BREADCRUMB:
            return {
                ...state,
                breadcrumbs: action.breadcrumbs
            };
        case Constant.CHANGE_SIDEMENU:
            return {
                ...state,
                sideMenu: action.menu
            };
        case menuConstants.LOAD_MENU:
            return {
                ...state,
                allMenuItems: action.payload
            };
        case menuConstants.CHANGE_SELECT_MENU:
            return {
                ...state,
                selectKeys: action.payload
            };
        case menuConstants.CHANGE_OPEN_KEY_MENU:
            return {
                ...state,
                openKeys: action.payload
            };
        case Constant.SET_HEADER_MENU:
            return {
                ...state,
                headerMenu: action.menu
            };
        case Constant.SHOW_SIDE_BAR_MENU:
            return {
                ...state,
                showSideMenuDesktop: action.bool
            };
        case Constant.SET_BRANCH:
            return {
                ...state,
                branch: action.branch
            };
        case Constant.CHANGE_IS_PERMISSTION:
            return {
                ...state,
                isPermisstion: action.isPermisstion
            };
        case Constant.CHANGE_BRANCH_OPTIONS:
            return {
                ...state,
                branchOptions: action.branchOptions
            };

        case Constant.SELECT_SERVICE:
        case Constant.SELECT_MANY_SERVICE:
        case Constant.SELECT_SERVICE_GROUP:
        case Constant.DESELECT_SERVICE:
        case Constant.DESELECT_MULTIPLE_SERVICES:
        case Constant.UPDATE_SELECTED_SERVICES:
        case Constant.SET_SELECTED_SERVICESBY_BOOKING_ITEMS: {
            const {dataReordering, totalTime} = action;
            const subTotal = getSubTotal(dataReordering, state.extraServices);
            const discountLoyalty = getDiscountLoyalty(subTotal, state.booking);
            const discountVoucher = getDiscountVoucher(subTotal, state.booking);
            const totalGST = getTotalGST(state.booking, subTotal, state.gst, discountVoucher);
            const totalAmount = getTotalAmount(state.booking, subTotal, totalGST, discountLoyalty, discountVoucher);

            return {
                ...state,
                selectedServiceStoreReordering: dataReordering,
                totalAmount,
                totalTime,
                subTotal,
                totalGST,
                discountVoucher,
                discountLoyalty
            };
        }
        case Constant.UPDATE_BOOKING: {
            const {booking} = action;
            const subTotal = getSubTotal(state.selectedServiceStoreReordering, state.extraServices);
            const discountLoyalty = getDiscountLoyalty(subTotal, booking);
            const discountVoucher = getDiscountVoucher(subTotal, booking);
            const totalGST = getTotalGST(booking, subTotal, state.gst, discountVoucher);
            const totalAmount = getTotalAmount(booking, subTotal, totalGST, discountLoyalty, discountVoucher);
            let tech = null;
            if (booking.technician) {
                tech = {
                    id: booking.technician,
                    name: booking.technicianName
                };
            }
            return {
                ...state,
                booking: booking,
                totalAmount,
                subTotal,
                totalGST,
                technician: tech,
                discountVoucher,
                discountLoyalty
            };
        }

        case Constant.RESET_SERVICES: {
            const storeListReset = {
                priorityTechnicians: storeList.priorityTechniciansDefault,
                technicianSkillStore: [],
                bookingStatus: [],
                customerStore: [],
                servicesStore: [],
                allServiceStore: [],
                allServiceStoreFlatData: []
            };
            return {
                ...state,
                storeList: storeListReset,
                selectedServiceStoreReordering: [],
                extraServices: [
                    // {
                    //     name: 'TIP',
                    //     technician: "",
                    //     price: 0,
                    //     time: "",
                    //     stt: "TIP",
                    //     field: "extra",
                    //     type: "tip",
                    //     index: uuidv4()
                    // }
                ],
                totalAmount: 0,
                subTotal: 0,
                totalTime: 0,
                technician: null,
                totalGST: 0,
                booking: {},
                discountLoyalty: 0,
                discountVoucher: 0
            };
        }
        case Constant.UPDATE_EXTRA_SERVICES: {
            const {extraServices} = action;
            const subTotal = getSubTotal(state.selectedServiceStoreReordering, extraServices);
            const discountVoucher = getDiscountVoucher(subTotal, state.booking);
            const discountLoyalty = getDiscountLoyalty(subTotal, state.booking);
            const totalGST = getTotalGST(state.booking, subTotal, state.gst, discountVoucher);
            const totalAmount = getTotalAmount(state.booking, subTotal, totalGST, discountLoyalty, discountVoucher);
            return {
                ...state,
                extraServices,
                totalAmount,
                subTotal,
                totalGST,
                discountVoucher,
                discountLoyalty
            };
        }
        case Constant.ADD_NULL_EXTRA_SERVICES: {
            const {nullExtra} = action;
            state.extraServices.push(nullExtra);
            return {
                ...state
            };
        }
        case Constant.SELECT_MAIN_TECHNICIAN: {
            const {dataReordering, technician, extraServices, technicianId} = action;
            return {
                ...state,
                selectedServiceStoreReordering: dataReordering,
                extraServices,
                technician,
                technicianId
            };
        }
        case Constant.SELECT_DATA_BOOKING: {
            const {booking} = action;
            return {
                ...state,
                booking
            };
        }
        case Constant.LOAD_COMBOBOX_DATA_BOOKING_FORM:
            const {comboboxData} = action;
            storeList.servicesStore = comboboxData["service-combo-data"];
            storeList.technicianSkillStore = comboboxData["skills"];
            storeList.bookingStatus = comboboxData["booking-status"];
            storeList.startTime = comboboxData["startTime"];
            storeList.timeWork = comboboxData["timeWork"];
            storeList.multiSetting = parseInt(comboboxData["multiSetting"]) === 1 ? true : false;
            return {
                ...state,
                storeList
            };
        case Constant.LOAD_COMBOBOX_PRIORITY_TECHNICIAN:
            storeList.priorityTechnicians = action.combo;
            if (_.get(action, "isDefault")) {
                storeList.priorityTechniciansDefault = action.combo;
            }
            return {
                ...state,
                storeList
            };
        case Constant.LOAD_TECHNICIAN_SKILL_STORE:
            storeList.technicianSkillStore = action.combo;
            return {
                ...state,
                storeList
            };
        case Constant.LOAD_BOOKING_STATUS:
            storeList.bookingStatus = action.combo;
            return {
                ...state,
                storeList
            };
        case Constant.LOAD_CUSTOMER_STORE:
            storeList.customerStore = action.combo;
            return {
                ...state,
                storeList
            };
        case Constant.LOAD_SERVICE_STORE:
            storeList.servicesStore = action.combo;
            return {
                ...state,
                storeList
            };
        case Constant.LOAD_ALL_SERVICE_STORE:
            storeList.allServiceStore = action.combo;
            storeList.allServiceStoreFlatData = parseFlatService(action.combo);
            return {
                ...state,
                storeList
            };
        case Constant.NOT_RESET_STORE: {
            const {value} = action;
            return {
                ...state,
                notResetStoreServices: value
            };
        }
        case Constant.UPDATE_CUSTOMER: {
            const {combo} = action;
            return {
                ...state,
                optionsCustomer: combo
            };
        }
        case Constant.CHANGE_GST: {
            const {value} = action;
            return {
                ...state,
                gst: value
            };
        }
        case Constant.UPDATE_BRAND_IN_AUTH_DATA: {
            const {authData, value} = action;
            if (authData.user) {
                authData.user.branchId = value;
            }
            return {
                ...state,
                authData
            };
        }
        case Constant.LOAD_TECHNICIAN_WORKING_SCHEDULE: {
            const {combo} = action;
            return {
                ...state,
                technicianSchedule: combo
            };
        }

        case Constant.LOAD_TASK_SCHEDULE: {
            const {combo} = action;
            const {bookingSchedule, nodeUpdate, reloadWaiting, scrollToCurrentHour} = combo;
            const newFlat = _.map(state.flatAllData, (x) => {
                if (x.BookingId === nodeUpdate.BookingId && x.BookingItemId === nodeUpdate.BookingItemId) {
                    return {...nodeUpdate};
                }
                return {
                    ...x
                };
            });
            if (reloadWaiting) {
                const filterStatus = _.filter(newFlat, (x) => !x.TechnicianId && x.Status === STATUS_WAITING);
                const waitingList = TreeUtil.buildTreeFromFlatData(filterStatus, "Id", "BookingId", "Children");
                const waitingListNew = _.filter(waitingList, (x) => !_.isEmpty(x.Children));
                return {
                    ...state,
                    bookingSchedule: bookingSchedule,
                    waitingList: waitingListNew,
                    flatAllData: newFlat,
                    reloadWaiting
                };
            }
            return {
                ...state,
                bookingSchedule: bookingSchedule,
                flatAllData: newFlat,
                reloadWaiting,
                scrollToCurrentHour
            };
        }
        case Constant.LOAD_CONFIG_DASHBOARD_V2: {
            const {combo} = action;
            return {
                ...state,
                technicianSchedule: combo.technicianSchedule,
                bookingSchedule: combo.bookingSchedule,
                startHour: combo.startHour,
                endHour: combo.endHour,
                waitingList: combo.waitingList ? combo.waitingList : [],
                dateSelected: combo.dateSelected ? combo.dateSelected : new Date(),
                flatAllData: combo.flatAllData,
                reloadWaiting: true,
                technicianSkills: combo.technicianSkills,
                readOnly: combo.readOnly,
                scrollToCurrentHour: combo.scrollToCurrentHour,
                multiSetting: combo.multiSetting,
                extraEndHour: combo.extraEndHour,
                minuteOverlap: combo.minuteOverlap,
                printBillSlip: combo.printBillSlip
            };
        }

        default:
            return state;
    }
};

export default rootReducer;

function getDiscountLoyalty(subTotal, booking) {
    const {bookingDiscounts} = booking;
    const loyalty = _.find(bookingDiscounts, (x) => x.type === TYPE_LOYALTY_BOOKING_BASED);
    let value = 0;
    if (loyalty) {
        if (loyalty.value && loyalty.value !== 0) {
            value = Math.abs(loyalty.value);
        } else if (loyalty.percent && loyalty.percent !== 0) {
            value = (parseFloat(subTotal) * Math.abs(loyalty.percent)) / 100;
        }
    }
    return value;
}

function getDiscountVoucher(subTotal, booking) {
    const {bookingDiscounts} = booking;
    const loyalty = _.find(bookingDiscounts, (x) => x.type === TYPE_VOUCHER);
    let value = 0;
    if (loyalty) {
        if (loyalty.value && loyalty.value !== 0) {
            value = Math.abs(loyalty.value);
        } else if (loyalty.percent && loyalty.percent !== 0) {
            value = (parseFloat(subTotal) * Math.abs(loyalty.percent)) / 100;
        }
    }
    return value;
}

function getTotalAmount(booking, subTotal, totalGST, discountLoyalty, discountVoucher) {
    const paymentFee = booking.paymentFee ? booking.paymentFee : 0;
    const total = parseFloat(subTotal) + parseFloat(totalGST) + parseFloat(paymentFee) - parseFloat(discountVoucher);
    // const total = parseFloat(subTotal) + parseFloat(totalGST) + parseFloat(paymentFee) - parseFloat(discountLoyalty) - parseFloat(discountVoucher);

    if (parseFloat(total) < 0) {
        return 0;
    }
    return total.toFixed(2);
}

function getSubTotal(dataReordering, extraServices = []) {
    let total = 0;
    dataReordering.map((service) => {
        total += service.price;
    });
    extraServices.map((extra_service) => {
        if (extra_service.type !== "tip") {
            total += extra_service.price ? parseFloat(extra_service.price) : 0;
        }
    });
    if (parseFloat(total) < 0) {
        return 0;
    }
    return total.toFixed(2);
}

function getTotalGST(booking, subTotal, gst, discountVoucher) {
    let total = 0;
    let gstValue = 0;
    if (gst) {
        if (booking.id) {
            total = subTotal - discountVoucher + booking.paymentFee;
        }
        gstValue = (total * parseFloat(gst)) / 100;
    }
    if (parseFloat(gstValue) < 0) {
        return 0;
    }
    return gstValue.toFixed(2);
}

function parseFlatService(allServiceStore) {
    const newResult = [];
    TreeUtil.onParseTreeToFlatData(allServiceStore, newResult);
    return newResult;
}
