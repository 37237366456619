import {useMergeState} from "@idtek/component";
import _ from "lodash";
import React, {useMemo, useState} from "react";
import useCalculatePayment from "../hook/useCalculatePayment";
import {PaymentContext} from "./PaymentContext";
import moment from "moment";

export const PaymentCreateProvider = ({
                                          children,
                                          initValue,
                                          branchId,
                                          bookingId,
                                          optData,
                                          guestIds,
                                          guestId,
                                          isDetailPayment,
                                          isEditPayment,
                                          cartIds,
                                          billId,
                                          customerIdCart
                                      }) => {
    const [paymentData, setPaymentData] = useMergeState(initValue);
    let findCus = _.find(_.get(initValue, "customerOptions"), (x) => x.value === _.get(initValue, "booking.customerId"));

    //uu tien cho customer Cart
    if (customerIdCart) {
        findCus = _.find(_.get(initValue, "customerOptions"), (x) => x.value === customerIdCart);
    }
    const [customer, setCustomer] = useState({...findCus, id: findCus ? findCus.value : ""});
    const [optionsData, setOptionsData] = useState(optData);
    const [guestIdsSelect, setGuestIdsSelect] = useState(guestIds);
    const [cartIdsSelect, setCartIdsSelect] = useState(cartIds)
    const [voucher, setVoucher] = useState(null);
    const [method, setMethod] = useState(_.get(optionsData, "paymentMethods[0]"));
    const [gift, setGift] = useState(null);
    const [checkouts, setCheckouts] = useState(_.get(optionsData, 'checkOutDefault'));
    const [totalReceivedMoney, setTotalReceivedMoney] = useState(0);
    const [backMoney, setBackMoney] = useState(0);
    const [receivedObj, setReceivedObj] = useState(null);
    const [moneyPoint, setMoneyPoint] = useState(0);
    const [tips, setTips] = useState(null)
    const [technicianTip, setTechnicianTip] = useState(null)
    const [isUseHst, setIsUseHst] = useState(true)
    const [initialValues, setInitialValues] = useState({
        customerId: findCus ? findCus.value : _.get(initValue, "booking.customerId"),
        typeCheckout: checkouts,
        typeCheckoutTip: checkouts[0],
        isHst: true,
        actionTime: moment().format("YYYY-MM-DD HH:mm")
    });

    const resultPayment = useCalculatePayment({
        guestList: paymentData.guestList,
        cartList: paymentData.cartList,
        hst: optionsData ? optionsData.hst : null,
        method,
        voucher,
        gift,
        moneyPoint,
        loyaltyType: paymentData.loyaltyType,
        objDiscount: _.get(paymentData.booking, "bookingDiscounts[0]"),
        tips,
        notLoad: isDetailPayment,
        isFromSaveBooking: paymentData.isFromSaveBooking,
        isUseHst
    });


    const value = useMemo(
        () => ({
            guestId,
            paymentData,
            setPaymentData,
            initialValues,
            setInitialValues,
            branchId,
            bookingId,
            optionsData,
            setOptionsData,
            guestIdsSelect,
            setGuestIdsSelect,
            cartIdsSelect,
            setCartIdsSelect,
            customer,
            setCustomer,
            voucher,
            setVoucher,
            method,
            setMethod,
            resultPayment,
            gift,
            setGift,
            checkouts,
            setCheckouts,
            tips, setTips,
            technicianTip, setTechnicianTip,
            totalReceivedMoney,
            setTotalReceivedMoney,
            backMoney,
            setBackMoney,
            receivedObj,
            setReceivedObj,
            moneyPoint,
            setMoneyPoint,
            isDetailPayment,
            isEditPayment,
            billId,
            isUseHst, setIsUseHst,
        }),
        [
            guestId,
            initialValues,
            paymentData,
            setPaymentData,
            branchId,
            bookingId,
            optionsData,
            setOptionsData,
            guestIdsSelect,
            setGuestIdsSelect,
            cartIdsSelect,
            setCartIdsSelect,
            customer,
            setCustomer,
            voucher,
            setVoucher,
            method,
            setMethod,
            resultPayment,
            gift,
            setGift,
            checkouts,
            setCheckouts,
            totalReceivedMoney,
            setTotalReceivedMoney,
            backMoney,
            setBackMoney,
            receivedObj,
            setReceivedObj,
            moneyPoint,
            setMoneyPoint,
            tips, setTips,
            technicianTip, setTechnicianTip, billId, isDetailPayment,
            isEditPayment,
            isUseHst, setIsUseHst,
        ]
    );
    return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
    //region return
};