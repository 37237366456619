import { SwitchField, TextAreaField, TextField } from '@idtek/form/lib';
import ImagePickerField from "@idtek/form/lib/file/ImagePickerField";
import React, { Fragment } from 'react';
import BaseForm from '../../../common/baseForm/BaseForm';
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";
import { PRODUCT_ACTIVE, PRODUCT_DEACTIVE } from '../contant/ProductActiveContant';
import _ from "lodash";
import AppStore from '../../../store';
import AppUtil from "../../../utils/AppUtil";
import { DataUtil } from '../../../utils/DataUtil';
import { t } from '@idtek/component/lib/language';

class ProductForm extends BaseForm {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: {
                status: true,
                ...props.initialValues
            }
        }
        this.apiDetail = "/product/show-detail-product";
        this.apiCreate = "/product/create-product";
        this.apiUpdate = "/product/update-product";
    }

    beforeSetValues = async (values) => {
        if (values.status === PRODUCT_ACTIVE) {
            values.status = true;
        } else {
            values.status = false;
        }
        values.imageFile = _.filter(values.image, x => x);
        return values;
    }
    onSave = async (form, values) => {
        form && form.mask();
        let url = "";
        if (this.isCreate) {
            url = this.apiCreate;
        } else {
            values.id = _.get(this.initialValues, "id");
            url = this.apiUpdate;
        }
        values = this.beforeSave(form, values);
        const res = await AppUtil.postApi(url, values);
        this.afterSave(form, res);
        form && form.unmask();
        if (res) {
            if (!_.get(res.data, "success")) {
                AppUtil.ToastApiError(t(_.get(res, "data.message")));
            } else {
                AppUtil.ToastSuccess();
                this.props.onClose && this.props.onClose(this.isCreate, _.get(res.data, "result"));
            }
        } else {
            AppUtil.ToastApiError();
        }
    };

    beforeSave(form, values) {
        let newValues = {
            ...values
        };

        if (values.status === true) {
            newValues.status = PRODUCT_ACTIVE;
        } else {
            newValues.status = PRODUCT_DEACTIVE;
        }
        if (!values.imageFile) {
            newValues.image = null;
        }
        newValues.branchId = _.get(AppStore.getState(), `root.branch.id`, null);
        return DataUtil.serializeToFromData(newValues, {
            noFilesWithArrayNotation: true,
            indices: true
        }, new FormData, '');
    }


    renderBody() {
        const { isCreate } = this.props;

        return (
            <Fragment>
                <Row>
                    <Cell>
                        <TextField name="code" upperCase={true} disabled={isCreate ? false : true} />
                    </Cell>
                    <Cell>
                        <TextField name="name" />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextField name="price" type='number' />
                    </Cell>
                    <Cell>
                        <SwitchField name="status" />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <ImagePickerField name={"imageFile"} />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextAreaField name="description" />
                    </Cell>
                </Row>
            </Fragment>
        );
    }
}

export default ProductForm;