import { t } from '@idtek/component/lib/language';
import React from 'react'
import {TAKE_MONEY, TYPE_TAKE_MONEY} from '../constant/BookingConstant';
import FormPosReceiveMoney from './FormPosReceiveMoney'

export default class FormPosTakeMoney extends FormPosReceiveMoney {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.posType = TYPE_TAKE_MONEY
        this.type = TAKE_MONEY
        this.model = {
            fields: {
                note: {
                    label: t("FormPosTakeMoney-NOTE"),
                },
                value: {
                    label: t("FormPosTakeMoney-INPUT_CASH_AMOUNT"),
                    required: true
                }
            }
        };
    }
}
