import { t } from '@idtek/component/lib/language'

export const SERVICE_STATUS_CONSTANT = [{
    value: 1,
    label: t('ServiceConstant-ACTIVE'),
}, {
    value: 0,
    label: t('ServiceConstant-INACTIVE'),
}]

export const SERVICE_GROUP_TYPE_CONSTANT = 'GROUP'
export const SERVICE_TYPE_CONSTANT = 'service'
export const SERVICE_TYPE = "Service"
