import React, {
    useEffect,
    useRef,
} from "react";
import _ from "lodash";
import "../style/add-cart.scss";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import { useMergeState } from "@idtek/component/lib/hook/IDHook";
import Loading from "../../../pages/Loading";
import { AddCartFormProvider } from "../context/AddCartFormContext";
import Button from "@idtek/component/lib/button/IDButton";
import Icon from "@idtek/component/lib/icon/Icon";
import AddOrderDetailComponent from "../component/AddOrderDetailComponent";
import { t } from "@idtek/component/lib/language";
import Alert from "@idtek/component/lib/alert/Alert";
import { useContentPaymentContextStep } from "../context/ContentPaymentContextStep";
import PaymentConstant from "../constant/PaymentConstant";

const AddCartDetailNoBookingForm = ({
    bookingId,
    guestId,
    onClose,
    onReloadData,
    branchId,
    isDetailCart,
    bodyCart,
    refFormCart,
    mainCartId,
    customerIdCart
}) => {
    const { stepCurrent, setStepCurrent, valuesProps, setValuesProps, reloadInit } = useContentPaymentContextStep();
    const [state, setState] = useMergeState({
        // guestId: _.isArray(guestId) ? guestId : _.isNull(guestId) || _.isUndefined(guestId) ? [] : [guestId],
        // booking: {},
        // guestList: [],
        // segmentList: [],
        giftCardIds: [],
        giftCardList: [],
        productList: [],
        productIds: [],
        cartIds: [],
        loading: true,
        initFirstTime: true,
        guestListInitId: null,
        giftListInitId: null,
        billId: null,
        cart: {}
    });

    const refSearchAndSelectComponent = useRef(null),
        refAddOrderDetailComponent = useRef(null)
    const urlDetail = ApiBookingConstant.API_GET_DETAIL_ONLY_CART
    const urlSave = ApiBookingConstant.API_UPDATE_CART


    useEffect(() => {
        const loadData = async () => {
            //vì lúc đầu ko init đc value gift, guest nên phải load từ api và set ngược lại
            // const valueGuest = refSearchAndSelectComponent.current && refSearchAndSelectComponent.current.getValue()
            const valueGift = refAddOrderDetailComponent.current && refAddOrderDetailComponent.current.getValueGift()
            const valueProduct = refAddOrderDetailComponent.current && refAddOrderDetailComponent.current.getValueProduct()
            // if (refFormCart) refFormCart.current && refFormCart.current.mask()

            //detail quanr ly only cart
            let params = {
                giftCardIds: valueGift,
                productIds: valueProduct,
                productData: _.filter(state.productList, x => _.indexOf(valueProduct, x.id) >= 0),
                initFirstTime: state.initFirstTime,
                mainCartId
            }
            const res = await AppUtil.postApi(urlDetail, params)

            if (_.get(res, "data.success")) {
                const data = _.get(res, "data.result")
                const valueFormCart = _.get(data, 'cart')
                if (valueFormCart) {
                    valueFormCart.customerName = _.get(valueFormCart, "customer.name");
                    valueFormCart.email = _.get(valueFormCart, "customer.email");
                    if (refFormCart) {
                        refFormCart.current && refFormCart.current.setValues(valueFormCart)
                        // refFormCart.current && refFormCart.current.unmask()
                    }
                }


                const newState = {
                    // booking: data.booking,
                    // guestList: data.guestList,
                    // segmentList: data.segmentList,
                    giftCardList: data.giftCardList,
                    productList: data.productList,
                    billId: data.billId,
                    initFirstTime: false,
                    loading: false
                }
                if (state.initFirstTime) {
                    // newState.guestListInitId = _.map(data.guestList, x => x.id)
                    newState.giftListInitId = _.map(data.giftCardList, x => x.id)
                    newState.productListInitId = _.map(data.productList, x => x.id)
                }
                setState(newState)
            } else {
                AppUtil.ToastApiError()
            }
        }

        stepCurrent === PaymentConstant.STEP_2_CART_DETAIL_NO_BOOKING && loadData()
    }, [state.guestId, state.giftCardIds, state.productIds, reloadInit])


    const addGiftToBill = (gift) => {
        setState({
            giftCardIds: gift
        })
    }

    const onCreateGiftSuccess = (id) => {
        const { giftCardIds } = state
        const newIds = _.cloneDeep(giftCardIds)
        newIds.push(id)
        setState({
            giftCardIds: newIds
        })
    }

    const addProductToBill = (product) => {
        setState({
            productIds: product
        })
    }

    const onCreateProductSuccess = (id) => {
        const { productIds } = state
        const newIds = _.cloneDeep(productIds)
        newIds.push(id)
        setState({
            productIds: newIds
        })
    }

    const onSaveAndContinue = async () => {
        const cart = {}
        if (refFormCart) {
            if (refFormCart.current.isValid()) {
                const valuesCart = refFormCart.current && refFormCart.current.getValues()
                cart.phone = _.get(valuesCart, "customer.phone");
                cart.branchId = branchId
                cart.customerName = _.get(valuesCart, "customerName");
                cart.customerId = _.get(valuesCart, "customerId");
                cart.email = _.get(valuesCart, "email");
                cart.id = mainCartId
            } else {
                AppUtil.ToastError(t("AddCartDetailNoBookingForm-FORM_INVALID"));
                return;
            }
        }
        const valueGuest = refSearchAndSelectComponent.current && refSearchAndSelectComponent.current.getValue()
        const valueGift = refAddOrderDetailComponent.current && refAddOrderDetailComponent.current.getValueGift()
        const valueProduct = refAddOrderDetailComponent.current && refAddOrderDetailComponent.current.getValueProduct()
        const params = {
            // guestIdList: valueGuest,
            giftCardIdList: valueGift,
            branchId: branchId,
            billId: state.billId,
            productIdList: valueProduct,
            productData: _.filter(state.productList, x => _.indexOf(valueProduct, x.id) >= 0),
            cartUpdate: cart,
        }
        if (!_.isEmpty(cart)) {
            params.cartCreate = {
                ...cart,
                id: null
            }
        }
        const response = await AppUtil.postApi(urlSave, params);
        // onReloadData && onReloadData()
        if (_.get(response, "data.success")) {
            const data = _.get(response, "data.result")
            const cartIds = _.get(data, 'cartIds')
            setState({
                ...state,
                cartIds,
                // guestId: _.get(data, 'guestIds'),
                billId: _.get(data, 'id'),
            })
            const vlProps = {
                ...valuesProps,
                // guestId: _.get(data, 'guestIds'),
                cartIds: cartIds,
                billId: _.get(data, 'id'),
                customerIdCart: _.get(data, 'customerIdCart'),
            }
            setValuesProps(vlProps)
            setStepCurrent(PaymentConstant.STEP_3_PAYMENT_NO_BOOKING)
        } else {
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    }

    const onCancel = async () => {
        let confirm = await Alert.Swal_confirm(t("AddCartDetailNoBookingForm-CONFIRM"), t("AddCartDetailNoBookingForm-ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_CART?"), 3);
        if (_.get(confirm, "value") === true) {
            const res = await AppUtil.getApi(`${ApiBookingConstant.API_CANCEL_CART_BILL}`, { cartId: mainCartId });
            if (res && _.get(res.data, "success")) {
                AppUtil.ToastDeleteSuccess();
                onReloadData && onReloadData()
                onClose && onClose();
            } else {
                AppUtil.ToastDeleteError(_.get(res, "data.messages") || _.get(res, "data.message") || t("AddCartDetailNoBookingForm-DATA_ERROR,_PLEASE_TRY_AGAIN!"));
                onClose && onClose();
            }
        }
    }

    const renderButton = () => {
        return <div className={"wrap-button"}>
            <Button className={"btn-form btn btn-danger"}
                // style={{width: 120}}
                type={"default"}
                icon={<Icon type="close-circle" style={{ fontSize: 16, marginTop: -3 }} />}
                onClick={() => onCancel()}
            >
                {t("AddCartDetailNoBookingForm-_CANCEL")}
            </Button>
            <Button className={"btn-form btn btn-primary-lighter"}
                // style={{width: 120}}
                type={"default"}
                icon={<Icon type="dollar" style={{ fontSize: 16, marginTop: -3 }} />}
                onClick={() => onSaveAndContinue()}
            >
                {t("AddCartDetailNoBookingForm-_PAYMENT")}
            </Button>
            <Button className={"btn-form btn btn-secondary"}
                // style={{width: 120}}
                type={"default"}
                icon={<Icon type="close-square" style={{ fontSize: 16, marginTop: -3 }} />}
                onClick={() => onClose && onClose()}
            >
                {t("AddCartDetailNoBookingForm-CLOSE")}
            </Button>
        </div>
    }

    if (state.loading) return <div style={{ flex: 1, height: "400px" }}><Loading /></div>;
    const {
        giftCardList,
        giftListInitId,
        billId,
        productList,
        productListInitId
    } = state

    return (
        <AddCartFormProvider giftCardList={giftCardList}
            isDetail={isDetailCart} productList={productList}
            customerIdCart={customerIdCart}>
            <div className={"add-cart"}>
                <div className={"right"}>
                    {bodyCart && bodyCart()}
                    <AddOrderDetailComponent
                        ref={refAddOrderDetailComponent}
                        giftListInitId={giftListInitId}
                        productListInitId={productListInitId}
                        addGiftToBill={addGiftToBill}
                        onCreateGiftSuccess={onCreateGiftSuccess}
                        addProductToBill={addProductToBill}
                        onCreateProductSuccess={onCreateProductSuccess}
                    />
                </div>
            </div>
            {renderButton()}
        </AddCartFormProvider>
    );
};

export default AddCartDetailNoBookingForm;