import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import ReactToPrint from "react-to-print";


const PrintBillComponent = forwardRef(({}, ref) => {
    const [template, setTemplate] = useState(null);
    const refReactToPrint = useRef(null)
    const componentRef = useRef(null)

    useEffect(() => {
        if (template) {
            setTimeout(() => {
                refReactToPrint.current && refReactToPrint.current.handlePrint();
            }, [])
        }
    }, [template])

    useImperativeHandle(ref, () => ({
        onChangBill(template) {
            setTemplate(template);
        }
    }))
    return <table style={{display: "none"}}>
        <tbody>
        <tr>
            <ReactToPrint ref={refReactToPrint} content={() => componentRef.current} removeAfterPrint={true}
                          onAfterPrint={() => setTemplate(null)}
            />
            <td>
                <div style={{width: '100%'}} ref={componentRef} dangerouslySetInnerHTML={{__html: template}}/>
            </td>
        </tr>
        </tbody>
    </table>
})
export default PrintBillComponent
