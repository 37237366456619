/* eslint-disable react-hooks/exhaustive-deps */

import _ from "lodash";
import React, {useMemo} from "react";
import Loading from "../../../pages/Loading";
import useGetDetailBill from "../hook/useGetDetailBill";
import useGetDetailPayment from "../hook/useGetDetailPayment";
import useGetOptionsPayment from "../hook/useGetOptionsPayment";
import "../style/payment-v3.scss";
import PaymentForm from "../form/PaymentForm";
import {PaymentDetailProvider} from "../context/PaymentDetailProvider";

const PaymentDetailContainer = ({branchId, bookingId, billId, onClose, onReloadData, viewCanCancelPayment}) => {
    const initBill = useGetDetailBill(billId);
    const optData = useGetOptionsPayment(branchId);
    const guests = useMemo(() => {
        if (!initBill) return null
        return _.map(initBill.guests, (x) => x.id)
    }, [initBill]);
    const carts = useMemo(() => {
        if (!initBill) return null
        return _.map(initBill.carts, (x) => x.id)
    }, [initBill]);
    const initValue = useGetDetailPayment(guests, carts, bookingId, billId, !!guests);
    if (initValue.loading) return <Loading loadingHeight={500}/>;
    return (
        <div className="proceed-payment-wrap payment-v3">
            <PaymentDetailProvider
                isDetailPayment={true}
                optData={optData}
                initValue={initValue}
                initBill={initBill}
                branchId={branchId}
                bookingId={bookingId}
                viewCanCancelPayment={viewCanCancelPayment}
                guestId={guests}>
                <PaymentForm onClose={onClose} onReloadData={onReloadData}/>
            </PaymentDetailProvider>
        </div>
    );
};
export default PaymentDetailContainer;
