import Alert from '@idtek/component/lib/alert/Alert';
import _ from "lodash";
import React, {Component} from 'react';
import {setConfigAppBeauty, showSideBarMenu} from './application/actions/action';
import {onChangeSelects} from './application/actions/menuAction';
import './login-page.scss';
import Loading from "./pages/Loading";
import AppStore from './store';
import AppUtil from './utils/AppUtil';
import {TYPE_LOYALTY_POINT_BASED} from "./modules/posTransaction/constant/BookingConstant";
import * as Constant from "./application/constants/constant";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                username: '',
                password: '',
                remember: true
            },
            viewPassword: false,
            loading: true
        };
        this.model = {
            fields: {
                username: {
                    label: "Username",
                },
                password: {
                    label: "Password",
                },
                remember: {},
            }
        };
        this.onKeyPress = this.onKeyPressFunc.bind(this);
        document.addEventListener("keypress", this.onKeyPress);

    }

    async componentDidMount() {
        if (!_.get(this.props, 'history.location.pathname').includes("bff")) {
            await this.checkLogin();
        }
    }


    checkLogin = async () => {

        const branch = localStorage.getItem("branchId")

        const type = localStorage.getItem("type")

        const resCurrentBranch = await AppUtil.postApi("/set-current-branch", {branchId: branch, type: type})

        const loyalty = _.get(resCurrentBranch, "data.result.loyalty.type");

        await AppUtil.Axios.all([
            AppUtil.getApi('/check-login'),
            AppUtil.getApi("/menu/index-flat"),
            AppUtil.getApi('/identity/load-config')
        ])
            .then(([resAuthData, resMenu, resConfig]) => {
                if (_.get(resAuthData, "data.success") &&
                    _.get(resAuthData, "data.result.isAuthenticated") === false) {

                    let pathName = window.location.pathname
                    if (pathName.includes("bff")) {
                        pathName = "/";
                    }
                    this.props.history && this.props.history.push({
                        pathname: "/bff/login",
                        search: `?requestVerificationToken=${AppUtil.getCookie(AppUtil.XSRF_TOKEN_COOKIE_NAME)}&returnUrl=${pathName}`
                    })
                    window.location.reload();
                }
                let authData;
                let rights;
                let branch;
                let menu;
                let headerMenu;
                let branchOptions;
                let rightsPM;
                if (_.get(resAuthData, "data.success") &&
                    _.get(resAuthData, "data.result.isAuthenticated")
                    && _.get(resMenu, "data.success")) {
                    authData = _.get(resAuthData, "data.result.authData");
                    branch = _.get(resAuthData, "data.result.branch");
                    rights = _.get(resAuthData, "data.result.rights.rights");
                    rightsPM = _.get(resAuthData, "data.result.rights.rightsPM");
                    menu = _.get(resMenu, "data.result.menu");
                    headerMenu = _.get(resMenu, "data.result.headerMenu")
                    const hasAppointment = _.find(headerMenu, x => x.route === "/appointment")
                    AppStore.dispatch(onChangeSelects([window.location.pathname]));
                    if ((window.location.pathname === "/appointment" || window.location.pathname === "/") && hasAppointment) {
                        AppStore.dispatch(showSideBarMenu(false));
                    } else {
                        AppStore.dispatch(showSideBarMenu(true));
                    }
                }

                if (resConfig) {
                    const {data} = resConfig;
                    branchOptions = _.get(data, "branchOptions")
                }

                AppStore.dispatch(setConfigAppBeauty(
                    {
                        authData,
                        menu,
                        headerMenu,
                        branch,
                        rights,
                        rightsPM,
                        branchOptions,
                        loyalty: loyalty === TYPE_LOYALTY_POINT_BASED,
                        phoneSetting: AppUtil.RegexPhone(_.find(_.get(branch, `branchConfigs`), (x) => x.type === Constant.PHONE_SET_UP)?.value) || "",
                        isAuthenticated: _.get(resAuthData, "data.result.isAuthenticated")
                    }
                ));

            })
            .catch(err => {
                console.log(err);
            });
        this.setState({
            loading: false
        });
    };

    checkIsMobile() {
        return navigator.userAgent.match(/Android/i) !== null
            || navigator.userAgent.match(/webOS/i) !== null
            || navigator.userAgent.match(/iPhone/i) !== null
            || navigator.userAgent.match(/iPad/i) !== null
            || navigator.userAgent.match(/iPod/i) !== null
            || navigator.userAgent.match(/BlackBerry/i) !== null
            || navigator.userAgent.match(/Windows Phone/i) !== null;

    }

    onKeyPressFunc = (e) => {
        // const me = this;
        if (e.key === "Enter") {
            // me.onLogin();
        }
    };

    onLogin = (event) => {
        const dataForm = this.form && this.form.getValues();
        if (dataForm.username && dataForm.password) {
            this.setState({
                loading: true
            });
            AppUtil.postApi("/login", dataForm)
                .then((response) => {
                    const {data} = response;
                    if (!data.success) {
                        Alert.Toast_info("Notification", "Login fail!", 0, {
                            position: 'topRight'
                        });
                    }
                })
                .catch(() => {
                    Alert.Toast_info("Notification", "Login fail!", 0, {
                        position: 'topRight'
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        this.checkLogin();
                    }, 500);
                });
        } else {
            this.form && this.form.setErrors();
            AppUtil.ToastError("Wrong username or password, please try again!")
        }
    };

    render() {
        // const paddingFormLogin = window.innerHeight > 670;
        return <div style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0}}><Loading/></div>;
    }
}

export default LoginPage;