import {Fieldset} from "@idtek/component/lib";
import { t } from "@idtek/component/lib/language";
import {TextField} from "@idtek/form/lib";
import _ from "lodash";
import React from "react";
import {usePaymentContext} from '../context/PaymentContext';

/* eslint-disable react-hooks/exhaustive-deps */
const BillInputMoney = ({}) => {
    const {
        checkouts, optionsData,
        backMoney
    } = usePaymentContext();
    return (
        <div className="bill-input-money">
            <Fieldset title={t("BillInputMoneyDetail-AMOUNT_RECEIVED")}>
                {checkouts && checkouts.map((item, index) => {
                    const find = _.find(optionsData.checkoutMethods, x => x.id === item);
                    let lastIndex = _.lastIndexOf(checkouts);
                    const style = index ? {marginTop: 10} : {};
                    return <div className="text-field" style={style}>
                        <TextField
                            options={{
                                numeralPositiveOnly: true
                            }}
                            hideError={index + 1 !== lastIndex}
                            className="back-money"
                            name={`received_${item}`}
                            label={find ? find.name : ""}
                            widthLabel="120px"
                            type="money"
                            required={true}
                        />
                    </div>;
                })}
            </Fieldset>
            <div className="text-field">
                <TextField
                    options={{
                        numeralPositiveOnly: true
                    }}
                    hideError={true}
                    className="back-money"
                    name="return"
                    type="money"
                    max={backMoney}
                    readOnly={backMoney === 0 || !backMoney}
                />
            </div>
        </div>
    );
};

export default BillInputMoney;