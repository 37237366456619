import {t} from "@idtek/component/lib/language";

export const TYPE_RECEIVE_MONEY = 2;
export const TYPE_TAKE_MONEY = 3;
export const TYPE_VOUCHER = "TYPE_VOUCHER_CODE";
export const TYPE_LOYALTY_BOOKING_BASED = "BOOKING_BASED";
export const TYPE_LOYALTY_POINT_BASED = "POINT_BASED";
export const BOOKING_TYPE = "Booking";
export const SEGMENT_TYPE = "Segment";
export const GUEST_TYPE = "Guest"
export const BILL_TYPE = "Bill";
///posType
export const RECEIVE_MONEY = "IN";
export const TAKE_MONEY = "OUT";
export const TIP_MONEY = "TIP";

//status
export const STATUS_NEW = "NEW";
export const STATUS_WAITING = t("BookingConstant-WAITING");
export const STATUS_PROCESSING = "Processing";
export const STATUS_COMPLETE = "Completed";
export const STATUS_CANCEL = "Cancel";

export const STATUS_NOSHOW = "NoShow";

export const OPTION_GENDER = [
    {
        value: "FEMALE",
        label: t("BookingConstant-FEMALE")
    },
    {
        value: "MALE",
        label: t("BookingConstant-MALE")
    }]

