import {allRights} from "../../../utils/rightUtils";

export default {
    STAFF_SHIFTS_SCHEDULER: allRights.TIMESHEET_ACCESS,
    CUSTOMER_FEEDBACK: allRights.REVIEW_ACCESS,
    MULTIPLE_OPTIONS_CHECKOUT_BILLING: "multipleOptionsCheckOutBilling",
    TRACK_CUSTOMER_HISTORY: "trackCustomerHistory",
    SCHEDULING_APPOINTMENTS: "schedulingAppointments",
    LOYALTY: allRights.SYSTEM_LOYALTY_ACCESS,
    PRODUCT: allRights.PRODUCT_ACCESS,
    SMS_CAMPAIGN: allRights.SMS_CAMPAIGN_ACCESS,
    VOUCHER: allRights.VOUCHER_ACCESS,
    GIFT: allRights.GIFTCARD_ACCESS,

}