import { t } from "@idtek/component/lib/language";
export const modelPayment = {
    fields: {
        received: {
            label: t("modelPayment-AMOUNT_RECEIVED"),
            required: true
        },
        return: {
            label: t("modelPayment-AMOUNT_BACK"),
            required: true
        },
        voucherCode: {
            label: t("modelPayment-VOUCHER_CODE")
        },
        giftCode: {
            label: t("modelPayment-GIFT_CODE")
        },
        moneyPoint: {
            label: t("modelPayment-MONEY_POINT")
        },
        typeCheckout: {
            label: t("modelPayment-CHECKOUT_PAYMENT")
        },
        typeCheckoutTip: {
            label: t("modelPayment-METHOD_TIP")
        },
        paymentAll: {
            label: t("modelPayment-FULL_PAYMENT_BOOKING")
        },
        customerId: {
            label: t("modelPayment-PAY_FOR_CUSTOMER")
        },
        valueTip: {
            label: t("modelPayment-Value")
        },
        isHst: {
            label: t("modelPayment-HST?")
        },
        actionTime: {
            label: ""
        }
    }
}