import { t } from '@idtek/component/lib/language';

export const BookingStatusContant = {
  STATUS_NEW: 'NEW',
  STATUS_WAITING: 'Waiting',
  STATUS_CHECKIN: 'CheckIn',
  STATUS_PROCESSING: 'Processing',
  STATUS_COMPLETE: 'Completed',
  STATUS_CANCEL: 'Cancel',
  STATUS_NOSHOW: 'NoShow',
};

export const BookingStatusOption = [
  {
    label: t('BookingStatusContant-PROCESSING'),
    value: BookingStatusContant.STATUS_PROCESSING,
  },
];