import React from 'react';
import Loading from '../../pages/Loading';

export default class Overlay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            text: ''
        };
    }

    open(text) {
        this.setState({
            open: true,
            text
        });
    }

    close() {
        this.setState({
            open: false,
            text: ''
        });
    }
    render() {
        if (!this.state.open) {
            return null;
        }
        return (
            <div
                style={Object.assign({
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    background: "rgba(255,255,255,0.5)",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }, this.props.style)}
            >
                <div
                    style={Object.assign({
                        border: '1px solid #ccc',
                        zIndex: 99999,
                        background: "rgba(255,255,255,0.5)",
                    }, this.props.style)}
                >
                    <Loading backgroundColor={this.props.backgroundColor} text={this.state.text} />
                </div>
            </div>
        );
    }
}

Loading.defaultProps = {
    backgroundColor: "#fff"
};