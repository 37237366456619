import React, {useMemo} from 'react';
import {useAppointmentContext} from "../context/AppointmentContext";
import _ from "lodash";
import {
    TECHNICIAN_WORKING_TYPE_OFF,
    TECHNICIAN_WORKING_TYPE_OFF_AL
} from "../../technician/constant/TechnicianConstant";
import moment from "moment";
import {t} from "@idtek/component/lib/language";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";

const CellTemplate = ({args, onUnbreak}) => {
    const {
        technicianSchedule
    } = useAppointmentContext();
    return useMemo(() => {
        if (args.type === "workCells") {
            const formatType = "YYYY-MM-DD HH:mm";
            const techWorking = _.get(technicianSchedule[args.groupIndex], 'technicianWorkings', [])
            const timesOff = _.filter(techWorking, item => item.type === TECHNICIAN_WORKING_TYPE_OFF || item.type === TECHNICIAN_WORKING_TYPE_OFF_AL)
            if (!_.isEmpty(timesOff)) {
                const dateOfCell = moment(args.date).format("YYYY-MM-DD")
                if (dateOfCell < moment().format("YYYY-MM-DD")) {
                    const dateCell = moment(args.date).format(formatType)
                    const cellTimesheetOff = _.find(timesOff, item =>
                        moment(item.startDate).format(formatType) <= dateCell && dateCell <= moment(item.endDate).format(formatType)
                    )
                    const firstCell = _.find(timesOff, item =>
                        moment(item.startDate).format(formatType) === dateCell && dateCell < moment(item.endDate).format(formatType)
                    )
                    if (cellTimesheetOff && !firstCell) {
                        return <div className={"break-time-cell"}
                                    style={{background: "#ccc", margin: -1, height: '39px'}}>
                        </div>
                    }
                    if (cellTimesheetOff && firstCell) {
                        const noteTimeOff = cellTimesheetOff.note
                        return <div className={"break-time-cell"}
                                    style={{background: "#ccc", margin: -1, height: '39px'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{
                                    marginLeft: 6,
                                    fontSize: 9,
                                    lineHeight: "19px",
                                    fontWeight: 400
                                }}>({cellTimesheetOff.type === TECHNICIAN_WORKING_TYPE_OFF_AL ? t("Paid") : t("Unpaid")})
                            </span>
                                {noteTimeOff && <Tooltip title={noteTimeOff} placement={'bottomLeft'}>
                                    <div className={"unbreak-note"}>
                                        <div>{`${t("AppointmentRenderUtil-NOTE")}: `}</div>
                                        <div className={"note-desc"}>{noteTimeOff}</div>
                                    </div>
                                </Tooltip>}
                            </div>
                        </div>
                    }

                } else {
                    const dateCell = moment(args.date).format(formatType)
                    const cellTimesheetOff = _.find(timesOff, item =>
                        moment(item.startDate).format(formatType) === dateCell && dateCell < moment(item.endDate).format(formatType)
                    )
                    if (cellTimesheetOff) {
                        const noteTimeOff = cellTimesheetOff.note
                        return <div className={"break-time-cell"}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className={"unbreak-click"} onClick={() => onUnbreak && onUnbreak(cellTimesheetOff)}>
                                {t("AppointmentRenderUtil-UNBREAK")}
                            </span>
                                <span style={{
                                    marginLeft: 6,
                                    fontSize: 9,
                                    lineHeight: "19px",
                                    fontWeight: 400
                                }}>({cellTimesheetOff.type === TECHNICIAN_WORKING_TYPE_OFF_AL ? t("Paid") : t("Unpaid")})
                            </span>
                            </div>
                            {noteTimeOff && <Tooltip title={noteTimeOff} placement={'bottomLeft'}>
                                <div className={"unbreak-note"}>
                                    <div>{`${t("AppointmentRenderUtil-NOTE")}: `}</div>
                                    <div className={"note-desc"}>{noteTimeOff}</div>
                                </div>
                            </Tooltip>}
                        </div>

                    }
                }


            }
        }
        return ""
    }, [args.date, args.groupIndex, args.type, onUnbreak, technicianSchedule])
};

export default CellTemplate;