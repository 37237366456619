import React from 'react';
import { Button, Empty } from 'antd';
import LOG_DESKTOP from '../../src/images/lock-desktop.svg';
import FB_MESS from '../../src/images/fb-mess.svg';
import './register-feature.scss';
import Icon from "@idtek/component/lib/icon/Icon";
import { t } from "@idtek/component/lib/language";

const RegisterFeature = () => {
    return (
        <div className='register-page'>
            <div className=''>
                <Empty
                    image={LOG_DESKTOP}
                    imageStyle={{
                        height: "100%",
                    }}
                    className="empty-custom"
                    description={
                        <span>
                            {t('Upgrade your IDBeauty Price Plan to access these advanced tools and features.')}
                        </span>
                    }
                >
                    <div className='contact-admin'>
                        <Button type="primary"
                            icon={<Icon type="phone" style={{ fontSize: 22, lineHeight: "19px" }} />}
                            href="tel:+16479488678"
                            className='button-phone'
                        >{t("Call us now")}</Button>
                        <Button type="primary"
                            className='button-messenger'
                            target="_blank"
                            href="https://m.me/IDBeautysoftware">
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <div>
                                    <img alt='' src={FB_MESS} style={{ marginRight: "8px", height: "20px" }} />
                                </div>
                                <div>
                                    {t("Send to messenger")}
                                </div>
                            </div>
                        </Button>
                    </div>
                </Empty>
            </div>
        </div>
    );
};

export default RegisterFeature;

