import {useMergeState} from "@idtek/component";
import _ from "lodash";
import React, {useMemo, useState} from "react";
import {PaymentContext} from "./PaymentContext";
import useCalculatePaymentCart from "../../cart/hook/useCalculatePaymentCart";
import moment from "moment";

export const PaymentCartProviderDetail = ({
                                              children, initValue, optData, cartIds, mainCartId, branchId, initBill,
                                              isDetailPayment
                                          }) => {
    const [paymentData, setPaymentData] = useMergeState(initValue);
    const mainCart = _.find(paymentData.cartList, x => x.id === mainCartId)
    let findCus = _.find(_.get(initValue, "customerOptions"), (x) => x.value === _.get(mainCart, "customerId"));

    const [customer, setCustomer] = useState({...findCus, id: findCus ? findCus.value : ""});
    const [optionsData, setOptionsData] = useState(optData);
    const [cartIdsSelect, setCartIdsSelect] = useState(cartIds)
    const [voucher, setVoucher] = useState(null);
    const [method, setMethod] = useState(_.get(optionsData, "paymentMethods[0]"));
    const [gift, setGift] = useState(null);
    const [checkouts, setCheckouts] = useState(_.get(optionsData, 'checkOutDefault'));
    const [totalReceivedMoney, setTotalReceivedMoney] = useState(0);
    const [backMoney, setBackMoney] = useState(0);
    const [receivedObj, setReceivedObj] = useState(null);
    const [moneyPoint, setMoneyPoint] = useState(0);
    const [tips, setTips] = useState(null)
    const [technicianTip, setTechnicianTip] = useState(null)
    const [isUseHst, setIsUseHst] = useState(true)

    const [initialValues, setInitialValues] = useState({
        customerId: _.get(initValue, "booking.customerId"),
        typeCheckout: checkouts,
        typeCheckoutTip: checkouts[0],
        isHst: true
    });


    /*REGION DETAIL*/
    let paymentAll = true
    let moneyPointDetail = 0
    let typeCheckoutDetail = []
    let objInputMoney = {}
    let giftDetail = {}
    let typeCheckoutDetailTip = ""
    let tipsDetail = null
    let technicianTipDetail = []
    if (isDetailPayment) {
        const guestInBooking = _.map(_.get(paymentData, 'booking.guests'), x => x.id)
        const guestInBill = _.map(_.get(initBill, 'guests'), x => x.id)
        _.each(guestInBooking, x => {
            if (_.indexOf(guestInBill, x) < 0) {
                paymentAll = false
            }
        })

        //nhập điểm tích lũy
        if (!_.isEmpty(initBill.customerTransactions)) {
            const findTransaction = _.find(initBill.customerTransactions, x => x.billId === initBill.id)
            moneyPointDetail = findTransaction.minusAmount || 0
        }

        //loại check out
        const posActionIn = _.filter(initBill.posActions, x => x.type === "IN")
        typeCheckoutDetail = _.map(posActionIn, y => y.checkoutMethodId)

        // nhận và trả tiền
        objInputMoney.return = _.get(_.find(initBill.posActions, x => x.type === "OUT"), 'value')
        _.each(posActionIn, y => {
            objInputMoney[`received_${y.checkoutMethodId}`] = y.value
        })

        // gift
        giftDetail = _.get(initBill, 'giftCardUsed')
        //tips
        const posActionTip = _.find(initBill.posActions, x => x.type === "TIP")
        if (posActionTip) {
            typeCheckoutDetailTip = _.get(posActionTip, 'checkoutMethodId')
            tipsDetail = _.get(posActionTip, 'value')
            technicianTipDetail = posActionTip.technicianTips
        }

    }

    const resultPaymentDetail = useCalculatePaymentCart({
        guestList: paymentData.guestList,
        cartList: paymentData.cartList,
        hst: optionsData ? optionsData.hst : null,
        method: _.get(initBill, 'paymentMethod'),
        voucher: _.get(initBill, 'voucher'),
        gift: giftDetail,
        moneyPoint: moneyPointDetail,
        loyaltyType: paymentData.loyaltyType,
        objDiscount: _.get(paymentData.booking, "bookingDiscounts[0]"),
        notLoad: !isDetailPayment,
        isFromSaveBooking: paymentData.isFromSaveBooking,
        isUseHst: _.get(initBill, 'isHst')
    })
    const valueDetail = useMemo(() => ({
        initBill,
        isDetailPayment,
        optionsData,
        paymentData,
        initialValues: {
            ...objInputMoney,
            customerId: _.get(initBill, "customer.id"),
            paymentAll,
            voucherCode: _.get(initBill, "voucherCode"),
            moneyPoint: moneyPointDetail,
            typeCheckout: typeCheckoutDetail,
            billId: _.get(initBill, "id"),
            typeCheckoutTip: typeCheckoutDetailTip,
            valueTip: tipsDetail,
            isHst: _.get(initBill, "isHst"),
            actionTime: _.get(initBill, "actionTime") ? moment(initBill.actionTime).format("YYYY-MM-DD HH:mm") : moment().format("YYYY-MM-DD HH:mm"),
        },
        checkouts: typeCheckoutDetail,
        voucher: _.get(initBill, 'voucher'),
        customer: _.find(_.get(initValue, "customerOptions"), (x) => x.value === _.get(initBill, "customer.id")),
        method: _.get(initBill, 'paymentMethod'),
        setMethod,
        gift: giftDetail,
        setGift,
        resultPayment: resultPaymentDetail,
        tips: tipsDetail,
        technicianTip: technicianTipDetail,
        setCustomer,
        setCheckouts,
        setTotalReceivedMoney,
        setBackMoney,
        setReceivedObj,
        setMoneyPoint,
        isUseHst: _.get(initBill, 'isHst'), setIsUseHst
    }), [initBill, optionsData, paymentData, initialValues, voucher, customer, method, resultPaymentDetail, giftDetail, tipsDetail, technicianTipDetail, setMethod,
        setGift, setCustomer, setCheckouts, setTotalReceivedMoney, setBackMoney, setReceivedObj, setMoneyPoint, isUseHst, setIsUseHst])

    return <PaymentContext.Provider value={valueDetail}>{children}</PaymentContext.Provider>;
    //region return


};