import React, {
    useEffect,
    useMemo,
} from 'react'
import { t } from '@idtek/component/lib/language'
import _ from 'lodash'
import { useMergeState } from '@idtek/component/lib/hook/IDHook'
import { usePaymentContext } from '../../appointment/context/PaymentContext'
import SearchAndSelectComponent from '../../appointment/component/SearchAndSelectComponent'
import ApiBookingConstant from '../../appointment/constant/ApiBookingConstant'
import DetailBillCart from './DetailBillCart'
import { getDetailPaymentCart } from '../hook/useGetDetailCartPayment'
import '../../appointment/style/table-bill.scss'
import RowOptionCart from '../../appointment/component/RowOptionCart'
import { IDField } from '@idtek/form'
import moment from 'moment'
import ReactDatePickerCustom from '../../../common/component/ReactDatePickerCustom'

let firstRender = true
const LeftPaymentCart = ({}) => {
    const {
              paymentData,
              branchId,
              guestId,
              setPaymentData,
              guestIdsSelect,
              setGuestIdsSelect,
              cartIdsSelect,
              setCartIdsSelect,
              bookingId,
              isDetailPayment,
              mainCartId,
          } = usePaymentContext()

    const [state, setState] = useMergeState({
        initCart: [],
    })

    useMemo(() => {
        // componentWillMount events
        setState({
            initCart: cartIdsSelect,
        })
        firstRender = true
    }, [])

    useEffect(() => {
        if (!firstRender) {
            getDetailPaymentCart(mainCartId, cartIdsSelect, setData)
            firstRender = true
        }
        firstRender = false
    }, [cartIdsSelect])

    const setData = (data) => {
        setPaymentData(data)
    }

    //add them guest de thanh toan
    const onChangeCart = (ids) => {
        setCartIdsSelect && setCartIdsSelect(_.uniq(_.concat(state.initCart, ids)))
    }

    const customRowCart = (data) => {
        return <RowOptionCart data={data} />
    }

    const renderAddMoreCart = () => {
        const params = {
            branchId: branchId,
            exceptCart: _.isArray(cartIdsSelect) ? cartIdsSelect : _.isNull(cartIdsSelect) || _.isUndefined(cartIdsSelect) ? [] : [cartIdsSelect],
        }
        const placeholder = t('LeftPaymentCart-SEARCH_CART')
        return (<div className="combo-search-guest">
            <SearchAndSelectComponent urlSearch={ApiBookingConstant.API_SEARCH_CART} params={params}
                                      onChange={onChangeCart} placeholder={`${placeholder}...`}
                                      customRow={customRowCart} method={'post'} />
        </div>)
    }

    const onChangeDate = async (val) => {

    }

    const renderDatePicker = () => {
        const styles = {
            marginBottom: 5,
            marginTop: 10,
        }

        return <div style={styles} className={'action-time-bill'}>
            <IDField
                    name={'actionTime'}
                    readOnly={isDetailPayment}
                    showTimeSelect={true}
                    minDate={null}
                    maxDate={moment().format('YYYY/MM/DD')}
                    onChange={onChangeDate}
                    // startHour={configAppointment.startHour}
                    // endHour={moment(configAppointment.endHour, "HH:mm").add(1, 'minutes').format("HH:mm")}
                    timeWork={8}
                    widthLabel={0}
                    hideError={true}
            >
                <ReactDatePickerCustom />
            </IDField>
        </div>
    }
    return (<div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }}>
        {renderDatePicker()}
        <DetailBillCart />
    </div>)
}

export default LeftPaymentCart