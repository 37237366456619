import React, {useContext} from "react";

export const PaymentContext = React.createContext({
    paymentData: {
        booking: {},
        guest: {},
        guestList: [],
        cartList: [],
        subTotal: 0,
        subBooking: 0,
        loyaltyType: "",
        isFromSaveBooking: false
    },
    initialValues: {},
    optionsData: {
        paymentMethods: [],
        checkoutMethods: [],
        hst: ""
    },
    customer: null,
    voucher: null,
    method: null,
    gift: null,
    checkouts: null,
    totalReceivedMoney: 0,
    backMoney: 0,
    receivedObj: null,
    moneyPoint: 0,
    isDetailPayment: false,
    setGuestIdsSelect: (data) => {
    },
});


export const usePaymentContext = () => useContext(PaymentContext);
