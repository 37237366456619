import React, { forwardRef, useState, useMemo, useCallback } from 'react';
import Icon from '@idtek/component/lib/icon/Icon';
import '../style/group-title.scss';
import { t, Tooltip } from '@idtek/component/lib';

const GroupTitle = forwardRef((props, ref) => {
    const [collapsible, setCollapsible] = useState(false);
    const { actionType, user, timeAction, children, index } = props;

    const onCollapse = useCallback(() => {
        setCollapsible(!collapsible);
        const wrapContentDiv = document.getElementById(`wrapContent${index}`);
        if (!collapsible) {
            const contentText = document.querySelector(`.content${index}`);
            wrapContentDiv.style.height = contentText.clientHeight + 10 + "px";
        }
        else {
            wrapContentDiv.style.height = 0;

        }
    }, [collapsible]);

    const contentChildrent = () => {
        return (
            <div className={`content${index} box-content-history`}>
                {children}
            </div>
        );
    }

    const component = useMemo(() => {
        return (
            <>
                <div className="group-title" >
                    <Tooltip title={collapsible ? t("GroupTitle-HIDE_HISTORY") : t("GroupTitle-SHOW_MORE_HISTORY")}>
                        <div className="title-label-group" onClick={onCollapse}>
                            <div className='title-content'>
                                <div className='text-title action-type'>
                                    {actionType.toUpperCase()}
                                </div>
                                <div className='text-title user-name'>
                                    {user}
                                </div>
                                <div className='text-title time-action-history'>
                                    {timeAction}
                                </div>
                            </div>
                            <div>
                                <Icon type={!collapsible ? "down" : "up"} className="icon-collapse" />
                            </div>
                        </div>
                    </Tooltip>
                    <div className="wrap-content" id={`wrapContent${index}`}>
                        {contentChildrent()}
                    </div>
                </div>
            </>
        );
    }, [children, collapsible, onCollapse, actionType, user, timeAction]);

    return component;
});

GroupTitle.defaultProps = {};

export default GroupTitle;