import React, { useEffect } from 'react';
import { useMergeState } from "@idtek/component/lib";
import { Loading } from "@idtek/component";
import Button from "@idtek/component/lib/button/IDButton";
import _ from "lodash";
import Icon from '@idtek/component/lib/icon/Icon';
import { t } from '@idtek/component/lib/language';
import GridBookingHistoryTemplate from './../../modules/appointment/component/GridBookingHistoryTemplate';
import AppUtil from './../../utils/AppUtil';
import TimeLineItem from './components/TimeLineItem';
import { BOOKING_TYPE } from '../../modules/posTransaction/constant/BookingConstant';

const GridHistoryBooking = ({ id, onClose }) => {
    const [state, setState] = useMergeState({
        data: [], loading: true
    });

    useEffect(() => {
        loadData(id);
    }, [id]);


    const loadData = async (id) => {
        const res = await AppUtil.getApi(`/action-log/get-history-booking`, { id: id });
        if (_.get(res, "data.success")) {
            setState({
                data: _.get(res, "data.result"),
                loading: false
            });
        }
    }

    const renderHistory = () => {
        return _.map(state.data, (x, index) => <TimeLineItem data={x} index={index} type={BOOKING_TYPE} isBooking component={GridBookingHistoryTemplate} />)
    }

    if (state.loading) {
        return <Loading style={{ height: 300 }} />
    }
    return (<div>
        {renderHistory()}
        <div className='flex justify-center'>
            <Button className="global-flex-align-center btn-secondary"
                onClick={onClose}
                style={{ borderRadius: 3 }}
                icon={<Icon type="close-square"
                    style={{ fontSize: 16, marginBottom: 3 }} />}>
                {t("BaseForm-CLOSE")}
            </Button>
        </div>
    </div>)
};


export default GridHistoryBooking;