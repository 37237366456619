import React, { Component } from "react";
import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import Grid from "@idtek/grid/lib/grid/Grid";
import _ from "lodash";
import Icon from "@idtek/component/lib/icon/Icon";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import { t } from "@idtek/component";
import { AddCartFormContext } from "../context/AddCartFormContext";
import AppUtil from "../../../utils/AppUtil";

class GridDetailGuest extends Component {
  static contextType = AddCartFormContext;

  constructor(props) {
    super(props);
    this.girdStore = new BaseStore({
      data: [],
      autoLoad: true,
    });
    this.columnDefs = [
      {
        headerName: t("GridDetailGuest-NO"),
        field: "stt",
        textAlign: "center",
        width: 50,
        rowSpan: function (params) {
          if (params.data && params.data.rowSpan > 1) {
            return params.data.rowSpan
          } else {
            return 1
          }
        },
        sortable: false,
      },
      {
        headerName: t("GridDetailGuest-BOOKING"),
        field: "bookingCode",
        width: 120,
        rowSpan: function (params) {
          if (params.data && params.data.rowSpan > 1) {
            return params.data.rowSpan
          } else {
            return 1
          }

        },
        colSpan: function (params) {
          if (params.data && params.data.colSpan > 1) {
            return params.data.colSpan
          } else {
            return 1
          }
        },
      },
      {
        headerName: t("GridDetailGuest-GUEST"),
        field: "guestName",
        minWidth: 120,
        rowSpan: function (params) {
          if (params.data && params.data.rowSpanGuest > 1) {
            return params.data.rowSpanGuest
          } else {
            return 1
          }

        },
      },
      {
        headerName: t("GridDetailGuest-SERVICE_NAME"),
        field: "serviceName",
        minWidth: 120,
        rowSpan: function (params) {
          if (params.data && params.data.rowSpanService > 1) {
            return params.data.rowSpanService
          } else {
            return 1
          }
        },
      },
      {
        headerName: t("GridDetailGuest-TECHNICIAN"),
        field: "technicianName",
        width: 100,
        // rowSpan: function (params) {
        //     if (params.data && params.data.rowSpanTech > 1) {
        //         return params.data.rowSpanTech
        //     } else {
        //         return 1
        //     }
        // },
      },
      {
        headerName: t("GridDetailGuest-START_TIME"),
        field: "startTime",
        width: 150,
        format: {
          type: "date",
          text: AppUtil.GLOBAL_DATETIME_FORMAT
        },
        rowSpan: function (params) {
          if (params.data && params.data.rowSpanService > 1) {
            return params.data.rowSpanService
          } else {
            return 1
          }
        },
      },
      {
        headerName: t("GridDetailGuest-PRICE"),
        field: "price",
        width: 80,
        textAlign: 'right',
        rowSpan: function (params) {
          if (params.data && params.data.rowSpanService > 1) {
            return params.data.rowSpanService
          } else {
            return 1
          }
        },
      },
      // {
      //     headerName: "Action",
      //     field: "action",
      //     width: 60,
      //     textAlign: 'right',
      //     rowSpan: function (params) {
      //         if (params.data && params.data.rowSpanGuest > 1) {
      //             return params.data.rowSpan
      //         } else {
      //             return 1
      //         }
      //
      //     },
      // }
    ]
    this.heightGrid = "auto"
    this.minHeightGrid = "0px"
    this.cellRenderer = {
      price: {
        renderer: (data, value, rowNode) => {
          if (data.field === 'pinned') {
            return <div className="global-flex-align-center-justify-end">
              $ {_.toNumber(this.context.totalAmountGuest).toFixed(2)}
            </div>
          }
          return (
            <div className="global-flex-align-center-justify-end">
              $ {value.toFixed(2)}
            </div>
          );
        }
      },
      action: {
        renderer: (data, value, rowNode) => {
          if (data.field !== "pinned" && data.guestId !== this.props.guestId) {
            return (
              <div className="global-flex-justify-align-center">
                <Tooltip title={t("Remove guest")}>
                  <a className={"action-btn action-btn-delete"}
                    onClick={() => this.props.onRemoveGuest && this.props.onRemoveGuest(data.guestId)}>
                    <Icon type={"delete"} style={{ fontSize: 15, color: "#fff" }} />
                  </a>
                </Tooltip>

              </div>
            );
          }
          return null
        }
      }

    }
  }

  componentDidMount() {
    this.setData(_.get(this.context, 'segmentList'))
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setData(_.get(nextContext, 'segmentList'))
  }

  setData(data) {
    this.myGrid && this.girdStore.setData(data)
    this.onGridReady()
  }

  onGridReady = () => {
    if (this.myGrid) {
      const pinned = [{
        stt: "",
        bookingCode: "Total",
        guestName: "",
        technicianName: "",
        price: this.context.totalAmountGuest,
        field: "pinned",
        colSpan: 5
      }]
      this.myGrid.gridApi && this.myGrid.gridApi.setPinnedBottomRowData(pinned);
    }
  };

  render() {
    return (
      <div>
        <Grid
          ref={grid => (this.myGrid = grid)}
          // className="grid-type-list"
          height={this.heightGrid}
          minHeight={this.minHeightGrid}
          columnDefs={this.columnDefs}
          cellRenderer={this.cellRenderer}
          onGridReady={
            this.onGridReady
          }
          defaultColDef={{
            sortable: false,
            filter: false,
          }}
          store={this.girdStore}
          floatingFilter={false}
          globalFilter={false}
          suppressRowClickSelection
          actionRows={{}}
          rowHeight={28}
          headerVerticalLines={false}
          columnLines={true}
          headerTransparent={true}
          pagination={false}
          numberRows={false}
        />
      </div>
    );
  }
}

export default GridDetailGuest;