import React, { PureComponent } from 'react';

export default class ContentView extends PureComponent {
    render() {
        const { background, height, padding, boxShadow, paddingTop, style } = this.props;
        return (
            <div className="body-page" style={Object.assign({ height: height, paddingTop: 0 }, style)}>
                <div className="content-body-page"
                    style={{ backgroundColor: background, padding, boxShadow, paddingTop: paddingTop }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

ContentView.defaultProps = {
    showIcon: false,
    iconCls: "fa fa-file-text-o",
    background: '#fff',
    height: 'auto',
    padding: '10px',
    paddingTop: '10px',
    boxShadow: '0 1px 15px 1px rgba(39, 39, 39, 0.1)',
};