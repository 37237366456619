/* eslint-disable react-hooks/exhaustive-deps */

import { t } from "@idtek/component/lib/language";
import { TextField } from "@idtek/form/lib";
import _ from "lodash";
import React, { useEffect } from "react";
import { usePaymentContext } from "../context/PaymentContext";

const DiscountLoyalty = ({ formRef }) => {
    const { setMoneyPoint, paymentData, resultPayment, customer, moneyPoint } = usePaymentContext();

    useEffect(() => {
        const maxValue = getMaxPoint();
        if (maxValue < moneyPoint) {
            formRef.current && formRef.current.setValueField("moneyPoint", maxValue, true);
        }
    }, [resultPayment]);

    const rowTitleLoyalty = () => {
        return <div className="total">
            <div className="icon-gift">
                <i class="fa fa-id-card" aria-hidden="true"></i>
            </div>
            <span className="title">
                {t("DiscountLoyalty-LOYALTY_DISCOUNT")}
            </span>
        </div>;
    };

    const getMaxPoint = () => {
        const { valueLimitMoneyPoint } = resultPayment;
        return _.min([customer.amountMoney, valueLimitMoneyPoint > 0 ? valueLimitMoneyPoint.toFixed(2) : 0]);
    };

    const renderLoyalty = () => {
        if (paymentData.loyaltyType === "POINT_BASED") {
            if (customer.amountMoney > 0) {
                return (
                    <>
                        {rowTitleLoyalty()}
                        <div className="money-point">
                            <div
                                className="point-info">{`*${t("DiscountLoyalty-CUSTOMER_HAVE")} $${customer.amountMoney} ${t("DiscountLoyalty-ACCUMULATED_MONEY")}`}</div>
                            <div className="text-field">
                                <TextField
                                    // readOnly={initialValues.moneyPoint && initialValues.moneyPoint > 0 ? true : false}
                                    onChange={(val) => setMoneyPoint(val ? parseFloat(val) : 0)}
                                    max={parseFloat(getMaxPoint())}
                                    min={0.1}
                                    className="back-money"
                                    name="moneyPoint"
                                    type="money"
                                    options={{
                                        numeralPositiveOnly: true
                                    }}
                                />
                            </div>
                        </div>
                    </>
                );

            }
        } else if (paymentData.loyaltyType === "BOOKING_BASED") {
            const discountObj = _.get(paymentData.booking, 'bookingDiscounts[0]');
            const { textRowLoyalty, valueRowLoyalty } = resultPayment;
            if (discountObj) {
                return (
                    <>
                        {rowTitleLoyalty()}
                        <div className="final-caculate discount-loyalty">
                            <div className="sub-total" style={{ paddingLeft: 10 }}>
                                <div className="title-final">{textRowLoyalty}</div>
                                <div className="value-final">$ -{valueRowLoyalty && valueRowLoyalty.toFixed(2)}</div>
                            </div>
                        </div>
                    </>
                );
            }
        }
    };
    return (
        <div>
            {renderLoyalty()}
        </div>
    );
};

export default DiscountLoyalty;