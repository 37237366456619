import React, {useEffect, useMemo, useRef} from 'react';
import {usePaymentContext} from "../context/PaymentContext";
import '../style/table-bill.scss'
import {t} from "@idtek/component/lib/language";
import DetailBill from "./DetailBill";
import SearchAndSelectComponent from "./SearchAndSelectComponent";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import _ from "lodash";
import RowOptionGuest from "./RowOptionGuest";
import {useMergeState} from "@idtek/component/lib/hook/IDHook";
import {getDetailBill, getDetailToInitBill} from "../hook/useGetDetailPayment";
import {IDField} from "@idtek/form";
import moment from "moment";
import ReactDatePickerCustom from "../../../common/component/ReactDatePickerCustom";

let firstRender = true;
const LeftPaymentV2 = ({}) => {
    const {
        paymentData,
        branchId,
        guestId,
        setPaymentData,
        guestIdsSelect,
        setGuestIdsSelect,
        cartIdsSelect,
        setCartIdsSelect,
        bookingId,
        isDetailPayment,
        backMoney,
        billId,
        initialValues
    } = usePaymentContext();

    const refSearchAndSelectComponent = useRef(null)

    const [state, setState] = useMergeState({
        initGuest: [],
        initCart: [],
        dateBill: moment(initialValues.actionTime).format("YYYY-MM-DD")
    });    

    useMemo(() => {
        // componentWillMount events
        setState({
            initCart: cartIdsSelect,
            initGuest: guestIdsSelect
        });
        firstRender = true;
    }, []);

    useEffect(() => {
        if (!firstRender) {
            if (billId) {
                getDetailBill(billId, state, setData);

            } else {
                getDetailToInitBill(guestIdsSelect, cartIdsSelect, bookingId, billId, state, setData);

            }
            firstRender = true;

        }
        firstRender = false;
    }, [guestIdsSelect, cartIdsSelect]);

    const setData = (data) => {
        setPaymentData(data);
    };

    //add them guest de thanh toan
    const onChangeGuest = (ids) => {
        setGuestIdsSelect && setGuestIdsSelect(_.uniq(_.concat(state.initGuest, ids)));
    };

    const customRowGuest = (data) => {
        return <RowOptionGuest data={data}/>;
    };

    const renderAddMoreGuest = () => {
        const params = {
            branchId: branchId,
            exceptGuest: _.isArray(guestId) ? guestId : _.isNull(guestId) || _.isUndefined(guestId) ? [] : [guestId],
            dateBill: moment(state.dateBill).format("YYYY-MM-DD")
        };
        const placeholder = t("LeftPaymentV2-SEARCH_GUEST");
        return (
            <div className="combo-search-guest">
                <SearchAndSelectComponent ref={refSearchAndSelectComponent}
                                          urlSearch={ApiBookingConstant.API_SEARCH_GUEST} params={params}
                                          onChange={onChangeGuest} placeholder={`${placeholder}...`}
                                          customRow={customRowGuest} method={"post"}/>
            </div>
        );
    };
    const onChangeDate = async (val) => {
        setState({dateBill: val})
        refSearchAndSelectComponent.current && refSearchAndSelectComponent.current.onReSearch()
    };
    const renderDatePicker = () => {
        const styles = {
            marginTop: 10
        }
        if (isDetailPayment) {
            styles.marginTop = 10
        }
        return <div style={styles} className={"action-time-bill"}>
            <IDField
                name={"actionTime"}
                readOnly={isDetailPayment}
                showTimeSelect={true}
                minDate={null}
                maxDate={moment().format("YYYY/MM/DD")}
                onChange={onChangeDate}
                // startHour={configAppointment.startHour}
                // endHour={moment(configAppointment.endHour, "HH:mm").add(1, 'minutes').format("HH:mm")}
                timeWork={8}
                widthLabel={0}
                hideError={true}
                style={{marginBottom: 0}}
            >
                <ReactDatePickerCustom/>
            </IDField>
        </div>
    }
    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            {renderDatePicker()}
            {!isDetailPayment && <>
                {renderAddMoreGuest()}
            </>}

            <DetailBill/>
        </div>

    );
  };


export default LeftPaymentV2;