import Form from "@idtek/form/lib/IDForm";
import _ from "lodash";
import React, {Component} from "react";
import Loading from "../../pages/Loading";
import AppUtil from "../../utils/AppUtil";
import Icon from "@idtek/component/lib/icon/Icon";
import {t} from "@idtek/component";

export default class BaseForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: props.initialValues
        };
    }

    isCreate = this.props.isCreate;
    initialValues = this.props.initialValues;
    model = this.props.model;
    formDefaults = this.props.formDefaults;
    apiCreate = "";
    apiUpdate = "";
    apiDetail = "";
    buttons = [
        {
            text: t("BaseForm-SAVE"),
            className: "btn-success",
            type: "submit",
            icon: <Icon type="save" style={{fontSize: 16}}/>,
            onPress: (form, values) => this.onSave(form, values)
        },
        {
            text: t("BaseForm-CLOSE"),
            className: "btn-secondary",
            icon: <Icon type="close-square" style={{fontSize: 16}}/>,
            onPress: () => this.props.onClose()
        }
    ];

    componentDidMount() {
        const {initialValues} = this.state;
        if (_.get(initialValues, "id")) {
            this.loadData(initialValues.id);
        } else {
            this.setState({
                loading: false
            });
        }
    }

    async loadData(id) {
        const res = await AppUtil.getApi(`${this.apiDetail}/${id}`, {});
        if (_.get(res, "data.success")) {
            const values = await this.beforeSetValues(_.get(res.data, "result"));
            this.setState({
                initialValues: Object.assign(this.initialValues, values),
                loading: false
            });
            this.afterSetValues(values);
        }
    }

    onSave = async (form, values) => {
        form && form.mask();
        let url = "";
        if (this.isCreate) {
            url = this.apiCreate;
        } else {
            values.id = _.get(this.initialValues, "id");
            url = this.apiUpdate;
        }
        values = this.beforeSave(form, values);
        const res = await AppUtil.postApi(url, values);
        this.afterSave(form, res);
        form && form.unmask();
        if (res) {
            if (!_.get(res.data, "success")) {
                AppUtil.ToastApiError();
            } else {
                AppUtil.ToastSuccess();
                this.props.onClose && this.props.onClose(this.isCreate, _.get(res.data, "result"));
            }
        } else {
            AppUtil.ToastApiError();
        }
    };

    beforeSave(form, values) {
        return values;
    }

    afterSave(form, res) {
        const data = form && form.getValues();
        if (_.get(data, "id")) {
            // const log = LogUtil.createActionLog(this.entityType, "Cập nhật", _.get(res, "results.data[this.keyID]"), this.functionName || this.titleUpdate);
            // LogUtil.writeActionLog(log);
        } else {
            // const log = LogUtil.createActionLog(this.entityType, "Tạo mới", _.get(res, "results.data[this.keyID]"), this.functionName || this.titleCreate);
            // LogUtil.writeActionLog(log);
        }
    }

    beforeSetValues = async (values) => {
        return values;
    };

    afterSetValues = (values) => {
    };

    onMask() {
        this.form && this.form.mask();
    }

    onUnMask() {
        this.form && this.form.unmask();
    }

    renderBody() {
    }

    render() {
        const {loading, initialValues} = this.state;
        if (loading) return <Loading/>;
        return (
            <Form
                ref={(component) => (this.form = component)}
                initialValues={initialValues}
                model={this.model}
                className="base-form-modal"
                style={{padding: 15}}
                buttons={this.buttons}
                defaults={this.formDefaults}>
                {this.renderBody()}
            </Form>
        );
    }
}
