import {Tag} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, {Component, Suspense,} from 'react'
import AppUtil from '../../../utils/AppUtil'
import '../style/pos-actions-detail.scss'
import IDDatePicker from '@idtek/component/lib/datetime/IDDatePicker'
import Overlay from '../../../common/component/Overlay'
import {RECEIVE_MONEY, TAKE_MONEY,} from '../constant/BookingConstant'
import CountUp from 'react-countup'
import AppStore from '../../../store'
import ItemTransaction from '../component/ItemTransaction'
import {Loading, Modal,} from '@idtek/component'
import PaymentDetailContainer from '../../appointment/view/PaymentDetailContainer'
import {t} from '@idtek/component/lib/language'
import CartPaymentDetailContainer from '../../cart/view/CartPaymentDetailContainer'
import BookingDetailApiForm from '../../booking/form/BookingDetailApiForm'

const {CheckableTag} = Tag
export default class PosTransactionDetailView extends Component {
    constructor(props) {
        super(props)
        this.fromDate = moment()
        // this.toDate = moment();
        this.state = {
            data: [],
            optionFilter: [{
                id: 'all',
                name: 'ALL',
                code: 'all',
            }],
            selectedTags: ['all'],
            groupBooking: [],
        }
        this.selectedTags = ['all']
    }

    componentDidMount() {
        this.refOverlay && this.refOverlay.open(<span>
            {t('PosTransactionDetailView-PLEASE_WAIT')}
        </span>)
        this.loadCombox()
    }

    async loadData() {
        this.refOverlay && this.refOverlay.open(<span>
            {t('PosTransactionDetailView-PLEASE_WAIT')}
        </span>)
        const {selectedTags} = this
        const {
            optionFilter,
            dataMethod,
        } = this.state
        const params = {
            branchId: _.get(AppStore.getState(), `root.branch.id`, null),
        }

        if (this.fromDate) {
            params.fromDate = this.fromDate
        }

        if (_.size(selectedTags) > 0 && _.indexOf(selectedTags, 'all') < 0) {
            const typeArr = _.filter(optionFilter, (opt) => _.indexOf(selectedTags, opt.id) >= 0)
            params.checkoutIds = _.map(typeArr, (x) => x.id)
        } else {
            params.checkoutIds = _.map(dataMethod, x => x.id)
        }
        const res = await AppUtil.postApi('/report/transaction-by-date', params)
        if (_.get(res, 'data.success')) {
            const data = _.get(res, 'data.result')
            this.setState({dataTransaction: _.orderBy(data, ['actionTime'], ['desc'])})
            this.refOverlay && this.refOverlay.close()
        } else {
            AppUtil.ToastApiError()
            this.refOverlay && this.refOverlay.close()
        }
    }

    async loadCombox() {
        const {optionFilter} = this.state
        const res = await AppUtil.getApi('/checkout-method/get-combo')
        if (_.get(res, 'data.success')) {
            const data = _.get(res, 'data.result')
            let options = optionFilter
            if (data) {
                options = _.concat(optionFilter, data)
            }
            this.setState({
                optionFilter: options,
                dataMethod: _.get(res, 'data.result'),
            })
            this.loadData()
        }
    }

    async onChangeDate(date) {
        if (date === 'today') {
            this.fromDate = moment().format('YYYY-MM-DD')
        } else {
            this.fromDate = date
        }
        this.loadData()
    }

    handleChange(tag, checked) {
        const {selectedTags} = this.state
        const selectedTagsNotAll = _.filter(selectedTags, (x) => x !== 'all')
        let nextSelectedTags = []
        if (tag !== 'all') {
            nextSelectedTags = checked ? [...selectedTagsNotAll, tag] : selectedTagsNotAll.filter((t) => t !== tag)
        } else {
            nextSelectedTags = checked ? [tag] : selectedTags.filter((t) => t !== tag)
        }
        if (_.isEmpty(nextSelectedTags)) {
            nextSelectedTags = ['all']
        }
        this.setState({selectedTags: nextSelectedTags})
        this.selectedTags = nextSelectedTags
        this.loadData()
    }

    onCloseDefault = () => {
    }
    onDetailBooking = (bookingId, code) => {
        this.Modal.onOpen(<Suspense fallback={<Loading style={{height: 300}}/>}>
            <BookingDetailApiForm
                onClose={(flag, dataRow) => {
                    this.Modal && this.Modal.onClose()
                }}
                ref={(component) => (this.refBaseForm = component)}
                formDefaults={{
                    alignLabel: 'left',
                    widthLabel: '100px',
                    readOnly: true,
                }}
                model={{
                    fields: {
                        customerName: {
                            label: t('PosTransactionDetailView-CUSTOMER'),
                            required: true,
                        },
                        startTime: {
                            label: t('PosTransactionDetailView-START_TIME'),
                            required: true,
                        },
                        technicians: {
                            label: t('PosTransactionDetailView-MAIN_TECHNICIAN'),
                        },
                        endTime: {
                            label: t('PosTransactionDetailView-END_TIME'),
                            required: true,
                        },
                        'customer.phone': {
                            label: t('PosTransactionDetailView-PHONE'),
                            required: true,
                        },
                        description: {
                            label: t('PosTransactionDetailView-NOTE'),
                        },
                        'customer.email': {
                            label: t('PosTransactionDetailView-EMAIL'),
                        },
                    },
                }}
                bookingId={bookingId}/>
        </Suspense>, AppUtil.renderTitleForm(`${t('PosTransactionDetailView-DETAIL_BOOKING')} ${code}`), '850px')
        this.refBaseForm && this.refBaseForm.unmask()
    }

    onDetailBill = (billId, bookingIds) => {
        this.Modal && this.Modal.onOpen(<PaymentDetailContainer
                onClose={() => this.Modal && this.Modal.onClose()}
                onReloadData={() => {
                }}
                bookingId={bookingIds[0]}
                billId={billId}
                branchId={_.get(AppStore.getState(), `root.branch.id`, null)}/>,
            <span>{t('PosTransactionDetailView-DETAIL_PAYMENT')}</span>, '1000px')
    }

    onDetailOnlyCart = (billId, carts) => {
        const branchId = _.get(AppStore.getState(), `root.branch.id`, null)
        const cart = carts[0]
        if (!cart) return
        this.Modal.onOpen(<Suspense fallback={<Loading style={{height: 300}}/>}>
            <CartPaymentDetailContainer
                onClose={() => this.Modal.onClose()}
                onReloadData={() => {
                }}
                // bookingId={row.bookingId}
                billId={billId}
                branchId={branchId}
                mainCartId={cart.id}
                cartIds={[cart.id]}
                customerIdCart={cart.customerId}
            />
        </Suspense>, <span>{t('PosTransactionDetailView-DETAIL_PAYMENT')}</span>, '1000px')
    }

    renderListActions() {
        const {dataTransaction} = this.state
        return _.map(dataTransaction, item => {
            return <ItemTransaction data={item} onDetailBill={this.onDetailBill}
                                    onDetailOnlyCart={this.onDetailOnlyCart} onDetailBooking={this.onDetailBooking}/>
        })
    }

    renderTag() {
        const {
            optionFilter,
            selectedTags,
        } = this.state
        return _.map(optionFilter, (tag) => {
            return (<CheckableTag key={tag.id} checked={selectedTags.indexOf(tag.id) > -1}
                                  onChange={(checked) => this.handleChange(tag.id, checked)} color={'#026eaa'}>
                {tag.code}
            </CheckableTag>)
        })
    }

    renderInfoReport() {
        const {dataTransaction} = this.state
        const actionReceived = _.filter(dataTransaction, (x) => x.type === RECEIVE_MONEY)
        const actionTake = _.filter(dataTransaction, (x) => x.type === TAKE_MONEY)
        const fullPosAction = [].concat.apply([], _.filter(_.map(dataTransaction, x => x.posAction), y => y))
        const fullReceived = _.concat(_.filter(fullPosAction, x => x.type === RECEIVE_MONEY), actionReceived)
        const fullTake = _.concat(_.filter(fullPosAction, x => x.type === TAKE_MONEY), actionTake)
        const sumReceived = _.sumBy(fullReceived, (x) => x.value)
        const sumTake = _.sumBy(fullTake, (x) => x.value)
        const total = sumReceived - sumTake
        return (<div className="report">
            <div className="title">
                {t('PosTransactionDetailView-OVERVIEW')}
            </div>
            <div className="report-money">
                <div className="report-recieved">
                    <div className="value">
                        <span className="dollar">$</span>
                        <CountUp separator="," preserveValue={true} duration={0.5} decimals={2}
                                 end={sumReceived}/>
                    </div>
                    <div className="description">
                        {t('PosTransactionDetailView-AMOUNT_RECEIVED')}
                    </div>
                </div>
                <div className="report-take">
                    <div className="value">
                        <span className="dollar">$</span>
                        {sumTake > 0 && <span className="icon">-</span>}
                        <CountUp separator="," preserveValue={true} duration={0.5} decimals={2} end={sumTake}/>
                    </div>
                    <div className="description">
                        {t('PosTransactionDetailView-AMOUNT_BACK')}
                    </div>
                </div>
            </div>
            <div className="total-rest">
                <div className="value">
                    <span className="dollar">$</span>
                    <CountUp separator="," preserveValue={true} duration={0.5} decimals={2} end={total}/>
                </div>
                <div className="des-total">
                    {t('PosTransactionDetailView-REMAIN_BALANCE')}
                </div>
            </div>
        </div>)
    }

    renderOverlay() {
        return (<Overlay
            ref={(component) => (this.refOverlay = component)}
            style={{
                padding: 15,
                borderRadius: 3,
                zIndex: 9,
            }}
        />)
    }

    renderModalPayment = () => {
        return <Modal draggable ref={c => this.Modal = c} onClose={this.onCloseDefault()} maskClosable={false}
                      className="popup-crud popup-container popup-method"/>
    }

    render() {
        return (<div className="pos-actions-detail">
            <div className="filter">
                <div className="filter-date">
                        <span className="date">
                            {t('PosTransactionDetailView-DATE')}
                        </span>
                    <div className="today">
                        <button style={{fontSize: 12}} onClick={() => this.onChangeDate('today')}
                                className="btn-date-filer today-date">
                            {t('PosTransactionDetailView-TODAY')}
                        </button>
                    </div>
                    <div className="date-range">
                        <IDDatePicker ref={(c) => (this.Date = c)} isClearable={false}
                                      format={AppUtil.GLOBAL_DATE_FORMAT}
                                      onChange={this.onChangeDate.bind(this)}
                                      value={this.fromDate}/>
                    </div>
                </div>
                <div className="filter-action">
                        <span className="date action-label" style={{marginTop: 10}}>
                            {t('PosTransactionDetailView-TYPE')}
                        </span>
                    <div className="tab-filter">{this.renderTag()}</div>
                </div>
            </div>
            {this.renderInfoReport()}
            <div className="list-actions">
                {/* <div className="header"></div> */}
                <div className="actions">{this.renderListActions()}</div>
            </div>
            {this.renderOverlay()}
            {this.renderModalPayment()}
        </div>)
    }
}
