import React from 'react';
import _ from "lodash";
import { t } from '@idtek/component/lib/language';

const InfoBooking = ({booking}) => {
    const style = {fontWeight: 600, marginLeft: 5};
    if (!booking) return <></>
    return (
        <div className="info-top">
            <div>
                <span className={"title"}>{t("InforBooking-BOOKING")}: </span>
                <span style={style}>{booking.code}</span>
            </div>
            <div>
                <span className={"title"}>{t("InforBooking-CUSTOMER")}: </span>
                <span style={style}>{booking.customerName}</span>
            </div>
            <div>
                <span className={"title"}>{t("InforBooking-CUSTOMER")}: </span>
                <span style={style}>{_.get(booking, "customer.phone")}</span>
            </div>
        </div>
    );

};

export default InfoBooking;