import React from 'react';
import BookingDetailForm from "./BookingDetailForm";
import AppUtil from "../../../utils/AppUtil";
import _ from "lodash";
import moment from "moment";

class BookingDetailApiForm extends BookingDetailForm {
    componentDidMount() {
        this.loadData(this.props.bookingId)
    }

    beforeSetValues = (values) => {
        let newEmailCustomer = _.get(values,"customer.email", "");
        if(newEmailCustomer){
            newEmailCustomer = newEmailCustomer.replaceAll(',',', ');
            values.customer.email = newEmailCustomer;
        }        
        values.startTime = values.startTime ? moment(values.startTime).format(AppUtil.GLOBAL_DATETIME_FORMAT) : "";
        values.endTime = values.endTime ? moment(values.endTime).format(AppUtil.GLOBAL_DATETIME_FORMAT) : "";
        return values;
    }

    async loadData(id) {
        const res = await AppUtil.getApi(`${this.apiDetail}/${id}`)
        if (_.get(res, 'data.success')) {
            const values = this.beforeSetValues(_.get(res, 'data.result'));
            this.setState({
                initialValues: values,
                loading: false,
            });
        }
    }
}

export default BookingDetailApiForm;