import React from 'react'
import { t } from '@idtek/component/lib/language';
import _ from 'lodash';
import { CANCEL_ACTION } from '../../../common/history/constant/HistoryConstant';

const BillHistoryTemplate = (data, className) => {
    const editInfo = data.editInfo;
    const { action } = data
    const renderCancelBill = () => {
        return <>
            <div className={`grid grid-cols-3`}>
                {/* <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-CREATED_BY")}:
                        </div>
                        <div className='value'>
                            {_.get(data, "user.fullName", "")}
                        </div>
                    </div>
                </div> */}
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("BillTemplateForm-BILL")}:
                        </div>
                        <div className='value'>
                            {_.get(editInfo, "code", "")}
                        </div>
                    </div>
                </div>
                {/* <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("DetailBill-SUB_TOTAL")}:
                        </div>
                        <div className='value'>
                            {_.get(editInfo, "subTotal", "")}$
                        </div>
                    </div>
                </div> */}
                <div className='col-span-2'>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("CustomerForm-NOTE")}:
                        </div>
                        <div className='value'>
                            {t("BillHistoryTemplate_BOOKING_CHANGE_STATUS_COMPLETE_TO_PROCESSING")}
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
    const renderCreateBill = () => {
        return (
            <>
                <div className={`grid grid-cols-3`}>
                    {/* <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("TechnicianListView-CREATED_BY")}:
                            </div>
                            <div className='value'>
                                {_.get(editInfo, "userCreated", "")}
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("BillTemplateForm-BILL")}:
                            </div>
                            <div className='value'>
                                {_.get(editInfo, "code", "")}
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("DetailBill-SUB_TOTAL")}:
                            </div>
                            <div className='value'>
                                {_.get(editInfo, "subTotal", "")}$
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("DetailBill-DISCOUNT")}:
                            </div>
                            <div className='value'>
                                ${_.get(editInfo, "discount", "")}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("BranchConstant-TAX")}:
                            </div>
                            <div className='value'>
                                ${_.get(editInfo, "tax", "")}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("ReportSummaryListView-TOTAL_BILL")}:
                            </div>
                            <div className='value'>
                                ${_.get(editInfo, "total", '')}
                            </div>
                        </div>
                    </div>
                </div>
            </>)
    }

    return <>{action === CANCEL_ACTION ? renderCancelBill() : renderCreateBill()}</>
}
export default BillHistoryTemplate;