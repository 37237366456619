import Icon from "@idtek/component/lib/icon/Icon";
import _ from "lodash";
import moment from "moment";
import React, { useMemo, useRef } from "react";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import { useAppointmentContext } from "../context/AppointmentContext";
import BookingForm from "../form/BookingForm";
import { t } from "@idtek/component/lib/language";

const BookingCreateView = ({ onClose, onReloadData, onCheckReloadPage }) => {
    const { branchId, setTechnicianData } = useAppointmentContext();
    const refForm = useRef(null);
    const fields = ["customer", "startTime", "customerName", "email", "branchId", "technicianId", "block", "isReOrder", "color", "note"];
    const buttons = [
        {
            text: t("BookingCreateView-SAVE"),
            type: "submit",
            className: "btn-success",
            icon: <Icon type="save" style={{ fontSize: 16 }} />,
            onPress: (form, values) => onSave(form, values)
        },
        {
            text: t("BookingCreateView-CONTINUE"),
            type: "submit",
            className: "btn-success",
            icon: <Icon type="play-square" style={{ fontSize: 16 }} />,
            onPress: (form, values) => onCreateAndContinue(form, values)
        },
        {
            text: t("BookingCreateView-START"),
            type: "submit",
            className: "btn-primary",
            icon: <Icon type="clock-circle" style={{ fontSize: 16 }} />,
            onPress: (form, values) => onStart(form, values)
        },
        {
            text: t("BookingCreateView-CLOSE"),
            className: "btn-secondary",
            icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
            onPress: () => {
                onClose && onClose();
                onCheckReloadPage && onCheckReloadPage();
            }
        }
    ];

    const formDefaults = {
        alignLabel: "left",
        widthLabel: "110px"
    };

    const loadData = async (startTime) => {
        const res = await AppUtil.getApi(ApiBookingConstant.API_LOAD_TECHNICAN_BY_DAY, {
            dateTime: moment(startTime).format("YYYY-MM-DD HH:mm"),
            branchId
        });
        if (_.get(res, "data.success")) {
            setTechnicianData(_.get(res, "data.result"));
        } else {
            AppUtil.ToastApiError();
        }
    };

    const onSave = (form, values) => {
        const urlCreate = ApiBookingConstant.API_CREATE_BOOKING + "/" + false;
        refForm.current && refForm.current.onSave(form, values, urlCreate);
    };

    const onCreateAndContinue = (form, values) => {
        const urlCreate = ApiBookingConstant.API_CREATE_BOOKING + "/" + false;
        refForm.current && refForm.current.onCreateAndContinue(form, values, urlCreate);
    };

    const onStart = (form, values) => {
        const url = ApiBookingConstant.API_CREATE_AND_START_BOOKING + "/" + false;
        refForm.current && refForm.current.onStart(form, values, url);
    };

    const renderForm = () => {
        return <BookingForm ref={refForm} loadData={loadData} buttons={buttons} fields={fields}
            formDefaults={formDefaults} isCreate={true} onCreateAndContinue={onCreateAndContinue}
            onClose={onClose} onReloadData={onReloadData} onCheckReloadPage={onCheckReloadPage} />;
    };


    return useMemo(() => {
        return (
            <div>
                {renderForm()}
            </div>
        );
    }, []);
};

export default BookingCreateView;
