import {
    Container,
    FullBrowser,
} from '@idtek/component/lib'
import Icon from '@idtek/component/lib/icon/Icon'
import { t } from '@idtek/component/lib/language'
import {
    IDStore,
    TreeGrid,
} from '@idtek/grid/lib'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import Button from '../../../common/component/Button'
import AppUtil from '../../../utils/AppUtil'
import { SERVICE_GROUP_TYPE_CONSTANT } from '../../service/constant/ServiceConstant'
import ApiBookingConstant from '../constant/ApiBookingConstant'

const TreeServiceSelect = ({
    onClose,
    branchId,
    optionHasSkill,
    onSelectTree,
    serviceSelected,
}) => {
    const refFullBrowser = useRef(null)
    const refContainer = useRef(null)
    const refGrid = useRef(null)
    const [dataGrid, setDataGrid] = useState()
    const className = 'tree-grid-select-service'
    const store = useMemo(() => new IDStore({
        data: [],
        autoLoad: true,
    }), [])

    const columnDefs = [{
        headerName: t('TreeServiceSelect-NAME'),
        field: 'name',
        minWidth: 270,
    }, {
        headerName: '',
        field: 'actionSelect',
        width: 70,
    }, {
        headerName: t('TreeServiceSelect-PRICE'),
        field: 'price',
        width: 80,
        textAlign: 'right',
    }, {
        headerName: t('TreeServiceSelect-DURATION'),
        field: 'duration',
        width: 80,
        textAlign: 'right',
    }]

    const cellRenderer = {
        name: {
            renderer: (data, value) => {
                if (data.code) {
                    return (<div className="global-flex-justify-align-center"
                        style={{ color: _.indexOf(optionHasSkill, data.id) > -1 ? '#026eaa' : '' }}>
                        {value + ' (' + data.code + ')'}
                    </div>)
                }
                return value
            },
        },

        actionSelect: {
            renderer: (data, value, valueNode) => {
                return (<div className="global-flex-justify-align-center">
                    <div className={'btn-export-report'} style={{
                        height: 25,
                        width: 25,
                    }}>
                        <Button icon={'select'} tooltip={t('TreeServiceSelect-SELECT')} fontSize={13}
                            onClick={() => onSelect(data, valueNode)} />
                    </div>
                </div>)
            },
        },
    }

    const loadData = async () => {
        const res = await AppUtil.getApi(ApiBookingConstant.API_LOAD_TREE_SERVICE, {
            branchId,
        })
        if (_.get(res, 'data.success')) {
            const result = _.get(res, 'data.result')
            const rs = _.map(result, item => {
                item.children = _.filter(item.children, x => _.indexOf(serviceSelected, x.id) < 0)
                return { ...item }
            })
            setDataGrid(rs)
            store && store.setData(rs)
        } else {
            AppUtil.ToastApiError()
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const renderButton = () => {
        return (
            <div className="global-flex-justify-center" style={{ marginTop: 10 }}>
                <button className={"btn-form btn btn-secondary"} onClick={() => onClose && onClose()}>
                    <Icon type="close-square" style={{ fontSize: 16 }} />
                    <span style={{ marginLeft: 5, marginRight: 5, marginBottom: 2, fontSize: 14 }}>
                        {t('TreeServiceSelect-CLOSE')}
                    </span>
                </button>
            </div>)
    }

    const onSelect = (row) => {
        const serviceSelect = []

        if (row.code) {
            serviceSelect.push(row.id)
        }
        _.each(row.children, (c) => {
            if (c.code) {
                serviceSelect.push(c.id)
            }
        })
        onSelectTree && onSelectTree(serviceSelect)
        setDataRemove(row)
    }

    const setDataRemove = (data) => {
        const dataStore = store && store.getData()
        if (data.type === SERVICE_GROUP_TYPE_CONSTANT) {
            const dataStoreFilter = _.filter(dataStore, (item) => {
                return item.id !== data.id
            })
            store.setData(dataStoreFilter)
        } else {
            const dataParent = _.find(dataStore, (item) => {
                return item['id'] === data['serviceGroupId']
            })
            dataParent.children = _.filter(dataParent.children, (item) => {
                return item.id !== data.id
            })
            store.setData(dataStore)
        }
    }

    const onGlobalFilter = (value) => {
        const root = dataGrid
        const valueBodau = AppUtil.boDauTiengViet(value)
        if (root) {
            if (value) {
                let newParent = []
                let newData = []
                _.each(root, (parent) => {
                    if (parent.children) {
                        let newChild = []
                        _.each(parent.children, (child) => {
                            const childName = _.get(child, 'name', '')
                            const childNameBoDau = AppUtil.boDauTiengViet(childName)
                            if (childNameBoDau.indexOf(valueBodau) > -1) {
                                newChild.push(child)
                            }
                        })
                        if (!_.isEmpty(newChild)) {
                            parent.children = newChild
                            newParent.push(parent)
                        }
                    }
                })
                store.setData(newParent)
            } else {
                loadData()
            }
        }
    }

    const renderActionBar = () => {
        const buttons = [{
            type: 'refresh',
            onPress: () => loadData(),
            tooltip: t('TreeServiceSelect-REFRESH'),
        }]
        return buttons
    }

    const renderTreeGrid = () => {
        return (
            <TreeGrid
                ref={refGrid}
                store={store}
                height={"600px"}
                numberRows={false}
                levelTree={{
                    levelMax: 2,
                    levelFormat: ["I", "1"]
                }}
                checkboxSelection={false}
                floatingFilter={false}
                defaultNumberRows={false}
                headerCheckboxSelection={false}
                className={`base-tree-grid base-data-grid`}
                clsToolbar="base-grid-toolbar"
                globalFilter={{ placeholder: `${t("BaseListView-SEARCH")}...` }}
                onGlobalFilter={(value) => onGlobalFilter(value)}
                columnDefs={columnDefs}
                groupDefaultExpanded={false}
                groupUseEntireRow={false}
                actionToolbar={renderActionBar()}
                autoHeightRow={false}
                headerVerticalLines={false}
                headerHorizotalLines={false}
                headerHeight={32}
                defaultColDef={{ sortable: false, filter: false, headerAlign: "left", textAlign: "left" }}
                actionRows={{}}
                cellRenderer={cellRenderer}
                suppressRowClickSelection={true}
                lineType={"dashed"}
                treeLine={true}
                gridOptions={{
                    suppressScrollOnNewData: true
                    // getRowStyle: (params) => {
                    //     if (_.get(params, 'data.lvl') >= 2) {
                    //         return {'display': 'none'};
                    //     }
                    // }
                }}
            />
        );
    };

    return (<FullBrowser ref={refFullBrowser}>
        <Container ref={refContainer}>
            <div className={className}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: 'auto',
                }}>
                    <div style={{ flex: 1 }}>{renderTreeGrid()}</div>
                    {renderButton()}
                </div>
            </div>
        </Container>
    </FullBrowser>)
}

export default TreeServiceSelect
