import { t } from "@idtek/component/lib/language";
export const modelProduct = {
    fields: {
        code: {
            label: t("modelProduct-CODE"),
            required: true
        },
        name: {
            label: t("modelProduct-NAME"),
            required: true
        },
        status: {
            label: t("modelProduct-STATUS")
        },
        price: {
            label: t("modelProduct-PRICE"),
            required: true
        },
        description: {
            label: t("modelProduct-DESCRIPTION")
        },
        imageFile: {
            label: t("modelProduct-PRODUCT_IMAGE")
        },
        image: {
            label: t("modelProduct-PRODUCT_IMAGE")
        }
    }
};
