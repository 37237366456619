import React from 'react'
import BaseListView from '../../../common/baseView/BaseListView'
import BaseStore from '@idtek/grid/lib/stores/BaseStore'
import Textbox from '@idtek/component/lib/textbox/Textbox'
import _ from 'lodash'
import { PaymentContext } from '../context/PaymentContext'
import { t } from '@idtek/component'

class TechnicianTipGrid extends BaseListView {
    static contextType = PaymentContext
    constructor (props) {
        super(props)
        this.state = {
            value: null,
        }

        this.store = new BaseStore({
            data: props.data ? props.data : [],
            pageSize: this.pageSize,
        })
        this.defaultColDef = {
            sortable: false,
        }
        this.defaultNumberRows = false
        this.overrideTopLeftGrid = true
        this.pagination = false
        this.globalFilter = false
        this.floatingFilter = false
        this.suppressRowClickSelection = true
        this.heightGrid = 'auto'
        this.columnDefs = [{
            headerName: t('TechnicianTipGrid-TECHNICIAN'),
            field: 'technicianName',
            width: 120,
        }, {
            headerName: t('TechnicianTipGrid-SERVICE'),
            field: 'segmentName',
            minWidth: 150,
        }, {
            headerName: t('TechnicianTipGrid-PERCENT'),
            field: 'percent',
            width: 80,
            textAlign: 'right',
        }, {
            headerName: t('TechnicianTipGrid-VALUE'),
            field: 'value',
            width: 80,
            textAlign: 'right',
        }]
        this.cellRenderer = {
            value: {
                renderer: (data, value, rowNode) => {
                    return (<Textbox
                            value={value}
                            placeholder={`${t('TechnicianTipGrid-INPUT')}...`}
                            className={`input-tips`}
                            onBlur={this.onBlurInput.bind(this, data, 'value')}
                            inputStyle={{ textAlign: 'right' }}
                            type={'number'}
                            autoFocus={true}
                            readOnly={props.isDetail || !this.state.value}
                            isClearable={false}
                            // visibleIcon={!props.isDetail}
                            iconLine={false}
                            icon={<span style={{
                                fontSize: 12,
                                fontWeight: 600,
                            }}>$</span>}
                            iconAlign="before"
                            max={this.state.value}
                    />)
                },
            },

            percent: {
                renderer: (data, value, rowNode) => {
                    return (<Textbox
                            value={value}
                            placeholder={''}
                            className={`input-tips`}
                            onBlur={this.onBlurInput.bind(this, data, 'percent')}
                            inputStyle={{ textAlign: 'right' }}
                            type={'number'}
                            autoFocus={true}
                            readOnly={props.isDetail || !this.state.value}
                            isClearable={false}
                            // visibleIcon={!props.isDetail}
                            iconLine={false}
                            icon={<span style={{
                                fontSize: 12,
                                fontWeight: 600,
                            }}>%</span>}
                            iconAlign="before"
                            max={100}
                    />)
                },
            },
        }

        this.minHeightGrid = 50
        this.heightGrid = 'auto'
    }

    componentWillReceiveProps (nextProps, nextContext) {
        this.setState({
            value: _.get(nextContext, 'tips'),
        })
    }

    onBlurInput = async (data, fieldKey, value) => {
        data[fieldKey] = value
        if (fieldKey === 'value') {
            data['seflEdit'] = true
            const percentInput = value / this.state.value
            data['percent'] = (percentInput * 100).toFixed(1)
            this.myGrid && this.myGrid.gridApi.updateRowData({ update: this.myGrid.store.getData() })
            const dataGrid = this.myGrid && this.myGrid.store.getData()
            const arr = _.filter(dataGrid, x => x.seflEdit)
            const valAccept = _.sumBy(arr, x => parseFloat(x.value))
            const restValue = this.state.value - valAccept
            const avgValue = (restValue / (_.size(dataGrid) - _.size(arr))).toFixed(1)
            const avgPercent = ((1 - percentInput) / (_.size(dataGrid) - _.size(arr)) * 100).toFixed(1)
            dataGrid && dataGrid.map(x => {
                if (!x.seflEdit) {
                    x.value = avgValue
                    x.percent = avgPercent
                }
            })
            this.setDataGird(dataGrid)
        }

        if (fieldKey === 'percent') {
            data['seflEditP'] = true
            const valueInput = (value / 100 * this.state.value).toFixed(1)
            data['value'] = valueInput
            this.myGrid && this.myGrid.gridApi.updateRowData({ update: this.myGrid.store.getData() })
            const dataGrid = this.myGrid && this.myGrid.store.getData()
            const arr = _.filter(dataGrid, x => x.seflEditP)
            const perAccpept = _.sumBy(arr, x => parseFloat(x.percent))
            const restPercent = 100 - perAccpept
            const avgPercent = (restPercent / (_.size(dataGrid) - _.size(arr))).toFixed(1)
            const avgValue = (avgPercent * this.state.value / 100).toFixed(1)
            dataGrid && dataGrid.map(x => {
                if (!x.seflEditP) {
                    x.value = avgValue
                    x.percent = avgPercent
                }
            })
            this.setDataGird(dataGrid)
        }
    }

    renderActionBar () {

    }

    actionRows = () => {
        return {}
    }

    onGridReady () {

    }

    setDataGird (data) {
        const {
                  setTechnicianTip,
                  isDetailPayment,
              } = this.context
        if (!isDetailPayment) {
            setTechnicianTip(data)
        }
        this.myGrid && this.myGrid.store.setData(data)
    }

    render () {
        return (this.renderGrid())
    }
}

export default TechnicianTipGrid