import React, {useEffect, useState} from 'react';
import Loading from "./pages/Loading";
import AppUtil from "./utils/AppUtil";
import _ from "lodash";

const ClosingPage = () => {

    const [state, setState] = useState(false);

    useEffect(() => {
        checkLogin();
    }, [])

    const checkLogin = async () => {
        await AppUtil.Axios.all([
            AppUtil.getApi('/check-login'),
        ])
            .then(([resAuthData]) => {
                if (_.get(resAuthData, "data.success") &&
                    _.get(resAuthData, "data.result.isAuthenticated") === false) {
                    // const tenantId = _.get(resAuthData, "data.result.tenantId")
                    // const xsrfToken = document.cookie.get

                    this.props.history && this.props.history.push({
                        pathname: "/bff/login",
                        search: `?requestVerificationToken=${AppUtil.getCookie(AppUtil.XSRF_TOKEN_COOKIE_NAME)}&returnUrl=${encodeURIComponent(window.location.pathname)}`
                    })
                    window.location.reload();
                }
                setState(_.get(resAuthData, "data.result.isAuthenticated"))

            })
            .catch(err => {
                console.log(err);
            });

    };

    useEffect(() => {
        state && window.close()
    }, [state])

    return (
        <div style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0}}><Loading/></div>
    )
};

export default ClosingPage;