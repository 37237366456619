import { Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import AppUtil from '../../../utils/AppUtil';
import { t } from '@idtek/component/lib/language';

const { Option } = Select;

class SelectCustomComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      branch: props.branch,
      loading: false,
      data: [],
      dataOptions: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.date !== this.props.date || nextProps.branch !== this.props.branch) {
      const newDate = nextProps.date ? nextProps.date : this.props.date;
      const newBranch = nextProps.branch ? nextProps.branch : this.props.branch;
      this.setState({
        date: newDate,
        branch: newBranch,
      });
      //this.loadData(newDate, newBranch);
    }
  }

  componentDidMount() {
    // this.loadData(this.state.date, this.state.branch);
  }

  loadData(date, branch) {
    if (moment().diff(date, 'days') > 0) {
      date = moment(date).subtract(30, 'days').format('YYYY-MM-DD');
    } else {
      date = moment().subtract(30, 'days').format('YYYY-MM-DD');
    }
    if (date !== this.date) {
      const me = this;
      const url = `/booking/new-results`;
      const params = {
        date: date,
        branch: branch,
      };
      this.date = date;
      return AppUtil.getApi(url, params)
        .then(function (response) {
          const result = response.data;
          me.setState({
            data: result,
            dataOptions: result,
            loading: false,
          });
        })
        .catch(function (error) {
          return [];
        });
    }
  }

  onSearch = async (inputValue) => {
    // if (!inputValue) {
    //     return;
    // }
    // this.setState({
    //     loading: true
    // });
    // if (inputValue.length >= 3) {

    // }

    if (inputValue && this.onBeforeQuery(inputValue)) {
      this.setState({
        loading: true,
      });
      const params = {
        query: inputValue.toUpperCase(),
        branch: this.state.branch,
      };
      const response = await AppUtil.getApi(`/booking/search-booking`, params);
      if (response && response.data) {
        const data = _.get(response, 'data.result');
        this.setState({
          dataOptions: data,
          loading: false,
        });
      } else {
        AppUtil.ToastApiError();
      }
    } else {
      this.setState({
        dataOptions: [],
      });
    }
    // console.log("🚀 ~ file: SelectCustomComponent.js ~ line 67 ~ SelectCustomComponent ~ inputValue", inputValue);
    // if (inputValue.length === 0) return this.loadData(this.state.date, this.state.branch);
    // if (inputValue.length >= 3) {
    //     const dataFilter = _.filter(this.state.data, (item) => {
    //         return _.toLower(item.text).includes(_.toLower(inputValue));
    //     });
    //     this.setState({
    //         dataOptions: dataFilter
    //     });
    // }
  };

  onBeforeQuery(inputValue) {
    if (inputValue && inputValue.length < 3) {
      return false;
    }
    if (AppUtil.REGIONAL_PHONE_NUMBER.indexOf(inputValue.slice(0, 3)) > -1) {
      if (inputValue.length < 4) {
        return false;
      }
    }
    return true;
  }

  filterData = async (inputValue) => {
    // if (inputValue.length >= 3){
    //     this.setState({
    //         loading: true
    //     });
    //     // return this.state.data.filter(i =>
    //     //     i.text.toLowerCase().includes(inputValue.toLowerCase())
    //     // );
    //     const params = {
    //         query: inputValue.toUpperCase(),
    //         branch: this.state.branch,
    //     };
    //     const response = await AppUtil.getApi(`/booking/search-booking`, params);
    //     if (response.data) {
    //         const data = response.data;
    //         this.setState({
    //             data,
    //             loading: false
    //         });
    //     }
    // }
  };

  onDropdownVisibleChange = (visible) => {
    const { data } = this.state;
    if (!visible) {
      this.setState({
        dataOptions: data,
      });
    }
  };

  onChange = (bookingId) => {
    const { dataOptions } = this.state;
    const booking = _.find(dataOptions, x => x.id === bookingId);
    this.props.onChange && this.props.onChange(booking);
  };

  render() {
    const { dataOptions } = this.state;
    return (<Select
      ref={(c) => (this.refSelect = c)}
      showSearch
      loading={this.state.loading}
      style={{ width: '100%' }}
      placeholder={`${t('SelectCustomComponent-SEARCH_CUSTOMER')}...`}
      filterOption={false}
      onChange={(data) => this.onChange(data)}
      onSearch={_.debounce(this.onSearch, 200)}
      getPopupContainer={(trigger) => trigger.parentNode.closest('.popup-container') || document.body}
      dropdownClassName="search-booking-overlay"
      value={null}
      autoClearSearchValue={true}
      onDropdownVisibleChange={this.onDropdownVisibleChange}
      virtual={true}
      listItemHeight={105}
      listHeight={428}>
      {dataOptions.map((item, index) => {
        return (<Option key={index} value={item.id} label={item.customerName} className="option-kiritmz">
          <div className="item-info-booking">
            <div className="text" style={{display: "flex"}}>
              <span>{`${t('SelectCustomComponent-BOOKING')}: `}</span>
              <span className="text-code">
                {item.code}
                <span className="display-block">-</span>
              </span>
              <span></span>
              {/* <span>{`${t('SelectCustomComponent-STATUS')}: `}</span> */}
              <div className='type-status' style={{width: "100%", textAlign: "end"}}>
                <span className={'inline-flex status-booking ' + item.statusPriority}>
                  {' '}
                  {item.statusPriority}
                  <span className="display-block">-</span>
                </span>
              </div>
            </div>
            <div className="text">
              <span>{`${t('SelectCustomComponent-NAME')}: `}</span>
              <span className="text-name">
                {' '}
                {item.customerName}
                <span className="display-block">-</span>
              </span>
            </div>
            <div className="text">
              <span>{`${t('SelectCustomComponent-PHONE')}: `}</span>
              <span className="text-phone"> {_.get(item, 'phone')}</span>
            </div>
            <div className="text">
              {`${t('SelectCustomComponent-START_TIME')}: `}
              <span
                className="text-time"> {item.startTime ? moment(item.startTime).format('MM/DD/YYYY HH:mm') : ''}</span>
            </div>
          </div>
        </Option>);
      })}
    </Select>);
  }
}

export default SelectCustomComponent;
