import React from 'react';
import moment from "moment"
import '../style/time-line.scss'
import _ from "lodash";
import { CREATE_ACTION} from "../constant/HistoryConstant";
import GroupTitle from "./GroupTitle";
import AppUtil from './../../../utils/AppUtil';
import { BOOKING_TYPE } from '../../../modules/posTransaction/constant/BookingConstant';
import { t } from '@idtek/component/lib/language';
import { HistoryUtil } from './../../../utils/HistoryUtil';
import { SEGMENT_TYPE, BILL_TYPE } from './../../../modules/posTransaction/constant/BookingConstant';

const TimeLineItem = ({ data, index, component, type, isBooking = false }) => {
    const action = _.get(data, 'action');
    let actionType = t('LogActionView-' + action.toUpperCase());
    let className = HistoryUtil.getClassNameAction(action);
    if (isBooking) {
        const entityType = HistoryUtil.getTypeFormEntity(_.get(data, 'entityType'));
        switch (entityType) {
            case SEGMENT_TYPE:
                actionType = t('LogActionView-' + action.toUpperCase()) + " " + t(`LogActionView-${entityType.toUpperCase()}`);
                className = 'update-segment-item';
                break;
            case BILL_TYPE:
                actionType = action === CREATE_ACTION
                    ? t(`ReportDailyTransaction-${entityType.toUpperCase()}`)
                    : t('LogActionView-' + action.toUpperCase()) + " " + t(`ReportDailyTransaction-${entityType.toUpperCase()}`);
                className = action === CREATE_ACTION ? 'create-bill-item' : 'cancel-bill-item';
                break;
            default:
                break;
        }
    }
    let user = _.get(data, "user.fullName", "");
    let timeAction = data.actionTime ? moment(data.actionTime).format(AppUtil.GLOBAL_DATETIME_FORMAT) : "";

    const Component = component;

    return (
        <div
            className="time-line">
            <div className="time-line-left">
                <div className={`avatar ${className}`}>
                    <span className="image-avatar" style={{ flexDirection: "column", alignItems: "center" }}>
                        <span style={{ fontSize: 12 }}>{moment(data.actionTime).format('HH:mm')} </span>
                        {/* <span style={{ fontSize: 10 }}>{moment(data.actionTime).format('YYYY')} </span> */}
                    </span>
                </div>
                <div
                    className={`avatar-line color-${className} "line"`}
                />
            </div>
            <div className={`time-line-right ${className}`}>
                <i className={`fa fa-caret-left icon-left color-${className}`} aria-hidden="true"></i>
                <GroupTitle actionType={actionType} user={user} index={index} timeAction={timeAction}>
                    {/* <div className={`grid grid-cols-3 children-content color-children-${className}`}> */}
                    <div className={`children-content color-children-${className}`}>
                        {type === BOOKING_TYPE ? <Component config={data} status={_.get(data, "action")} classNames={className} /> : component(data, className)}
                    </div>
                    {/* </div> */}
                </GroupTitle>
            </div>
        </div>
    );
};

export default TimeLineItem;