import { t } from "@idtek/component/lib/language";

export const modelBooking = {
    fields: {
        numberGuest: {
            label: t("modelBooking-NUMBER_OF_GUESTS"),
        },
        customer: {
            label: t("modelBooking-PHONE"),
            required: true,
        },
        startTime: {
            label: t("modelBooking-TIME"),
            required: true,
        },
        customerName: {
            label: t("modelBooking-CUSTOMER_NAME"),
            required: true,
        },
        email: {
            label: t("modelBooking-EMAIL"),
        },
        branchId: {
            label: t("modelBooking-BRANCH"),
            required: true,
        },
        status: {
            label: t("modelBooking-STATUS"),

        },
        technicianId: {
            label: t("modelBooking-TECHNICIAN"),
            valueKey: "id",
            labelKey: "name",
        },
        note: {
            label: t("modelBooking-NOTE"),
        },
        quantity: {
            label: t("modelBooking-QUANTITY"),
            required: true,
        },
        block: {
            label: t("modelBooking-LOCK_TECHNICIAN"),
        },
        gender: {
            label: t("modelBooking-PRIORITY_TECHNICIAN"),
        },
        color: {
            label: t("modelBooking-COLOR"),
        },
        createdTime: {
            label: t("modelBooking-CREATED_AT"),
        },
        guests: {
            label: t("modelBooking-WORK_HOURS"),
        },
        services: {},
        isReOrder: {
            label: t("modelBooking-REORDER")
        },
    }
};
