import React from 'react';
import _ from "lodash"
import { t } from '@idtek/component/lib/language';
import moment from 'moment';
import AppUtil from './../../../utils/AppUtil';
import { UPDATE_ACTION } from '../../../common/history/constant/HistoryConstant';

const HistoryHolidayComponent = (data, className) => {
    let createdTime = data.createdTime ? moment(data.createdTime).format(AppUtil.GLOBAL_DATETIME_FORMAT) : "";
    const renderUpdate = () => {
        let oldDate = _.get(data, "editInfo.oldDate") ? moment(_.get(data, "editInfo.oldDate")).format(AppUtil.GLOBAL_DATETIME_FORMAT) : "";
        let newDate = _.get(data, "editInfo.newDate") ? moment(_.get(data, "editInfo.newDate")).format(AppUtil.GLOBAL_DATETIME_FORMAT) : "";
        return (
            <>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("BranchForm-OLDDATE")}:
                        </div>
                        <div className='value'>
                            {oldDate}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("BranchForm-NEWDATE")}:
                        </div>
                        <div className='value'>
                            {newDate}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const renderCreate = () => {
        let dateOff = _.get(data, "editInfo.date") ? moment(_.get(data, "editInfo.date")).format(AppUtil.GLOBAL_DATETIME_FORMAT) : "";
        return (
            <>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("BranchConstant-DATE")}:
                        </div>
                        <div className='value'>
                            {dateOff}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const renderBody = () =>{
        const type = _.get(data, "action");
        switch (type) {
            case UPDATE_ACTION:
                return renderUpdate();
            default:
                return renderCreate();
        }
    }

    return (
        <>
            <div className={`grid grid-cols-3`}>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-CREATED_BY")}:
                        </div>
                        <div className='value'>
                            {_.get(data, "user.fullName", "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-CREATED_TIME")}:
                        </div>
                        <div className='value'>
                            {createdTime}
                        </div>
                    </div>
                </div>
                {renderBody()}
                <div className='col-span-2'>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("BranchConstant-NOTE")}:
                        </div>
                        <div className='value'>
                            {_.get(data, "editInfo.note")}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HistoryHolidayComponent;
