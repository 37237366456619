import React, { Component } from 'react';
import Tooltip from '@idtek/component/lib/tooltip/Tooltip';
import Icon from '@idtek/component/lib/icon/Icon';
import Dropdown from '@idtek/component/lib/dropdown/Dropdown';
import _ from 'lodash';
import Menu from '@idtek/component/lib/menu/Menu';

class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        const { icon, tooltip, style, width, color, rotate, height, marginLeft, fontSize, onClick } = this.props;
        const { active } = this.state;
        return (
            <Tooltip title={tooltip}>
                <div
                    {...this.props}
                    style={Object.assign({
                        height: height || 32,
                        width: width || 32,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        fontSize: fontSize || 16,
                        color: color,
                        marginLeft: marginLeft || 0
                    }, style)}
                    onClick={() => {
                        onClick && onClick();
                    }}
                >
                    <Icon type={icon} rotate={rotate}
                        style={{ color: active ? 'var(--main-color)' : "", fontWeight: 600 }} />
                </div>
            </Tooltip>

        );
    }
}

export default Button;