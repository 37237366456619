import React from "react";
import Result from "@idtek/component/lib/result/Result";
import { connect } from "react-redux";
import HOLIDAY_OFF from "../../../images/off-holiday.png";
import { t } from "@idtek/component/lib/language";

const HolidayOffView = (props) => {
  const {
          text,
          status,
          subTitle,
          title,
          hideBack,
          headerMenu,
          href,
        } = props;
  const height = window.innerHeight - 140;
  return (
    <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
            width: '100%',
            flexDirection: 'column',
            zIndex: 2,
            backgroundColor: '#fff'
        }}>
      <Result
        icon={
          <img src={HOLIDAY_OFF} />
        }
        title={title || t("HolidayOffView-ACCESS_DENIED")}
        subTitle={subTitle || t("HolidayOffView-SORRY,_BUT_YOU_DON'T_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE") + "."}
        extra={hideBack ? null : <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        </div>}
      />
        </div>
    );
};
const mapStateToProps = (state) => {
    const headerMenu = state.root.headerMenu;
    return {
        headerMenu: headerMenu
    };
};

export default connect(
    mapStateToProps
)(HolidayOffView);

