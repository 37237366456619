import React from 'react'
import BaseListView from './../../../common/baseView/BaseListView';
import { IDStore } from "@idtek/grid/lib";
import { t } from '@idtek/component/lib/language';
import _ from 'lodash';
class GridHistoryBookingItem extends BaseListView {
    constructor(props) {
        super(props);
        this.state = {};
        this.store = new IDStore({
            column: this.columnDefs,
            data: []
        })
        this.initialValues = {
            ...props
        }
        this.isComponent = true
        this.contentHeight = 'auto'
        this.floatingFilter = false
        this.globalFilter = false
        this.defaultColDef = {
            sortable: false,
            filter: false,
            headerAlign: 'left',
            textAlign: 'left',
        }
        this.pagination = false
        this.widthActionRow = 60;
        this.heightGrid = "auto"
        this.headerHeight = 42;
        this.popUpWidth = "1000px";
        this.rowHeight = 28
        this.columnDefs = [{
            headerName: t("GridDetailGuest-GUEST"),
            field: "guestName",
            textAlign: 'center',
            rowSpan: function (param) {
                if (param.data && param.data.rowGuest > 1)
                    return param.data.rowGuest;
                return 1
            }
        }, {
            headerName: t("ServiceListView-SERVICE_NAME"),
            field: "serviceName",
            rowSpan: function () {
                return 1
            }
        }, {
            headerName: t('TechnicianListView-TECHNICIAN'),
            field: 'technicianName',
            rowSpan: () => { return 1 }
        }, {
            headerName: "TreeServiceSelect-PRICE",
            field: "price",
            textAlign: "right",
            width: 90,
            rowSpan: function () {
                return 1
            }
        }, {
            headerName: "TreeServiceSelect-DURATION",
            field: "duration",
            width: 80,
            textAlign: 'right',
            rowSpan: function () {
                return 1
            }
        }, {
            headerName: "CustomerDetailForm-START_TIME",
            field: "startTime",
            textAlign: "center",
            width: 130,
            rowSpan: function () {
                return 1
            }
        }, {
            headerName: "BookingHistoryListView-END_TIME",
            field: "endTime",
            textAlign: "center",
            width: 130,
            rowSpan: function () {
                return 1
            }
        }];
    }

    componentDidMount() {
        this.setDataGrid();
    }

    setDataGrid() {
        const { initialValues } = this.props;
        this.store.setData(this.beforeSetValues(_.get(initialValues, "editInfo.guestAffect")));
    }

    beforeSetValues(values) {
        const fn = _.spread(_.union);
        const newValues = _.map(values, guest => {
            const countSegment = _.get(guest, "segments").length;
            const segmentLists = _.get(guest, "segments");
            const firstServiceName = _.get(_.head(segmentLists), "serviceName");
            const newData = _.map(segmentLists, segment => {
                if (firstServiceName === _.get(segment, "serviceName")) {
                    return {
                        ...segment,
                        rowSpan: countSegment,
                        rowGuest: countSegment,
                        guestName: _.get(guest, "guestName")
                    }
                } else {
                    return {
                        ...segment,
                        rowSpan: countSegment,
                        rowGuest: 1,
                        guestName: _.get(guest, "guestName")
                    }
                }

            });
            return newData;
        })
        const mergeArray = fn(newValues)
        return mergeArray;
    }

    actionRows = () => {
    };

    renderActionBar() {
    }

    render() {
        return (
            <div className='color-childrent-white'>
                <div className={this.className}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        height: this.contentHeight
                    }}>
                        <div
                            style={{
                                flex: 1,
                                display: "flex"
                            }}>
                            {this.renderGrid()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default GridHistoryBookingItem;

