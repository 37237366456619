import Icon from "@idtek/component/lib/icon/Icon";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import { useAppointmentContext } from "../context/AppointmentContext";
import BookingForm from "../form/BookingForm";
import Loading from "../../../pages/Loading";
import { BookingStatusContant } from "../constant/BookingStatusContant";
import { t } from "@idtek/component/lib/language";
import PaymentDetailByBookingContainer from "./PaymentDetailByBookingContainer";
import PaymentContainer from "./PaymentContainer";

const BookingUpdateView = ({
    onClose,
    onReloadData,
    isDetail,
    bookingId,
    unsetMinDate,
    bookingStatus,
    onCloneBooking,
    guestId,
    onDetailAddCart,
    onAddCart,
    onProceedPayment,
    onCheckReloadPage
}) => {
    const { branchId, setTechnicianData, setDataInitBooking, dataInitBooking } = useAppointmentContext();
    const [loading, setLoading] = useState(true);
    const refForm = useRef(null),
        modalRef = useRef(null)
        ;
    const fields = ["customer", "startTime", "customerName", "email", "branchId", "technicianId", "block", "isReOrder", "createdTime", "color", "note"];

    const formDefaults = {
        alignLabel: "left",
        widthLabel: "110px",
        readOnly: isDetail
    };

    useEffect(() => {
        const loadDetailBooking = async () => {
            const resDetail = await AppUtil.getApi(ApiBookingConstant.API_GET_DETAIL_BOOKING, {
                bookingId: bookingId
            });
            if (_.get(resDetail, "data.success")) {
                const bookingInfo = _.get(resDetail, "data.result.booking");
                const guests = _.get(resDetail, "data.result.guests");
                const allObjGuests = _.cloneDeep(guests)

                guests && guests.map(x => {
                    x.segments && x.segments.map(item => {
                        if (_.size(item.technicianMulti) > 0) {
                            item.technicianId = _.map(item.technicianMulti, t => t.id)
                        }
                    })
                })
                // người đầu tiên là customer
                bookingInfo.segments = _.get(guests[0], "segments");
                bookingInfo.block = _.get(guests[0], "block");
                bookingInfo.firstGuest = guests[0];
                bookingInfo.createdTime = moment(bookingInfo.createdTime).format(AppUtil.GLOBAL_DATETIME_FORMAT);
                bookingInfo.email = _.get(bookingInfo, 'customer.email')
                if (bookingInfo.status === "Completed") {
                    formDefaults.readOnly = true
                }
                guests.splice(0, 1);
                const obj = {
                    ...dataInitBooking,
                    ...bookingInfo,
                    guests,
                    allObjGuests
                };
                setDataInitBooking(obj);
            } else {
                AppUtil.ToastApiError(_.get(resDetail, "data.message"));
                setTimeout(() => {
                    onClose && onClose()
                }, 500)
            }
            setLoading(false);
        };
        loadDetailBooking();
    }, [bookingId]);

    const loadData = async (startTime, callback) => {
        const resTech = await AppUtil.getApi(ApiBookingConstant.API_LOAD_TECHNICAN_BY_DAY, {
            dateTime: moment(startTime).format("YYYY-MM-DD HH:mm"),
            branchId
        });

        if (_.get(resTech, "data.success")) {
            setTechnicianData(_.get(resTech, "data.result"));
            callback && callback();
        } else {
            AppUtil.ToastApiError(_.get(resTech, "data.message"));
        }
    };

    const getLabelButtonByStatus = () => {
        let arr = [];
        switch (bookingStatus) {
            case BookingStatusContant.STATUS_WAITING:
                arr = ["save", "start", "cancel", "duplicate", "close"];
                break;
            case BookingStatusContant.STATUS_CHECKIN:
                arr = ["save", "start", "cancel", "duplicate", "close"];
                break;
            case BookingStatusContant.STATUS_PROCESSING:
                arr = ["save", "payment", "duplicate", "close"];
                break;
            case BookingStatusContant.STATUS_COMPLETE:
                formDefaults.readOnly = true;
                arr = ["detail-payment", "duplicate", "close"];
                break;
            case BookingStatusContant.STATUS_NOSHOW:
                formDefaults.readOnly = true;
                arr = ["duplicate", "close"];
                break;
            case BookingStatusContant.STATUS_CANCEL:
                formDefaults.readOnly = true;
                break;
            default:
        }
        return arr;
    };

    const onPayment = async (isDetail, guest) => {
        const isChange = refForm.current && refForm.current.checkChangeData()
        // if (isChange) {
        //     return Alert.Swal_info(`${t("WARNING")}`, `${t("You have unsaved changes, please save form before proceeding!")}`, 3)
        // }
        const PaymentCtn = isDetail ? PaymentDetailByBookingContainer : PaymentContainer
        modalRef.current && modalRef.current.onOpen(
            <PaymentCtn
                onClose={() =>
                    modalRef.current && modalRef.current.onClose()
                }
                onReloadData={() => {
                    onReloadData && onReloadData()
                }}
                bookingId={bookingId}
                guestId={guest}
                branchId={branchId} />,
            <span>{isDetail ? `${t("BookingUpdateView-DETAIL_PAYMENT")}` : `${t("BookingUpdateView-PAYMENT")}`}</span>,
            "1000px");
    }


    const getButtonExtra = () => {
        const guestProcess = _.map(_.filter(dataInitBooking.allObjGuests, x => x.status === BookingStatusContant.STATUS_PROCESSING), item => item.id)
        if (!guestProcess[0]) return;
        const billBookingMappings = _.get(dataInitBooking, 'billBookingMappings');
        const guestMappingBill = _.map(billBookingMappings, x => x.guestId)
        const guestProcessHaveBill = _.intersection(guestMappingBill, guestProcess)
        const guestProcessNoBill = _.difference(guestProcess, guestMappingBill)
        const buttons = []
        if (_.isEmpty(guestProcessHaveBill)) {
            buttons.push({
                onPress: (form, values) => {
                    const urlUpdate = ApiBookingConstant.API_UPDATE_BOOKING + "/" + false;
                    refForm.current && refForm.current.onSave(form, values, urlUpdate, () => {
                        onClose && onClose()
                        onProceedPayment(bookingId, guestProcessNoBill)
                    });
                },
                icon: <Icon type="dollar" style={{ fontSize: 18 }} />,
                text: t("BookingUpdateView-TAKE_PAYMENT"),
                className: "btn-primary",
                type: "submit",
            });

            buttons.push({
                onPress: (form, values) => {
                    const urlUpdate = ApiBookingConstant.API_UPDATE_BOOKING + "/" + false;
                    refForm.current && refForm.current.onSave(form, values, urlUpdate, () => {
                        onClose && onClose()
                        onAddCart(bookingId, guestProcessNoBill)
                    });
                },
                icon: <Icon type="shopping-cart" style={{ fontSize: 18 }} />,
                className: "btn-primary",
                text: t("BookingUpdateView-ADD_TO_CART"),
                type: "submit",
            })
        } else {
            buttons.push({
                onPress: (form, values) => {
                    const urlUpdate = ApiBookingConstant.API_UPDATE_BOOKING + "/" + false;
                    refForm.current && refForm.current.onSave(form, values, urlUpdate, () => {
                        onClose && onClose()
                        onDetailAddCart(bookingId, guestProcessHaveBill)
                    });
                },
                icon: <Icon type="shopping-cart" style={{ fontSize: 18 }} />,
                className: "btn-success",
                text: t("BookingUpdateView-CONTINUE_PAYMENT"),
                type: "submit",
            })
        }
        return buttons
    }

    const getButton = () => {
        let arrButton = ["close"];
        if (!isDetail) arrButton = getLabelButtonByStatus();
        const buttons = [];
        _.each(arrButton, (x) => {
            switch (x) {
                case "save":
                    buttons.push({
                        text: t("BookingUpdateView-SAVE"),
                        type: "submit",
                        className: "btn-success",
                        icon: <Icon type="save" style={{ fontSize: 16 }} />,
                        onPress: (form, values) => {
                            const urlUpdate = ApiBookingConstant.API_UPDATE_BOOKING + "/" + false;
                            refForm.current && refForm.current.onSave(form, values, urlUpdate);
                        }
                    });
                    break;
                case "detail-payment":
                    buttons.push({
                        text: t("BookingUpdateView-DETAIL_PAYMENT"),
                        className: "btn-primary",
                        icon: <Icon type="dollar" style={{ fontSize: 18 }} />,
                        onPress: () => {
                            // const billId =
                            refForm.current && refForm.current.onProceedPayment(guestId, true);
                        },
                    });
                    break;
                case "start":
                    buttons.push({
                        text: t("BookingUpdateView-START"),
                        type: "submit",
                        className: "btn-primary",
                        icon: <Icon type="clock-circle" style={{ fontSize: 16 }} />,
                        onPress: (form, values) => {
                            const urlUpdate = ApiBookingConstant.API_UPDATE_BOOKING + "/" + false;
                            refForm.current && refForm.current.onStart(form, values, urlUpdate);
                        }
                    });
                    break;
                case "cancel":
                    buttons.push({
                        text: t("BookingUpdateView-CANCEL"),
                        type: "submit",
                        className: "btn-danger",
                        icon: <Icon type="close-circle" style={{ fontSize: 15 }} />,
                        onPress: (form, values) => {
                            refForm.current && refForm.current.onCancel(form, values);
                        }
                    });
                    break;
                case "duplicate":
                    buttons.push({
                        text: t("BookingUpdateView-DUPLICATE"),
                        type: "submit",
                        className: "btn-clone",
                        icon: <Icon type="copy" style={{ fontSize: 16 }} />,
                        onPress: (form, values) => onClone(values)
                    });
                    break;
                case "close":
                    buttons.push({
                        text: t("BookingUpdateView-CLOSE"),
                        className: "btn-secondary",
                        icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                        onPress: () => {
                            onClose && onClose();
                            onCheckReloadPage && onCheckReloadPage();
                        }
                    });
                    break;
                default:
            }
        });

        return buttons;
    };

    const onClone = (booking) => {
        booking.status = "Waiting";
        booking.startTime = moment().format("YYYY-MM-DD HH:mm")
        setDataInitBooking(booking);
        _.set(booking, "customerName", _.get(booking, "customerId"))
        onCloneBooking && onCloneBooking(booking);
    };

    const onReloadDataPayment = () => {
        onClose && onClose()
        onReloadData()
    }

    const renderForm = () => {
        const buttons = getButton();
        if (dataInitBooking.status === BookingStatusContant.STATUS_PROCESSING) {
            const extra = getButtonExtra();
            _.each(extra, x => buttons.push(x))
        }
        return <BookingForm ref={refForm} bookingId={bookingId} loadData={loadData} buttons={buttons} fields={fields}
            formDefaults={formDefaults} onClose={onClose} onReloadData={onReloadData}
            onReloadDataPayment={onReloadDataPayment} onCheckReloadPage={onCheckReloadPage} />;
    };


    if (loading) return <Loading loadingHeight={500} />;

    return (
        <div>
            {renderForm()}
        </div>
    );
};

export default BookingUpdateView;
