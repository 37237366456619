import React from 'react';
import useGetBillByBooking from "../hook/useGetBillByBooking";
import PaymentDetailContainer from "./PaymentDetailContainer";
import Tabs from '@idtek/component/lib/tabs/Tabs'
import '../style/style-tab-component.scss'
import { t } from '@idtek/component/lib/language';

const {TabPane} = Tabs;

const PaymentDetailByBookingContainer = ({branchId, bookingId, guestId, onClose, onReloadData}) => {
    const bills = useGetBillByBooking(bookingId);
    return (
        <div className='tab-component'>
            <Tabs defaultActiveKey="1" animated={false}>
                {
                    bills && bills.map(x => {
                        return <TabPane
                            key={x.id}
                            forceRender={false}
                            type='card'
                            tab={
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: 300,
                                }}>
                                    {`${t("PaymentDetailByBookingContainer-BILL")} ${x.code ? x.code : null}`}
                                </div>
                            }>
                            <PaymentDetailContainer
                                billId={x.id}
                                branchId={branchId}
                                bookingId={bookingId}
                                onClose={onClose}
                                onReloadData={onReloadData}
                                viewCanCancelPayment={true}
                            />
                        </TabPane>
                    })
                }
            </Tabs>
        </div>


    );
};

export default PaymentDetailByBookingContainer;