import React from 'react';
import ProductForm from '../../product/form/ProductForm';
import AppUtil from "../../../utils/AppUtil";
import _ from "lodash";
import AppStore from "../../../store";
import {ContentPaymentContextStep} from "../context/ContentPaymentContextStep";
import { t } from "@idtek/component/lib/language";

class ProductCreateAddBillForm extends ProductForm {
    static contextType = ContentPaymentContextStep;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: {
                status: true,
                branchId: _.get(AppStore.getState(), `root.branch.id`, {}),
                ...props.initialValues
            }
        };
    }

    afterSave(form, res) {
        if (!this.isCreate) {
            const {setReloadInit, reloadInit} = this.context
            setReloadInit(reloadInit + 1)
        }
    }

    onSave = async (form, values) => {
        form && form.mask();
        let url = "";
        if (this.isCreate) {
            url = this.apiCreate;
        } else {
            values.id = _.get(this.initialValues, "id");
            url = this.apiUpdate;
        }
        values = this.beforeSave(form, values);
        const res = await AppUtil.postApi(url, values);
        this.afterSave(form, res);
        form && form.unmask();
        if (res) {
            if (!_.get(res.data, "success")) {
                AppUtil.ToastApiError(t(_.get(res, "data.message")));
            } else {
                AppUtil.ToastSuccess();
                if (this.isCreate) {
                    this.props.onCreateSuccess && this.props.onCreateSuccess(_.get(res.data, "result.id"));
                }
                this.props.onClose && this.props.onClose(this.isCreate, _.get(res.data, "result"));
            }
        } else {
            AppUtil.ToastApiError();
        }
    };
}

export default ProductCreateAddBillForm;