/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import AppUtil from '../../../utils/AppUtil';
import { useMergeState } from '@idtek/component/lib/hook/IDHook';
import Loading from '../../../pages/Loading';

const { Option } = Select;
const SearchAndSelectComponent = forwardRef(({
  urlSearch,
  placeholder,
  params,
  onChange,
  customRow,
  className,
  initValue,
  method = 'get',
}, ref) => {
  const [state, setState] = useMergeState({
    loading: initValue ? true : false,
    dataOptions: [],
    value: initValue ? initValue : [],
  });

  const refSelect = useRef(null);

  useImperativeHandle(ref, () => ({
    pushNewValue (ids) {
      const { value } = state;
      const newVal = _.cloneDeep(value);
      _.each(ids, id => {
        newVal.push(id);
      });
      setState({
        value: newVal,
      });
      onSearch('');
    },
    getValue () {
      return state.value;
    },

    setValue (val) {
      setState({
        ...state,
        value: val,
      });
    },

    onReSearch () {
      onSearch('');
    },
  }));

  useEffect(() => {
    onSearch('');
  }, []);

  useEffect(() => {
    onChange && onChange(state.value);
  }, [state.value]);

  const onSearch = async (inputValue) => {
    params.query = inputValue.toUpperCase();
    let res;
    if (method === 'get') {
      res = await AppUtil.getApi(urlSearch, params);
    } else {
      res = await AppUtil.postApi(urlSearch, params);
    }
    if (_.get(res, 'data.success')) {
      setState({
        dataOptions: _.get(res, 'data.result'),
        loading: false,
      });
    }
  };

  const onDropdownVisibleChange = (visible) => {

  };

  const onChangeValue = (val) => {
    setState({
      ...state,
      value: val,
    });
  };

  const {
          loading,
          dataOptions,
          value,
        } = state;
  if (loading) return <div><Loading /></div>;
  return (
    <div className={className}>
      <Select
        ref={refSelect}
        mode="multiple"
        showSearch
        loading={loading}
        style={{ width: '100%' }}
        filterOption={false}
        onChange={(data) => onChangeValue(data)}
        placeholder={placeholder}
        onSearch={_.debounce(onSearch, 200)}
        getPopupContainer={(trigger) => trigger.parentNode.closest('.popup-container') || document.body}
        dropdownClassName="popup-add-payment"
        value={value}
        autoClearSearchValue={true}
        onDropdownVisibleChange={onDropdownVisibleChange}
        virtual={true}
      >
        {dataOptions.map((item, index) => {
          return (
            <Option key={index} value={item.id} label={item.label}>
              {customRow(item)}
            </Option>
          );
        })}
      </Select>
    </div>
  );
});
export default SearchAndSelectComponent;