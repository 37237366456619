import {t} from "@idtek/component/lib/language";
import _ from "lodash";

export default class CalculatePaymentUtil {

    static calculateAll = ({
                               subTotalPayment,
                               isFromSaveBooking,
                               isUseHst,
                               hst,
                               method,
                               voucher,
                               gift,
                               moneyPoint,
                               loyaltyType,
                               objDiscount,
                               isOnlyCart,
                               initBill,
                               setState
                           }) => {
        if (subTotalPayment > 0) {
            //khi aps dunng payment method va thue
            let titleRowPM = ""
            let valueRowPM = 0
            //detail
            if (initBill) {
                titleRowPM = _.get(method, 'name') + " " + initBill.paymentFee + "$";
                valueRowPM = initBill.paymentFee;
            } else {
                const objPM = this.handlePaymentMethod(valueRowPM, titleRowPM, subTotalPayment, method)
                valueRowPM = objPM.valueRowPM
                titleRowPM = objPM.titleRowPM
            }
            const valueAfterApplyPM = (valueRowPM) + subTotalPayment;

            let {
                titleRowHST,
                valueRowHST,
            } = this.handleHst(isUseHst, hst, valueAfterApplyPM)
            // row total amount
            let valueRowTotalAmount = valueRowHST + parseFloat(valueAfterApplyPM);

            if (valueRowTotalAmount < 0) {
                valueRowTotalAmount = 0;
            }
            const {
                restValueAfterApplyVoucher,
                valueRowVoucher,
                textRowVoucher
            } = CalculatePaymentUtil.calculateMoneyWithVoucher(valueRowTotalAmount, voucher);
            const {
                valueRowLoyalty,
                textRowLoyalty
            } = CalculatePaymentUtil.calculateMoneyLoyalty(valueRowTotalAmount, restValueAfterApplyVoucher, isFromSaveBooking, loyaltyType, objDiscount, moneyPoint, isOnlyCart, initBill);
            const {
                valueRowGift,
                textRowGift
            } = CalculatePaymentUtil.calculateMoneyWithGift(restValueAfterApplyVoucher - Math.abs(valueRowLoyalty), gift);

            //money point
            let valueLimitMoneyPoint = (restValueAfterApplyVoucher - valueRowGift);

            /// so tien thực nhận
            let valueFinally = (restValueAfterApplyVoucher - (valueRowGift + valueRowLoyalty));
            if (valueFinally && valueFinally > 0) {
                valueFinally = Math.round(valueFinally * 100) / 100
            }

            setState({
                subTotalPayment,
                titleRowPM,
                valueRowPM,
                valueAfterApplyPM,
                valueRowTotalAmount,
                textRowVoucher,
                valueRowVoucher,
                valueRowGift,
                textRowGift,
                valueRowHST,
                titleRowHST,
                valueFinally,
                valueLimitMoneyPoint,
                valueRowLoyalty,
                textRowLoyalty,
                loading: false
            });
        }
    }

    static handlePaymentMethod = (valueRowPM, titleRowPM, subTotalPayment, method) => {
        if (method) {
            if (method.radio === "value" && !_.isNull(method.fee) && !_.isUndefined(method.fee) && method.fee !== 0) {
                titleRowPM = method.name + " " + method.fee + "$";
                valueRowPM = parseFloat(method.fee);
            } else if (method.radio === "percent" && !_.isUndefined(method.percentFee) && method.percentFee !== 0) {
                titleRowPM = method.name + " " + method.percentFee + "%";
                valueRowPM = (subTotalPayment * parseFloat(method.percentFee)) / 100;
            }
        }
        return {
            valueRowPM,
            titleRowPM,
        };
    }

    static handleHst = (isUseHst, hst, valueAfterApplyPM) => {
        let valueRowHST = 0
        let titleRowHST = ""
        if (isUseHst) {
            titleRowHST = `${t("CalculatePaymentUtil-HST")} ${hst}%`
            valueRowHST = hst ? (parseFloat(valueAfterApplyPM) * parseFloat(hst)) / 100 : 0;
        } else {
            titleRowHST = t("CalculatePaymentUtil-TAX_EXEMPTION")
            valueRowHST = 0
        }
        if (valueRowHST < 0) {
            valueRowHST = 0
        }
        return {
            valueRowHST,
            titleRowHST,
        };
    }

    static calculateMoneyWithVoucher = (restValueAfterApplyHST, voucher) => {
        let valueRowVoucher = 0;
        let textRowVoucher = "";
        let restValueAfterApplyVoucher = restValueAfterApplyHST;
        if (voucher) {
            if (voucher.value && voucher.value !== 0) {
                valueRowVoucher = Math.abs(voucher.value);
                textRowVoucher = voucher.value + "$" + " (" + voucher.code + ")";
            } else if (voucher.percent && voucher.percent !== 0) {
                valueRowVoucher = (parseFloat(restValueAfterApplyHST) * Math.abs(voucher.percent)) / 100;
                textRowVoucher = voucher.percent + "%" + " (" + voucher.code + ")";
            }
            valueRowVoucher = valueRowVoucher < restValueAfterApplyHST ? valueRowVoucher : restValueAfterApplyHST
            restValueAfterApplyVoucher = parseFloat(restValueAfterApplyHST) - parseFloat(valueRowVoucher);
        }
        return {
            valueRowVoucher: (valueRowVoucher),
            textRowVoucher,
            restValueAfterApplyVoucher
        };
    };

    static calculateMoneyLoyalty = (valueRowTotalAmount, restValueAfterApplyVoucher, isFromSaveBooking, loyaltyType, objDiscount, moneyPoint, isOnlyCart, initBill) => {
        let textRowLoyalty = "";
        let valueRowLoyalty = 0;
        if (isOnlyCart) {
            valueRowLoyalty = this.calculateMoneyLoyaltyCart(loyaltyType, moneyPoint)
        } else {
            if (isFromSaveBooking) {
                //detail
                if (initBill) {
                    textRowLoyalty = t("CalculatePaymentUtil-DISCOUNT_LOYALTY");
                    if (objDiscount) {
                        if (objDiscount.value !== 0) {
                            valueRowLoyalty = Math.abs(objDiscount.value);
                        } else if (objDiscount.percent !== 0) {
                            valueRowLoyalty = (parseFloat(valueRowTotalAmount) * Math.abs(objDiscount.percent)) / 100;
                        }
                    } else if (moneyPoint) {
                        valueRowLoyalty = moneyPoint;
                    }
                } else {

                    if (loyaltyType === "BOOKING_BASED") {
                        textRowLoyalty = t("CalculatePaymentUtil-DISCOUNT_LOYALTY");
                        if (objDiscount) {
                            if (objDiscount.value !== 0) {
                                valueRowLoyalty = Math.abs(objDiscount.value);
                            } else if (objDiscount.percent !== 0) {
                                valueRowLoyalty = (parseFloat(valueRowTotalAmount) * Math.abs(objDiscount.percent)) / 100;
                            }
                        }
                    } else if (loyaltyType === "POINT_BASED") {
                        textRowLoyalty = t("CalculatePaymentUtil-DISCOUNT_LOYALTY");
                        valueRowLoyalty = moneyPoint;
                    }
                }
            }
            valueRowLoyalty = Math.round(valueRowLoyalty * 100) / 100
        }
        if (restValueAfterApplyVoucher <= 0) return {textRowLoyalty, valueRowLoyalty: 0};
        return {
            textRowLoyalty,
            valueRowLoyalty: valueRowLoyalty < restValueAfterApplyVoucher ? valueRowLoyalty : restValueAfterApplyVoucher
        };
    };

    static calculateMoneyLoyaltyCart = (loyaltyType, moneyPoint) => {
        let valueRowLoyalty = 0;
        if (loyaltyType === "POINT_BASED") {
            valueRowLoyalty = moneyPoint;
        }
        valueRowLoyalty = Math.round(valueRowLoyalty * 100) / 100
        return (valueRowLoyalty);
    };

    static calculateMoneyWithGift = (valueRowTotalAmount, gift) => {
        let textRowGift = "";
        let valueRowGift = 0;
        const valueGift = gift ? gift.amount : 0;
        if (gift && !gift.isUsed) {
            if (valueRowTotalAmount <= valueGift) {
                valueRowGift = valueRowTotalAmount;
            } else {
                valueRowGift = valueGift;
            }
            textRowGift = t("Pay by gift card")
        }

        if (gift && gift.isUsed) {
            valueRowGift = gift.value ? parseFloat(gift.value) : 0
            textRowGift = t("Pay by gift ") + "(" + gift.giftCode + ")"
        }
        return {
            textRowGift,
            valueRowGift: (valueRowGift)
        };
    };
}
