import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import _ from "lodash";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import {useMergeState} from "@idtek/component/lib/hook/IDHook";
import ComboField from "@idtek/form/lib/combox/ComboField";
import TextField from "@idtek/form/lib/text/TextField";
import {t} from "@idtek/component/lib/language";

const SearchTextCustomer = forwardRef(({field, branchId, onChange, onChangeName, defaultData}, ref) => {
    const inputRef = useRef(null);
    const [state, setState] = useMergeState({
        visibleTag: true,
        options: [],
        allOptions: []
    });

    useImperativeHandle(ref, () => ({
        setVisibleTag(val) {
            setState({visibleTag: val, options: state.allOptions})
            if (val) {
                inputRef.current && inputRef.current.setData(state.allOptions)
            }
        },
        getCustomerNameById(id) {
            return _.get(_.find(state.allOptions, x => x.value == id), 'name');
        }
    }));

    useEffect(() => {
        onSearchCustomer("", true);
    }, []);

    const onSearchCustomer = async (val, didmount = false) => {
        const res = await AppUtil.postApi(ApiBookingConstant.API_SEARCH_TEXT_COMBO_CUSTOMER, {
            start: 0,
            limit: 100,
            query: val,
            branchId,
            customerId: defaultData
        });
        if (_.get(res, "data.success")) {
            const combo = _.get(res, 'data.result', [])
            if (val) {
                combo.unshift({
                    label: t(`SearchTextCustomer_Create`) + " " + `"${val.toUpperCase()}"`,
                    value: val.toUpperCase(),
                    isNew: true
                })
            }
            const objState = {
                options: combo,
            }
            if (didmount) {
                objState.allOptions = combo
            }
            inputRef.current && inputRef.current.setData(combo)
            setState(objState)
        }

    }

    const onSelect = (val) => {
        const {options} = state
        const findVal = _.find(options, x => x.value === val)
        onChange && onChange(findVal)
    }
    if (state.visibleTag) {
        return (
            <ComboField
                name={field}
                ref={inputRef}
                upperCase={true}
                onInputChange={_.debounce(onSearchCustomer, 300)}
                onChange={(val) => onSelect(val)}
                isMulti={false}
                open={true}
                options={[]}
                isLoadingInputChange={true}
                className={"tag-input-customer-name"}
                classNameInput={"tag-input"}

            />
        );
    }
    return <TextField name={field} upperCase={true} onChange={onChangeName}/>
});

export default SearchTextCustomer;