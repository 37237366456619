import React, {Component} from "react";
import MouseTooltip from "react-sticky-mouse-tooltip";

export default class MouseTooltipCpn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseTooltipVisible: false,
            element: null
        };
    }

    toggleMouseTooltip(element) {
        if (React.isValidElement(element)) {
            this.setState({
                element
            });
        } else {
            this.setState({
                element: <span>{element}</span>
            });
        }

    }

    onChangeVisible(value) {
        this.setState({
            isMouseTooltipVisible: value
        });
    }

    onClickMouse() {
        this.setState({
            isMouseTooltipVisible: !this.state.isMouseTooltipVisible
        });
    }

    render() {
        const {element, isMouseTooltipVisible} = this.state;
        return (
            <MouseTooltip visible={isMouseTooltipVisible}>
                <span>
                    {element}
                </span>
            </MouseTooltip>
        );
    }
}
