import {Alert, TextTranslate} from "@idtek/component";
import Icon from "@idtek/component/lib/icon/Icon";
import Axios from "axios";
import {default as DataUtil, default as _} from "lodash";
import moment from "moment";
import React from "react";
import voucher_codes from "voucher-code-generator";
import SideMenu from "../application/components/SideMenu";
import "../application/css/styleArrow.scss";
import AppStore from "../store";
import {t} from '@idtek/component/lib/language';
import * as Contant from '../application/constants/constant';
import {LOG_OUT_SUCCESSFUL} from "../application/constants/constant";
import {setAxiosStop} from "../application/actions/action";
/* global numeral */

export default class AppUtil {
    static GLOBAL_DATE_FORMAT = "MMM/DD/YYYY";
    static GLOBAL_DATETIME_FORMAT = "hh:mm A MMM/DD/YYYY ";
    static GLOBAL_DATE_LOCALE = "en";
    static GLOBAL_TENANT_DOMAIN_PATH = window.location.protocol + "//" + window.location.host;
    static GLOBAL_LIMIT_RECORD = 100000;
    
    static GLOBAL_IMAGE_PATH = AppUtil.GLOBAL_TENANT_DOMAIN_PATH + "/api/tenant/public";

    static roundNumber(value) {
        if (!value) return 0
        return Math.round(value * 100) / 100
    }

    static toNumber(value) {
        return numeral(value).value();
    }

    static capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    static formatNumberValue(value, format) {
        return numeral(value).format(format);
    }

    static GLOBAL_DOMAIN_PATH = "";

    static getCurrentUrl = (props) => {
        return DataUtil.get(props, "match.url", "undefined");
    };

    static getQueryParams(name, url) {
        if (!url) url = window.location.href;
        name = name.replace("/[]/g", "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static encodeURLQuery(obj) {
        let str = "?" + Object.keys(obj)
            .reduce(function (a, k) {
                let val = obj[k];
                if (typeof val == "object") {
                    val = JSON.stringify(val);
                }
                a.push(k + "=" + encodeURIComponent(val));
                return a;
            }, [])
            .join("&");
        return str;
    }

    static CurrencyMoneyBranch = () => {
        const findCurrency = _.find(_.get(AppStore.getState(), `root.branch.branchConfigs`), x => x.type === "SYMBOL_MONEY")
        let currentMoney = "en"
        if (findCurrency) {
            switch (findCurrency?.value) {
                case "VND":
                    currentMoney = "vi"
                    break;
                default:
                    currentMoney = "en"
                    break;
            }
        }
        return currentMoney
    };
    static FormatCurrencyBranch = (price) => {
        const findCurrency = _.find(_.get(AppStore.getState(), `root.branch.branchConfigs`), x => x.type === "SYMBOL_MONEY")
        let newPrice = price
        if (findCurrency && _.isNumber(price) && price) {
            let priceFormat
            switch (findCurrency?.value) {
                case "đ":
                    priceFormat = Intl.NumberFormat('VI');
                    newPrice = priceFormat.format(price.toFixed(2))
                    break;
                default:
                    priceFormat = Intl.NumberFormat('en-US');
                    newPrice = priceFormat.format(price.toFixed(2))
                    break;
            }
        }
        return newPrice
    };
    static mergeConfig = (mergeTarget, customize) => {
    };
    static GLOBAL_API_PATH = this.GLOBAL_DOMAIN_PATH + "/api";
    static REGIONAL_PHONE_NUMBER = ["226", "289", "343", "416", "519", "613", "647", "705", "807", "905"];

    static DataUtil = DataUtil;

    static Axios = Axios;

    static Locale_TZ = "Asia/Ho_Chi_Minh";


    static RegexPhone(regex) {
        if (regex === null || regex === undefined || regex === '') {
            return null;
        }

        let regexPhone = _.split(regex, ',');
        _.map(regexPhone, (x, index) => {
            regexPhone[index] = _.toNumber(regexPhone[index])
        })
        const delimiter = []
        for (let i = 0; i < regexPhone.length; i++) {
            delimiter.push(' ')
        }
        const option = {
            blocks: regexPhone, delimiters: delimiter,
            length: _.sum(regexPhone)
        }
        return option
    }

    static getApi = async (api, params) => {
        const url = AppUtil.GLOBAL_API_PATH + api;
        return await AppUtil.Axios.get(url, {params}).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
            }
        });
    };

    static postApi = async (api, params) => {
        const url = AppUtil.GLOBAL_API_PATH + api;
        return await AppUtil.Axios.post(url, params).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
            }
        });
    };

    static deleteApi = async (api, params) => {
        const url = AppUtil.GLOBAL_API_PATH + api;
        return await AppUtil.Axios.delete(url, params).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
            }
        });
    };

    static checkPermission = (actionKey, rights) => {
        if (_.isEmpty(rights)) return false
        let right = false;

        if (actionKey) {
            if (!_.isArray(actionKey)) {
                right = rights.includes(actionKey);
            } else {
                // có 1 quyền trong array là ok
                right = !!_.find(rights, x => _.indexOf(actionKey, x) >= 0)
            }
        } else {
            right = true;
        }
        return right;
    };

    static checkPricing = (actionKey, rights) => {
        const pricing = _.find(rights, x => x.isActive)
        if (pricing) {
            return true
        }
        return false;
    };

    static LogErrors(message = "Log 123") {
        console.log(message);
    }

    static ToastSuccess(message = `${t("MessageSuccess-SUCCESS!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 1);
    }

    static ToastUpdateSuccess(message = `${t("MessageSuccess-UPDATE_SUCCESS!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 1);
    }

    static ToastSaveSuccess(message = `${t("MessageSuccess-SAVE_SUCCESS!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 1);
    }

    static ToastCreateSuccess(message = `${t("MessageSuccess-CREATE_SUCCESS!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 1);
    }

    static ToastServerError(message = `${t("MessageError-SERVER_ERROR,_PLEASE_TRY_AGAIN!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 0);
    }

    static ToastHostError(message = `${t("MessageError-SERVER_ERROR,_PLEASE_TRY_AGAIN!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 0);
    }

    static ToastApiError(message = `${t("MessageError-SERVER_ERROR,_PLEASE_TRY_AGAIN!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 0);
    }

    static ToastDeleteSuccess(message = `${t("MessageSuccess-DELETE_SUCCESS!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 1);
    }

    static ToastDeleteError(message = `${t("MessageError-DATA_ERROR,_PLEASE_TRY_AGAIN!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 0);
    }

    static ToastError(message = `${t("MessageError-DATA_ERROR,_PLEASE_TRY_AGAIN!")}`) {
        Alert.Toast_info(`${t("AppUtil-NOTIFICATION")}`, message, 0);
    }

    static ToastWarning(message) {
        Alert.Toast_info(`${t("AppUtil-WARNING")}`, message, 3);
    }

    static async DeleteConfirm(message, confirmHandler) {
        let confirm = await Alert.Swal_confirm(`${t("AppUtil-NOTIFICATION")}`, message, 0);
        if (confirm.value) {
            confirmHandler && confirmHandler();
        }
    }

    static isNullOrUndefined = (value) => {
        return _.isNull(value) || _.isUndefined(value);
    };

    static capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    static ObjectToQuery(obj) {
        return Object.entries(obj).map(([key, val]) => `${key}=${val}`).join('&');
    }

    static QueryToObject(str) {
        const urlParams = new URLSearchParams(str);
        return Object.fromEntries(urlParams);
    }


    static renderTitleForm = (text) => {
        return (<span
            style={{
                position: "relative", marginRight: 7, display: "flex", alignItems: "center"
            }}>
                <Icon type="form" style={{fontSize: 16, display: "flex"}}/>
                <span
                    className={"desc"}
                    style={{
                        fontSize: 15, display: "flex", marginLeft: 10
                    }}>
                    <TextTranslate text={text}/>
                </span>
            </span>);
    }

    static getMenuName() {
        const allMenuItems = _.get(AppStore.getState(), "root.allMenuItems");
        const selectKey = AppStore.getState().root.selectKeys ? AppStore.getState().root.selectKeys[0] : "";
        const route = SideMenu.findRoute(selectKey, allMenuItems);
        return _.get(route, "name");
    }

    static subTitle(str, length) {
        let title = "";
        if (str.length > length) {
            title = str.substr(0, length);
            const lastSpace = title.lastIndexOf(" ");
            title = title.substr(0, lastSpace) + "...";
        } else {
            title = str;
        }
        return title;
    }

    // grid to excel
    static exportExcelClient = (grid, params) => {
        grid.exportToExcel(params);
    };


    static isNumber = (value) => {
        let processValue;
        try {
            processValue = value && value.toString().replace(",", "");
            processValue = processValue && processValue.replace("%", "");
        } catch (e) {
            console.error(value);
        }
        return !isNaN(Number(processValue));
    };


    static toNumeric = (value) => {
        let processValue;
        try {
            processValue = value && value.toString().replace(",", "");
            processValue = processValue && processValue.replace("%", "");
        } catch (e) {
            console.error(value);
        }
        return Number(processValue);
    };

    static customDataCellExcel = (params) => {
        const {value, column, node} = params;
        const field = _.get(column, "colDef.field");
        const rawValue = field && node.data[field];
        let returnVal = node.data.hasOwnProperty(field) ? rawValue : value;
        if (returnVal === null || returnVal === "") {
            return "";
        }
        if (AppUtil.isNumber(returnVal) && !_.isArray(returnVal)) {
            return AppUtil.toNumeric(returnVal);
        }
        if (_.isEmpty(returnVal) && returnVal !== 0 && returnVal !== "0") {
            returnVal = "";
            return returnVal;
        }
        if (_.isArray(returnVal)) {
            let tempValue = "";
            _.map(returnVal, (item) => {
                if (item.name) {
                    tempValue = tempValue.concat(`${item.name}`);
                } else {
                    tempValue.concat(item);
                }
            });
            returnVal = tempValue;
            return returnVal;
        }

        return returnVal;
    };

    static titleExcelv2 = (content = []) => {
        return [[], ...content, [], []];
    };


    static excelStylesv2 = () => {
        const fontName = "Times New Roman";
        const size = 11;
        const sizeHeader = 12;
        return [{
            id: "header", alignment: {
                horizontal: "Center", vertical: "Center", wrapText: true
            }, interior: {
                color: "#dbebfa", pattern: "Solid"
            }, font: {
                bold: true, color: "#0072bc", size: sizeHeader, fontName
            }, borders: {
                borderBottom: {
                    weight: 1, color: "#adadad"
                }, borderLeft: {
                    weight: 1, color: "#adadad"
                }, borderRight: {
                    weight: 1, color: "#adadad"
                }, borderTop: {
                    weight: 1, color: "#adadad"
                }
            }
        },

            {
                id: "center", alignment: {
                    horizontal: "Center", vertical: "Center", shrinkToFit: true, indent: 1
                }
            },

            {
                id: "check", font: {
                    color: "#73a839", size: 12,
                }, alignment: {
                    horizontal: "Center", vertical: "Center", wrapText: true
                }
            }, {
                id: "remove", font: {
                    color: "#d34d4d", size: 12,
                }, alignment: {
                    horizontal: "Center", vertical: "Center", wrapText: true

                }
            }, // {
            //     id: "odd",
            //     font: {
            //         bold: false,
            //         color: '#15294d'
            //     },
            //     interior: {
            //         color: "#e5f7ff",
            //         pattern: "Solid"
            //     }
            // },
            {
                id: "left", alignment: {
                    horizontal: "Left", shrinkToFit: true, indent: 1
                }
            },

            {
                id: "right", alignment: {
                    horizontal: "Right", shrinkToFit: false, indent: 1
                }
            }, {
                id: "cell", alignment: {
                    vertical: "Center", shrinkToFit: false, indent: 1
                }, // dataType: "number",
                borders: {
                    borderBottom: {
                        weight: 0, color: "#adadad"
                    }, borderLeft: {
                        weight: 0, color: "#adadad"
                    }, borderRight: {
                        weight: 0, color: "#adadad"
                    }, borderTop: {
                        weight: 0, color: "#adadad"
                    }
                }, font: {
                    size, fontName
                }
            },

            {
                id: "titleExcelv2", alignment: {
                    vertical: "Left", horizontal: "Left", shrinkToFit: true, indent: 1, wrapText: true
                }, font: {
                    bold: true, size: 16, color: "#172b4d", fontName
                }
            }, {
                id: "titleTime", alignment: {
                    vertical: "Left", horizontal: "Left", shrinkToFit: true, indent: 1, wrapText: true
                }, font: {
                    bold: true, size: 14, color: "#172b4d"
                }
            }, {
                id: "company", alignment: {
                    horizontal: "Left", vertical: "Center", wrapText: true
                }, interior: {
                    color: "#fff"
                    // pattern: "Solid"
                }, font: {
                    bold: true, color: "#333", size: 16, fontName
                }
            }, {
                id: "treeLevel1", font: {
                    size: 11
                }
            }, {
                id: "treeLevel1Value", font: {
                    bold: true, size: 11
                }, alignment: {
                    wrapText: true
                }, interior: {
                    color: "#fff2cc", pattern: "Solid"
                }
            }, {
                id: "treeLevel2", alignment: {
                    indent: 3
                }, font: {
                    size: 11
                }
            }, {
                id: "treeLevel2Value", font: {
                    // bold: true
                }, alignment: {
                    wrapText: true
                }, interior: {
                    color: "#eeeeee", //#BDD7EE
                    pattern: "Solid"
                }
            }, {
                id: "treeLevel3", alignment: {
                    indent: 10
                }
            }, {
                id: "treeLevel4", alignment: {
                    indent: 15
                }, font: {
                    italic: true
                }
            }, {
                id: "treeLevel5", alignment: {
                    indent: 20
                }
            }, {
                id: "textArea", alignment: {
                    wrapText: true
                }
            }, // {
            //     id: "number",
            //     numberFormat: {
            //         format: "#,##0.00"
            //     },
            //     font: {
            //         size: 11
            //     }
            // }
        ];
    };

    static renderDateTime = (data, value, rowNode, grid) => {
        if (data && value && !_.isEmpty(value)) {
            return moment(value).format("DD/MM/YYYY HH:mm");
        } else return <div/>;
    };
    static renderDate = (data, value, rowNode, grid) => {
        if (data && value && !_.isEmpty(value)) {
            return moment(value).format("DD/MM/YYYY");
        } else return <div/>;
    };

    static renderUserCreatedBy = (data, value) => {
        if (data && value) {
            const userCreatedBy = AppStore.getState().root.userCreatedBy;
            const user = _.find(userCreatedBy, (x) => {
                return value === x.id || value === x.userName;
            });
            if (user) {
                return _.get(user, "userName");
            }
        } else return <div/>;
    };

    static autoGenGiftCode = () => {
        const arrayCode = voucher_codes.generate({
            length: 6, count: 1, charset: voucher_codes.charset("alphanumeric")
        });
        return arrayCode[0].toUpperCase();
    };

    static formatPhone(phone) {
        let value = phone ? phone.replace(/\D/g, "") : "";
        const {
            blocks, delimiters
        } = AppUtil.RegexPhone(_.find(_.get(AppStore.getState(), `root.branch.branchConfigs`), (x) => x.type === Contant.PHONE_SET_UP).value);
        let result = "";
        if (parseInt(value) != NaN) {
            let indexDelimetesr = 0;
            let counter = 0;
            let counterBlock = 0
            for (let i = 0; i < value.length; i++) {
                if(counter === blocks[counterBlock])
                {
                    result = result + delimiters[counterBlock];
                    counterBlock++;
                    counter = 0;
                }
                result = result + value[i];
                counter++;
                // result = result + value[i];
                // if (i !== value.length - 1) {
                //     if (i == blocks[0] - 1 || i == blocks[0] + blocks[1] - 1 || (i >= 9 && (i - 9) % 4 == 0)) {
                //         result = result + delimiters[indexDelimetesr++];
                //     }
                // }
            }
        }

        return result;
    }

    static formatPhoneByBlock(phoneNumberString, block) {
        const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        let blockRegex = "";
        _.each(block, (x) => {
            blockRegex += `(\\d{${x}})`;
        });
        if (blockRegex) {
            const regex = new RegExp(`^${blockRegex}$`);
            const match = cleaned.match(regex);
            if (match) {
                let value = "";
                _.each(block, (x, index) => {
                    value += ` ${match[index + 1]}`;
                });
                return value;
            }
        }

        return null;
    }

    static boDauTiengViet(str) {
        if (!str) {
            return "";
        }
        str = str.toString();
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        return str;
    }

    static XSRF_TOKEN_COOKIE_NAME = "xsrf-id-token";

    static getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}


// AppUtil.Axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
// }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     if (error.response.status === 401) {

//         !_.get(AppStore.getState(), `root.axiosStop`, false) && refreshToken()
//     }

//     return Promise.reject(error);
// })


export const refreshToken = _.debounce(async () => {
    AppStore.dispatch(setAxiosStop(true))
    const confirm = await Alert.Swal_confirm(t("BaseListView-CONFIRM"), t("BaseListView-SESSION_TIME_OUT?"), 3, {
        allowOutsideClick: false,
    })
    if (_.get(confirm, "value") === true) {
        openLoginModal();
    } else {
        AppStore.dispatch({
            type: LOG_OUT_SUCCESSFUL
        });
        window.location.href = window.location.origin + `/bff/logout?requestVerificationToken=${AppUtil.getCookie(AppUtil.XSRF_TOKEN_COOKIE_NAME)}`
        window.location.reload();
    }
    AppStore.dispatch(setAxiosStop(false))
}, 1500);

export const openLoginModal = () => {
    let loginPage = window.open("", "", "width=800,height=1000");
    loginPage.document.write('Loading preview...');
    loginPage.location.href = window.location.origin + '/closing';
}

export const exportExcelClient = (grid, params) => {
    grid.exportToExcel(params);
};

export const getYearFromCurrentWeek = (week, month) => {
    if (week === 1 && month === 12) {
        return moment().year() + 1;
    } else {
        return moment().year();
    }
};
