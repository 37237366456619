import React, {Fragment} from 'react'
import BaseForm from '../../../common/baseForm/BaseForm'
import _ from 'lodash'
import Icon from '@idtek/component/lib/icon/Icon'
import DisplayField from '@idtek/form/lib/display/DisplayField'
import Fieldset from '@idtek/component/lib/container/Fieldset'
import IconFilterDown from '../../../application/elements/IconFilterDown'
import IconFilter from '../../../application/elements/IconFilter'
import Cell from '@idtek/component/lib/container/Cell'
import Row from '@idtek/component/lib/container/Row'
import moment from 'moment'
import AppUtil from '../../../utils/AppUtil'
import GridBookingCustomerDetail from '../component/GridBookingCustomerDetail'
import TextField from '@idtek/form/lib/text/TextField'
import AppStore from '../../../store'
import * as Constant from '../../../application/constants/constant'
import '../style/booking-detail-form.scss'
import {t} from '@idtek/component/lib/language'

class BookingDetailForm extends BaseForm {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            initialValues: props.initialValues,
        }
        this.buttons = [{
            text: t('BookingDetailForm-CLOSE'),
            className: 'btn-secondary',
            icon: <Icon type="close-square" style={{fontSize: 16}}/>,
            onPress: () => this.props.onClose(),
        }]
        this.apiDetail = '/booking/detail'
    }

    beforeSetValues = (values) => {
        const newValues = {
            ...values
        }
        if (!newValues?.customer) {
            newValues.customer = _.get(newValues, 'customerDto')
        }
        let newEmailCustomer = _.get(values, 'customer.email', '')
        if (newEmailCustomer) {
            newEmailCustomer = newEmailCustomer.replaceAll(',', ', ')
            values.customer.email = newEmailCustomer
        }
        _.set(newValues, "customer.phone", _.get(newValues, 'customer.phone') ? AppUtil.formatPhone(_.get(newValues, 'customer.phone')) : '')
        //newValues.customer.phone = _.get(newValues, 'customer.phone') ? AppUtil.formatPhone(_.get(newValues, 'customer.phone')) : ''
        newValues.startTime = values.startTime ? moment(values.startTime).format(AppUtil.GLOBAL_DATETIME_FORMAT) : ''
        newValues.endTime = values.endTime ? moment(values.endTime).format(AppUtil.GLOBAL_DATETIME_FORMAT) : ''
        return newValues
    }

    componentDidMount() {
        const values = this.beforeSetValues(this.initialValues)
        this.setState({
            initialValues: values,
            loading: false,
        })
    }

    renderBookingCustomer(values) {
        return _.map(values, x => {
            return (<Fieldset
                title={x.name}
                collapsed={false}
                icon={{
                    open: <IconFilterDown/>,
                    close: <IconFilter/>,
                }}>
                <div className="grid-booking" style={{
                    marginBottom: 10,
                    marginTop: 10,
                }}>
                    <GridBookingCustomerDetail segment={x} guests={x}/>
                </div>
            </Fieldset>)
        })
    }

    renderBody() {
        const optionPhoneSetting = AppUtil.RegexPhone(_.find(_.get(AppStore.getState(), `root.branch.branchConfigs`), (x) => x.type === Constant.PHONE_SET_UP).value)
        return (<Fragment>
            <div className="body-booking-detail-form">
                <Row>
                    <Cell>
                        <DisplayField name="customerName" upperCase={true} widthLabel={110} required={false}/>
                    </Cell>
                    <Cell>
                        <TextField
                            name="customer.phone"
                            type={'phone'}
                            required={false}
                            options={optionPhoneSetting}
                            isDisplay={true}
                            visibleIcon={false}/>
                    </Cell>
                    <Cell>
                        <DisplayField name="customer.email" widthLabel={110}/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <DisplayField name="technicians" widthLabel={110}/>
                    </Cell>
                    <Cell>
                        <DisplayField name="startTime" widthLabel={110} required={false}/>
                    </Cell>
                    <Cell>
                        <DisplayField name="endTime" widthLabel={110} required={false}/>
                    </Cell>
                </Row>
            </div>
            <div style={{marginTop: 10}}>
                {this.renderBookingCustomer(_.get(this.state.initialValues, 'guests'))}
            </div>
        </Fragment>)
    }
}

export default BookingDetailForm