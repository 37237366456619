/* eslint-disable react-hooks/exhaustive-deps */

import {useMergeState} from "@idtek/component/lib";
import _ from "lodash";
import {useEffect} from "react";
import CalculatePaymentUtil from "../../../utils/CalculatePaymentUtil";

const useCalculatePayment = ({
                                 guestList,
                                 cartList,
                                 hst,
                                 method,
                                 voucher,
                                 gift,
                                 moneyPoint,
                                 loyaltyType,
                                 objDiscount,
                                 tips,
                                 notLoad,
                                 isFromSaveBooking,
                                 isUseHst
                             }) => {
    const [state, setState] = useMergeState({
        subTotalPayment: 0,
        titleRowPM: "",
        valueRowPM: 0,
        valueAfterApplyPM: 0,
        valueRowHST: 0,
        valueRowTotalAmount: 0,
        textRowVoucher: "",
        valueRowVoucher: 0,
        loading: true
    });
    useEffect(() => {
        const subTotalPayment = _.sumBy(guestList, x => x.totalAmount) + _.sumBy(cartList, x => x.total);
        const option = {
            notLoad,
            subTotalPayment,
            isFromSaveBooking,
            isUseHst,
            hst,
            method,
            voucher,
            gift,
            moneyPoint,
            loyaltyType,
            objDiscount,
            setState
        }
        CalculatePaymentUtil.calculateAll(option);
    }, [
        guestList,
        hst,
        method,
        voucher,
        gift,
        moneyPoint,
        loyaltyType,
        objDiscount,
        tips,
        cartList,
        isUseHst
    ]);


    return state;
};

export default useCalculatePayment;