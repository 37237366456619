import React, {forwardRef, useImperativeHandle} from 'react';
import ComboPhoneComponent from "./ComboPhoneComponent";
import {IDField} from "@idtek/form";
import {useMergeState} from "@idtek/component/lib/hook/IDHook";
import Icon from "@idtek/component/lib/icon/Icon";
import TextField from "@idtek/form/lib/text/TextField";

const SelectPhoneFieldCustom = forwardRef(({
                                               name,
                                               onSetErrorField,
                                               onChange,
                                               branchId,
                                               options,
                                               tooltip,
                                               readOnly
                                           }, ref) => {
    const [state, setState] = useMergeState({
        optionVisible: true
    });
    useImperativeHandle(ref, () => ({
        setVisibleOption(val) {
            if (val === true) {
                setState({optionVisible: val})
            } else {
                setState({optionVisible: val})
            }
        },
    }));
    if (state.optionVisible) {
        return (
            <IDField name={name}
                     onSetErrorField={onSetErrorField}
                     onChange={onChange}
                     branchId={branchId}
                     type={"phone"}
                     required
                     options={options}
                     tooltip={tooltip}
                     readOnly={readOnly}

            >
                <ComboPhoneComponent/>
            </IDField>
        );
    }
    return <TextField
        name={name}
        onChange={(val) => onChange(val, true)}
        // defaultValue={_.get(value, "phone")}
        // value={_.get(value, "phone")}
        type={"phone"}
        icon={<Icon type="edit" style={{
            fontSize: 16,
            color: "#0d81c0",
            display: "flex",
            justifyContent: "center"
        }}/>}
        required
        options={options}
        style={{width: "100%"}}/>
});

export default SelectPhoneFieldCustom;