import React from 'react';
import _ from "lodash"
import { t } from '@idtek/component/lib/language';
import { CHANGE_STATUS_ACTION, DELETE_ACTION } from './../../../common/history/constant/HistoryConstant';

const TechnicianHistoryComponent = (data, className) => {
    let status = _.get(data, 'editInfo.status');
    if (status) {
        status = <>
            <span className='status-booking Completed'>
                {t("ProductListView-ACTIVE")}
            </span>
        </>
    } else {
        status = <>
            <div className='status-booking Cancel'>
                {t("ProductListView-INACTIVE")}
            </div>
        </>
    }

    let type = _.get(data, "action");
    if (type === CHANGE_STATUS_ACTION) {
        const oldStatus = _.get(data, "editInfo.oldStatus", "");
        const newStatus = _.get(data, "editInfo.newStatus", "");

        return (
            <>
                <div className={`grid grid-cols-2 color-children-${className}`}>
                    <div className='col-span-2'>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("ServiceForm-ACTION")}:
                            </div>
                            <div className='value' style={{ fontStyle: "italic" }}>
                                {`${t("BaseListView-UPDATE")} ${t("TechnicianListView-STATUS").toLowerCase()} ${t("SchedulerComponent-FROM")} ${oldStatus ? t("TechnicianListView-ACTIVE").toLowerCase() : t("TechnicianListView-INACTIVE").toLowerCase()} ${t("TechnicianHistoryComponent-TO")} ${newStatus ? t("TechnicianListView-ACTIVE").toLowerCase() : t("TechnicianListView-INACTIVE").toLowerCase()}`}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    if (type === DELETE_ACTION) {
        return (
            <>
                <div className={`grid grid-cols-2 color-children-${className}`}>
                    <div className='col-span-2'>
                        <div className='flex'>
                            <div className='text-label'>
                                {t("ServiceForm-ACTION")}:
                            </div>
                            <div className='value' style={{ fontStyle: "italic" }}>
                                {t("TechnicianHistoryComponent-TECHNICIAN_WAS_DELETED")}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <div className={`grid grid-cols-3 color-children-${className}`}>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-NAME")}:
                        </div>
                        <div className='value'>
                            {_.get(data, "editInfo.name", "")}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-GENDER")}:
                        </div>
                        <div className='value'>
                            {t(`TechnicianForm-${_.get(data, "editInfo.gender", "")}`)}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-STATUS")}:
                        </div>
                        <div className='value'>
                            {status}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-SKILL")}:
                        </div>
                        <div className='value'>
                            {_.get(data, "editInfo.skills", 0)}
                        </div>
                    </div>
                </div>
                {/* <div className='col-span-2'>
                    <div className='flex'>
                        <div className='text-label'>
                            {t("TechnicianListView-CREATED_TIME")}:
                        </div>
                        <div className='value'>
                            {createdTime}
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default TechnicianHistoryComponent;