import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from "react";
import Collapse from "@idtek/component/lib/collapse/Collapse";
import Icon from "@idtek/component/lib/icon/Icon";
import _ from "lodash";
import Button from "@idtek/component/lib/button/IDButton";
import SearchAndSelectComponent from "./SearchAndSelectComponent";
import GridDetailGift from "./GridDetailGift";
import { useAddCartFormContext } from "../context/AddCartFormContext";
import AppStore from "../../../store";
import GridDetailProduct from "./GridDetailProduct";
import AppUtil from "../../../utils/AppUtil";
import TenantPricingKeyConstant from "../constant/TenantPricingKeyConstant";
import { t } from "@idtek/component/lib/language";

const {Panel} = Collapse;

const AddOrderComponent = forwardRef(({
                                          addGiftToBill,
                                          onCreateGiftSuccess,
                                          addProductToBill,
                                          onCreateProductSuccess,
                                      }, ref) => {
    const {isDetail, giftCardList} = useAddCartFormContext()
    const refGridGift = useRef(null),
        refGridProduct = useRef(null),
        refSearchGift = useRef(null),
        refSearchProduct = useRef(null)
    const rightsPM = _.get(AppStore.getState(), `root.rightsPM`, []);
    const pricingRightProduct = AppUtil.checkPermission(TenantPricingKeyConstant.PRODUCT, rightsPM)
    const pricingRightGift = AppUtil.checkPermission(TenantPricingKeyConstant.GIFT, rightsPM)
    useImperativeHandle(ref, () => ({
        getDataProduct() {
            return getDataStoreProduct()
        },
    }));

    const getDataStoreProduct = () => {
        return refGridProduct.current && refGridProduct.current.getDataStore()
    }
    const onChangeGift = (giftId) => {
        refSearchGift.current && refSearchGift.current.setValue(giftId)
        addGiftToBill && addGiftToBill(giftId)
    }

    const onChangeProduct = (productId) => {
        refSearchProduct.current && refSearchProduct.current.setValue(productId)
        addProductToBill && addProductToBill(productId)
    }

    const onCreateGiftSuccessCustom = (id) => {
        refSearchGift.current && refSearchGift.current.pushNewValue([id])
        onCreateGiftSuccess && onCreateGiftSuccess(id)
    }

    const onCreateProductSuccessCustom = (productId) => {
        refSearchProduct.current && refSearchProduct.current.pushNewValue([productId])
        onCreateProductSuccess && onCreateProductSuccess(productId)
    }

    const checkDefaultKey = () => {
        if (isDetail && _.size(giftCardList) > 0) {
            return ["1"]
        }
        return []
    }

    const customRowGuest = (data) => {
        return <div className="row-add-more">
            <div>
                {data.content + " (" + data.code + ")"}
            </div>
        </div>;
    };

    const customRowProduct = (data) => {
        return <div className="row-add-more">
            <div>
                {data.name + " (" + data.code + ")"}
            </div>
        </div>;
    };

    return (
        <div style={{marginTop: 10}}>
            <div>
                <Collapse
                    // bordered={false}
                    defaultActiveKey={checkDefaultKey()}
                    expandIcon={({isActive}) => {
                        let icon = <Icon type="plus-square" style={{fontSize: 16}}/>
                        if (isActive) {
                            icon = <Icon type="minus-square" style={{fontSize: 16}}/>
                        }
                        return <div>{icon}</div>
                    }}
                    expandIconPosition={"right"}
                    className="site-collapse-custom-collapse"
                >
                    {pricingRightGift &&
                        <Panel header={t('AddOrderComponent-ADD_GIFT_CARD_TO_ORDER')} key="1" className="site-collapse-custom-panel">
                            <div style={{display: "flex", flex: 1, fontSize: 12, alignItems: "center"}}>
                                <div className="combo-search-guest" style={{flex: 1}}>
                                    <SearchAndSelectComponent
                                        ref={refSearchGift}
                                        urlSearch={"/gift-card/get-options-to-add-cart"}
                                        params={{
                                            branchId: _.get(AppStore.getState(), `root.branch.id`, null),
                                        }}
                                        onChange={onChangeGift}
                                        placeholder={`${t("AddOrderComponent-SEARCH_AND_CHOOSE_GIFT")}...`}
                                        customRow={customRowGuest}
                                    />
                                </div>
                                <div style={{margin: "0px 10px"}}> {t("AddOrderComponent-OR")} </div>
                                <Button
                                    className={"btn-create-custom"}
                                    style={{display: 'flex', alignItems: "center"}}
                                    type={"primary"}
                                    icon={<Icon type="plus-square" style={{fontSize: 16, marginRight: 8, marginTop: -2}}/>}
                                    onClick={() => refGridGift.current && refGridGift.current.onCreate()}
                                >
                                    {t("AddOrderComponent-CREATE")}
                                </Button>
                            </div>
                            <GridDetailGift ref={refGridGift} onChangeGift={onChangeGift}
                                            onCreateGiftSuccess={onCreateGiftSuccessCustom}/>
                        </Panel>}
                    {pricingRightProduct &&
                        <Panel header={t('AddOrderComponent-ADD_PRODUCT_CARD_TO_ORDER')} key="2" className="site-collapse-custom-panel"
                               style={{marginTop: 5}}>
                            <div style={{display: "flex", flex: 1, fontSize: 12, alignItems: "center"}}>
                                <div className="combo-search-guest" style={{flex: 1}}>
                                    <SearchAndSelectComponent
                                        ref={refSearchProduct}
                                        urlSearch={"/product/get-options-to-add-cart"}
                                        params={{
                                            branchId: _.get(AppStore.getState(), `root.branch.id`, null),
                                        }}
                                        onChange={onChangeProduct}
                                        placeholder={`${t("AddOrderComponent-SEARCH_AND_CHOOSE_PRODUCT")}...`}
                                        customRow={customRowProduct}
                                    />
                                </div>
                                <div style={{margin: "0px 10px"}}>{t("AddOrderComponent-OR")}</div>
                                <Button
                                    className={"btn-create-custom"}
                                    style={{display: 'flex', alignItems: "center"}}
                                    type={"primary"}
                                    icon={<Icon type="plus-square" style={{fontSize: 16, marginRight: 8, marginTop: -2}}/>}
                                    onClick={() => refGridProduct.current && refGridProduct.current.onCreate()}
                                >
                                    {t("AddOrderComponent-CREATE")}
                                </Button>
                            </div>
                            <GridDetailProduct ref={refGridProduct} onChange={onChangeProduct}
                                               onCreateSuccess={onCreateProductSuccessCustom}
                            />
                        </Panel>
                    }
                </Collapse>
            </div>
        </div>
    );
});

export default AddOrderComponent;