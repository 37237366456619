import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import NotPermission from '../../pages/NotPermission';
import AppUtil from '../../utils/AppUtil';
import RegisterFeature from "../../pages/RegisterFeature";

class BasePermission extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.rightAccess = props.rightAccess ? props.rightAccess : "";
    }

    render() {
        const {rights, children, rightsPM} = this.props;
        const validRight = AppUtil.checkPermission(this.rightAccess, rights);
        const validPM = AppUtil.checkPermission(this.rightAccess, rightsPM);
        if (!validPM) {
            return <RegisterFeature/>
        }
        return (
            <Fragment>
                {validRight ? children : <NotPermission/>}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const rights = state.root.rights;
    const rightsPM = state.root.rightsPM;

    return {
        rights: rights,
        rightsPM
    };
}

export default connect(
    mapStateToProps
)(BasePermission);
